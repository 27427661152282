// source: pbtypes/vehicle/skills/image_waypoint.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_vehicle_skills_obstacle_avoidance_pb = require('../../../pbtypes/vehicle/skills/obstacle_avoidance_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_obstacle_avoidance_pb);
var pbtypes_vehicle_skills_waypoints_pb = require('../../../pbtypes/vehicle/skills/waypoints_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_waypoints_pb);
var pbtypes_gen_body_trans_pb = require('../../../pbtypes/gen/body/trans_pb.js');
goog.object.extend(proto, pbtypes_gen_body_trans_pb);
var pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb = require('../../../pbtypes/vehicle/nav_cam_reconstruction/pose_id_pb.js');
goog.object.extend(proto, pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb);
goog.exportSymbol('proto.skills.ImageWaypoint', null, global);
goog.exportSymbol('proto.skills.NcpgInfo', null, global);
goog.exportSymbol('proto.skills.WaypointPanoramaMode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.NcpgInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.NcpgInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.NcpgInfo.displayName = 'proto.skills.NcpgInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.ImageWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.ImageWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.ImageWaypoint.displayName = 'proto.skills.ImageWaypoint';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.NcpgInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.NcpgInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.NcpgInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
poseId: (f = msg.getPoseId()) && pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.toObject(includeInstance, f),
defaultNavTrans: (f = msg.getDefaultNavTrans()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
defaultNavTakeoffTPoseId: (f = msg.getDefaultNavTakeoffTPoseId()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.NcpgInfo}
 */
proto.skills.NcpgInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.NcpgInfo;
  return proto.skills.NcpgInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.NcpgInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.NcpgInfo}
 */
proto.skills.NcpgInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb;
      reader.readMessage(value,pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.deserializeBinaryFromReader);
      msg.setPoseId(value);
      break;
    case 2:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setDefaultNavTrans(value);
      break;
    case 3:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setDefaultNavTakeoffTPoseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.NcpgInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.NcpgInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.NcpgInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoseId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.serializeBinaryToWriter
    );
  }
  f = message.getDefaultNavTrans();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getDefaultNavTakeoffTPoseId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
};


/**
 * optional nav_cam_reconstruction.PoseIdPb pose_id = 1;
 * @return {?proto.nav_cam_reconstruction.PoseIdPb}
 */
proto.skills.NcpgInfo.prototype.getPoseId = function() {
  return /** @type{?proto.nav_cam_reconstruction.PoseIdPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb, 1));
};


/**
 * @param {?proto.nav_cam_reconstruction.PoseIdPb|undefined} value
 * @return {!proto.skills.NcpgInfo} returns this
*/
proto.skills.NcpgInfo.prototype.setPoseId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.NcpgInfo} returns this
 */
proto.skills.NcpgInfo.prototype.clearPoseId = function() {
  return this.setPoseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.NcpgInfo.prototype.hasPoseId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional body.TransPb default_nav_trans = 2;
 * @return {?proto.body.TransPb}
 */
proto.skills.NcpgInfo.prototype.getDefaultNavTrans = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 2));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.skills.NcpgInfo} returns this
*/
proto.skills.NcpgInfo.prototype.setDefaultNavTrans = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.NcpgInfo} returns this
 */
proto.skills.NcpgInfo.prototype.clearDefaultNavTrans = function() {
  return this.setDefaultNavTrans(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.NcpgInfo.prototype.hasDefaultNavTrans = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional body.TransPb default_nav_takeoff_T_pose_id = 3;
 * @return {?proto.body.TransPb}
 */
proto.skills.NcpgInfo.prototype.getDefaultNavTakeoffTPoseId = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 3));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.skills.NcpgInfo} returns this
*/
proto.skills.NcpgInfo.prototype.setDefaultNavTakeoffTPoseId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.NcpgInfo} returns this
 */
proto.skills.NcpgInfo.prototype.clearDefaultNavTakeoffTPoseId = function() {
  return this.setDefaultNavTakeoffTPoseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.NcpgInfo.prototype.hasDefaultNavTakeoffTPoseId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.ImageWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.ImageWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.ImageWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ImageWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 7, ""),
waypoint: (f = msg.getWaypoint()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
timedWaitSeconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
subjectPhoto: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
navPanorama: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
subjectPanorama: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
ncpgInfo: (f = msg.getNcpgInfo()) && proto.skills.NcpgInfo.toObject(includeInstance, f),
transitOaSetting: jspb.Message.getFieldWithDefault(msg, 9, 0),
recordVideo: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
panoramaMode: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.ImageWaypoint}
 */
proto.skills.ImageWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.ImageWaypoint;
  return proto.skills.ImageWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.ImageWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.ImageWaypoint}
 */
proto.skills.ImageWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 1:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimedWaitSeconds(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubjectPhoto(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNavPanorama(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubjectPanorama(value);
      break;
    case 8:
      var value = new proto.skills.NcpgInfo;
      reader.readMessage(value,proto.skills.NcpgInfo.deserializeBinaryFromReader);
      msg.setNcpgInfo(value);
      break;
    case 9:
      var value = /** @type {!proto.skills.ObstacleAvoidanceEnum.Enum} */ (reader.readEnum());
      msg.setTransitOaSetting(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecordVideo(value);
      break;
    case 11:
      var value = /** @type {!proto.skills.WaypointPanoramaMode} */ (reader.readEnum());
      msg.setPanoramaMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.ImageWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.ImageWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.ImageWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ImageWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getTimedWaitSeconds();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getSubjectPhoto();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getNavPanorama();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSubjectPanorama();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getNcpgInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.skills.NcpgInfo.serializeBinaryToWriter
    );
  }
  f = message.getTransitOaSetting();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getRecordVideo();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPanoramaMode();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.skills.ImageWaypoint.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.ImageWaypoint} returns this
 */
proto.skills.ImageWaypoint.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Waypoint waypoint = 1;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.ImageWaypoint.prototype.getWaypoint = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 1));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.ImageWaypoint} returns this
*/
proto.skills.ImageWaypoint.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ImageWaypoint} returns this
 */
proto.skills.ImageWaypoint.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ImageWaypoint.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float timed_wait_seconds = 3;
 * @return {number}
 */
proto.skills.ImageWaypoint.prototype.getTimedWaitSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.ImageWaypoint} returns this
 */
proto.skills.ImageWaypoint.prototype.setTimedWaitSeconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool subject_photo = 6;
 * @return {boolean}
 */
proto.skills.ImageWaypoint.prototype.getSubjectPhoto = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.ImageWaypoint} returns this
 */
proto.skills.ImageWaypoint.prototype.setSubjectPhoto = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool nav_panorama = 4;
 * @return {boolean}
 */
proto.skills.ImageWaypoint.prototype.getNavPanorama = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.ImageWaypoint} returns this
 */
proto.skills.ImageWaypoint.prototype.setNavPanorama = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool subject_panorama = 5;
 * @return {boolean}
 */
proto.skills.ImageWaypoint.prototype.getSubjectPanorama = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.ImageWaypoint} returns this
 */
proto.skills.ImageWaypoint.prototype.setSubjectPanorama = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional NcpgInfo ncpg_info = 8;
 * @return {?proto.skills.NcpgInfo}
 */
proto.skills.ImageWaypoint.prototype.getNcpgInfo = function() {
  return /** @type{?proto.skills.NcpgInfo} */ (
    jspb.Message.getWrapperField(this, proto.skills.NcpgInfo, 8));
};


/**
 * @param {?proto.skills.NcpgInfo|undefined} value
 * @return {!proto.skills.ImageWaypoint} returns this
*/
proto.skills.ImageWaypoint.prototype.setNcpgInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ImageWaypoint} returns this
 */
proto.skills.ImageWaypoint.prototype.clearNcpgInfo = function() {
  return this.setNcpgInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ImageWaypoint.prototype.hasNcpgInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ObstacleAvoidanceEnum.Enum transit_oa_setting = 9;
 * @return {!proto.skills.ObstacleAvoidanceEnum.Enum}
 */
proto.skills.ImageWaypoint.prototype.getTransitOaSetting = function() {
  return /** @type {!proto.skills.ObstacleAvoidanceEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.skills.ObstacleAvoidanceEnum.Enum} value
 * @return {!proto.skills.ImageWaypoint} returns this
 */
proto.skills.ImageWaypoint.prototype.setTransitOaSetting = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bool record_video = 10;
 * @return {boolean}
 */
proto.skills.ImageWaypoint.prototype.getRecordVideo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.ImageWaypoint} returns this
 */
proto.skills.ImageWaypoint.prototype.setRecordVideo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional WaypointPanoramaMode panorama_mode = 11;
 * @return {!proto.skills.WaypointPanoramaMode}
 */
proto.skills.ImageWaypoint.prototype.getPanoramaMode = function() {
  return /** @type {!proto.skills.WaypointPanoramaMode} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.skills.WaypointPanoramaMode} value
 * @return {!proto.skills.ImageWaypoint} returns this
 */
proto.skills.ImageWaypoint.prototype.setPanoramaMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * @enum {number}
 */
proto.skills.WaypointPanoramaMode = {
  PANO_UNSPECIFIED: 0,
  HORIZONTAL: 1,
  VERTICAL: 2,
  SPHERE: 3
};

goog.object.extend(exports, proto.skills);
