// source: pbtypes/gen/ambassador/flight_state.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.ambassador.FlightState', null, global);
goog.exportSymbol('proto.ambassador.FlightState.Enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ambassador.FlightState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ambassador.FlightState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ambassador.FlightState.displayName = 'proto.ambassador.FlightState';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ambassador.FlightState.prototype.toObject = function(opt_includeInstance) {
  return proto.ambassador.FlightState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ambassador.FlightState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.FlightState.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ambassador.FlightState}
 */
proto.ambassador.FlightState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ambassador.FlightState;
  return proto.ambassador.FlightState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ambassador.FlightState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ambassador.FlightState}
 */
proto.ambassador.FlightState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ambassador.FlightState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ambassador.FlightState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ambassador.FlightState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ambassador.FlightState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ambassador.FlightState.Enum = {
  INVALID: 0,
  UNKNOWN: 1,
  REST: 101,
  PREP: 102,
  WAIT_FOR_ARM: 103,
  LOGGING_START: 112,
  FLIGHT_PROCESSES_CHECK: 104,
  SAFETY_CHECK: 105,
  DEPRECATED_GROUND_TAKEOFF_READY: 106,
  DEPRECATED_GROUND_TAKEOFF_CHECK: 110,
  HAND_TAKEOFF_CHECK: 111,
  HAND_TAKEOFF_READY: 108,
  MANUAL_TAKEOFF_READY: 109,
  PERCH: 116,
  TAKEOFF_PREP: 107,
  TAKING_OFF: 301,
  TAKEOFF_SUBJECT_LOCKING: 302,
  FLYING_UNKNOWN: 200,
  FLYING_SUBJECT_NONE: 210,
  FLYING_SUBJECT_LOST: 211,
  FLYING_SUBJECT_FOLLOWING: 213,
  FLYING_SUBJECT_RTP: 215,
  FLYING_SUBJECT_SPOOFED: 217,
  FLYING_SUBJECT_PHONE_GPS: 218,
  FLYING_SUBJECT_LOOKAT: 219,
  FLYING_MANUAL: 220,
  FLYING_HOVER: 230,
  FLYING_TO_WAYPOINT: 231,
  SMART_LAND_PREP: 312,
  LANDING_WITH_AVOIDANCE: 313,
  PAUSED_FOR_FINAL_DESCENT: 314,
  LANDING: 303,
  CASE_LANDING: 304,
  RETURNING_TO_DOCK: 350,
  RETURNING_TO_LAUNCH: 352,
  RETURNING_TO_SAFE_LANDING: 354,
  DOCK_LANDING: 351,
  ARMED_IN_DOCK_UNKNOWN: 113,
  ARMED_IN_DOCK_INSPECTION: 114,
  ARMED_IN_DOCK_TAKEOFF_CANCELED: 115,
  ARMED_IN_DOCK_POST_FLIGHT: 353,
  POST_FLIGHT: 150,
  FAILED_TAKEOFF: 401,
  EMERGENCY_LANDING: 402,
  ABORTING_LANDING: 403,
  PILOTED_E_LANDING: 404,
  COMMUNICATION_LOST: 10,
  COMMUNICATION_LOST_IN_FLIGHT: 20,
  COMMUNICATION_LOST_RTP: 40,
  COMMUNICATION_LOST_RTH: 42,
  COMMUNICATION_LOST_RTL: 45,
  COMMUNICATION_LOST_WITH_JOYSTICKS: 43,
  COMMUNICATION_LOST_WITH_BEACON: 44
};

goog.object.extend(exports, proto.ambassador);
