// source: pbtypes/vehicle/complimentary_actions/complimentary_actions_impls/spawn_complimentary_actions_on_hook/spawn_complimentary_actions_on_hook.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_util_protobuf_util_comparison_operator_pb = require('../../../../../pbtypes/util/protobuf_util/comparison_operator_pb.js');
goog.object.extend(proto, pbtypes_util_protobuf_util_comparison_operator_pb);
goog.exportSymbol('proto.complimentary_actions.SpawnComplimentaryActionHook', null, global);
goog.exportSymbol('proto.complimentary_actions.SpawnComplimentaryActionHook.ChannelCallbackHookSpecCase', null, global);
goog.exportSymbol('proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec', null, global);
goog.exportSymbol('proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition', null, global);
goog.exportSymbol('proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.ConditionCase', null, global);
goog.exportSymbol('proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress', null, global);
goog.exportSymbol('proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.complimentary_actions.SpawnComplimentaryActionHook = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.complimentary_actions.SpawnComplimentaryActionHook.oneofGroups_);
};
goog.inherits(proto.complimentary_actions.SpawnComplimentaryActionHook, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.complimentary_actions.SpawnComplimentaryActionHook.displayName = 'proto.complimentary_actions.SpawnComplimentaryActionHook';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.repeatedFields_, null);
};
goog.inherits(proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.displayName = 'proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.oneofGroups_);
};
goog.inherits(proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.displayName = 'proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.displayName = 'proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.displayName = 'proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.ChannelCallbackHookSpecCase = {
  CHANNEL_CALLBACK_HOOK_SPEC_NOT_SET: 0,
  WAYPOINT_STATUS_HOOK_SPEC: 1
};

/**
 * @return {proto.complimentary_actions.SpawnComplimentaryActionHook.ChannelCallbackHookSpecCase}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.prototype.getChannelCallbackHookSpecCase = function() {
  return /** @type {proto.complimentary_actions.SpawnComplimentaryActionHook.ChannelCallbackHookSpecCase} */(jspb.Message.computeOneofCase(this, proto.complimentary_actions.SpawnComplimentaryActionHook.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.prototype.toObject = function(opt_includeInstance) {
  return proto.complimentary_actions.SpawnComplimentaryActionHook.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.toObject = function(includeInstance, msg) {
  var f, obj = {
waypointStatusHookSpec: (f = msg.getWaypointStatusHookSpec()) && proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.complimentary_actions.SpawnComplimentaryActionHook;
  return proto.complimentary_actions.SpawnComplimentaryActionHook.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec;
      reader.readMessage(value,proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.deserializeBinaryFromReader);
      msg.setWaypointStatusHookSpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.complimentary_actions.SpawnComplimentaryActionHook.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypointStatusHookSpec();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.prototype.toObject = function(opt_includeInstance) {
  return proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.toObject = function(includeInstance, msg) {
  var f, obj = {
conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec;
  return proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition;
      reader.readMessage(value,proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.ConditionCase = {
  CONDITION_NOT_SET: 0,
  PROGRESS_CONDITION: 1,
  WAYPOINT_NONCE_COMPARED_TO_REGISTERED_UTIME: 2
};

/**
 * @return {proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.ConditionCase}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.prototype.getConditionCase = function() {
  return /** @type {proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.ConditionCase} */(jspb.Message.computeOneofCase(this, proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.prototype.toObject = function(opt_includeInstance) {
  return proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.toObject = function(includeInstance, msg) {
  var f, obj = {
progressCondition: (f = msg.getProgressCondition()) && proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.toObject(includeInstance, f),
waypointNonceComparedToRegisteredUtime: (f = msg.getWaypointNonceComparedToRegisteredUtime()) && proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition;
  return proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress;
      reader.readMessage(value,proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.deserializeBinaryFromReader);
      msg.setProgressCondition(value);
      break;
    case 2:
      var value = new proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime;
      reader.readMessage(value,proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.deserializeBinaryFromReader);
      msg.setWaypointNonceComparedToRegisteredUtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgressCondition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.serializeBinaryToWriter
    );
  }
  f = message.getWaypointNonceComparedToRegisteredUtime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.prototype.toObject = function(opt_includeInstance) {
  return proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.toObject = function(includeInstance, msg) {
  var f, obj = {
comp: jspb.Message.getFieldWithDefault(msg, 1, 0),
value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress;
  return proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.protobuf_util.Op.Enum} */ (reader.readEnum());
      msg.setComp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComp();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional protobuf_util.Op.Enum comp = 1;
 * @return {!proto.protobuf_util.Op.Enum}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.prototype.getComp = function() {
  return /** @type {!proto.protobuf_util.Op.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.protobuf_util.Op.Enum} value
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress} returns this
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.prototype.setComp = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float value = 2;
 * @return {number}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress} returns this
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.prototype.toObject = function(opt_includeInstance) {
  return proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.toObject = function(includeInstance, msg) {
  var f, obj = {
comp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime;
  return proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.protobuf_util.Op.Enum} */ (reader.readEnum());
      msg.setComp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComp();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional protobuf_util.Op.Enum comp = 1;
 * @return {!proto.protobuf_util.Op.Enum}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.prototype.getComp = function() {
  return /** @type {!proto.protobuf_util.Op.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.protobuf_util.Op.Enum} value
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime} returns this
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime.prototype.setComp = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Progress progress_condition = 1;
 * @return {?proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.prototype.getProgressCondition = function() {
  return /** @type{?proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress} */ (
    jspb.Message.getWrapperField(this, proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress, 1));
};


/**
 * @param {?proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.Progress|undefined} value
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition} returns this
*/
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.prototype.setProgressCondition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition} returns this
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.prototype.clearProgressCondition = function() {
  return this.setProgressCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.prototype.hasProgressCondition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WaypointNonceComparedToRegisteredUtime waypoint_nonce_compared_to_registered_utime = 2;
 * @return {?proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.prototype.getWaypointNonceComparedToRegisteredUtime = function() {
  return /** @type{?proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime} */ (
    jspb.Message.getWrapperField(this, proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime, 2));
};


/**
 * @param {?proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.WaypointNonceComparedToRegisteredUtime|undefined} value
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition} returns this
*/
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.prototype.setWaypointNonceComparedToRegisteredUtime = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition} returns this
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.prototype.clearWaypointNonceComparedToRegisteredUtime = function() {
  return this.setWaypointNonceComparedToRegisteredUtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition.prototype.hasWaypointNonceComparedToRegisteredUtime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Condition conditions = 1;
 * @return {!Array<!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition>}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition, 1));
};


/**
 * @param {!Array<!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition>} value
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec} returns this
*/
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.Condition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec} returns this
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


/**
 * optional WaypointStatusHookSpec waypoint_status_hook_spec = 1;
 * @return {?proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.prototype.getWaypointStatusHookSpec = function() {
  return /** @type{?proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec} */ (
    jspb.Message.getWrapperField(this, proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec, 1));
};


/**
 * @param {?proto.complimentary_actions.SpawnComplimentaryActionHook.WaypointStatusHookSpec|undefined} value
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook} returns this
*/
proto.complimentary_actions.SpawnComplimentaryActionHook.prototype.setWaypointStatusHookSpec = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.complimentary_actions.SpawnComplimentaryActionHook.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionHook} returns this
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.prototype.clearWaypointStatusHookSpec = function() {
  return this.setWaypointStatusHookSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.SpawnComplimentaryActionHook.prototype.hasWaypointStatusHookSpec = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.complimentary_actions);
