// source: pbtypes/gen/surface_scan/user_controls.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.surface_scan.UserControls', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.surface_scan.UserControls = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.surface_scan.UserControls, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.surface_scan.UserControls.displayName = 'proto.surface_scan.UserControls';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.surface_scan.UserControls.prototype.toObject = function(opt_includeInstance) {
  return proto.surface_scan.UserControls.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.surface_scan.UserControls} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.surface_scan.UserControls.toObject = function(includeInstance, msg) {
  var f, obj = {
disableZoomSlider: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
disableUserZoomInput: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
disableTapToFocus: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
disableCameraSettings: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
disableManualFocus: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.surface_scan.UserControls}
 */
proto.surface_scan.UserControls.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.surface_scan.UserControls;
  return proto.surface_scan.UserControls.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.surface_scan.UserControls} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.surface_scan.UserControls}
 */
proto.surface_scan.UserControls.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableZoomSlider(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableUserZoomInput(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableTapToFocus(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableCameraSettings(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableManualFocus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.surface_scan.UserControls.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.surface_scan.UserControls.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.surface_scan.UserControls} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.surface_scan.UserControls.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisableZoomSlider();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDisableUserZoomInput();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDisableTapToFocus();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDisableCameraSettings();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDisableManualFocus();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional bool disable_zoom_slider = 1;
 * @return {boolean}
 */
proto.surface_scan.UserControls.prototype.getDisableZoomSlider = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.UserControls} returns this
 */
proto.surface_scan.UserControls.prototype.setDisableZoomSlider = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool disable_user_zoom_input = 2;
 * @return {boolean}
 */
proto.surface_scan.UserControls.prototype.getDisableUserZoomInput = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.UserControls} returns this
 */
proto.surface_scan.UserControls.prototype.setDisableUserZoomInput = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool disable_tap_to_focus = 3;
 * @return {boolean}
 */
proto.surface_scan.UserControls.prototype.getDisableTapToFocus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.UserControls} returns this
 */
proto.surface_scan.UserControls.prototype.setDisableTapToFocus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool disable_camera_settings = 4;
 * @return {boolean}
 */
proto.surface_scan.UserControls.prototype.getDisableCameraSettings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.UserControls} returns this
 */
proto.surface_scan.UserControls.prototype.setDisableCameraSettings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool disable_manual_focus = 5;
 * @return {boolean}
 */
proto.surface_scan.UserControls.prototype.getDisableManualFocus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.surface_scan.UserControls} returns this
 */
proto.surface_scan.UserControls.prototype.setDisableManualFocus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


goog.object.extend(exports, proto.surface_scan);
