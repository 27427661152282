// source: pbtypes/vehicle/skills/actions/controls_test/controls_test.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_eigen_lcm_Vector3f_pb = require('../../../../../pbtypes/gen/eigen_lcm/Vector3f_pb.js');
goog.object.extend(proto, pbtypes_gen_eigen_lcm_Vector3f_pb);
goog.exportSymbol('proto.skills.AtvSubjectSpoofActionArgs', null, global);
goog.exportSymbol('proto.skills.AtvSubjectSpoofActionStateData', null, global);
goog.exportSymbol('proto.skills.AtvSubjectSpoofResultData', null, global);
goog.exportSymbol('proto.skills.MovementApiSettings', null, global);
goog.exportSymbol('proto.skills.PrecomputedSubjectSpoofActionArgs', null, global);
goog.exportSymbol('proto.skills.PrecomputedSubjectSpoofActionStateData', null, global);
goog.exportSymbol('proto.skills.PrecomputedSubjectSpoofResultData', null, global);
goog.exportSymbol('proto.skills.SetGimbalOrientationActionStateData', null, global);
goog.exportSymbol('proto.skills.SetGimbalOrientationArgs', null, global);
goog.exportSymbol('proto.skills.SetGimbalOrientationResultData', null, global);
goog.exportSymbol('proto.skills.SkiSubjectSpoofActionArgs', null, global);
goog.exportSymbol('proto.skills.SkiSubjectSpoofActionStateData', null, global);
goog.exportSymbol('proto.skills.SkiSubjectSpoofResultData', null, global);
goog.exportSymbol('proto.skills.TestDesiredVelocityActionArgs', null, global);
goog.exportSymbol('proto.skills.TestDesiredVelocityActionStateData', null, global);
goog.exportSymbol('proto.skills.TestDesiredVelocityResultData', null, global);
goog.exportSymbol('proto.skills.TestTrackTrajectoryActionArgs', null, global);
goog.exportSymbol('proto.skills.TestTrackTrajectoryActionStateData', null, global);
goog.exportSymbol('proto.skills.TestTrackTrajectoryResultData', null, global);
goog.exportSymbol('proto.skills.TrackTrajectoryWaypoint', null, global);
goog.exportSymbol('proto.skills.VelocityFrame', null, global);
goog.exportSymbol('proto.skills.VelocityFrame.Enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MovementApiSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MovementApiSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MovementApiSettings.displayName = 'proto.skills.MovementApiSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.AtvSubjectSpoofActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.AtvSubjectSpoofActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.AtvSubjectSpoofActionArgs.displayName = 'proto.skills.AtvSubjectSpoofActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SkiSubjectSpoofActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SkiSubjectSpoofActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SkiSubjectSpoofActionArgs.displayName = 'proto.skills.SkiSubjectSpoofActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PrecomputedSubjectSpoofActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.PrecomputedSubjectSpoofActionArgs.repeatedFields_, null);
};
goog.inherits(proto.skills.PrecomputedSubjectSpoofActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PrecomputedSubjectSpoofActionArgs.displayName = 'proto.skills.PrecomputedSubjectSpoofActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.VelocityFrame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.VelocityFrame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.VelocityFrame.displayName = 'proto.skills.VelocityFrame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SetGimbalOrientationArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SetGimbalOrientationArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SetGimbalOrientationArgs.displayName = 'proto.skills.SetGimbalOrientationArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TestDesiredVelocityActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.TestDesiredVelocityActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TestDesiredVelocityActionArgs.displayName = 'proto.skills.TestDesiredVelocityActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TrackTrajectoryWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.TrackTrajectoryWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TrackTrajectoryWaypoint.displayName = 'proto.skills.TrackTrajectoryWaypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TestTrackTrajectoryActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.TestTrackTrajectoryActionArgs.repeatedFields_, null);
};
goog.inherits(proto.skills.TestTrackTrajectoryActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TestTrackTrajectoryActionArgs.displayName = 'proto.skills.TestTrackTrajectoryActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TestTrackTrajectoryResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.TestTrackTrajectoryResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TestTrackTrajectoryResultData.displayName = 'proto.skills.TestTrackTrajectoryResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TestDesiredVelocityResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.TestDesiredVelocityResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TestDesiredVelocityResultData.displayName = 'proto.skills.TestDesiredVelocityResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.AtvSubjectSpoofResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.AtvSubjectSpoofResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.AtvSubjectSpoofResultData.displayName = 'proto.skills.AtvSubjectSpoofResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SkiSubjectSpoofResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SkiSubjectSpoofResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SkiSubjectSpoofResultData.displayName = 'proto.skills.SkiSubjectSpoofResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PrecomputedSubjectSpoofResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.PrecomputedSubjectSpoofResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PrecomputedSubjectSpoofResultData.displayName = 'proto.skills.PrecomputedSubjectSpoofResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SetGimbalOrientationResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SetGimbalOrientationResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SetGimbalOrientationResultData.displayName = 'proto.skills.SetGimbalOrientationResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TestTrackTrajectoryActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.TestTrackTrajectoryActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TestTrackTrajectoryActionStateData.displayName = 'proto.skills.TestTrackTrajectoryActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TestDesiredVelocityActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.TestDesiredVelocityActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TestDesiredVelocityActionStateData.displayName = 'proto.skills.TestDesiredVelocityActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.AtvSubjectSpoofActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.AtvSubjectSpoofActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.AtvSubjectSpoofActionStateData.displayName = 'proto.skills.AtvSubjectSpoofActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SkiSubjectSpoofActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SkiSubjectSpoofActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SkiSubjectSpoofActionStateData.displayName = 'proto.skills.SkiSubjectSpoofActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PrecomputedSubjectSpoofActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.PrecomputedSubjectSpoofActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PrecomputedSubjectSpoofActionStateData.displayName = 'proto.skills.PrecomputedSubjectSpoofActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SetGimbalOrientationActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SetGimbalOrientationActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SetGimbalOrientationActionStateData.displayName = 'proto.skills.SetGimbalOrientationActionStateData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MovementApiSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MovementApiSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MovementApiSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MovementApiSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
maxSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
maxHeadingRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
maxGimbalPitchRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
overridePrecisionFlight: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MovementApiSettings}
 */
proto.skills.MovementApiSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MovementApiSettings;
  return proto.skills.MovementApiSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MovementApiSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MovementApiSettings}
 */
proto.skills.MovementApiSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxSpeed(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxHeadingRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxGimbalPitchRate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverridePrecisionFlight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MovementApiSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MovementApiSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MovementApiSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MovementApiSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getMaxHeadingRate();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMaxGimbalPitchRate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getOverridePrecisionFlight();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional float max_speed = 1;
 * @return {number}
 */
proto.skills.MovementApiSettings.prototype.getMaxSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MovementApiSettings} returns this
 */
proto.skills.MovementApiSettings.prototype.setMaxSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float max_heading_rate = 2;
 * @return {number}
 */
proto.skills.MovementApiSettings.prototype.getMaxHeadingRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MovementApiSettings} returns this
 */
proto.skills.MovementApiSettings.prototype.setMaxHeadingRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float max_gimbal_pitch_rate = 3;
 * @return {number}
 */
proto.skills.MovementApiSettings.prototype.getMaxGimbalPitchRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.MovementApiSettings} returns this
 */
proto.skills.MovementApiSettings.prototype.setMaxGimbalPitchRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool override_precision_flight = 4;
 * @return {boolean}
 */
proto.skills.MovementApiSettings.prototype.getOverridePrecisionFlight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.MovementApiSettings} returns this
 */
proto.skills.MovementApiSettings.prototype.setOverridePrecisionFlight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.AtvSubjectSpoofActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.AtvSubjectSpoofActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.AtvSubjectSpoofActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
numLoops: jspb.Message.getFieldWithDefault(msg, 1, 0),
accel: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
decel: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
minSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
maxSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
length: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
width: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
altitudeOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
forward: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
reverse: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.AtvSubjectSpoofActionArgs}
 */
proto.skills.AtvSubjectSpoofActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.AtvSubjectSpoofActionArgs;
  return proto.skills.AtvSubjectSpoofActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.AtvSubjectSpoofActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.AtvSubjectSpoofActionArgs}
 */
proto.skills.AtvSubjectSpoofActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumLoops(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAccel(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDecel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinSpeed(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxSpeed(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLength(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWidth(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRadius(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAltitudeOffset(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForward(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReverse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.AtvSubjectSpoofActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.AtvSubjectSpoofActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.AtvSubjectSpoofActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumLoops();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAccel();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDecel();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getMinSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMaxSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getLength();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getAltitudeOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getForward();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getReverse();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int32 num_loops = 1;
 * @return {number}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.getNumLoops = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.AtvSubjectSpoofActionArgs} returns this
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.setNumLoops = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float accel = 2;
 * @return {number}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.getAccel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.AtvSubjectSpoofActionArgs} returns this
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.setAccel = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float decel = 3;
 * @return {number}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.getDecel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.AtvSubjectSpoofActionArgs} returns this
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.setDecel = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float min_speed = 4;
 * @return {number}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.getMinSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.AtvSubjectSpoofActionArgs} returns this
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.setMinSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float max_speed = 5;
 * @return {number}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.getMaxSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.AtvSubjectSpoofActionArgs} returns this
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.setMaxSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float length = 6;
 * @return {number}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.AtvSubjectSpoofActionArgs} returns this
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.setLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float width = 7;
 * @return {number}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.AtvSubjectSpoofActionArgs} returns this
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float radius = 8;
 * @return {number}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.AtvSubjectSpoofActionArgs} returns this
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float altitude_offset = 9;
 * @return {number}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.getAltitudeOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.AtvSubjectSpoofActionArgs} returns this
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.setAltitudeOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional bool forward = 10;
 * @return {boolean}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.getForward = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.AtvSubjectSpoofActionArgs} returns this
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.setForward = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool reverse = 11;
 * @return {boolean}
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.getReverse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.AtvSubjectSpoofActionArgs} returns this
 */
proto.skills.AtvSubjectSpoofActionArgs.prototype.setReverse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SkiSubjectSpoofActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SkiSubjectSpoofActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SkiSubjectSpoofActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
length: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
numberOfTurns: jspb.Message.getFieldWithDefault(msg, 2, 0),
width: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
slopeAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
minSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
maxSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
altitudeOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SkiSubjectSpoofActionArgs}
 */
proto.skills.SkiSubjectSpoofActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SkiSubjectSpoofActionArgs;
  return proto.skills.SkiSubjectSpoofActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SkiSubjectSpoofActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SkiSubjectSpoofActionArgs}
 */
proto.skills.SkiSubjectSpoofActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLength(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfTurns(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSlopeAngle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinSpeed(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxSpeed(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAltitudeOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SkiSubjectSpoofActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SkiSubjectSpoofActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SkiSubjectSpoofActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLength();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getNumberOfTurns();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getSlopeAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMinSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMaxSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getAltitudeOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional float length = 1;
 * @return {number}
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SkiSubjectSpoofActionArgs} returns this
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.setLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int32 number_of_turns = 2;
 * @return {number}
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.getNumberOfTurns = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SkiSubjectSpoofActionArgs} returns this
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.setNumberOfTurns = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float width = 3;
 * @return {number}
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SkiSubjectSpoofActionArgs} returns this
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float slope_angle = 4;
 * @return {number}
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.getSlopeAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SkiSubjectSpoofActionArgs} returns this
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.setSlopeAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float min_speed = 5;
 * @return {number}
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.getMinSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SkiSubjectSpoofActionArgs} returns this
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.setMinSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float max_speed = 6;
 * @return {number}
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.getMaxSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SkiSubjectSpoofActionArgs} returns this
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.setMaxSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float altitude_offset = 7;
 * @return {number}
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.getAltitudeOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SkiSubjectSpoofActionArgs} returns this
 */
proto.skills.SkiSubjectSpoofActionArgs.prototype.setAltitudeOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PrecomputedSubjectSpoofActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PrecomputedSubjectSpoofActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
numLoops: jspb.Message.getFieldWithDefault(msg, 1, 0),
timesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject, includeInstance),
velocitiesList: jspb.Message.toObjectList(msg.getVelocitiesList(),
    pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject, includeInstance),
azimuth: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
range: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PrecomputedSubjectSpoofActionArgs;
  return proto.skills.PrecomputedSubjectSpoofActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PrecomputedSubjectSpoofActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumLoops(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTimes(values[i]);
      }
      break;
    case 3:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    case 4:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.addVelocities(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAzimuth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PrecomputedSubjectSpoofActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PrecomputedSubjectSpoofActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumLoops();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTimesList();
  if (f.length > 0) {
    writer.writePackedFloat(
      2,
      f
    );
  }
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getVelocitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getAzimuth();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional int32 num_loops = 1;
 * @return {number}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.getNumLoops = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs} returns this
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.setNumLoops = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated float times = 2;
 * @return {!Array<number>}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.getTimesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs} returns this
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.setTimesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs} returns this
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.addTimes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs} returns this
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.clearTimesList = function() {
  return this.setTimesList([]);
};


/**
 * repeated eigen_lcm.Vector3fPb positions = 3;
 * @return {!Array<!proto.eigen_lcm.Vector3fPb>}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.eigen_lcm.Vector3fPb>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 3));
};


/**
 * @param {!Array<!proto.eigen_lcm.Vector3fPb>} value
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs} returns this
*/
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.eigen_lcm.Vector3fPb=} opt_value
 * @param {number=} opt_index
 * @return {!proto.eigen_lcm.Vector3fPb}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.eigen_lcm.Vector3fPb, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs} returns this
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};


/**
 * repeated eigen_lcm.Vector3fPb velocities = 4;
 * @return {!Array<!proto.eigen_lcm.Vector3fPb>}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.getVelocitiesList = function() {
  return /** @type{!Array<!proto.eigen_lcm.Vector3fPb>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 4));
};


/**
 * @param {!Array<!proto.eigen_lcm.Vector3fPb>} value
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs} returns this
*/
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.setVelocitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.eigen_lcm.Vector3fPb=} opt_value
 * @param {number=} opt_index
 * @return {!proto.eigen_lcm.Vector3fPb}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.addVelocities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.eigen_lcm.Vector3fPb, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs} returns this
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.clearVelocitiesList = function() {
  return this.setVelocitiesList([]);
};


/**
 * optional float azimuth = 5;
 * @return {number}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.getAzimuth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs} returns this
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.setAzimuth = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float range = 6;
 * @return {number}
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.PrecomputedSubjectSpoofActionArgs} returns this
 */
proto.skills.PrecomputedSubjectSpoofActionArgs.prototype.setRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.VelocityFrame.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.VelocityFrame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.VelocityFrame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.VelocityFrame.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.VelocityFrame}
 */
proto.skills.VelocityFrame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.VelocityFrame;
  return proto.skills.VelocityFrame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.VelocityFrame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.VelocityFrame}
 */
proto.skills.VelocityFrame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.VelocityFrame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.VelocityFrame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.VelocityFrame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.VelocityFrame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.VelocityFrame.Enum = {
  NAV: 0,
  BODY: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SetGimbalOrientationArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SetGimbalOrientationArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SetGimbalOrientationArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SetGimbalOrientationArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
gimbalPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
gimbalRoll: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SetGimbalOrientationArgs}
 */
proto.skills.SetGimbalOrientationArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SetGimbalOrientationArgs;
  return proto.skills.SetGimbalOrientationArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SetGimbalOrientationArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SetGimbalOrientationArgs}
 */
proto.skills.SetGimbalOrientationArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGimbalPitch(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGimbalRoll(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SetGimbalOrientationArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SetGimbalOrientationArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SetGimbalOrientationArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SetGimbalOrientationArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGimbalPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getGimbalRoll();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float gimbal_pitch = 1;
 * @return {number}
 */
proto.skills.SetGimbalOrientationArgs.prototype.getGimbalPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SetGimbalOrientationArgs} returns this
 */
proto.skills.SetGimbalOrientationArgs.prototype.setGimbalPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float gimbal_roll = 2;
 * @return {number}
 */
proto.skills.SetGimbalOrientationArgs.prototype.getGimbalRoll = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SetGimbalOrientationArgs} returns this
 */
proto.skills.SetGimbalOrientationArgs.prototype.setGimbalRoll = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TestDesiredVelocityActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TestDesiredVelocityActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestDesiredVelocityActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
settings: (f = msg.getSettings()) && proto.skills.MovementApiSettings.toObject(includeInstance, f),
velocity: (f = msg.getVelocity()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
velocityFrame: jspb.Message.getFieldWithDefault(msg, 8, 0),
headingRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
pitchRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
durationSeconds: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
headingAtStart: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
waitForHeading: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
description: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TestDesiredVelocityActionArgs}
 */
proto.skills.TestDesiredVelocityActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TestDesiredVelocityActionArgs;
  return proto.skills.TestDesiredVelocityActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TestDesiredVelocityActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TestDesiredVelocityActionArgs}
 */
proto.skills.TestDesiredVelocityActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.MovementApiSettings;
      reader.readMessage(value,proto.skills.MovementApiSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 2:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setVelocity(value);
      break;
    case 8:
      var value = /** @type {!proto.skills.VelocityFrame.Enum} */ (reader.readEnum());
      msg.setVelocityFrame(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeadingRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPitchRate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDurationSeconds(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeadingAtStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWaitForHeading(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TestDesiredVelocityActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TestDesiredVelocityActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestDesiredVelocityActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.MovementApiSettings.serializeBinaryToWriter
    );
  }
  f = message.getVelocity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getVelocityFrame();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getHeadingRate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getPitchRate();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDurationSeconds();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getHeadingAtStart();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getWaitForHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional MovementApiSettings settings = 1;
 * @return {?proto.skills.MovementApiSettings}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.getSettings = function() {
  return /** @type{?proto.skills.MovementApiSettings} */ (
    jspb.Message.getWrapperField(this, proto.skills.MovementApiSettings, 1));
};


/**
 * @param {?proto.skills.MovementApiSettings|undefined} value
 * @return {!proto.skills.TestDesiredVelocityActionArgs} returns this
*/
proto.skills.TestDesiredVelocityActionArgs.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TestDesiredVelocityActionArgs} returns this
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional eigen_lcm.Vector3fPb velocity = 2;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.getVelocity = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 2));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.skills.TestDesiredVelocityActionArgs} returns this
*/
proto.skills.TestDesiredVelocityActionArgs.prototype.setVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TestDesiredVelocityActionArgs} returns this
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.clearVelocity = function() {
  return this.setVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.hasVelocity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional VelocityFrame.Enum velocity_frame = 8;
 * @return {!proto.skills.VelocityFrame.Enum}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.getVelocityFrame = function() {
  return /** @type {!proto.skills.VelocityFrame.Enum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.skills.VelocityFrame.Enum} value
 * @return {!proto.skills.TestDesiredVelocityActionArgs} returns this
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.setVelocityFrame = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional float heading_rate = 3;
 * @return {number}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.getHeadingRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TestDesiredVelocityActionArgs} returns this
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.setHeadingRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float pitch_rate = 4;
 * @return {number}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.getPitchRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TestDesiredVelocityActionArgs} returns this
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.setPitchRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float duration_seconds = 5;
 * @return {number}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.getDurationSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TestDesiredVelocityActionArgs} returns this
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.setDurationSeconds = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float heading_at_start = 6;
 * @return {number}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.getHeadingAtStart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TestDesiredVelocityActionArgs} returns this
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.setHeadingAtStart = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float wait_for_heading = 7;
 * @return {number}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.getWaitForHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TestDesiredVelocityActionArgs} returns this
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.setWaitForHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.TestDesiredVelocityActionArgs} returns this
 */
proto.skills.TestDesiredVelocityActionArgs.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TrackTrajectoryWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TrackTrajectoryWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TrackTrajectoryWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TrackTrajectoryWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
position: (f = msg.getPosition()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TrackTrajectoryWaypoint}
 */
proto.skills.TrackTrajectoryWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TrackTrajectoryWaypoint;
  return proto.skills.TrackTrajectoryWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TrackTrajectoryWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TrackTrajectoryWaypoint}
 */
proto.skills.TrackTrajectoryWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TrackTrajectoryWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TrackTrajectoryWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TrackTrajectoryWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TrackTrajectoryWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional eigen_lcm.Vector3fPb position = 1;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.skills.TrackTrajectoryWaypoint.prototype.getPosition = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 1));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.skills.TrackTrajectoryWaypoint} returns this
*/
proto.skills.TrackTrajectoryWaypoint.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TrackTrajectoryWaypoint} returns this
 */
proto.skills.TrackTrajectoryWaypoint.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TrackTrajectoryWaypoint.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float speed = 2;
 * @return {number}
 */
proto.skills.TrackTrajectoryWaypoint.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TrackTrajectoryWaypoint} returns this
 */
proto.skills.TrackTrajectoryWaypoint.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.TestTrackTrajectoryActionArgs.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TestTrackTrajectoryActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TestTrackTrajectoryActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestTrackTrajectoryActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
settings: (f = msg.getSettings()) && proto.skills.MovementApiSettings.toObject(includeInstance, f),
waypointsList: jspb.Message.toObjectList(msg.getWaypointsList(),
    proto.skills.TrackTrajectoryWaypoint.toObject, includeInstance),
positionAggressiveness: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
velocityAggressiveness: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
maxAcceleration: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TestTrackTrajectoryActionArgs}
 */
proto.skills.TestTrackTrajectoryActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TestTrackTrajectoryActionArgs;
  return proto.skills.TestTrackTrajectoryActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TestTrackTrajectoryActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TestTrackTrajectoryActionArgs}
 */
proto.skills.TestTrackTrajectoryActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.MovementApiSettings;
      reader.readMessage(value,proto.skills.MovementApiSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 2:
      var value = new proto.skills.TrackTrajectoryWaypoint;
      reader.readMessage(value,proto.skills.TrackTrajectoryWaypoint.deserializeBinaryFromReader);
      msg.addWaypoints(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPositionAggressiveness(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVelocityAggressiveness(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxAcceleration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TestTrackTrajectoryActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TestTrackTrajectoryActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestTrackTrajectoryActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.MovementApiSettings.serializeBinaryToWriter
    );
  }
  f = message.getWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.skills.TrackTrajectoryWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getPositionAggressiveness();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getVelocityAggressiveness();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMaxAcceleration();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional MovementApiSettings settings = 1;
 * @return {?proto.skills.MovementApiSettings}
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.getSettings = function() {
  return /** @type{?proto.skills.MovementApiSettings} */ (
    jspb.Message.getWrapperField(this, proto.skills.MovementApiSettings, 1));
};


/**
 * @param {?proto.skills.MovementApiSettings|undefined} value
 * @return {!proto.skills.TestTrackTrajectoryActionArgs} returns this
*/
proto.skills.TestTrackTrajectoryActionArgs.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TestTrackTrajectoryActionArgs} returns this
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TrackTrajectoryWaypoint waypoints = 2;
 * @return {!Array<!proto.skills.TrackTrajectoryWaypoint>}
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.getWaypointsList = function() {
  return /** @type{!Array<!proto.skills.TrackTrajectoryWaypoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.TrackTrajectoryWaypoint, 2));
};


/**
 * @param {!Array<!proto.skills.TrackTrajectoryWaypoint>} value
 * @return {!proto.skills.TestTrackTrajectoryActionArgs} returns this
*/
proto.skills.TestTrackTrajectoryActionArgs.prototype.setWaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.skills.TrackTrajectoryWaypoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.TrackTrajectoryWaypoint}
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.addWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.skills.TrackTrajectoryWaypoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.TestTrackTrajectoryActionArgs} returns this
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.clearWaypointsList = function() {
  return this.setWaypointsList([]);
};


/**
 * optional float position_aggressiveness = 3;
 * @return {number}
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.getPositionAggressiveness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TestTrackTrajectoryActionArgs} returns this
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.setPositionAggressiveness = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float velocity_aggressiveness = 4;
 * @return {number}
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.getVelocityAggressiveness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TestTrackTrajectoryActionArgs} returns this
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.setVelocityAggressiveness = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float max_acceleration = 5;
 * @return {number}
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.getMaxAcceleration = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TestTrackTrajectoryActionArgs} returns this
 */
proto.skills.TestTrackTrajectoryActionArgs.prototype.setMaxAcceleration = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TestTrackTrajectoryResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TestTrackTrajectoryResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TestTrackTrajectoryResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestTrackTrajectoryResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TestTrackTrajectoryResultData}
 */
proto.skills.TestTrackTrajectoryResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TestTrackTrajectoryResultData;
  return proto.skills.TestTrackTrajectoryResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TestTrackTrajectoryResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TestTrackTrajectoryResultData}
 */
proto.skills.TestTrackTrajectoryResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TestTrackTrajectoryResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TestTrackTrajectoryResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TestTrackTrajectoryResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestTrackTrajectoryResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TestDesiredVelocityResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TestDesiredVelocityResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TestDesiredVelocityResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestDesiredVelocityResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TestDesiredVelocityResultData}
 */
proto.skills.TestDesiredVelocityResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TestDesiredVelocityResultData;
  return proto.skills.TestDesiredVelocityResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TestDesiredVelocityResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TestDesiredVelocityResultData}
 */
proto.skills.TestDesiredVelocityResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TestDesiredVelocityResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TestDesiredVelocityResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TestDesiredVelocityResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestDesiredVelocityResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.AtvSubjectSpoofResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.AtvSubjectSpoofResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.AtvSubjectSpoofResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.AtvSubjectSpoofResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.AtvSubjectSpoofResultData}
 */
proto.skills.AtvSubjectSpoofResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.AtvSubjectSpoofResultData;
  return proto.skills.AtvSubjectSpoofResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.AtvSubjectSpoofResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.AtvSubjectSpoofResultData}
 */
proto.skills.AtvSubjectSpoofResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.AtvSubjectSpoofResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.AtvSubjectSpoofResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.AtvSubjectSpoofResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.AtvSubjectSpoofResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SkiSubjectSpoofResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SkiSubjectSpoofResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SkiSubjectSpoofResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SkiSubjectSpoofResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SkiSubjectSpoofResultData}
 */
proto.skills.SkiSubjectSpoofResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SkiSubjectSpoofResultData;
  return proto.skills.SkiSubjectSpoofResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SkiSubjectSpoofResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SkiSubjectSpoofResultData}
 */
proto.skills.SkiSubjectSpoofResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SkiSubjectSpoofResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SkiSubjectSpoofResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SkiSubjectSpoofResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SkiSubjectSpoofResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PrecomputedSubjectSpoofResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PrecomputedSubjectSpoofResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PrecomputedSubjectSpoofResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PrecomputedSubjectSpoofResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PrecomputedSubjectSpoofResultData}
 */
proto.skills.PrecomputedSubjectSpoofResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PrecomputedSubjectSpoofResultData;
  return proto.skills.PrecomputedSubjectSpoofResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PrecomputedSubjectSpoofResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PrecomputedSubjectSpoofResultData}
 */
proto.skills.PrecomputedSubjectSpoofResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PrecomputedSubjectSpoofResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PrecomputedSubjectSpoofResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PrecomputedSubjectSpoofResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PrecomputedSubjectSpoofResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SetGimbalOrientationResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SetGimbalOrientationResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SetGimbalOrientationResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SetGimbalOrientationResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SetGimbalOrientationResultData}
 */
proto.skills.SetGimbalOrientationResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SetGimbalOrientationResultData;
  return proto.skills.SetGimbalOrientationResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SetGimbalOrientationResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SetGimbalOrientationResultData}
 */
proto.skills.SetGimbalOrientationResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SetGimbalOrientationResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SetGimbalOrientationResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SetGimbalOrientationResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SetGimbalOrientationResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TestTrackTrajectoryActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TestTrackTrajectoryActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TestTrackTrajectoryActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestTrackTrajectoryActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TestTrackTrajectoryActionStateData}
 */
proto.skills.TestTrackTrajectoryActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TestTrackTrajectoryActionStateData;
  return proto.skills.TestTrackTrajectoryActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TestTrackTrajectoryActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TestTrackTrajectoryActionStateData}
 */
proto.skills.TestTrackTrajectoryActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TestTrackTrajectoryActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TestTrackTrajectoryActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TestTrackTrajectoryActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestTrackTrajectoryActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TestDesiredVelocityActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TestDesiredVelocityActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TestDesiredVelocityActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestDesiredVelocityActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TestDesiredVelocityActionStateData}
 */
proto.skills.TestDesiredVelocityActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TestDesiredVelocityActionStateData;
  return proto.skills.TestDesiredVelocityActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TestDesiredVelocityActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TestDesiredVelocityActionStateData}
 */
proto.skills.TestDesiredVelocityActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TestDesiredVelocityActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TestDesiredVelocityActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TestDesiredVelocityActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TestDesiredVelocityActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.AtvSubjectSpoofActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.AtvSubjectSpoofActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.AtvSubjectSpoofActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.AtvSubjectSpoofActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.AtvSubjectSpoofActionStateData}
 */
proto.skills.AtvSubjectSpoofActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.AtvSubjectSpoofActionStateData;
  return proto.skills.AtvSubjectSpoofActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.AtvSubjectSpoofActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.AtvSubjectSpoofActionStateData}
 */
proto.skills.AtvSubjectSpoofActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.AtvSubjectSpoofActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.AtvSubjectSpoofActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.AtvSubjectSpoofActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.AtvSubjectSpoofActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SkiSubjectSpoofActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SkiSubjectSpoofActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SkiSubjectSpoofActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SkiSubjectSpoofActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SkiSubjectSpoofActionStateData}
 */
proto.skills.SkiSubjectSpoofActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SkiSubjectSpoofActionStateData;
  return proto.skills.SkiSubjectSpoofActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SkiSubjectSpoofActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SkiSubjectSpoofActionStateData}
 */
proto.skills.SkiSubjectSpoofActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SkiSubjectSpoofActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SkiSubjectSpoofActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SkiSubjectSpoofActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SkiSubjectSpoofActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PrecomputedSubjectSpoofActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PrecomputedSubjectSpoofActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PrecomputedSubjectSpoofActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PrecomputedSubjectSpoofActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PrecomputedSubjectSpoofActionStateData}
 */
proto.skills.PrecomputedSubjectSpoofActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PrecomputedSubjectSpoofActionStateData;
  return proto.skills.PrecomputedSubjectSpoofActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PrecomputedSubjectSpoofActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PrecomputedSubjectSpoofActionStateData}
 */
proto.skills.PrecomputedSubjectSpoofActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PrecomputedSubjectSpoofActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PrecomputedSubjectSpoofActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PrecomputedSubjectSpoofActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PrecomputedSubjectSpoofActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SetGimbalOrientationActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SetGimbalOrientationActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SetGimbalOrientationActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SetGimbalOrientationActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SetGimbalOrientationActionStateData}
 */
proto.skills.SetGimbalOrientationActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SetGimbalOrientationActionStateData;
  return proto.skills.SetGimbalOrientationActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SetGimbalOrientationActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SetGimbalOrientationActionStateData}
 */
proto.skills.SetGimbalOrientationActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SetGimbalOrientationActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SetGimbalOrientationActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SetGimbalOrientationActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SetGimbalOrientationActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.skills);
