// source: pbtypes/vehicle/skills/builtin_skills/surface_scan/surface_scan.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_vehicle_skills_ui_workflow_pb = require('../../../../../pbtypes/vehicle/skills/ui_workflow_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_ui_workflow_pb);
var pbtypes_gen_surface_scan_inspection_parameters_pb = require('../../../../../pbtypes/gen/surface_scan/inspection_parameters_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_inspection_parameters_pb);
var pbtypes_gen_surface_scan_settings_manager_pb = require('../../../../../pbtypes/gen/surface_scan/settings_manager_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_settings_manager_pb);
goog.exportSymbol('proto.skills.SurfaceScanActionArgs', null, global);
goog.exportSymbol('proto.skills.SurfaceScanActionStateData', null, global);
goog.exportSymbol('proto.skills.SurfaceScanResultData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SurfaceScanActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.SurfaceScanActionArgs.repeatedFields_, null);
};
goog.inherits(proto.skills.SurfaceScanActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SurfaceScanActionArgs.displayName = 'proto.skills.SurfaceScanActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SurfaceScanResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SurfaceScanResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SurfaceScanResultData.displayName = 'proto.skills.SurfaceScanResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SurfaceScanActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SurfaceScanActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SurfaceScanActionStateData.displayName = 'proto.skills.SurfaceScanActionStateData';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.SurfaceScanActionArgs.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SurfaceScanActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SurfaceScanActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SurfaceScanActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SurfaceScanActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
inspectionParameters: (f = msg.getInspectionParameters()) && pbtypes_gen_surface_scan_inspection_parameters_pb.InspectionParameters.toObject(includeInstance, f),
settingsManager: (f = msg.getSettingsManager()) && pbtypes_gen_surface_scan_settings_manager_pb.SettingsManager.toObject(includeInstance, f),
settingsKeysList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
scanSkillStateId: jspb.Message.getFieldWithDefault(msg, 5, ""),
resumeScan: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
siteName: jspb.Message.getFieldWithDefault(msg, 7, ""),
saveName: jspb.Message.getFieldWithDefault(msg, 8, ""),
saveUclock: jspb.Message.getFieldWithDefault(msg, 9, 0),
stats: (f = msg.getStats()) && pbtypes_vehicle_skills_ui_workflow_pb.UiStatsView.toObject(includeInstance, f),
scanModeLabel: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SurfaceScanActionArgs}
 */
proto.skills.SurfaceScanActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SurfaceScanActionArgs;
  return proto.skills.SurfaceScanActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SurfaceScanActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SurfaceScanActionArgs}
 */
proto.skills.SurfaceScanActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_surface_scan_inspection_parameters_pb.InspectionParameters;
      reader.readMessage(value,pbtypes_gen_surface_scan_inspection_parameters_pb.InspectionParameters.deserializeBinaryFromReader);
      msg.setInspectionParameters(value);
      break;
    case 3:
      var value = new pbtypes_gen_surface_scan_settings_manager_pb.SettingsManager;
      reader.readMessage(value,pbtypes_gen_surface_scan_settings_manager_pb.SettingsManager.deserializeBinaryFromReader);
      msg.setSettingsManager(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSettingsKeys(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanSkillStateId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResumeScan(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSaveName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSaveUclock(value);
      break;
    case 10:
      var value = new pbtypes_vehicle_skills_ui_workflow_pb.UiStatsView;
      reader.readMessage(value,pbtypes_vehicle_skills_ui_workflow_pb.UiStatsView.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanModeLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SurfaceScanActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SurfaceScanActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SurfaceScanActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SurfaceScanActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspectionParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_surface_scan_inspection_parameters_pb.InspectionParameters.serializeBinaryToWriter
    );
  }
  f = message.getSettingsManager();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_surface_scan_settings_manager_pb.SettingsManager.serializeBinaryToWriter
    );
  }
  f = message.getSettingsKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getScanSkillStateId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getResumeScan();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSaveName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSaveUclock();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      pbtypes_vehicle_skills_ui_workflow_pb.UiStatsView.serializeBinaryToWriter
    );
  }
  f = message.getScanModeLabel();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional surface_scan.InspectionParameters inspection_parameters = 1;
 * @return {?proto.surface_scan.InspectionParameters}
 */
proto.skills.SurfaceScanActionArgs.prototype.getInspectionParameters = function() {
  return /** @type{?proto.surface_scan.InspectionParameters} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_surface_scan_inspection_parameters_pb.InspectionParameters, 1));
};


/**
 * @param {?proto.surface_scan.InspectionParameters|undefined} value
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
*/
proto.skills.SurfaceScanActionArgs.prototype.setInspectionParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.clearInspectionParameters = function() {
  return this.setInspectionParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.SurfaceScanActionArgs.prototype.hasInspectionParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional surface_scan.SettingsManager settings_manager = 3;
 * @return {?proto.surface_scan.SettingsManager}
 */
proto.skills.SurfaceScanActionArgs.prototype.getSettingsManager = function() {
  return /** @type{?proto.surface_scan.SettingsManager} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_surface_scan_settings_manager_pb.SettingsManager, 3));
};


/**
 * @param {?proto.surface_scan.SettingsManager|undefined} value
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
*/
proto.skills.SurfaceScanActionArgs.prototype.setSettingsManager = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.clearSettingsManager = function() {
  return this.setSettingsManager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.SurfaceScanActionArgs.prototype.hasSettingsManager = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string settings_keys = 4;
 * @return {!Array<string>}
 */
proto.skills.SurfaceScanActionArgs.prototype.getSettingsKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.setSettingsKeysList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.addSettingsKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.clearSettingsKeysList = function() {
  return this.setSettingsKeysList([]);
};


/**
 * optional string scan_skill_state_id = 5;
 * @return {string}
 */
proto.skills.SurfaceScanActionArgs.prototype.getScanSkillStateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.setScanSkillStateId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool resume_scan = 6;
 * @return {boolean}
 */
proto.skills.SurfaceScanActionArgs.prototype.getResumeScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.setResumeScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string site_name = 7;
 * @return {string}
 */
proto.skills.SurfaceScanActionArgs.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string save_name = 8;
 * @return {string}
 */
proto.skills.SurfaceScanActionArgs.prototype.getSaveName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.setSaveName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 save_uclock = 9;
 * @return {number}
 */
proto.skills.SurfaceScanActionArgs.prototype.getSaveUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.setSaveUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional UiStatsView stats = 10;
 * @return {?proto.skills.UiStatsView}
 */
proto.skills.SurfaceScanActionArgs.prototype.getStats = function() {
  return /** @type{?proto.skills.UiStatsView} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_ui_workflow_pb.UiStatsView, 10));
};


/**
 * @param {?proto.skills.UiStatsView|undefined} value
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
*/
proto.skills.SurfaceScanActionArgs.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.SurfaceScanActionArgs.prototype.hasStats = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string scan_mode_label = 11;
 * @return {string}
 */
proto.skills.SurfaceScanActionArgs.prototype.getScanModeLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.SurfaceScanActionArgs} returns this
 */
proto.skills.SurfaceScanActionArgs.prototype.setScanModeLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SurfaceScanResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SurfaceScanResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SurfaceScanResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SurfaceScanResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SurfaceScanResultData}
 */
proto.skills.SurfaceScanResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SurfaceScanResultData;
  return proto.skills.SurfaceScanResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SurfaceScanResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SurfaceScanResultData}
 */
proto.skills.SurfaceScanResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SurfaceScanResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SurfaceScanResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SurfaceScanResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SurfaceScanResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SurfaceScanActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SurfaceScanActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SurfaceScanActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SurfaceScanActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SurfaceScanActionStateData}
 */
proto.skills.SurfaceScanActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SurfaceScanActionStateData;
  return proto.skills.SurfaceScanActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SurfaceScanActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SurfaceScanActionStateData}
 */
proto.skills.SurfaceScanActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SurfaceScanActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SurfaceScanActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SurfaceScanActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SurfaceScanActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.skills);
