// source: pbtypes/vehicle/skills/actions/orbit_poi/orbit_poi.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_vehicle_skills_waypoints_pb = require('../../../../../pbtypes/vehicle/skills/waypoints_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_waypoints_pb);
goog.exportSymbol('proto.skills.GotoAndOrbitPoiActionArgs', null, global);
goog.exportSymbol('proto.skills.GotoAndOrbitPoiResultData', null, global);
goog.exportSymbol('proto.skills.InterceptOrbitPoiMode', null, global);
goog.exportSymbol('proto.skills.InterceptOrbitPoiMode.Enum', null, global);
goog.exportSymbol('proto.skills.OrbitPoiActionArgs', null, global);
goog.exportSymbol('proto.skills.OrbitPoiActionStateData', null, global);
goog.exportSymbol('proto.skills.OrbitPoiResultData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.OrbitPoiActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.OrbitPoiActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.OrbitPoiActionArgs.displayName = 'proto.skills.OrbitPoiActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.OrbitPoiResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.OrbitPoiResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.OrbitPoiResultData.displayName = 'proto.skills.OrbitPoiResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.OrbitPoiActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.OrbitPoiActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.OrbitPoiActionStateData.displayName = 'proto.skills.OrbitPoiActionStateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.InterceptOrbitPoiMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.InterceptOrbitPoiMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.InterceptOrbitPoiMode.displayName = 'proto.skills.InterceptOrbitPoiMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoAndOrbitPoiActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GotoAndOrbitPoiActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoAndOrbitPoiActionArgs.displayName = 'proto.skills.GotoAndOrbitPoiActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GotoAndOrbitPoiResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GotoAndOrbitPoiResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GotoAndOrbitPoiResultData.displayName = 'proto.skills.GotoAndOrbitPoiResultData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.OrbitPoiActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.OrbitPoiActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.OrbitPoiActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.OrbitPoiActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
orbitCenter: (f = msg.getOrbitCenter()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
numRotations: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
gimbalPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
hideAr: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.OrbitPoiActionArgs}
 */
proto.skills.OrbitPoiActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.OrbitPoiActionArgs;
  return proto.skills.OrbitPoiActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.OrbitPoiActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.OrbitPoiActionArgs}
 */
proto.skills.OrbitPoiActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setOrbitCenter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRadius(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNumRotations(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGimbalPitch(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideAr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.OrbitPoiActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.OrbitPoiActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.OrbitPoiActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.OrbitPoiActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrbitCenter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getNumRotations();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getGimbalPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getHideAr();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional Waypoint orbit_center = 1;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.OrbitPoiActionArgs.prototype.getOrbitCenter = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 1));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.OrbitPoiActionArgs} returns this
*/
proto.skills.OrbitPoiActionArgs.prototype.setOrbitCenter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.OrbitPoiActionArgs} returns this
 */
proto.skills.OrbitPoiActionArgs.prototype.clearOrbitCenter = function() {
  return this.setOrbitCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.OrbitPoiActionArgs.prototype.hasOrbitCenter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float radius = 2;
 * @return {number}
 */
proto.skills.OrbitPoiActionArgs.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.OrbitPoiActionArgs} returns this
 */
proto.skills.OrbitPoiActionArgs.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float speed = 3;
 * @return {number}
 */
proto.skills.OrbitPoiActionArgs.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.OrbitPoiActionArgs} returns this
 */
proto.skills.OrbitPoiActionArgs.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float num_rotations = 4;
 * @return {number}
 */
proto.skills.OrbitPoiActionArgs.prototype.getNumRotations = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.OrbitPoiActionArgs} returns this
 */
proto.skills.OrbitPoiActionArgs.prototype.setNumRotations = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float gimbal_pitch = 5;
 * @return {number}
 */
proto.skills.OrbitPoiActionArgs.prototype.getGimbalPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.OrbitPoiActionArgs} returns this
 */
proto.skills.OrbitPoiActionArgs.prototype.setGimbalPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool hide_ar = 6;
 * @return {boolean}
 */
proto.skills.OrbitPoiActionArgs.prototype.getHideAr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.OrbitPoiActionArgs} returns this
 */
proto.skills.OrbitPoiActionArgs.prototype.setHideAr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.OrbitPoiResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.OrbitPoiResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.OrbitPoiResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.OrbitPoiResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.OrbitPoiResultData}
 */
proto.skills.OrbitPoiResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.OrbitPoiResultData;
  return proto.skills.OrbitPoiResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.OrbitPoiResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.OrbitPoiResultData}
 */
proto.skills.OrbitPoiResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.OrbitPoiResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.OrbitPoiResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.OrbitPoiResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.OrbitPoiResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.OrbitPoiActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.OrbitPoiActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.OrbitPoiActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.OrbitPoiActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {
actionArgs: (f = msg.getActionArgs()) && proto.skills.OrbitPoiActionArgs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.OrbitPoiActionStateData}
 */
proto.skills.OrbitPoiActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.OrbitPoiActionStateData;
  return proto.skills.OrbitPoiActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.OrbitPoiActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.OrbitPoiActionStateData}
 */
proto.skills.OrbitPoiActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.OrbitPoiActionArgs;
      reader.readMessage(value,proto.skills.OrbitPoiActionArgs.deserializeBinaryFromReader);
      msg.setActionArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.OrbitPoiActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.OrbitPoiActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.OrbitPoiActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.OrbitPoiActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionArgs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.OrbitPoiActionArgs.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrbitPoiActionArgs action_args = 1;
 * @return {?proto.skills.OrbitPoiActionArgs}
 */
proto.skills.OrbitPoiActionStateData.prototype.getActionArgs = function() {
  return /** @type{?proto.skills.OrbitPoiActionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.OrbitPoiActionArgs, 1));
};


/**
 * @param {?proto.skills.OrbitPoiActionArgs|undefined} value
 * @return {!proto.skills.OrbitPoiActionStateData} returns this
*/
proto.skills.OrbitPoiActionStateData.prototype.setActionArgs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.OrbitPoiActionStateData} returns this
 */
proto.skills.OrbitPoiActionStateData.prototype.clearActionArgs = function() {
  return this.setActionArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.OrbitPoiActionStateData.prototype.hasActionArgs = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.InterceptOrbitPoiMode.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.InterceptOrbitPoiMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.InterceptOrbitPoiMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.InterceptOrbitPoiMode.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.InterceptOrbitPoiMode}
 */
proto.skills.InterceptOrbitPoiMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.InterceptOrbitPoiMode;
  return proto.skills.InterceptOrbitPoiMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.InterceptOrbitPoiMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.InterceptOrbitPoiMode}
 */
proto.skills.InterceptOrbitPoiMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.InterceptOrbitPoiMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.InterceptOrbitPoiMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.InterceptOrbitPoiMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.InterceptOrbitPoiMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.InterceptOrbitPoiMode.Enum = {
  UNSPECIFIED: 0,
  PERPENDICULAR: 1,
  TANGENTIAL: 2,
  CUSTOM: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoAndOrbitPoiActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoAndOrbitPoiActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoAndOrbitPoiActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
orbitArgs: (f = msg.getOrbitArgs()) && proto.skills.OrbitPoiActionArgs.toObject(includeInstance, f),
interceptMode: jspb.Message.getFieldWithDefault(msg, 2, 0),
motionArgs: (f = msg.getMotionArgs()) && pbtypes_vehicle_skills_waypoints_pb.MotionArgs.toObject(includeInstance, f),
customIntercept: (f = msg.getCustomIntercept()) && pbtypes_vehicle_skills_waypoints_pb.Waypoint.toObject(includeInstance, f),
waypointFreeLookMode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoAndOrbitPoiActionArgs}
 */
proto.skills.GotoAndOrbitPoiActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoAndOrbitPoiActionArgs;
  return proto.skills.GotoAndOrbitPoiActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoAndOrbitPoiActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoAndOrbitPoiActionArgs}
 */
proto.skills.GotoAndOrbitPoiActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.OrbitPoiActionArgs;
      reader.readMessage(value,proto.skills.OrbitPoiActionArgs.deserializeBinaryFromReader);
      msg.setOrbitArgs(value);
      break;
    case 2:
      var value = /** @type {!proto.skills.InterceptOrbitPoiMode.Enum} */ (reader.readEnum());
      msg.setInterceptMode(value);
      break;
    case 3:
      var value = new pbtypes_vehicle_skills_waypoints_pb.MotionArgs;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.MotionArgs.deserializeBinaryFromReader);
      msg.setMotionArgs(value);
      break;
    case 4:
      var value = new pbtypes_vehicle_skills_waypoints_pb.Waypoint;
      reader.readMessage(value,pbtypes_vehicle_skills_waypoints_pb.Waypoint.deserializeBinaryFromReader);
      msg.setCustomIntercept(value);
      break;
    case 5:
      var value = /** @type {!proto.skills.FreeLookMode.Enum} */ (reader.readEnum());
      msg.setWaypointFreeLookMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoAndOrbitPoiActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoAndOrbitPoiActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoAndOrbitPoiActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrbitArgs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.OrbitPoiActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getInterceptMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMotionArgs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_vehicle_skills_waypoints_pb.MotionArgs.serializeBinaryToWriter
    );
  }
  f = message.getCustomIntercept();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_vehicle_skills_waypoints_pb.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getWaypointFreeLookMode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional OrbitPoiActionArgs orbit_args = 1;
 * @return {?proto.skills.OrbitPoiActionArgs}
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.getOrbitArgs = function() {
  return /** @type{?proto.skills.OrbitPoiActionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.OrbitPoiActionArgs, 1));
};


/**
 * @param {?proto.skills.OrbitPoiActionArgs|undefined} value
 * @return {!proto.skills.GotoAndOrbitPoiActionArgs} returns this
*/
proto.skills.GotoAndOrbitPoiActionArgs.prototype.setOrbitArgs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GotoAndOrbitPoiActionArgs} returns this
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.clearOrbitArgs = function() {
  return this.setOrbitArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.hasOrbitArgs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InterceptOrbitPoiMode.Enum intercept_mode = 2;
 * @return {!proto.skills.InterceptOrbitPoiMode.Enum}
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.getInterceptMode = function() {
  return /** @type {!proto.skills.InterceptOrbitPoiMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.skills.InterceptOrbitPoiMode.Enum} value
 * @return {!proto.skills.GotoAndOrbitPoiActionArgs} returns this
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.setInterceptMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional MotionArgs motion_args = 3;
 * @return {?proto.skills.MotionArgs}
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.getMotionArgs = function() {
  return /** @type{?proto.skills.MotionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.MotionArgs, 3));
};


/**
 * @param {?proto.skills.MotionArgs|undefined} value
 * @return {!proto.skills.GotoAndOrbitPoiActionArgs} returns this
*/
proto.skills.GotoAndOrbitPoiActionArgs.prototype.setMotionArgs = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GotoAndOrbitPoiActionArgs} returns this
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.clearMotionArgs = function() {
  return this.setMotionArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.hasMotionArgs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Waypoint custom_intercept = 4;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.getCustomIntercept = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_waypoints_pb.Waypoint, 4));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.GotoAndOrbitPoiActionArgs} returns this
*/
proto.skills.GotoAndOrbitPoiActionArgs.prototype.setCustomIntercept = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GotoAndOrbitPoiActionArgs} returns this
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.clearCustomIntercept = function() {
  return this.setCustomIntercept(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.hasCustomIntercept = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FreeLookMode.Enum waypoint_free_look_mode = 5;
 * @return {!proto.skills.FreeLookMode.Enum}
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.getWaypointFreeLookMode = function() {
  return /** @type {!proto.skills.FreeLookMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.skills.FreeLookMode.Enum} value
 * @return {!proto.skills.GotoAndOrbitPoiActionArgs} returns this
 */
proto.skills.GotoAndOrbitPoiActionArgs.prototype.setWaypointFreeLookMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GotoAndOrbitPoiResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GotoAndOrbitPoiResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GotoAndOrbitPoiResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoAndOrbitPoiResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GotoAndOrbitPoiResultData}
 */
proto.skills.GotoAndOrbitPoiResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GotoAndOrbitPoiResultData;
  return proto.skills.GotoAndOrbitPoiResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GotoAndOrbitPoiResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GotoAndOrbitPoiResultData}
 */
proto.skills.GotoAndOrbitPoiResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GotoAndOrbitPoiResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GotoAndOrbitPoiResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GotoAndOrbitPoiResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GotoAndOrbitPoiResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.skills);
