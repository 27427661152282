// source: pbtypes/experimental/webrtc_c_impl/webrtc_stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.webrtc.BandwidthUpdateStats', null, global);
goog.exportSymbol('proto.webrtc.ChannelStats', null, global);
goog.exportSymbol('proto.webrtc.HostConnectionStats', null, global);
goog.exportSymbol('proto.webrtc.IceCandidateStats', null, global);
goog.exportSymbol('proto.webrtc.IceServerStats', null, global);
goog.exportSymbol('proto.webrtc.JitterBufferStats', null, global);
goog.exportSymbol('proto.webrtc.LivekitClientStats', null, global);
goog.exportSymbol('proto.webrtc.LivekitHealthStats', null, global);
goog.exportSymbol('proto.webrtc.PeerConnectionStats', null, global);
goog.exportSymbol('proto.webrtc.RateStatistic', null, global);
goog.exportSymbol('proto.webrtc.RealtimeStatistic', null, global);
goog.exportSymbol('proto.webrtc.RtpStats', null, global);
goog.exportSymbol('proto.webrtc.StreamStats', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.RateStatistic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.webrtc.RateStatistic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.RateStatistic.displayName = 'proto.webrtc.RateStatistic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.RealtimeStatistic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.webrtc.RealtimeStatistic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.RealtimeStatistic.displayName = 'proto.webrtc.RealtimeStatistic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.ChannelStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.webrtc.ChannelStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.ChannelStats.displayName = 'proto.webrtc.ChannelStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.RtpStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.webrtc.RtpStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.RtpStats.displayName = 'proto.webrtc.RtpStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.StreamStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.webrtc.StreamStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.StreamStats.displayName = 'proto.webrtc.StreamStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.IceCandidateStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.webrtc.IceCandidateStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.IceCandidateStats.displayName = 'proto.webrtc.IceCandidateStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.IceServerStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.webrtc.IceServerStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.IceServerStats.displayName = 'proto.webrtc.IceServerStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.JitterBufferStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.webrtc.JitterBufferStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.JitterBufferStats.displayName = 'proto.webrtc.JitterBufferStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.BandwidthUpdateStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.webrtc.BandwidthUpdateStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.BandwidthUpdateStats.displayName = 'proto.webrtc.BandwidthUpdateStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.PeerConnectionStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.webrtc.PeerConnectionStats.repeatedFields_, null);
};
goog.inherits(proto.webrtc.PeerConnectionStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.PeerConnectionStats.displayName = 'proto.webrtc.PeerConnectionStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.LivekitClientStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.webrtc.LivekitClientStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.LivekitClientStats.displayName = 'proto.webrtc.LivekitClientStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.HostConnectionStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.webrtc.HostConnectionStats.repeatedFields_, null);
};
goog.inherits(proto.webrtc.HostConnectionStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.HostConnectionStats.displayName = 'proto.webrtc.HostConnectionStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.webrtc.LivekitHealthStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.webrtc.LivekitHealthStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.webrtc.LivekitHealthStats.displayName = 'proto.webrtc.LivekitHealthStats';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.RateStatistic.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.RateStatistic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.RateStatistic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.RateStatistic.toObject = function(includeInstance, msg) {
  var f, obj = {
frequency: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
mean: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
pb_var: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
sum: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.RateStatistic}
 */
proto.webrtc.RateStatistic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.RateStatistic;
  return proto.webrtc.RateStatistic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.RateStatistic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.RateStatistic}
 */
proto.webrtc.RateStatistic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMean(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVar(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.RateStatistic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.RateStatistic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.RateStatistic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.RateStatistic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMean();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getVar();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSum();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double frequency = 1;
 * @return {number}
 */
proto.webrtc.RateStatistic.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RateStatistic} returns this
 */
proto.webrtc.RateStatistic.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double mean = 2;
 * @return {number}
 */
proto.webrtc.RateStatistic.prototype.getMean = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RateStatistic} returns this
 */
proto.webrtc.RateStatistic.prototype.setMean = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double var = 3;
 * @return {number}
 */
proto.webrtc.RateStatistic.prototype.getVar = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RateStatistic} returns this
 */
proto.webrtc.RateStatistic.prototype.setVar = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double sum = 4;
 * @return {number}
 */
proto.webrtc.RateStatistic.prototype.getSum = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RateStatistic} returns this
 */
proto.webrtc.RateStatistic.prototype.setSum = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.RealtimeStatistic.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.RealtimeStatistic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.RealtimeStatistic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.RealtimeStatistic.toObject = function(includeInstance, msg) {
  var f, obj = {
missedDeadlines: jspb.Message.getFieldWithDefault(msg, 1, 0),
avgValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
avgInterval: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
jitter: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.RealtimeStatistic}
 */
proto.webrtc.RealtimeStatistic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.RealtimeStatistic;
  return proto.webrtc.RealtimeStatistic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.RealtimeStatistic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.RealtimeStatistic}
 */
proto.webrtc.RealtimeStatistic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMissedDeadlines(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgInterval(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setJitter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.RealtimeStatistic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.RealtimeStatistic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.RealtimeStatistic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.RealtimeStatistic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMissedDeadlines();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAvgValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAvgInterval();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getJitter();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional uint32 missed_deadlines = 1;
 * @return {number}
 */
proto.webrtc.RealtimeStatistic.prototype.getMissedDeadlines = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RealtimeStatistic} returns this
 */
proto.webrtc.RealtimeStatistic.prototype.setMissedDeadlines = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double avg_value = 2;
 * @return {number}
 */
proto.webrtc.RealtimeStatistic.prototype.getAvgValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RealtimeStatistic} returns this
 */
proto.webrtc.RealtimeStatistic.prototype.setAvgValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double avg_interval = 3;
 * @return {number}
 */
proto.webrtc.RealtimeStatistic.prototype.getAvgInterval = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RealtimeStatistic} returns this
 */
proto.webrtc.RealtimeStatistic.prototype.setAvgInterval = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double jitter = 4;
 * @return {number}
 */
proto.webrtc.RealtimeStatistic.prototype.getJitter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RealtimeStatistic} returns this
 */
proto.webrtc.RealtimeStatistic.prototype.setJitter = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.ChannelStats.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.ChannelStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.ChannelStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.ChannelStats.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
packetsSent: jspb.Message.getFieldWithDefault(msg, 2, 0),
bytesSent: jspb.Message.getFieldWithDefault(msg, 3, 0),
framesSent: jspb.Message.getFieldWithDefault(msg, 4, 0),
bitrateActual: jspb.Message.getFieldWithDefault(msg, 5, 0),
framesQueued: jspb.Message.getFieldWithDefault(msg, 6, 0),
bytesQueued: jspb.Message.getFieldWithDefault(msg, 7, 0),
bitrateEstimated: jspb.Message.getFieldWithDefault(msg, 8, 0),
frameSendTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
framesS: jspb.Message.getFieldWithDefault(msg, 10, 0),
missingBytes: jspb.Message.getFieldWithDefault(msg, 11, 0),
missingFrames: jspb.Message.getFieldWithDefault(msg, 12, 0),
framesDiscarded: jspb.Message.getFieldWithDefault(msg, 14, 0),
packetsDiscarded: jspb.Message.getFieldWithDefault(msg, 15, 0),
packetBufferTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
targetBitrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
nackCount: jspb.Message.getFieldWithDefault(msg, 18, 0),
firCount: jspb.Message.getFieldWithDefault(msg, 19, 0),
pliCount: jspb.Message.getFieldWithDefault(msg, 20, 0),
sliCount: jspb.Message.getFieldWithDefault(msg, 21, 0),
videoWidth: jspb.Message.getFieldWithDefault(msg, 22, 0),
videoHeight: jspb.Message.getFieldWithDefault(msg, 23, 0),
samplesSent: jspb.Message.getFieldWithDefault(msg, 24, 0),
lastPacket: jspb.Message.getFieldWithDefault(msg, 25, 0),
frameDeltaMsMax: jspb.Message.getFieldWithDefault(msg, 26, 0),
frameDeltaMsMin: jspb.Message.getFieldWithDefault(msg, 27, 0),
rttiMs: jspb.Message.getFieldWithDefault(msg, 28, 0),
frameProcTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.ChannelStats}
 */
proto.webrtc.ChannelStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.ChannelStats;
  return proto.webrtc.ChannelStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.ChannelStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.ChannelStats}
 */
proto.webrtc.ChannelStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPacketsSent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBytesSent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFramesSent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBitrateActual(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFramesQueued(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBytesQueued(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBitrateEstimated(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameSendTime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFramesS(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMissingBytes(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMissingFrames(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFramesDiscarded(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPacketsDiscarded(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPacketBufferTime(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetBitrate(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNackCount(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFirCount(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPliCount(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSliCount(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVideoWidth(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVideoHeight(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSamplesSent(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastPacket(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameDeltaMsMax(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameDeltaMsMin(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRttiMs(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFrameProcTimeMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.ChannelStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.ChannelStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.ChannelStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.ChannelStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPacketsSent();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getBytesSent();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getFramesSent();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getBitrateActual();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getFramesQueued();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getBytesQueued();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getBitrateEstimated();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getFrameSendTime();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getFramesS();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getMissingBytes();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getMissingFrames();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getFramesDiscarded();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getPacketsDiscarded();
  if (f !== 0) {
    writer.writeUint64(
      15,
      f
    );
  }
  f = message.getPacketBufferTime();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getTargetBitrate();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getNackCount();
  if (f !== 0) {
    writer.writeUint64(
      18,
      f
    );
  }
  f = message.getFirCount();
  if (f !== 0) {
    writer.writeUint64(
      19,
      f
    );
  }
  f = message.getPliCount();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getSliCount();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getVideoWidth();
  if (f !== 0) {
    writer.writeUint64(
      22,
      f
    );
  }
  f = message.getVideoHeight();
  if (f !== 0) {
    writer.writeUint64(
      23,
      f
    );
  }
  f = message.getSamplesSent();
  if (f !== 0) {
    writer.writeUint64(
      24,
      f
    );
  }
  f = message.getLastPacket();
  if (f !== 0) {
    writer.writeUint64(
      25,
      f
    );
  }
  f = message.getFrameDeltaMsMax();
  if (f !== 0) {
    writer.writeUint64(
      26,
      f
    );
  }
  f = message.getFrameDeltaMsMin();
  if (f !== 0) {
    writer.writeUint64(
      27,
      f
    );
  }
  f = message.getRttiMs();
  if (f !== 0) {
    writer.writeUint64(
      28,
      f
    );
  }
  f = message.getFrameProcTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.webrtc.ChannelStats.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 packets_sent = 2;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getPacketsSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setPacketsSent = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 bytes_sent = 3;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getBytesSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setBytesSent = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 frames_sent = 4;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getFramesSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setFramesSent = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 bitrate_actual = 5;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getBitrateActual = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setBitrateActual = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 frames_queued = 6;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getFramesQueued = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setFramesQueued = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 bytes_queued = 7;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getBytesQueued = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setBytesQueued = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 bitrate_estimated = 8;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getBitrateEstimated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setBitrateEstimated = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 frame_send_time = 9;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getFrameSendTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setFrameSendTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 frames_s = 10;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getFramesS = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setFramesS = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 missing_bytes = 11;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getMissingBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setMissingBytes = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 missing_frames = 12;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getMissingFrames = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setMissingFrames = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint64 frames_discarded = 14;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getFramesDiscarded = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setFramesDiscarded = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint64 packets_discarded = 15;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getPacketsDiscarded = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setPacketsDiscarded = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional double packet_buffer_time = 16;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getPacketBufferTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setPacketBufferTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double target_bitrate = 17;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getTargetBitrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setTargetBitrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional uint64 nack_count = 18;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getNackCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setNackCount = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional uint64 fir_count = 19;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getFirCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setFirCount = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional uint64 pli_count = 20;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getPliCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setPliCount = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 sli_count = 21;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getSliCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setSliCount = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint64 video_width = 22;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getVideoWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setVideoWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint64 video_height = 23;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getVideoHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setVideoHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional uint64 samples_sent = 24;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getSamplesSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setSamplesSent = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional uint64 last_packet = 25;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getLastPacket = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setLastPacket = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional uint64 frame_delta_ms_max = 26;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getFrameDeltaMsMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setFrameDeltaMsMax = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional uint64 frame_delta_ms_min = 27;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getFrameDeltaMsMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setFrameDeltaMsMin = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional uint64 rtti_ms = 28;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getRttiMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setRttiMs = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional double frame_proc_time_ms = 29;
 * @return {number}
 */
proto.webrtc.ChannelStats.prototype.getFrameProcTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.ChannelStats} returns this
 */
proto.webrtc.ChannelStats.prototype.setFrameProcTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.RtpStats.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.RtpStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.RtpStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.RtpStats.toObject = function(includeInstance, msg) {
  var f, obj = {
sentBytes: jspb.Message.getFieldWithDefault(msg, 1, 0),
sentFrames: jspb.Message.getFieldWithDefault(msg, 2, 0),
nackCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
pliCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
sliCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
firCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
packetSendDelay: jspb.Message.getFieldWithDefault(msg, 7, 0),
networkFrameDrops: jspb.Message.getFieldWithDefault(msg, 8, 0),
rtti: jspb.Message.getFieldWithDefault(msg, 9, 0),
utime: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.RtpStats}
 */
proto.webrtc.RtpStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.RtpStats;
  return proto.webrtc.RtpStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.RtpStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.RtpStats}
 */
proto.webrtc.RtpStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSentBytes(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSentFrames(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNackCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPliCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSliCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFirCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPacketSendDelay(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNetworkFrameDrops(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRtti(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.RtpStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.RtpStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.RtpStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.RtpStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSentBytes();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getSentFrames();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getNackCount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getPliCount();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getSliCount();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getFirCount();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getPacketSendDelay();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getNetworkFrameDrops();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getRtti();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getUtime();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
};


/**
 * optional uint64 sent_bytes = 1;
 * @return {number}
 */
proto.webrtc.RtpStats.prototype.getSentBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RtpStats} returns this
 */
proto.webrtc.RtpStats.prototype.setSentBytes = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 sent_frames = 2;
 * @return {number}
 */
proto.webrtc.RtpStats.prototype.getSentFrames = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RtpStats} returns this
 */
proto.webrtc.RtpStats.prototype.setSentFrames = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 nack_count = 3;
 * @return {number}
 */
proto.webrtc.RtpStats.prototype.getNackCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RtpStats} returns this
 */
proto.webrtc.RtpStats.prototype.setNackCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 pli_count = 4;
 * @return {number}
 */
proto.webrtc.RtpStats.prototype.getPliCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RtpStats} returns this
 */
proto.webrtc.RtpStats.prototype.setPliCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 sli_count = 5;
 * @return {number}
 */
proto.webrtc.RtpStats.prototype.getSliCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RtpStats} returns this
 */
proto.webrtc.RtpStats.prototype.setSliCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 fir_count = 6;
 * @return {number}
 */
proto.webrtc.RtpStats.prototype.getFirCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RtpStats} returns this
 */
proto.webrtc.RtpStats.prototype.setFirCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 packet_send_delay = 7;
 * @return {number}
 */
proto.webrtc.RtpStats.prototype.getPacketSendDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RtpStats} returns this
 */
proto.webrtc.RtpStats.prototype.setPacketSendDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 network_frame_drops = 8;
 * @return {number}
 */
proto.webrtc.RtpStats.prototype.getNetworkFrameDrops = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RtpStats} returns this
 */
proto.webrtc.RtpStats.prototype.setNetworkFrameDrops = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 rtti = 9;
 * @return {number}
 */
proto.webrtc.RtpStats.prototype.getRtti = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RtpStats} returns this
 */
proto.webrtc.RtpStats.prototype.setRtti = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 utime = 10;
 * @return {number}
 */
proto.webrtc.RtpStats.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.RtpStats} returns this
 */
proto.webrtc.RtpStats.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.StreamStats.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.StreamStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.StreamStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.StreamStats.toObject = function(includeInstance, msg) {
  var f, obj = {
frameSendTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
tickTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
avgFrameProcTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
avgIntraframeTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
avgTickTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
avgIntratickTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
bytesS: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
totalBytes: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
framesS: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
tickS: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
totalFrames: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
minFrameDelta: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
maxFrameDelta: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.StreamStats}
 */
proto.webrtc.StreamStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.StreamStats;
  return proto.webrtc.StreamStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.StreamStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.StreamStats}
 */
proto.webrtc.StreamStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFrameSendTimeMs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTickTimeMs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgFrameProcTimeMs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgIntraframeTimeMs(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgTickTimeMs(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgIntratickTimeMs(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBytesS(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalBytes(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFramesS(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTickS(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFrames(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinFrameDelta(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxFrameDelta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.StreamStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.StreamStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.StreamStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.StreamStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrameSendTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTickTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAvgFrameProcTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getAvgIntraframeTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAvgTickTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAvgIntratickTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getBytesS();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalBytes();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getFramesS();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTickS();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getTotalFrames();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getMinFrameDelta();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getMaxFrameDelta();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
};


/**
 * optional double frame_send_time_ms = 1;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getFrameSendTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setFrameSendTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double tick_time_ms = 2;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getTickTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setTickTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double avg_frame_proc_time_ms = 3;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getAvgFrameProcTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setAvgFrameProcTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double avg_intraframe_time_ms = 4;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getAvgIntraframeTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setAvgIntraframeTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double avg_tick_time_ms = 5;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getAvgTickTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setAvgTickTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double avg_intratick_time_ms = 6;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getAvgIntratickTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setAvgIntratickTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double bytes_s = 7;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getBytesS = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setBytesS = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double total_bytes = 8;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getTotalBytes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setTotalBytes = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double frames_s = 9;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getFramesS = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setFramesS = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double tick_s = 10;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getTickS = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setTickS = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double total_frames = 11;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getTotalFrames = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setTotalFrames = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double min_frame_delta = 12;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getMinFrameDelta = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setMinFrameDelta = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double max_frame_delta = 13;
 * @return {number}
 */
proto.webrtc.StreamStats.prototype.getMaxFrameDelta = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.StreamStats} returns this
 */
proto.webrtc.StreamStats.prototype.setMaxFrameDelta = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.IceCandidateStats.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.IceCandidateStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.IceCandidateStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.IceCandidateStats.toObject = function(includeInstance, msg) {
  var f, obj = {
address: jspb.Message.getFieldWithDefault(msg, 1, ""),
protocol: jspb.Message.getFieldWithDefault(msg, 2, ""),
type: jspb.Message.getFieldWithDefault(msg, 3, ""),
url: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.IceCandidateStats}
 */
proto.webrtc.IceCandidateStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.IceCandidateStats;
  return proto.webrtc.IceCandidateStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.IceCandidateStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.IceCandidateStats}
 */
proto.webrtc.IceCandidateStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtocol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.IceCandidateStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.IceCandidateStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.IceCandidateStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.IceCandidateStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProtocol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.webrtc.IceCandidateStats.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.webrtc.IceCandidateStats} returns this
 */
proto.webrtc.IceCandidateStats.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string protocol = 2;
 * @return {string}
 */
proto.webrtc.IceCandidateStats.prototype.getProtocol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.webrtc.IceCandidateStats} returns this
 */
proto.webrtc.IceCandidateStats.prototype.setProtocol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.webrtc.IceCandidateStats.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.webrtc.IceCandidateStats} returns this
 */
proto.webrtc.IceCandidateStats.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string url = 4;
 * @return {string}
 */
proto.webrtc.IceCandidateStats.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.webrtc.IceCandidateStats} returns this
 */
proto.webrtc.IceCandidateStats.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.IceServerStats.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.IceServerStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.IceServerStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.IceServerStats.toObject = function(includeInstance, msg) {
  var f, obj = {
url: msg.getUrl_asB64(),
protocol: jspb.Message.getFieldWithDefault(msg, 2, ""),
port: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.IceServerStats}
 */
proto.webrtc.IceServerStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.IceServerStats;
  return proto.webrtc.IceServerStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.IceServerStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.IceServerStats}
 */
proto.webrtc.IceServerStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtocol(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.IceServerStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.IceServerStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.IceServerStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.IceServerStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getProtocol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bytes url = 1;
 * @return {!(string|Uint8Array)}
 */
proto.webrtc.IceServerStats.prototype.getUrl = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes url = 1;
 * This is a type-conversion wrapper around `getUrl()`
 * @return {string}
 */
proto.webrtc.IceServerStats.prototype.getUrl_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getUrl()));
};


/**
 * optional bytes url = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getUrl()`
 * @return {!Uint8Array}
 */
proto.webrtc.IceServerStats.prototype.getUrl_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getUrl()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.webrtc.IceServerStats} returns this
 */
proto.webrtc.IceServerStats.prototype.setUrl = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string protocol = 2;
 * @return {string}
 */
proto.webrtc.IceServerStats.prototype.getProtocol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.webrtc.IceServerStats} returns this
 */
proto.webrtc.IceServerStats.prototype.setProtocol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 port = 3;
 * @return {number}
 */
proto.webrtc.IceServerStats.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.IceServerStats} returns this
 */
proto.webrtc.IceServerStats.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.JitterBufferStats.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.JitterBufferStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.JitterBufferStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.JitterBufferStats.toObject = function(includeInstance, msg) {
  var f, obj = {
tickRate: (f = msg.getTickRate()) && proto.webrtc.RealtimeStatistic.toObject(includeInstance, f),
inRate: (f = msg.getInRate()) && proto.webrtc.RealtimeStatistic.toObject(includeInstance, f),
outRate: (f = msg.getOutRate()) && proto.webrtc.RealtimeStatistic.toObject(includeInstance, f),
holdTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.JitterBufferStats}
 */
proto.webrtc.JitterBufferStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.JitterBufferStats;
  return proto.webrtc.JitterBufferStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.JitterBufferStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.JitterBufferStats}
 */
proto.webrtc.JitterBufferStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.webrtc.RealtimeStatistic;
      reader.readMessage(value,proto.webrtc.RealtimeStatistic.deserializeBinaryFromReader);
      msg.setTickRate(value);
      break;
    case 2:
      var value = new proto.webrtc.RealtimeStatistic;
      reader.readMessage(value,proto.webrtc.RealtimeStatistic.deserializeBinaryFromReader);
      msg.setInRate(value);
      break;
    case 3:
      var value = new proto.webrtc.RealtimeStatistic;
      reader.readMessage(value,proto.webrtc.RealtimeStatistic.deserializeBinaryFromReader);
      msg.setOutRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHoldTimeMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.JitterBufferStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.JitterBufferStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.JitterBufferStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.JitterBufferStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTickRate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.webrtc.RealtimeStatistic.serializeBinaryToWriter
    );
  }
  f = message.getInRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.webrtc.RealtimeStatistic.serializeBinaryToWriter
    );
  }
  f = message.getOutRate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.webrtc.RealtimeStatistic.serializeBinaryToWriter
    );
  }
  f = message.getHoldTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional RealtimeStatistic tick_rate = 1;
 * @return {?proto.webrtc.RealtimeStatistic}
 */
proto.webrtc.JitterBufferStats.prototype.getTickRate = function() {
  return /** @type{?proto.webrtc.RealtimeStatistic} */ (
    jspb.Message.getWrapperField(this, proto.webrtc.RealtimeStatistic, 1));
};


/**
 * @param {?proto.webrtc.RealtimeStatistic|undefined} value
 * @return {!proto.webrtc.JitterBufferStats} returns this
*/
proto.webrtc.JitterBufferStats.prototype.setTickRate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.webrtc.JitterBufferStats} returns this
 */
proto.webrtc.JitterBufferStats.prototype.clearTickRate = function() {
  return this.setTickRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.webrtc.JitterBufferStats.prototype.hasTickRate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RealtimeStatistic in_rate = 2;
 * @return {?proto.webrtc.RealtimeStatistic}
 */
proto.webrtc.JitterBufferStats.prototype.getInRate = function() {
  return /** @type{?proto.webrtc.RealtimeStatistic} */ (
    jspb.Message.getWrapperField(this, proto.webrtc.RealtimeStatistic, 2));
};


/**
 * @param {?proto.webrtc.RealtimeStatistic|undefined} value
 * @return {!proto.webrtc.JitterBufferStats} returns this
*/
proto.webrtc.JitterBufferStats.prototype.setInRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.webrtc.JitterBufferStats} returns this
 */
proto.webrtc.JitterBufferStats.prototype.clearInRate = function() {
  return this.setInRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.webrtc.JitterBufferStats.prototype.hasInRate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RealtimeStatistic out_rate = 3;
 * @return {?proto.webrtc.RealtimeStatistic}
 */
proto.webrtc.JitterBufferStats.prototype.getOutRate = function() {
  return /** @type{?proto.webrtc.RealtimeStatistic} */ (
    jspb.Message.getWrapperField(this, proto.webrtc.RealtimeStatistic, 3));
};


/**
 * @param {?proto.webrtc.RealtimeStatistic|undefined} value
 * @return {!proto.webrtc.JitterBufferStats} returns this
*/
proto.webrtc.JitterBufferStats.prototype.setOutRate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.webrtc.JitterBufferStats} returns this
 */
proto.webrtc.JitterBufferStats.prototype.clearOutRate = function() {
  return this.setOutRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.webrtc.JitterBufferStats.prototype.hasOutRate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double hold_time_ms = 4;
 * @return {number}
 */
proto.webrtc.JitterBufferStats.prototype.getHoldTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.JitterBufferStats} returns this
 */
proto.webrtc.JitterBufferStats.prototype.setHoldTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.BandwidthUpdateStats.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.BandwidthUpdateStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.BandwidthUpdateStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.BandwidthUpdateStats.toObject = function(includeInstance, msg) {
  var f, obj = {
oldBandwidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
newBandwidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
streamHealth: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
bitrateSuggestion: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.BandwidthUpdateStats}
 */
proto.webrtc.BandwidthUpdateStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.BandwidthUpdateStats;
  return proto.webrtc.BandwidthUpdateStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.BandwidthUpdateStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.BandwidthUpdateStats}
 */
proto.webrtc.BandwidthUpdateStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOldBandwidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNewBandwidth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStreamHealth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBitrateSuggestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.BandwidthUpdateStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.BandwidthUpdateStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.BandwidthUpdateStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.BandwidthUpdateStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldBandwidth();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getNewBandwidth();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getStreamHealth();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBitrateSuggestion();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double old_bandwidth = 1;
 * @return {number}
 */
proto.webrtc.BandwidthUpdateStats.prototype.getOldBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.BandwidthUpdateStats} returns this
 */
proto.webrtc.BandwidthUpdateStats.prototype.setOldBandwidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double new_bandwidth = 2;
 * @return {number}
 */
proto.webrtc.BandwidthUpdateStats.prototype.getNewBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.BandwidthUpdateStats} returns this
 */
proto.webrtc.BandwidthUpdateStats.prototype.setNewBandwidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double stream_health = 3;
 * @return {number}
 */
proto.webrtc.BandwidthUpdateStats.prototype.getStreamHealth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.BandwidthUpdateStats} returns this
 */
proto.webrtc.BandwidthUpdateStats.prototype.setStreamHealth = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double bitrate_suggestion = 4;
 * @return {number}
 */
proto.webrtc.BandwidthUpdateStats.prototype.getBitrateSuggestion = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.BandwidthUpdateStats} returns this
 */
proto.webrtc.BandwidthUpdateStats.prototype.setBitrateSuggestion = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.webrtc.PeerConnectionStats.repeatedFields_ = [6,7,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.PeerConnectionStats.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.PeerConnectionStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.PeerConnectionStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.PeerConnectionStats.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
connectionTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
iceServer: (f = msg.getIceServer()) && proto.webrtc.IceServerStats.toObject(includeInstance, f),
localIce: (f = msg.getLocalIce()) && proto.webrtc.IceCandidateStats.toObject(includeInstance, f),
remoteIce: (f = msg.getRemoteIce()) && proto.webrtc.IceCandidateStats.toObject(includeInstance, f),
videoChannelsList: jspb.Message.toObjectList(msg.getVideoChannelsList(),
    proto.webrtc.ChannelStats.toObject, includeInstance),
dataChannelsList: jspb.Message.toObjectList(msg.getDataChannelsList(),
    proto.webrtc.ChannelStats.toObject, includeInstance),
localSdp: jspb.Message.getFieldWithDefault(msg, 8, ""),
remoteSdp: jspb.Message.getFieldWithDefault(msg, 9, ""),
iceCandidatesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
sendRtpStatsMap: (f = msg.getSendRtpStatsMap()) ? f.toObject(includeInstance, proto.webrtc.RtpStats.toObject) : [],
sendStreamStatsMap: (f = msg.getSendStreamStatsMap()) ? f.toObject(includeInstance, proto.webrtc.StreamStats.toObject) : [],
recvStreamStatsMap: (f = msg.getRecvStreamStatsMap()) ? f.toObject(includeInstance, proto.webrtc.StreamStats.toObject) : [],
recvRtpStatsMap: (f = msg.getRecvRtpStatsMap()) ? f.toObject(includeInstance, proto.webrtc.RtpStats.toObject) : [],
bandwidthInfo: (f = msg.getBandwidthInfo()) && proto.webrtc.BandwidthUpdateStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.PeerConnectionStats}
 */
proto.webrtc.PeerConnectionStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.PeerConnectionStats;
  return proto.webrtc.PeerConnectionStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.PeerConnectionStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.PeerConnectionStats}
 */
proto.webrtc.PeerConnectionStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setConnectionTime(value);
      break;
    case 3:
      var value = new proto.webrtc.IceServerStats;
      reader.readMessage(value,proto.webrtc.IceServerStats.deserializeBinaryFromReader);
      msg.setIceServer(value);
      break;
    case 4:
      var value = new proto.webrtc.IceCandidateStats;
      reader.readMessage(value,proto.webrtc.IceCandidateStats.deserializeBinaryFromReader);
      msg.setLocalIce(value);
      break;
    case 5:
      var value = new proto.webrtc.IceCandidateStats;
      reader.readMessage(value,proto.webrtc.IceCandidateStats.deserializeBinaryFromReader);
      msg.setRemoteIce(value);
      break;
    case 6:
      var value = new proto.webrtc.ChannelStats;
      reader.readMessage(value,proto.webrtc.ChannelStats.deserializeBinaryFromReader);
      msg.addVideoChannels(value);
      break;
    case 7:
      var value = new proto.webrtc.ChannelStats;
      reader.readMessage(value,proto.webrtc.ChannelStats.deserializeBinaryFromReader);
      msg.addDataChannels(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalSdp(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteSdp(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addIceCandidates(value);
      break;
    case 11:
      var value = msg.getSendRtpStatsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.webrtc.RtpStats.deserializeBinaryFromReader, "", new proto.webrtc.RtpStats());
         });
      break;
    case 12:
      var value = msg.getSendStreamStatsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.webrtc.StreamStats.deserializeBinaryFromReader, "", new proto.webrtc.StreamStats());
         });
      break;
    case 13:
      var value = msg.getRecvStreamStatsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.webrtc.StreamStats.deserializeBinaryFromReader, "", new proto.webrtc.StreamStats());
         });
      break;
    case 14:
      var value = msg.getRecvRtpStatsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.webrtc.RtpStats.deserializeBinaryFromReader, "", new proto.webrtc.RtpStats());
         });
      break;
    case 15:
      var value = new proto.webrtc.BandwidthUpdateStats;
      reader.readMessage(value,proto.webrtc.BandwidthUpdateStats.deserializeBinaryFromReader);
      msg.setBandwidthInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.PeerConnectionStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.PeerConnectionStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.PeerConnectionStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.PeerConnectionStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnectionTime();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getIceServer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.webrtc.IceServerStats.serializeBinaryToWriter
    );
  }
  f = message.getLocalIce();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.webrtc.IceCandidateStats.serializeBinaryToWriter
    );
  }
  f = message.getRemoteIce();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.webrtc.IceCandidateStats.serializeBinaryToWriter
    );
  }
  f = message.getVideoChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.webrtc.ChannelStats.serializeBinaryToWriter
    );
  }
  f = message.getDataChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.webrtc.ChannelStats.serializeBinaryToWriter
    );
  }
  f = message.getLocalSdp();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRemoteSdp();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIceCandidatesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getSendRtpStatsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.webrtc.RtpStats.serializeBinaryToWriter);
  }
  f = message.getSendStreamStatsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(12, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.webrtc.StreamStats.serializeBinaryToWriter);
  }
  f = message.getRecvStreamStatsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(13, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.webrtc.StreamStats.serializeBinaryToWriter);
  }
  f = message.getRecvRtpStatsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(14, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.webrtc.RtpStats.serializeBinaryToWriter);
  }
  f = message.getBandwidthInfo();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.webrtc.BandwidthUpdateStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.webrtc.PeerConnectionStats.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 connection_time = 2;
 * @return {number}
 */
proto.webrtc.PeerConnectionStats.prototype.getConnectionTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.setConnectionTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional IceServerStats ice_server = 3;
 * @return {?proto.webrtc.IceServerStats}
 */
proto.webrtc.PeerConnectionStats.prototype.getIceServer = function() {
  return /** @type{?proto.webrtc.IceServerStats} */ (
    jspb.Message.getWrapperField(this, proto.webrtc.IceServerStats, 3));
};


/**
 * @param {?proto.webrtc.IceServerStats|undefined} value
 * @return {!proto.webrtc.PeerConnectionStats} returns this
*/
proto.webrtc.PeerConnectionStats.prototype.setIceServer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.clearIceServer = function() {
  return this.setIceServer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.webrtc.PeerConnectionStats.prototype.hasIceServer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional IceCandidateStats local_ice = 4;
 * @return {?proto.webrtc.IceCandidateStats}
 */
proto.webrtc.PeerConnectionStats.prototype.getLocalIce = function() {
  return /** @type{?proto.webrtc.IceCandidateStats} */ (
    jspb.Message.getWrapperField(this, proto.webrtc.IceCandidateStats, 4));
};


/**
 * @param {?proto.webrtc.IceCandidateStats|undefined} value
 * @return {!proto.webrtc.PeerConnectionStats} returns this
*/
proto.webrtc.PeerConnectionStats.prototype.setLocalIce = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.clearLocalIce = function() {
  return this.setLocalIce(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.webrtc.PeerConnectionStats.prototype.hasLocalIce = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional IceCandidateStats remote_ice = 5;
 * @return {?proto.webrtc.IceCandidateStats}
 */
proto.webrtc.PeerConnectionStats.prototype.getRemoteIce = function() {
  return /** @type{?proto.webrtc.IceCandidateStats} */ (
    jspb.Message.getWrapperField(this, proto.webrtc.IceCandidateStats, 5));
};


/**
 * @param {?proto.webrtc.IceCandidateStats|undefined} value
 * @return {!proto.webrtc.PeerConnectionStats} returns this
*/
proto.webrtc.PeerConnectionStats.prototype.setRemoteIce = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.clearRemoteIce = function() {
  return this.setRemoteIce(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.webrtc.PeerConnectionStats.prototype.hasRemoteIce = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated ChannelStats video_channels = 6;
 * @return {!Array<!proto.webrtc.ChannelStats>}
 */
proto.webrtc.PeerConnectionStats.prototype.getVideoChannelsList = function() {
  return /** @type{!Array<!proto.webrtc.ChannelStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.webrtc.ChannelStats, 6));
};


/**
 * @param {!Array<!proto.webrtc.ChannelStats>} value
 * @return {!proto.webrtc.PeerConnectionStats} returns this
*/
proto.webrtc.PeerConnectionStats.prototype.setVideoChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.webrtc.ChannelStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.webrtc.ChannelStats}
 */
proto.webrtc.PeerConnectionStats.prototype.addVideoChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.webrtc.ChannelStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.clearVideoChannelsList = function() {
  return this.setVideoChannelsList([]);
};


/**
 * repeated ChannelStats data_channels = 7;
 * @return {!Array<!proto.webrtc.ChannelStats>}
 */
proto.webrtc.PeerConnectionStats.prototype.getDataChannelsList = function() {
  return /** @type{!Array<!proto.webrtc.ChannelStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.webrtc.ChannelStats, 7));
};


/**
 * @param {!Array<!proto.webrtc.ChannelStats>} value
 * @return {!proto.webrtc.PeerConnectionStats} returns this
*/
proto.webrtc.PeerConnectionStats.prototype.setDataChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.webrtc.ChannelStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.webrtc.ChannelStats}
 */
proto.webrtc.PeerConnectionStats.prototype.addDataChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.webrtc.ChannelStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.clearDataChannelsList = function() {
  return this.setDataChannelsList([]);
};


/**
 * optional string local_sdp = 8;
 * @return {string}
 */
proto.webrtc.PeerConnectionStats.prototype.getLocalSdp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.setLocalSdp = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string remote_sdp = 9;
 * @return {string}
 */
proto.webrtc.PeerConnectionStats.prototype.getRemoteSdp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.setRemoteSdp = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string ice_candidates = 10;
 * @return {!Array<string>}
 */
proto.webrtc.PeerConnectionStats.prototype.getIceCandidatesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.setIceCandidatesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.addIceCandidates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.clearIceCandidatesList = function() {
  return this.setIceCandidatesList([]);
};


/**
 * map<string, RtpStats> send_rtp_stats = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.webrtc.RtpStats>}
 */
proto.webrtc.PeerConnectionStats.prototype.getSendRtpStatsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.webrtc.RtpStats>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
      proto.webrtc.RtpStats));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.clearSendRtpStatsMap = function() {
  this.getSendRtpStatsMap().clear();
  return this;
};


/**
 * map<string, StreamStats> send_stream_stats = 12;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.webrtc.StreamStats>}
 */
proto.webrtc.PeerConnectionStats.prototype.getSendStreamStatsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.webrtc.StreamStats>} */ (
      jspb.Message.getMapField(this, 12, opt_noLazyCreate,
      proto.webrtc.StreamStats));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.clearSendStreamStatsMap = function() {
  this.getSendStreamStatsMap().clear();
  return this;
};


/**
 * map<string, StreamStats> recv_stream_stats = 13;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.webrtc.StreamStats>}
 */
proto.webrtc.PeerConnectionStats.prototype.getRecvStreamStatsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.webrtc.StreamStats>} */ (
      jspb.Message.getMapField(this, 13, opt_noLazyCreate,
      proto.webrtc.StreamStats));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.clearRecvStreamStatsMap = function() {
  this.getRecvStreamStatsMap().clear();
  return this;
};


/**
 * map<string, RtpStats> recv_rtp_stats = 14;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.webrtc.RtpStats>}
 */
proto.webrtc.PeerConnectionStats.prototype.getRecvRtpStatsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.webrtc.RtpStats>} */ (
      jspb.Message.getMapField(this, 14, opt_noLazyCreate,
      proto.webrtc.RtpStats));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.clearRecvRtpStatsMap = function() {
  this.getRecvRtpStatsMap().clear();
  return this;
};


/**
 * optional BandwidthUpdateStats bandwidth_info = 15;
 * @return {?proto.webrtc.BandwidthUpdateStats}
 */
proto.webrtc.PeerConnectionStats.prototype.getBandwidthInfo = function() {
  return /** @type{?proto.webrtc.BandwidthUpdateStats} */ (
    jspb.Message.getWrapperField(this, proto.webrtc.BandwidthUpdateStats, 15));
};


/**
 * @param {?proto.webrtc.BandwidthUpdateStats|undefined} value
 * @return {!proto.webrtc.PeerConnectionStats} returns this
*/
proto.webrtc.PeerConnectionStats.prototype.setBandwidthInfo = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.webrtc.PeerConnectionStats} returns this
 */
proto.webrtc.PeerConnectionStats.prototype.clearBandwidthInfo = function() {
  return this.setBandwidthInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.webrtc.PeerConnectionStats.prototype.hasBandwidthInfo = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.LivekitClientStats.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.LivekitClientStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.LivekitClientStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.LivekitClientStats.toObject = function(includeInstance, msg) {
  var f, obj = {
publishPeer: (f = msg.getPublishPeer()) && proto.webrtc.PeerConnectionStats.toObject(includeInstance, f),
subscribePeer: (f = msg.getSubscribePeer()) && proto.webrtc.PeerConnectionStats.toObject(includeInstance, f),
statusFlags: jspb.Message.getFieldWithDefault(msg, 3, ""),
outBufferPerfMap: (f = msg.getOutBufferPerfMap()) ? f.toObject(includeInstance, proto.webrtc.JitterBufferStats.toObject) : [],
inBufferPerfMap: (f = msg.getInBufferPerfMap()) ? f.toObject(includeInstance, proto.webrtc.JitterBufferStats.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.LivekitClientStats}
 */
proto.webrtc.LivekitClientStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.LivekitClientStats;
  return proto.webrtc.LivekitClientStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.LivekitClientStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.LivekitClientStats}
 */
proto.webrtc.LivekitClientStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.webrtc.PeerConnectionStats;
      reader.readMessage(value,proto.webrtc.PeerConnectionStats.deserializeBinaryFromReader);
      msg.setPublishPeer(value);
      break;
    case 2:
      var value = new proto.webrtc.PeerConnectionStats;
      reader.readMessage(value,proto.webrtc.PeerConnectionStats.deserializeBinaryFromReader);
      msg.setSubscribePeer(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusFlags(value);
      break;
    case 6:
      var value = msg.getOutBufferPerfMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.webrtc.JitterBufferStats.deserializeBinaryFromReader, "", new proto.webrtc.JitterBufferStats());
         });
      break;
    case 7:
      var value = msg.getInBufferPerfMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.webrtc.JitterBufferStats.deserializeBinaryFromReader, "", new proto.webrtc.JitterBufferStats());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.LivekitClientStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.LivekitClientStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.LivekitClientStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.LivekitClientStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublishPeer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.webrtc.PeerConnectionStats.serializeBinaryToWriter
    );
  }
  f = message.getSubscribePeer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.webrtc.PeerConnectionStats.serializeBinaryToWriter
    );
  }
  f = message.getStatusFlags();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutBufferPerfMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.webrtc.JitterBufferStats.serializeBinaryToWriter);
  }
  f = message.getInBufferPerfMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.webrtc.JitterBufferStats.serializeBinaryToWriter);
  }
};


/**
 * optional PeerConnectionStats publish_peer = 1;
 * @return {?proto.webrtc.PeerConnectionStats}
 */
proto.webrtc.LivekitClientStats.prototype.getPublishPeer = function() {
  return /** @type{?proto.webrtc.PeerConnectionStats} */ (
    jspb.Message.getWrapperField(this, proto.webrtc.PeerConnectionStats, 1));
};


/**
 * @param {?proto.webrtc.PeerConnectionStats|undefined} value
 * @return {!proto.webrtc.LivekitClientStats} returns this
*/
proto.webrtc.LivekitClientStats.prototype.setPublishPeer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.webrtc.LivekitClientStats} returns this
 */
proto.webrtc.LivekitClientStats.prototype.clearPublishPeer = function() {
  return this.setPublishPeer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.webrtc.LivekitClientStats.prototype.hasPublishPeer = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PeerConnectionStats subscribe_peer = 2;
 * @return {?proto.webrtc.PeerConnectionStats}
 */
proto.webrtc.LivekitClientStats.prototype.getSubscribePeer = function() {
  return /** @type{?proto.webrtc.PeerConnectionStats} */ (
    jspb.Message.getWrapperField(this, proto.webrtc.PeerConnectionStats, 2));
};


/**
 * @param {?proto.webrtc.PeerConnectionStats|undefined} value
 * @return {!proto.webrtc.LivekitClientStats} returns this
*/
proto.webrtc.LivekitClientStats.prototype.setSubscribePeer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.webrtc.LivekitClientStats} returns this
 */
proto.webrtc.LivekitClientStats.prototype.clearSubscribePeer = function() {
  return this.setSubscribePeer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.webrtc.LivekitClientStats.prototype.hasSubscribePeer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string status_flags = 3;
 * @return {string}
 */
proto.webrtc.LivekitClientStats.prototype.getStatusFlags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.webrtc.LivekitClientStats} returns this
 */
proto.webrtc.LivekitClientStats.prototype.setStatusFlags = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, JitterBufferStats> out_buffer_perf = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.webrtc.JitterBufferStats>}
 */
proto.webrtc.LivekitClientStats.prototype.getOutBufferPerfMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.webrtc.JitterBufferStats>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.webrtc.JitterBufferStats));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.webrtc.LivekitClientStats} returns this
 */
proto.webrtc.LivekitClientStats.prototype.clearOutBufferPerfMap = function() {
  this.getOutBufferPerfMap().clear();
  return this;
};


/**
 * map<string, JitterBufferStats> in_buffer_perf = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.webrtc.JitterBufferStats>}
 */
proto.webrtc.LivekitClientStats.prototype.getInBufferPerfMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.webrtc.JitterBufferStats>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.webrtc.JitterBufferStats));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.webrtc.LivekitClientStats} returns this
 */
proto.webrtc.LivekitClientStats.prototype.clearInBufferPerfMap = function() {
  this.getInBufferPerfMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.webrtc.HostConnectionStats.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.HostConnectionStats.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.HostConnectionStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.HostConnectionStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.HostConnectionStats.toObject = function(includeInstance, msg) {
  var f, obj = {
peersList: jspb.Message.toObjectList(msg.getPeersList(),
    proto.webrtc.PeerConnectionStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.HostConnectionStats}
 */
proto.webrtc.HostConnectionStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.HostConnectionStats;
  return proto.webrtc.HostConnectionStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.HostConnectionStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.HostConnectionStats}
 */
proto.webrtc.HostConnectionStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.webrtc.PeerConnectionStats;
      reader.readMessage(value,proto.webrtc.PeerConnectionStats.deserializeBinaryFromReader);
      msg.addPeers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.HostConnectionStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.HostConnectionStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.HostConnectionStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.HostConnectionStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.webrtc.PeerConnectionStats.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PeerConnectionStats peers = 1;
 * @return {!Array<!proto.webrtc.PeerConnectionStats>}
 */
proto.webrtc.HostConnectionStats.prototype.getPeersList = function() {
  return /** @type{!Array<!proto.webrtc.PeerConnectionStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.webrtc.PeerConnectionStats, 1));
};


/**
 * @param {!Array<!proto.webrtc.PeerConnectionStats>} value
 * @return {!proto.webrtc.HostConnectionStats} returns this
*/
proto.webrtc.HostConnectionStats.prototype.setPeersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.webrtc.PeerConnectionStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.webrtc.PeerConnectionStats}
 */
proto.webrtc.HostConnectionStats.prototype.addPeers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.webrtc.PeerConnectionStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.webrtc.HostConnectionStats} returns this
 */
proto.webrtc.HostConnectionStats.prototype.clearPeersList = function() {
  return this.setPeersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.webrtc.LivekitHealthStats.prototype.toObject = function(opt_includeInstance) {
  return proto.webrtc.LivekitHealthStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.webrtc.LivekitHealthStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.LivekitHealthStats.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
lossRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
bytesSent: jspb.Message.getFieldWithDefault(msg, 3, 0),
rtt: jspb.Message.getFieldWithDefault(msg, 4, 0),
jitter: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
jitterVar: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
clientId: jspb.Message.getFieldWithDefault(msg, 7, ""),
byteRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.webrtc.LivekitHealthStats}
 */
proto.webrtc.LivekitHealthStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.webrtc.LivekitHealthStats;
  return proto.webrtc.LivekitHealthStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.webrtc.LivekitHealthStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.webrtc.LivekitHealthStats}
 */
proto.webrtc.LivekitHealthStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLossRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBytesSent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRtt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setJitter(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setJitterVar(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setByteRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.webrtc.LivekitHealthStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.webrtc.LivekitHealthStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.webrtc.LivekitHealthStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.webrtc.LivekitHealthStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLossRate();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getBytesSent();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getRtt();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getJitter();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getJitterVar();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getByteRate();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional uint64 utime = 1;
 * @return {number}
 */
proto.webrtc.LivekitHealthStats.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.LivekitHealthStats} returns this
 */
proto.webrtc.LivekitHealthStats.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float loss_rate = 2;
 * @return {number}
 */
proto.webrtc.LivekitHealthStats.prototype.getLossRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.LivekitHealthStats} returns this
 */
proto.webrtc.LivekitHealthStats.prototype.setLossRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional uint64 bytes_sent = 3;
 * @return {number}
 */
proto.webrtc.LivekitHealthStats.prototype.getBytesSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.LivekitHealthStats} returns this
 */
proto.webrtc.LivekitHealthStats.prototype.setBytesSent = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 rtt = 4;
 * @return {number}
 */
proto.webrtc.LivekitHealthStats.prototype.getRtt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.LivekitHealthStats} returns this
 */
proto.webrtc.LivekitHealthStats.prototype.setRtt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float jitter = 5;
 * @return {number}
 */
proto.webrtc.LivekitHealthStats.prototype.getJitter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.LivekitHealthStats} returns this
 */
proto.webrtc.LivekitHealthStats.prototype.setJitter = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float jitter_var = 6;
 * @return {number}
 */
proto.webrtc.LivekitHealthStats.prototype.getJitterVar = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.LivekitHealthStats} returns this
 */
proto.webrtc.LivekitHealthStats.prototype.setJitterVar = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string client_id = 7;
 * @return {string}
 */
proto.webrtc.LivekitHealthStats.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.webrtc.LivekitHealthStats} returns this
 */
proto.webrtc.LivekitHealthStats.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double byte_rate = 8;
 * @return {number}
 */
proto.webrtc.LivekitHealthStats.prototype.getByteRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.webrtc.LivekitHealthStats} returns this
 */
proto.webrtc.LivekitHealthStats.prototype.setByteRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


goog.object.extend(exports, proto.webrtc);
