// source: pbtypes/infrastructure/drivers/attachment_manager/attachment_manager.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_accessories_accessory_enum_pb = require('../../../../pbtypes/gen/accessories/accessory_enum_pb.js');
goog.object.extend(proto, pbtypes_gen_accessories_accessory_enum_pb);
var pbtypes_gen_attachment_manager_port_data_to_consumers_pb = require('../../../../pbtypes/gen/attachment_manager/port_data_to_consumers_pb.js');
goog.object.extend(proto, pbtypes_gen_attachment_manager_port_data_to_consumers_pb);
goog.exportSymbol('proto.attachment_manager.AttachmentFault', null, global);
goog.exportSymbol('proto.attachment_manager.AttachmentFault.Enum', null, global);
goog.exportSymbol('proto.attachment_manager.AttachmentHealth', null, global);
goog.exportSymbol('proto.attachment_manager.AttachmentInfo', null, global);
goog.exportSymbol('proto.attachment_manager.PortStatusToConsumers', null, global);
goog.exportSymbol('proto.attachment_manager.VehicleAttachmentHealth', null, global);
goog.exportSymbol('proto.attachment_manager.VehicleAttachmentInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment_manager.PortStatusToConsumers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment_manager.PortStatusToConsumers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment_manager.PortStatusToConsumers.displayName = 'proto.attachment_manager.PortStatusToConsumers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment_manager.AttachmentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment_manager.AttachmentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment_manager.AttachmentInfo.displayName = 'proto.attachment_manager.AttachmentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment_manager.VehicleAttachmentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment_manager.VehicleAttachmentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment_manager.VehicleAttachmentInfo.displayName = 'proto.attachment_manager.VehicleAttachmentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment_manager.AttachmentFault = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment_manager.AttachmentFault, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment_manager.AttachmentFault.displayName = 'proto.attachment_manager.AttachmentFault';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment_manager.AttachmentHealth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment_manager.AttachmentHealth.repeatedFields_, null);
};
goog.inherits(proto.attachment_manager.AttachmentHealth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment_manager.AttachmentHealth.displayName = 'proto.attachment_manager.AttachmentHealth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment_manager.VehicleAttachmentHealth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment_manager.VehicleAttachmentHealth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment_manager.VehicleAttachmentHealth.displayName = 'proto.attachment_manager.VehicleAttachmentHealth';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment_manager.PortStatusToConsumers.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment_manager.PortStatusToConsumers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment_manager.PortStatusToConsumers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.PortStatusToConsumers.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
portDataMap: (f = msg.getPortDataMap()) ? f.toObject(includeInstance, proto.attachment_manager.PortDataToConsumers.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment_manager.PortStatusToConsumers}
 */
proto.attachment_manager.PortStatusToConsumers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment_manager.PortStatusToConsumers;
  return proto.attachment_manager.PortStatusToConsumers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment_manager.PortStatusToConsumers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment_manager.PortStatusToConsumers}
 */
proto.attachment_manager.PortStatusToConsumers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = msg.getPortDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.attachment_manager.PortDataToConsumers.deserializeBinaryFromReader, 0, new proto.attachment_manager.PortDataToConsumers());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment_manager.PortStatusToConsumers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment_manager.PortStatusToConsumers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment_manager.PortStatusToConsumers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.PortStatusToConsumers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPortDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.attachment_manager.PortDataToConsumers.serializeBinaryToWriter);
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.attachment_manager.PortStatusToConsumers.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment_manager.PortStatusToConsumers} returns this
 */
proto.attachment_manager.PortStatusToConsumers.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<int32, PortDataToConsumers> port_data = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.attachment_manager.PortDataToConsumers>}
 */
proto.attachment_manager.PortStatusToConsumers.prototype.getPortDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.attachment_manager.PortDataToConsumers>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.attachment_manager.PortDataToConsumers));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.attachment_manager.PortStatusToConsumers} returns this
 */
proto.attachment_manager.PortStatusToConsumers.prototype.clearPortDataMap = function() {
  this.getPortDataMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment_manager.AttachmentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment_manager.AttachmentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment_manager.AttachmentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.AttachmentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
serialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
attachmentType: jspb.Message.getFieldWithDefault(msg, 2, 0),
swVersion: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment_manager.AttachmentInfo}
 */
proto.attachment_manager.AttachmentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment_manager.AttachmentInfo;
  return proto.attachment_manager.AttachmentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment_manager.AttachmentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment_manager.AttachmentInfo}
 */
proto.attachment_manager.AttachmentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 2:
      var value = /** @type {!proto.accessories.AccessoryEnum.Enum} */ (reader.readEnum());
      msg.setAttachmentType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment_manager.AttachmentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment_manager.AttachmentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment_manager.AttachmentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.AttachmentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAttachmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSwVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string serial_number = 1;
 * @return {string}
 */
proto.attachment_manager.AttachmentInfo.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment_manager.AttachmentInfo} returns this
 */
proto.attachment_manager.AttachmentInfo.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional accessories.AccessoryEnum.Enum attachment_type = 2;
 * @return {!proto.accessories.AccessoryEnum.Enum}
 */
proto.attachment_manager.AttachmentInfo.prototype.getAttachmentType = function() {
  return /** @type {!proto.accessories.AccessoryEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.accessories.AccessoryEnum.Enum} value
 * @return {!proto.attachment_manager.AttachmentInfo} returns this
 */
proto.attachment_manager.AttachmentInfo.prototype.setAttachmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string sw_version = 3;
 * @return {string}
 */
proto.attachment_manager.AttachmentInfo.prototype.getSwVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment_manager.AttachmentInfo} returns this
 */
proto.attachment_manager.AttachmentInfo.prototype.setSwVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment_manager.VehicleAttachmentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment_manager.VehicleAttachmentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment_manager.VehicleAttachmentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.VehicleAttachmentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
attachmentsMap: (f = msg.getAttachmentsMap()) ? f.toObject(includeInstance, proto.attachment_manager.AttachmentInfo.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment_manager.VehicleAttachmentInfo}
 */
proto.attachment_manager.VehicleAttachmentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment_manager.VehicleAttachmentInfo;
  return proto.attachment_manager.VehicleAttachmentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment_manager.VehicleAttachmentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment_manager.VehicleAttachmentInfo}
 */
proto.attachment_manager.VehicleAttachmentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = msg.getAttachmentsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.attachment_manager.AttachmentInfo.deserializeBinaryFromReader, 0, new proto.attachment_manager.AttachmentInfo());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment_manager.VehicleAttachmentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment_manager.VehicleAttachmentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment_manager.VehicleAttachmentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.VehicleAttachmentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAttachmentsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.attachment_manager.AttachmentInfo.serializeBinaryToWriter);
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.attachment_manager.VehicleAttachmentInfo.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment_manager.VehicleAttachmentInfo} returns this
 */
proto.attachment_manager.VehicleAttachmentInfo.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<int32, AttachmentInfo> attachments = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.attachment_manager.AttachmentInfo>}
 */
proto.attachment_manager.VehicleAttachmentInfo.prototype.getAttachmentsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.attachment_manager.AttachmentInfo>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.attachment_manager.AttachmentInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.attachment_manager.VehicleAttachmentInfo} returns this
 */
proto.attachment_manager.VehicleAttachmentInfo.prototype.clearAttachmentsMap = function() {
  this.getAttachmentsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment_manager.AttachmentFault.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment_manager.AttachmentFault.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment_manager.AttachmentFault} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.AttachmentFault.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment_manager.AttachmentFault}
 */
proto.attachment_manager.AttachmentFault.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment_manager.AttachmentFault;
  return proto.attachment_manager.AttachmentFault.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment_manager.AttachmentFault} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment_manager.AttachmentFault}
 */
proto.attachment_manager.AttachmentFault.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment_manager.AttachmentFault.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment_manager.AttachmentFault.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment_manager.AttachmentFault} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.AttachmentFault.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.attachment_manager.AttachmentFault.Enum = {
  NO_ERROR: 0,
  GENERIC_UNIDENTIFIABLE_ATTACHMENT: 1,
  GENERIC_SOFTWARE_DISCONNECT_DURING_FLIGHT: 2,
  GENERIC_HARDWARE_DISCONNECT_DURING_FLIGHT: 3,
  SPOTLIGHT_MAVLINK_ILLUMINATOR_ERROR_FLAGS_THERMAL_THROTTLING: 10,
  SPOTLIGHT_MAVLINK_ILLUMINATOR_ERROR_FLAGS_OVER_TEMPERATURE_SHUTDOWN: 11,
  SPOTLIGHT_MAVLINK_ILLUMINATOR_ERROR_FLAGS_THERMISTOR_FAILURE: 12,
  SPOTLIGHT_COMM_FAILURE_HEARTBEAT_TIMEOUT: 13,
  SPOTLIGHT_COMM_FAILURE_MAVLINK_ILLUMINATOR_STATUS_TIMEOUT: 14,
  NIGHTSENSE_STATUS_FLAG_LED1_OVERCURRENT: 15,
  NIGHTSENSE_STATUS_FLAG_LED1_UNDERCURRENT: 16,
  NIGHTSENSE_STATUS_FLAG_LED1_OVERVOLTAGE: 17,
  NIGHTSENSE_STATUS_FLAG_LED1_UNDERVOLTAGE: 18,
  NIGHTSENSE_STATUS_FLAG_LED2_OVERCURRENT: 19,
  NIGHTSENSE_STATUS_FLAG_LED2_UNDERCURRENT: 20,
  NIGHTSENSE_STATUS_FLAG_LED2_OVERVOLTAGE: 21,
  NIGHTSENSE_STATUS_FLAG_LED2_UNDERVOLTAGE: 22,
  NIGHTSENSE_STATUS_FLAG_OVERTEMP_SHUTDOWN: 23,
  NIGHTSENSE_STATUS_FLAG_FAN_STALLED: 24,
  NIGHTSENSE_STATUS_FLAG_THERMISTOR_TOP_FAULT: 25,
  NIGHTSENSE_STATUS_FLAG_THERMISTOR_SIDE_FAULT: 26,
  NIGHTSENSE_STATUS_FLAG_LED1_VOLTAGE_OPEN: 27,
  NIGHTSENSE_STATUS_FLAG_LED1_VOLTAGE_SHORT: 28,
  NIGHTSENSE_STATUS_FLAG_LED2_VOLTAGE_OPEN: 29,
  NIGHTSENSE_STATUS_FLAG_LED2_VOLTAGE_SHORT: 30,
  NIGHTSENSE_STATUS_FLAG_UNKNOWN_BIT: 31,
  NIGHTSENSE_COMM_FAILURE_STATUS_TIMEOUT: 32,
  NIGHTSENSE_COMM_FAILURE_INFO_TIMEOUT: 33,
  SPEAKERMIC_STATUS_HV_THERMAL_WARNING: 34,
  SPEAKERMIC_STATUS_HV_FAULTB: 35,
  SPEAKERMIC_PULSEAUDIO_INACTIVE: 36
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment_manager.AttachmentHealth.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment_manager.AttachmentHealth.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment_manager.AttachmentHealth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment_manager.AttachmentHealth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.AttachmentHealth.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
activeFaultsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment_manager.AttachmentHealth}
 */
proto.attachment_manager.AttachmentHealth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment_manager.AttachmentHealth;
  return proto.attachment_manager.AttachmentHealth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment_manager.AttachmentHealth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment_manager.AttachmentHealth}
 */
proto.attachment_manager.AttachmentHealth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.accessories.AccessoryEnum.Enum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.attachment_manager.AttachmentFault.Enum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActiveFaults(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment_manager.AttachmentHealth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment_manager.AttachmentHealth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment_manager.AttachmentHealth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.AttachmentHealth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getActiveFaultsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional accessories.AccessoryEnum.Enum type = 1;
 * @return {!proto.accessories.AccessoryEnum.Enum}
 */
proto.attachment_manager.AttachmentHealth.prototype.getType = function() {
  return /** @type {!proto.accessories.AccessoryEnum.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.accessories.AccessoryEnum.Enum} value
 * @return {!proto.attachment_manager.AttachmentHealth} returns this
 */
proto.attachment_manager.AttachmentHealth.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated AttachmentFault.Enum active_faults = 2;
 * @return {!Array<!proto.attachment_manager.AttachmentFault.Enum>}
 */
proto.attachment_manager.AttachmentHealth.prototype.getActiveFaultsList = function() {
  return /** @type {!Array<!proto.attachment_manager.AttachmentFault.Enum>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.attachment_manager.AttachmentFault.Enum>} value
 * @return {!proto.attachment_manager.AttachmentHealth} returns this
 */
proto.attachment_manager.AttachmentHealth.prototype.setActiveFaultsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.attachment_manager.AttachmentFault.Enum} value
 * @param {number=} opt_index
 * @return {!proto.attachment_manager.AttachmentHealth} returns this
 */
proto.attachment_manager.AttachmentHealth.prototype.addActiveFaults = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment_manager.AttachmentHealth} returns this
 */
proto.attachment_manager.AttachmentHealth.prototype.clearActiveFaultsList = function() {
  return this.setActiveFaultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment_manager.VehicleAttachmentHealth.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment_manager.VehicleAttachmentHealth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment_manager.VehicleAttachmentHealth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.VehicleAttachmentHealth.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
attachmentsMap: (f = msg.getAttachmentsMap()) ? f.toObject(includeInstance, proto.attachment_manager.AttachmentHealth.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment_manager.VehicleAttachmentHealth}
 */
proto.attachment_manager.VehicleAttachmentHealth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment_manager.VehicleAttachmentHealth;
  return proto.attachment_manager.VehicleAttachmentHealth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment_manager.VehicleAttachmentHealth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment_manager.VehicleAttachmentHealth}
 */
proto.attachment_manager.VehicleAttachmentHealth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = msg.getAttachmentsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.attachment_manager.AttachmentHealth.deserializeBinaryFromReader, 0, new proto.attachment_manager.AttachmentHealth());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment_manager.VehicleAttachmentHealth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment_manager.VehicleAttachmentHealth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment_manager.VehicleAttachmentHealth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment_manager.VehicleAttachmentHealth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAttachmentsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.attachment_manager.AttachmentHealth.serializeBinaryToWriter);
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.attachment_manager.VehicleAttachmentHealth.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment_manager.VehicleAttachmentHealth} returns this
 */
proto.attachment_manager.VehicleAttachmentHealth.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<int32, AttachmentHealth> attachments = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.attachment_manager.AttachmentHealth>}
 */
proto.attachment_manager.VehicleAttachmentHealth.prototype.getAttachmentsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.attachment_manager.AttachmentHealth>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.attachment_manager.AttachmentHealth));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.attachment_manager.VehicleAttachmentHealth} returns this
 */
proto.attachment_manager.VehicleAttachmentHealth.prototype.clearAttachmentsMap = function() {
  this.getAttachmentsMap().clear();
  return this;
};


goog.object.extend(exports, proto.attachment_manager);
