// source: pbtypes/cloud/services/webrtc_signal_proxy/signal_proxy.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.signal_proxy.DeviceSignalProxyConnectionParams', null, global);
goog.exportSymbol('proto.signal_proxy.IceCandidate', null, global);
goog.exportSymbol('proto.signal_proxy.IceCandidate.IceCandidateTarget', null, global);
goog.exportSymbol('proto.signal_proxy.LivekitTokenRequest', null, global);
goog.exportSymbol('proto.signal_proxy.LivekitTokenRequest.Role', null, global);
goog.exportSymbol('proto.signal_proxy.LivekitTokenResponse', null, global);
goog.exportSymbol('proto.signal_proxy.Participant', null, global);
goog.exportSymbol('proto.signal_proxy.ParticipantDevice', null, global);
goog.exportSymbol('proto.signal_proxy.ParticipantInfo', null, global);
goog.exportSymbol('proto.signal_proxy.ParticipantRole', null, global);
goog.exportSymbol('proto.signal_proxy.SFUSessionRequest', null, global);
goog.exportSymbol('proto.signal_proxy.SdpMessage', null, global);
goog.exportSymbol('proto.signal_proxy.SdpMessage.SdpType', null, global);
goog.exportSymbol('proto.signal_proxy.SignalProxyConnectionParams', null, global);
goog.exportSymbol('proto.signal_proxy.Track', null, global);
goog.exportSymbol('proto.signal_proxy.TrackAudienceInfo', null, global);
goog.exportSymbol('proto.signal_proxy.TrackAudienceInfo.TrackAudience', null, global);
goog.exportSymbol('proto.signal_proxy.TrackInfo', null, global);
goog.exportSymbol('proto.signal_proxy.WebsocketClientMessage', null, global);
goog.exportSymbol('proto.signal_proxy.WebsocketClientMessage.PayloadCase', null, global);
goog.exportSymbol('proto.signal_proxy.WebsocketServerMessage', null, global);
goog.exportSymbol('proto.signal_proxy.WebsocketServerMessage.PayloadCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.SignalProxyConnectionParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.signal_proxy.SignalProxyConnectionParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.SignalProxyConnectionParams.displayName = 'proto.signal_proxy.SignalProxyConnectionParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.signal_proxy.DeviceSignalProxyConnectionParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.DeviceSignalProxyConnectionParams.displayName = 'proto.signal_proxy.DeviceSignalProxyConnectionParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.SdpMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.signal_proxy.SdpMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.SdpMessage.displayName = 'proto.signal_proxy.SdpMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.IceCandidate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.signal_proxy.IceCandidate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.IceCandidate.displayName = 'proto.signal_proxy.IceCandidate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.TrackInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.signal_proxy.TrackInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.TrackInfo.displayName = 'proto.signal_proxy.TrackInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.SFUSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.signal_proxy.SFUSessionRequest.repeatedFields_, null);
};
goog.inherits(proto.signal_proxy.SFUSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.SFUSessionRequest.displayName = 'proto.signal_proxy.SFUSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.ParticipantInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.signal_proxy.ParticipantInfo.repeatedFields_, null);
};
goog.inherits(proto.signal_proxy.ParticipantInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.ParticipantInfo.displayName = 'proto.signal_proxy.ParticipantInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.Participant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.signal_proxy.Participant.repeatedFields_, null);
};
goog.inherits(proto.signal_proxy.Participant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.Participant.displayName = 'proto.signal_proxy.Participant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.Track = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.signal_proxy.Track, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.Track.displayName = 'proto.signal_proxy.Track';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.TrackAudienceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.signal_proxy.TrackAudienceInfo.repeatedFields_, null);
};
goog.inherits(proto.signal_proxy.TrackAudienceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.TrackAudienceInfo.displayName = 'proto.signal_proxy.TrackAudienceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.signal_proxy.TrackAudienceInfo.TrackAudience.repeatedFields_, null);
};
goog.inherits(proto.signal_proxy.TrackAudienceInfo.TrackAudience, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.TrackAudienceInfo.TrackAudience.displayName = 'proto.signal_proxy.TrackAudienceInfo.TrackAudience';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.WebsocketClientMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.signal_proxy.WebsocketClientMessage.oneofGroups_);
};
goog.inherits(proto.signal_proxy.WebsocketClientMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.WebsocketClientMessage.displayName = 'proto.signal_proxy.WebsocketClientMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.WebsocketServerMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.signal_proxy.WebsocketServerMessage.oneofGroups_);
};
goog.inherits(proto.signal_proxy.WebsocketServerMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.WebsocketServerMessage.displayName = 'proto.signal_proxy.WebsocketServerMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.LivekitTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.signal_proxy.LivekitTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.LivekitTokenRequest.displayName = 'proto.signal_proxy.LivekitTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.signal_proxy.LivekitTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.signal_proxy.LivekitTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.signal_proxy.LivekitTokenResponse.displayName = 'proto.signal_proxy.LivekitTokenResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.SignalProxyConnectionParams.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.SignalProxyConnectionParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.SignalProxyConnectionParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.SignalProxyConnectionParams.toObject = function(includeInstance, msg) {
  var f, obj = {
serverUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
jwt: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.SignalProxyConnectionParams}
 */
proto.signal_proxy.SignalProxyConnectionParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.SignalProxyConnectionParams;
  return proto.signal_proxy.SignalProxyConnectionParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.SignalProxyConnectionParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.SignalProxyConnectionParams}
 */
proto.signal_proxy.SignalProxyConnectionParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServerUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.SignalProxyConnectionParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.SignalProxyConnectionParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.SignalProxyConnectionParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.SignalProxyConnectionParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getJwt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string server_url = 1;
 * @return {string}
 */
proto.signal_proxy.SignalProxyConnectionParams.prototype.getServerUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.SignalProxyConnectionParams} returns this
 */
proto.signal_proxy.SignalProxyConnectionParams.prototype.setServerUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string jwt = 2;
 * @return {string}
 */
proto.signal_proxy.SignalProxyConnectionParams.prototype.getJwt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.SignalProxyConnectionParams} returns this
 */
proto.signal_proxy.SignalProxyConnectionParams.prototype.setJwt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.DeviceSignalProxyConnectionParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.DeviceSignalProxyConnectionParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
deviceType: jspb.Message.getFieldWithDefault(msg, 2, ""),
signalProxyConnection: (f = msg.getSignalProxyConnection()) && proto.signal_proxy.SignalProxyConnectionParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.DeviceSignalProxyConnectionParams}
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.DeviceSignalProxyConnectionParams;
  return proto.signal_proxy.DeviceSignalProxyConnectionParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.DeviceSignalProxyConnectionParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.DeviceSignalProxyConnectionParams}
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 3:
      var value = new proto.signal_proxy.SignalProxyConnectionParams;
      reader.readMessage(value,proto.signal_proxy.SignalProxyConnectionParams.deserializeBinaryFromReader);
      msg.setSignalProxyConnection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.DeviceSignalProxyConnectionParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.DeviceSignalProxyConnectionParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSignalProxyConnection();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.signal_proxy.SignalProxyConnectionParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.DeviceSignalProxyConnectionParams} returns this
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_type = 2;
 * @return {string}
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.DeviceSignalProxyConnectionParams} returns this
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SignalProxyConnectionParams signal_proxy_connection = 3;
 * @return {?proto.signal_proxy.SignalProxyConnectionParams}
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.prototype.getSignalProxyConnection = function() {
  return /** @type{?proto.signal_proxy.SignalProxyConnectionParams} */ (
    jspb.Message.getWrapperField(this, proto.signal_proxy.SignalProxyConnectionParams, 3));
};


/**
 * @param {?proto.signal_proxy.SignalProxyConnectionParams|undefined} value
 * @return {!proto.signal_proxy.DeviceSignalProxyConnectionParams} returns this
*/
proto.signal_proxy.DeviceSignalProxyConnectionParams.prototype.setSignalProxyConnection = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.signal_proxy.DeviceSignalProxyConnectionParams} returns this
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.prototype.clearSignalProxyConnection = function() {
  return this.setSignalProxyConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.signal_proxy.DeviceSignalProxyConnectionParams.prototype.hasSignalProxyConnection = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.SdpMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.SdpMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.SdpMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.SdpMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
payload: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.SdpMessage}
 */
proto.signal_proxy.SdpMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.SdpMessage;
  return proto.signal_proxy.SdpMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.SdpMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.SdpMessage}
 */
proto.signal_proxy.SdpMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.signal_proxy.SdpMessage.SdpType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.SdpMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.SdpMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.SdpMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.SdpMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.signal_proxy.SdpMessage.SdpType = {
  OFFER: 0,
  ANSWER: 1
};

/**
 * optional SdpType type = 1;
 * @return {!proto.signal_proxy.SdpMessage.SdpType}
 */
proto.signal_proxy.SdpMessage.prototype.getType = function() {
  return /** @type {!proto.signal_proxy.SdpMessage.SdpType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.signal_proxy.SdpMessage.SdpType} value
 * @return {!proto.signal_proxy.SdpMessage} returns this
 */
proto.signal_proxy.SdpMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string payload = 2;
 * @return {string}
 */
proto.signal_proxy.SdpMessage.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.SdpMessage} returns this
 */
proto.signal_proxy.SdpMessage.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.IceCandidate.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.IceCandidate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.IceCandidate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.IceCandidate.toObject = function(includeInstance, msg) {
  var f, obj = {
payload: jspb.Message.getFieldWithDefault(msg, 1, ""),
target: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.IceCandidate}
 */
proto.signal_proxy.IceCandidate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.IceCandidate;
  return proto.signal_proxy.IceCandidate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.IceCandidate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.IceCandidate}
 */
proto.signal_proxy.IceCandidate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    case 2:
      var value = /** @type {!proto.signal_proxy.IceCandidate.IceCandidateTarget} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.IceCandidate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.IceCandidate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.IceCandidate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.IceCandidate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.signal_proxy.IceCandidate.IceCandidateTarget = {
  PUBLISH: 0,
  SUBSCRIBE: 1
};

/**
 * optional string payload = 1;
 * @return {string}
 */
proto.signal_proxy.IceCandidate.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.IceCandidate} returns this
 */
proto.signal_proxy.IceCandidate.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IceCandidateTarget target = 2;
 * @return {!proto.signal_proxy.IceCandidate.IceCandidateTarget}
 */
proto.signal_proxy.IceCandidate.prototype.getTarget = function() {
  return /** @type {!proto.signal_proxy.IceCandidate.IceCandidateTarget} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.signal_proxy.IceCandidate.IceCandidateTarget} value
 * @return {!proto.signal_proxy.IceCandidate} returns this
 */
proto.signal_proxy.IceCandidate.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.TrackInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.TrackInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.TrackInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.TrackInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.TrackInfo}
 */
proto.signal_proxy.TrackInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.TrackInfo;
  return proto.signal_proxy.TrackInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.TrackInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.TrackInfo}
 */
proto.signal_proxy.TrackInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.TrackInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.TrackInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.TrackInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.TrackInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.signal_proxy.TrackInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.TrackInfo} returns this
 */
proto.signal_proxy.TrackInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.signal_proxy.SFUSessionRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.SFUSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.SFUSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.SFUSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.SFUSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
clientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
sdpOffer: (f = msg.getSdpOffer()) && proto.signal_proxy.SdpMessage.toObject(includeInstance, f),
videoTracksList: jspb.Message.toObjectList(msg.getVideoTracksList(),
    proto.signal_proxy.TrackInfo.toObject, includeInstance),
autoSubscribe: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
participantDevice: jspb.Message.getFieldWithDefault(msg, 6, 0),
participantRole: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.SFUSessionRequest}
 */
proto.signal_proxy.SFUSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.SFUSessionRequest;
  return proto.signal_proxy.SFUSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.SFUSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.SFUSessionRequest}
 */
proto.signal_proxy.SFUSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 3:
      var value = new proto.signal_proxy.SdpMessage;
      reader.readMessage(value,proto.signal_proxy.SdpMessage.deserializeBinaryFromReader);
      msg.setSdpOffer(value);
      break;
    case 4:
      var value = new proto.signal_proxy.TrackInfo;
      reader.readMessage(value,proto.signal_proxy.TrackInfo.deserializeBinaryFromReader);
      msg.addVideoTracks(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoSubscribe(value);
      break;
    case 6:
      var value = /** @type {!proto.signal_proxy.ParticipantDevice} */ (reader.readEnum());
      msg.setParticipantDevice(value);
      break;
    case 7:
      var value = /** @type {!proto.signal_proxy.ParticipantRole} */ (reader.readEnum());
      msg.setParticipantRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.SFUSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.SFUSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.SFUSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.SFUSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSdpOffer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.signal_proxy.SdpMessage.serializeBinaryToWriter
    );
  }
  f = message.getVideoTracksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.signal_proxy.TrackInfo.serializeBinaryToWriter
    );
  }
  f = message.getAutoSubscribe();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getParticipantDevice();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getParticipantRole();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.signal_proxy.SFUSessionRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.SFUSessionRequest} returns this
 */
proto.signal_proxy.SFUSessionRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_id = 2;
 * @return {string}
 */
proto.signal_proxy.SFUSessionRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.SFUSessionRequest} returns this
 */
proto.signal_proxy.SFUSessionRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SdpMessage sdp_offer = 3;
 * @return {?proto.signal_proxy.SdpMessage}
 */
proto.signal_proxy.SFUSessionRequest.prototype.getSdpOffer = function() {
  return /** @type{?proto.signal_proxy.SdpMessage} */ (
    jspb.Message.getWrapperField(this, proto.signal_proxy.SdpMessage, 3));
};


/**
 * @param {?proto.signal_proxy.SdpMessage|undefined} value
 * @return {!proto.signal_proxy.SFUSessionRequest} returns this
*/
proto.signal_proxy.SFUSessionRequest.prototype.setSdpOffer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.signal_proxy.SFUSessionRequest} returns this
 */
proto.signal_proxy.SFUSessionRequest.prototype.clearSdpOffer = function() {
  return this.setSdpOffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.signal_proxy.SFUSessionRequest.prototype.hasSdpOffer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated TrackInfo video_tracks = 4;
 * @return {!Array<!proto.signal_proxy.TrackInfo>}
 */
proto.signal_proxy.SFUSessionRequest.prototype.getVideoTracksList = function() {
  return /** @type{!Array<!proto.signal_proxy.TrackInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.signal_proxy.TrackInfo, 4));
};


/**
 * @param {!Array<!proto.signal_proxy.TrackInfo>} value
 * @return {!proto.signal_proxy.SFUSessionRequest} returns this
*/
proto.signal_proxy.SFUSessionRequest.prototype.setVideoTracksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.signal_proxy.TrackInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.signal_proxy.TrackInfo}
 */
proto.signal_proxy.SFUSessionRequest.prototype.addVideoTracks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.signal_proxy.TrackInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.signal_proxy.SFUSessionRequest} returns this
 */
proto.signal_proxy.SFUSessionRequest.prototype.clearVideoTracksList = function() {
  return this.setVideoTracksList([]);
};


/**
 * optional bool auto_subscribe = 5;
 * @return {boolean}
 */
proto.signal_proxy.SFUSessionRequest.prototype.getAutoSubscribe = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.signal_proxy.SFUSessionRequest} returns this
 */
proto.signal_proxy.SFUSessionRequest.prototype.setAutoSubscribe = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional ParticipantDevice participant_device = 6;
 * @return {!proto.signal_proxy.ParticipantDevice}
 */
proto.signal_proxy.SFUSessionRequest.prototype.getParticipantDevice = function() {
  return /** @type {!proto.signal_proxy.ParticipantDevice} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.signal_proxy.ParticipantDevice} value
 * @return {!proto.signal_proxy.SFUSessionRequest} returns this
 */
proto.signal_proxy.SFUSessionRequest.prototype.setParticipantDevice = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ParticipantRole participant_role = 7;
 * @return {!proto.signal_proxy.ParticipantRole}
 */
proto.signal_proxy.SFUSessionRequest.prototype.getParticipantRole = function() {
  return /** @type {!proto.signal_proxy.ParticipantRole} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.signal_proxy.ParticipantRole} value
 * @return {!proto.signal_proxy.SFUSessionRequest} returns this
 */
proto.signal_proxy.SFUSessionRequest.prototype.setParticipantRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.signal_proxy.ParticipantInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.ParticipantInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.ParticipantInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.ParticipantInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.ParticipantInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
viewerCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
sidMapMap: (f = msg.getSidMapMap()) ? f.toObject(includeInstance, undefined) : [],
clientParticipantIdentity: jspb.Message.getFieldWithDefault(msg, 3, ""),
participantsList: jspb.Message.toObjectList(msg.getParticipantsList(),
    proto.signal_proxy.Participant.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.ParticipantInfo}
 */
proto.signal_proxy.ParticipantInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.ParticipantInfo;
  return proto.signal_proxy.ParticipantInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.ParticipantInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.ParticipantInfo}
 */
proto.signal_proxy.ParticipantInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setViewerCount(value);
      break;
    case 2:
      var value = msg.getSidMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientParticipantIdentity(value);
      break;
    case 4:
      var value = new proto.signal_proxy.Participant;
      reader.readMessage(value,proto.signal_proxy.Participant.deserializeBinaryFromReader);
      msg.addParticipants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.ParticipantInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.ParticipantInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.ParticipantInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.ParticipantInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewerCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSidMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getClientParticipantIdentity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getParticipantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.signal_proxy.Participant.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 viewer_count = 1;
 * @return {number}
 */
proto.signal_proxy.ParticipantInfo.prototype.getViewerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.signal_proxy.ParticipantInfo} returns this
 */
proto.signal_proxy.ParticipantInfo.prototype.setViewerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<string, string> sid_map = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.signal_proxy.ParticipantInfo.prototype.getSidMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.signal_proxy.ParticipantInfo} returns this
 */
proto.signal_proxy.ParticipantInfo.prototype.clearSidMapMap = function() {
  this.getSidMapMap().clear();
  return this;
};


/**
 * optional string client_participant_identity = 3;
 * @return {string}
 */
proto.signal_proxy.ParticipantInfo.prototype.getClientParticipantIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.ParticipantInfo} returns this
 */
proto.signal_proxy.ParticipantInfo.prototype.setClientParticipantIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Participant participants = 4;
 * @return {!Array<!proto.signal_proxy.Participant>}
 */
proto.signal_proxy.ParticipantInfo.prototype.getParticipantsList = function() {
  return /** @type{!Array<!proto.signal_proxy.Participant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.signal_proxy.Participant, 4));
};


/**
 * @param {!Array<!proto.signal_proxy.Participant>} value
 * @return {!proto.signal_proxy.ParticipantInfo} returns this
*/
proto.signal_proxy.ParticipantInfo.prototype.setParticipantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.signal_proxy.Participant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.signal_proxy.Participant}
 */
proto.signal_proxy.ParticipantInfo.prototype.addParticipants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.signal_proxy.Participant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.signal_proxy.ParticipantInfo} returns this
 */
proto.signal_proxy.ParticipantInfo.prototype.clearParticipantsList = function() {
  return this.setParticipantsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.signal_proxy.Participant.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.Participant.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.Participant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.Participant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.Participant.toObject = function(includeInstance, msg) {
  var f, obj = {
sid: jspb.Message.getFieldWithDefault(msg, 1, ""),
identity: jspb.Message.getFieldWithDefault(msg, 2, ""),
tracksList: jspb.Message.toObjectList(msg.getTracksList(),
    proto.signal_proxy.Track.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.Participant}
 */
proto.signal_proxy.Participant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.Participant;
  return proto.signal_proxy.Participant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.Participant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.Participant}
 */
proto.signal_proxy.Participant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentity(value);
      break;
    case 3:
      var value = new proto.signal_proxy.Track;
      reader.readMessage(value,proto.signal_proxy.Track.deserializeBinaryFromReader);
      msg.addTracks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.Participant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.Participant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.Participant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.Participant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTracksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.signal_proxy.Track.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sid = 1;
 * @return {string}
 */
proto.signal_proxy.Participant.prototype.getSid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.Participant} returns this
 */
proto.signal_proxy.Participant.prototype.setSid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string identity = 2;
 * @return {string}
 */
proto.signal_proxy.Participant.prototype.getIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.Participant} returns this
 */
proto.signal_proxy.Participant.prototype.setIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Track tracks = 3;
 * @return {!Array<!proto.signal_proxy.Track>}
 */
proto.signal_proxy.Participant.prototype.getTracksList = function() {
  return /** @type{!Array<!proto.signal_proxy.Track>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.signal_proxy.Track, 3));
};


/**
 * @param {!Array<!proto.signal_proxy.Track>} value
 * @return {!proto.signal_proxy.Participant} returns this
*/
proto.signal_proxy.Participant.prototype.setTracksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.signal_proxy.Track=} opt_value
 * @param {number=} opt_index
 * @return {!proto.signal_proxy.Track}
 */
proto.signal_proxy.Participant.prototype.addTracks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.signal_proxy.Track, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.signal_proxy.Participant} returns this
 */
proto.signal_proxy.Participant.prototype.clearTracksList = function() {
  return this.setTracksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.Track.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.Track.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.Track} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.Track.toObject = function(includeInstance, msg) {
  var f, obj = {
sid: jspb.Message.getFieldWithDefault(msg, 1, ""),
name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.Track}
 */
proto.signal_proxy.Track.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.Track;
  return proto.signal_proxy.Track.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.Track} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.Track}
 */
proto.signal_proxy.Track.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.Track.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.Track.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.Track} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.Track.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string sid = 1;
 * @return {string}
 */
proto.signal_proxy.Track.prototype.getSid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.Track} returns this
 */
proto.signal_proxy.Track.prototype.setSid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.signal_proxy.Track.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.Track} returns this
 */
proto.signal_proxy.Track.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.signal_proxy.TrackAudienceInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.TrackAudienceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.TrackAudienceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.TrackAudienceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.TrackAudienceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
trackAudiencesList: jspb.Message.toObjectList(msg.getTrackAudiencesList(),
    proto.signal_proxy.TrackAudienceInfo.TrackAudience.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.TrackAudienceInfo}
 */
proto.signal_proxy.TrackAudienceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.TrackAudienceInfo;
  return proto.signal_proxy.TrackAudienceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.TrackAudienceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.TrackAudienceInfo}
 */
proto.signal_proxy.TrackAudienceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.signal_proxy.TrackAudienceInfo.TrackAudience;
      reader.readMessage(value,proto.signal_proxy.TrackAudienceInfo.TrackAudience.deserializeBinaryFromReader);
      msg.addTrackAudiences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.TrackAudienceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.TrackAudienceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.TrackAudienceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.TrackAudienceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackAudiencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.signal_proxy.TrackAudienceInfo.TrackAudience.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.TrackAudienceInfo.TrackAudience.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.TrackAudienceInfo.TrackAudience} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.toObject = function(includeInstance, msg) {
  var f, obj = {
trackSid: jspb.Message.getFieldWithDefault(msg, 2, ""),
trackName: jspb.Message.getFieldWithDefault(msg, 3, ""),
forceMute: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
viewerSidsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.TrackAudienceInfo.TrackAudience}
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.TrackAudienceInfo.TrackAudience;
  return proto.signal_proxy.TrackAudienceInfo.TrackAudience.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.TrackAudienceInfo.TrackAudience} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.TrackAudienceInfo.TrackAudience}
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackSid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceMute(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addViewerSids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.TrackAudienceInfo.TrackAudience.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.TrackAudienceInfo.TrackAudience} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackSid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrackName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getForceMute();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getViewerSidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string track_sid = 2;
 * @return {string}
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.getTrackSid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.TrackAudienceInfo.TrackAudience} returns this
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.setTrackSid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string track_name = 3;
 * @return {string}
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.getTrackName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.TrackAudienceInfo.TrackAudience} returns this
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.setTrackName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool force_mute = 4;
 * @return {boolean}
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.getForceMute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.signal_proxy.TrackAudienceInfo.TrackAudience} returns this
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.setForceMute = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated string viewer_sids = 5;
 * @return {!Array<string>}
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.getViewerSidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.signal_proxy.TrackAudienceInfo.TrackAudience} returns this
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.setViewerSidsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.signal_proxy.TrackAudienceInfo.TrackAudience} returns this
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.addViewerSids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.signal_proxy.TrackAudienceInfo.TrackAudience} returns this
 */
proto.signal_proxy.TrackAudienceInfo.TrackAudience.prototype.clearViewerSidsList = function() {
  return this.setViewerSidsList([]);
};


/**
 * repeated TrackAudience track_audiences = 1;
 * @return {!Array<!proto.signal_proxy.TrackAudienceInfo.TrackAudience>}
 */
proto.signal_proxy.TrackAudienceInfo.prototype.getTrackAudiencesList = function() {
  return /** @type{!Array<!proto.signal_proxy.TrackAudienceInfo.TrackAudience>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.signal_proxy.TrackAudienceInfo.TrackAudience, 1));
};


/**
 * @param {!Array<!proto.signal_proxy.TrackAudienceInfo.TrackAudience>} value
 * @return {!proto.signal_proxy.TrackAudienceInfo} returns this
*/
proto.signal_proxy.TrackAudienceInfo.prototype.setTrackAudiencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.signal_proxy.TrackAudienceInfo.TrackAudience=} opt_value
 * @param {number=} opt_index
 * @return {!proto.signal_proxy.TrackAudienceInfo.TrackAudience}
 */
proto.signal_proxy.TrackAudienceInfo.prototype.addTrackAudiences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.signal_proxy.TrackAudienceInfo.TrackAudience, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.signal_proxy.TrackAudienceInfo} returns this
 */
proto.signal_proxy.TrackAudienceInfo.prototype.clearTrackAudiencesList = function() {
  return this.setTrackAudiencesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.signal_proxy.WebsocketClientMessage.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.signal_proxy.WebsocketClientMessage.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  SFUSESSIONREQUEST: 1,
  SDPANSWER: 2
};

/**
 * @return {proto.signal_proxy.WebsocketClientMessage.PayloadCase}
 */
proto.signal_proxy.WebsocketClientMessage.prototype.getPayloadCase = function() {
  return /** @type {proto.signal_proxy.WebsocketClientMessage.PayloadCase} */(jspb.Message.computeOneofCase(this, proto.signal_proxy.WebsocketClientMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.WebsocketClientMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.WebsocketClientMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.WebsocketClientMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.WebsocketClientMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
sfusessionrequest: (f = msg.getSfusessionrequest()) && proto.signal_proxy.SFUSessionRequest.toObject(includeInstance, f),
sdpanswer: (f = msg.getSdpanswer()) && proto.signal_proxy.SdpMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.WebsocketClientMessage}
 */
proto.signal_proxy.WebsocketClientMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.WebsocketClientMessage;
  return proto.signal_proxy.WebsocketClientMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.WebsocketClientMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.WebsocketClientMessage}
 */
proto.signal_proxy.WebsocketClientMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.signal_proxy.SFUSessionRequest;
      reader.readMessage(value,proto.signal_proxy.SFUSessionRequest.deserializeBinaryFromReader);
      msg.setSfusessionrequest(value);
      break;
    case 2:
      var value = new proto.signal_proxy.SdpMessage;
      reader.readMessage(value,proto.signal_proxy.SdpMessage.deserializeBinaryFromReader);
      msg.setSdpanswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.WebsocketClientMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.WebsocketClientMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.WebsocketClientMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.WebsocketClientMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSfusessionrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.signal_proxy.SFUSessionRequest.serializeBinaryToWriter
    );
  }
  f = message.getSdpanswer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.signal_proxy.SdpMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional SFUSessionRequest sfuSessionRequest = 1;
 * @return {?proto.signal_proxy.SFUSessionRequest}
 */
proto.signal_proxy.WebsocketClientMessage.prototype.getSfusessionrequest = function() {
  return /** @type{?proto.signal_proxy.SFUSessionRequest} */ (
    jspb.Message.getWrapperField(this, proto.signal_proxy.SFUSessionRequest, 1));
};


/**
 * @param {?proto.signal_proxy.SFUSessionRequest|undefined} value
 * @return {!proto.signal_proxy.WebsocketClientMessage} returns this
*/
proto.signal_proxy.WebsocketClientMessage.prototype.setSfusessionrequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.signal_proxy.WebsocketClientMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.signal_proxy.WebsocketClientMessage} returns this
 */
proto.signal_proxy.WebsocketClientMessage.prototype.clearSfusessionrequest = function() {
  return this.setSfusessionrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.signal_proxy.WebsocketClientMessage.prototype.hasSfusessionrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SdpMessage sdpAnswer = 2;
 * @return {?proto.signal_proxy.SdpMessage}
 */
proto.signal_proxy.WebsocketClientMessage.prototype.getSdpanswer = function() {
  return /** @type{?proto.signal_proxy.SdpMessage} */ (
    jspb.Message.getWrapperField(this, proto.signal_proxy.SdpMessage, 2));
};


/**
 * @param {?proto.signal_proxy.SdpMessage|undefined} value
 * @return {!proto.signal_proxy.WebsocketClientMessage} returns this
*/
proto.signal_proxy.WebsocketClientMessage.prototype.setSdpanswer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.signal_proxy.WebsocketClientMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.signal_proxy.WebsocketClientMessage} returns this
 */
proto.signal_proxy.WebsocketClientMessage.prototype.clearSdpanswer = function() {
  return this.setSdpanswer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.signal_proxy.WebsocketClientMessage.prototype.hasSdpanswer = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.signal_proxy.WebsocketServerMessage.oneofGroups_ = [[2,3,4,5]];

/**
 * @enum {number}
 */
proto.signal_proxy.WebsocketServerMessage.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  SDPMESSAGE: 2,
  ICECANDIDATE: 3,
  PARTICIPANT_INFO: 4,
  TRACK_AUDIENCE_INFO: 5
};

/**
 * @return {proto.signal_proxy.WebsocketServerMessage.PayloadCase}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.getPayloadCase = function() {
  return /** @type {proto.signal_proxy.WebsocketServerMessage.PayloadCase} */(jspb.Message.computeOneofCase(this, proto.signal_proxy.WebsocketServerMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.WebsocketServerMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.WebsocketServerMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.WebsocketServerMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
error: jspb.Message.getFieldWithDefault(msg, 1, ""),
sdpmessage: (f = msg.getSdpmessage()) && proto.signal_proxy.SdpMessage.toObject(includeInstance, f),
icecandidate: (f = msg.getIcecandidate()) && proto.signal_proxy.IceCandidate.toObject(includeInstance, f),
participantInfo: (f = msg.getParticipantInfo()) && proto.signal_proxy.ParticipantInfo.toObject(includeInstance, f),
trackAudienceInfo: (f = msg.getTrackAudienceInfo()) && proto.signal_proxy.TrackAudienceInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.WebsocketServerMessage}
 */
proto.signal_proxy.WebsocketServerMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.WebsocketServerMessage;
  return proto.signal_proxy.WebsocketServerMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.WebsocketServerMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.WebsocketServerMessage}
 */
proto.signal_proxy.WebsocketServerMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 2:
      var value = new proto.signal_proxy.SdpMessage;
      reader.readMessage(value,proto.signal_proxy.SdpMessage.deserializeBinaryFromReader);
      msg.setSdpmessage(value);
      break;
    case 3:
      var value = new proto.signal_proxy.IceCandidate;
      reader.readMessage(value,proto.signal_proxy.IceCandidate.deserializeBinaryFromReader);
      msg.setIcecandidate(value);
      break;
    case 4:
      var value = new proto.signal_proxy.ParticipantInfo;
      reader.readMessage(value,proto.signal_proxy.ParticipantInfo.deserializeBinaryFromReader);
      msg.setParticipantInfo(value);
      break;
    case 5:
      var value = new proto.signal_proxy.TrackAudienceInfo;
      reader.readMessage(value,proto.signal_proxy.TrackAudienceInfo.deserializeBinaryFromReader);
      msg.setTrackAudienceInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.WebsocketServerMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.WebsocketServerMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.WebsocketServerMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSdpmessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.signal_proxy.SdpMessage.serializeBinaryToWriter
    );
  }
  f = message.getIcecandidate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.signal_proxy.IceCandidate.serializeBinaryToWriter
    );
  }
  f = message.getParticipantInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.signal_proxy.ParticipantInfo.serializeBinaryToWriter
    );
  }
  f = message.getTrackAudienceInfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.signal_proxy.TrackAudienceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string error = 1;
 * @return {string}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.WebsocketServerMessage} returns this
 */
proto.signal_proxy.WebsocketServerMessage.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SdpMessage sdpMessage = 2;
 * @return {?proto.signal_proxy.SdpMessage}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.getSdpmessage = function() {
  return /** @type{?proto.signal_proxy.SdpMessage} */ (
    jspb.Message.getWrapperField(this, proto.signal_proxy.SdpMessage, 2));
};


/**
 * @param {?proto.signal_proxy.SdpMessage|undefined} value
 * @return {!proto.signal_proxy.WebsocketServerMessage} returns this
*/
proto.signal_proxy.WebsocketServerMessage.prototype.setSdpmessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.signal_proxy.WebsocketServerMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.signal_proxy.WebsocketServerMessage} returns this
 */
proto.signal_proxy.WebsocketServerMessage.prototype.clearSdpmessage = function() {
  return this.setSdpmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.hasSdpmessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IceCandidate IceCandidate = 3;
 * @return {?proto.signal_proxy.IceCandidate}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.getIcecandidate = function() {
  return /** @type{?proto.signal_proxy.IceCandidate} */ (
    jspb.Message.getWrapperField(this, proto.signal_proxy.IceCandidate, 3));
};


/**
 * @param {?proto.signal_proxy.IceCandidate|undefined} value
 * @return {!proto.signal_proxy.WebsocketServerMessage} returns this
*/
proto.signal_proxy.WebsocketServerMessage.prototype.setIcecandidate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.signal_proxy.WebsocketServerMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.signal_proxy.WebsocketServerMessage} returns this
 */
proto.signal_proxy.WebsocketServerMessage.prototype.clearIcecandidate = function() {
  return this.setIcecandidate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.hasIcecandidate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ParticipantInfo participant_info = 4;
 * @return {?proto.signal_proxy.ParticipantInfo}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.getParticipantInfo = function() {
  return /** @type{?proto.signal_proxy.ParticipantInfo} */ (
    jspb.Message.getWrapperField(this, proto.signal_proxy.ParticipantInfo, 4));
};


/**
 * @param {?proto.signal_proxy.ParticipantInfo|undefined} value
 * @return {!proto.signal_proxy.WebsocketServerMessage} returns this
*/
proto.signal_proxy.WebsocketServerMessage.prototype.setParticipantInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.signal_proxy.WebsocketServerMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.signal_proxy.WebsocketServerMessage} returns this
 */
proto.signal_proxy.WebsocketServerMessage.prototype.clearParticipantInfo = function() {
  return this.setParticipantInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.hasParticipantInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TrackAudienceInfo track_audience_info = 5;
 * @return {?proto.signal_proxy.TrackAudienceInfo}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.getTrackAudienceInfo = function() {
  return /** @type{?proto.signal_proxy.TrackAudienceInfo} */ (
    jspb.Message.getWrapperField(this, proto.signal_proxy.TrackAudienceInfo, 5));
};


/**
 * @param {?proto.signal_proxy.TrackAudienceInfo|undefined} value
 * @return {!proto.signal_proxy.WebsocketServerMessage} returns this
*/
proto.signal_proxy.WebsocketServerMessage.prototype.setTrackAudienceInfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.signal_proxy.WebsocketServerMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.signal_proxy.WebsocketServerMessage} returns this
 */
proto.signal_proxy.WebsocketServerMessage.prototype.clearTrackAudienceInfo = function() {
  return this.setTrackAudienceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.signal_proxy.WebsocketServerMessage.prototype.hasTrackAudienceInfo = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.LivekitTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.LivekitTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.LivekitTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.LivekitTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
roomName: jspb.Message.getFieldWithDefault(msg, 1, ""),
participantIdentity: jspb.Message.getFieldWithDefault(msg, 2, ""),
role: jspb.Message.getFieldWithDefault(msg, 3, 0),
durationSeconds: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.LivekitTokenRequest}
 */
proto.signal_proxy.LivekitTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.LivekitTokenRequest;
  return proto.signal_proxy.LivekitTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.LivekitTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.LivekitTokenRequest}
 */
proto.signal_proxy.LivekitTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipantIdentity(value);
      break;
    case 3:
      var value = /** @type {!proto.signal_proxy.LivekitTokenRequest.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.LivekitTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.LivekitTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.LivekitTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.LivekitTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParticipantIdentity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDurationSeconds();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.signal_proxy.LivekitTokenRequest.Role = {
  NONE: 0,
  VIEWER: 1,
  PILOT: 2,
  PUBLISHER: 3
};

/**
 * optional string room_name = 1;
 * @return {string}
 */
proto.signal_proxy.LivekitTokenRequest.prototype.getRoomName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.LivekitTokenRequest} returns this
 */
proto.signal_proxy.LivekitTokenRequest.prototype.setRoomName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string participant_identity = 2;
 * @return {string}
 */
proto.signal_proxy.LivekitTokenRequest.prototype.getParticipantIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.LivekitTokenRequest} returns this
 */
proto.signal_proxy.LivekitTokenRequest.prototype.setParticipantIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Role role = 3;
 * @return {!proto.signal_proxy.LivekitTokenRequest.Role}
 */
proto.signal_proxy.LivekitTokenRequest.prototype.getRole = function() {
  return /** @type {!proto.signal_proxy.LivekitTokenRequest.Role} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.signal_proxy.LivekitTokenRequest.Role} value
 * @return {!proto.signal_proxy.LivekitTokenRequest} returns this
 */
proto.signal_proxy.LivekitTokenRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 duration_seconds = 4;
 * @return {number}
 */
proto.signal_proxy.LivekitTokenRequest.prototype.getDurationSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.signal_proxy.LivekitTokenRequest} returns this
 */
proto.signal_proxy.LivekitTokenRequest.prototype.setDurationSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.signal_proxy.LivekitTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.signal_proxy.LivekitTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.signal_proxy.LivekitTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.LivekitTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
token: jspb.Message.getFieldWithDefault(msg, 1, ""),
error: jspb.Message.getFieldWithDefault(msg, 2, ""),
viewerCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
maxViewerCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.signal_proxy.LivekitTokenResponse}
 */
proto.signal_proxy.LivekitTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.signal_proxy.LivekitTokenResponse;
  return proto.signal_proxy.LivekitTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.signal_proxy.LivekitTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.signal_proxy.LivekitTokenResponse}
 */
proto.signal_proxy.LivekitTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setViewerCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxViewerCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.signal_proxy.LivekitTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.signal_proxy.LivekitTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.signal_proxy.LivekitTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.signal_proxy.LivekitTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getViewerCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMaxViewerCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.signal_proxy.LivekitTokenResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.LivekitTokenResponse} returns this
 */
proto.signal_proxy.LivekitTokenResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.signal_proxy.LivekitTokenResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.signal_proxy.LivekitTokenResponse} returns this
 */
proto.signal_proxy.LivekitTokenResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 viewer_count = 3;
 * @return {number}
 */
proto.signal_proxy.LivekitTokenResponse.prototype.getViewerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.signal_proxy.LivekitTokenResponse} returns this
 */
proto.signal_proxy.LivekitTokenResponse.prototype.setViewerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 max_viewer_count = 4;
 * @return {number}
 */
proto.signal_proxy.LivekitTokenResponse.prototype.getMaxViewerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.signal_proxy.LivekitTokenResponse} returns this
 */
proto.signal_proxy.LivekitTokenResponse.prototype.setMaxViewerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.signal_proxy.ParticipantDevice = {
  UNKNOWN_DEVICE: 0,
  ANDROID_DEVICE: 1,
  IOS_DEVICE: 2,
  VEHICLE: 3,
  DOCK: 4,
  BROWSER: 5,
  EXTERNAL: 7,
  AXON_BRIDGE: 8,
  SKYEGRESS: 9,
  CLOUD_NODE: 10
};

/**
 * @enum {number}
 */
proto.signal_proxy.ParticipantRole = {
  UNKNOWN_ROLE: 0,
  PUBLISHER: 1,
  VIEWER: 2,
  TELEOP: 3,
  PILOT: 4,
  AUX: 5
};

goog.object.extend(exports, proto.signal_proxy);
