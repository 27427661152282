// source: pbtypes/gen/skills/spoof_obstacle_internal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_eigen_lcm_Vector3d_pb = require('../../../pbtypes/gen/eigen_lcm/Vector3d_pb.js');
goog.object.extend(proto, pbtypes_gen_eigen_lcm_Vector3d_pb);
var pbtypes_gen_skills_obstacle_type_pb = require('../../../pbtypes/gen/skills/obstacle_type_pb.js');
goog.object.extend(proto, pbtypes_gen_skills_obstacle_type_pb);
goog.exportSymbol('proto.skills.SpoofObstacleInternal', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SpoofObstacleInternal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SpoofObstacleInternal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SpoofObstacleInternal.displayName = 'proto.skills.SpoofObstacleInternal';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SpoofObstacleInternal.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SpoofObstacleInternal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SpoofObstacleInternal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SpoofObstacleInternal.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
obstacleType: jspb.Message.getFieldWithDefault(msg, 2, 0),
alwaysSpoof: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
timeAhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
width: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
length: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
position: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
yzPlane: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
distanceAhead: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
boxMinPoint: (f = msg.getBoxMinPoint()) && pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.toObject(includeInstance, f),
boxMaxPoint: (f = msg.getBoxMaxPoint()) && pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SpoofObstacleInternal}
 */
proto.skills.SpoofObstacleInternal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SpoofObstacleInternal;
  return proto.skills.SpoofObstacleInternal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SpoofObstacleInternal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SpoofObstacleInternal}
 */
proto.skills.SpoofObstacleInternal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.skills.ObstacleType.Enum} */ (reader.readEnum());
      msg.setObstacleType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlwaysSpoof(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimeAhead(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRadius(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWidth(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLength(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPosition(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setYzPlane(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistanceAhead(value);
      break;
    case 40:
      var value = new pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.deserializeBinaryFromReader);
      msg.setBoxMinPoint(value);
      break;
    case 41:
      var value = new pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.deserializeBinaryFromReader);
      msg.setBoxMaxPoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SpoofObstacleInternal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SpoofObstacleInternal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SpoofObstacleInternal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SpoofObstacleInternal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getObstacleType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAlwaysSpoof();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTimeAhead();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getLength();
  if (f !== 0.0) {
    writer.writeFloat(
      21,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0.0) {
    writer.writeFloat(
      22,
      f
    );
  }
  f = message.getYzPlane();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getDistanceAhead();
  if (f !== 0.0) {
    writer.writeFloat(
      31,
      f
    );
  }
  f = message.getBoxMinPoint();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.serializeBinaryToWriter
    );
  }
  f = message.getBoxMaxPoint();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.serializeBinaryToWriter
    );
  }
};


/**
 * optional sint64 utime = 1;
 * @return {number}
 */
proto.skills.SpoofObstacleInternal.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ObstacleType.Enum obstacle_type = 2;
 * @return {!proto.skills.ObstacleType.Enum}
 */
proto.skills.SpoofObstacleInternal.prototype.getObstacleType = function() {
  return /** @type {!proto.skills.ObstacleType.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.skills.ObstacleType.Enum} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.setObstacleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool always_spoof = 3;
 * @return {boolean}
 */
proto.skills.SpoofObstacleInternal.prototype.getAlwaysSpoof = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.setAlwaysSpoof = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional float time_ahead = 10;
 * @return {number}
 */
proto.skills.SpoofObstacleInternal.prototype.getTimeAhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.setTimeAhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float radius = 11;
 * @return {number}
 */
proto.skills.SpoofObstacleInternal.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float width = 20;
 * @return {number}
 */
proto.skills.SpoofObstacleInternal.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional float length = 21;
 * @return {number}
 */
proto.skills.SpoofObstacleInternal.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.setLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional float position = 22;
 * @return {number}
 */
proto.skills.SpoofObstacleInternal.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.setPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional bool yz_plane = 30;
 * @return {boolean}
 */
proto.skills.SpoofObstacleInternal.prototype.getYzPlane = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.setYzPlane = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional float distance_ahead = 31;
 * @return {number}
 */
proto.skills.SpoofObstacleInternal.prototype.getDistanceAhead = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.setDistanceAhead = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional eigen_lcm.Vector3dPb box_min_point = 40;
 * @return {?proto.eigen_lcm.Vector3dPb}
 */
proto.skills.SpoofObstacleInternal.prototype.getBoxMinPoint = function() {
  return /** @type{?proto.eigen_lcm.Vector3dPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb, 40));
};


/**
 * @param {?proto.eigen_lcm.Vector3dPb|undefined} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
*/
proto.skills.SpoofObstacleInternal.prototype.setBoxMinPoint = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.clearBoxMinPoint = function() {
  return this.setBoxMinPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.SpoofObstacleInternal.prototype.hasBoxMinPoint = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional eigen_lcm.Vector3dPb box_max_point = 41;
 * @return {?proto.eigen_lcm.Vector3dPb}
 */
proto.skills.SpoofObstacleInternal.prototype.getBoxMaxPoint = function() {
  return /** @type{?proto.eigen_lcm.Vector3dPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb, 41));
};


/**
 * @param {?proto.eigen_lcm.Vector3dPb|undefined} value
 * @return {!proto.skills.SpoofObstacleInternal} returns this
*/
proto.skills.SpoofObstacleInternal.prototype.setBoxMaxPoint = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.SpoofObstacleInternal} returns this
 */
proto.skills.SpoofObstacleInternal.prototype.clearBoxMaxPoint = function() {
  return this.setBoxMaxPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.SpoofObstacleInternal.prototype.hasBoxMaxPoint = function() {
  return jspb.Message.getField(this, 41) != null;
};


goog.object.extend(exports, proto.skills);
