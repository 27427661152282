// source: pbtypes/tools/cloud_config/cloud_config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_cloud_config_client_type_pb = require('../../../pbtypes/gen/cloud_config/client_type_pb.js');
goog.object.extend(proto, pbtypes_gen_cloud_config_client_type_pb);
var pbtypes_tools_cloud_api_enums_pb = require('../../../pbtypes/tools/cloud_api/enums_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_enums_pb);
var pbtypes_tools_cloud_api_pagination_pb = require('../../../pbtypes/tools/cloud_api/pagination_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_pagination_pb);
goog.exportSymbol('proto.cloud_config.CloudConfig', null, global);
goog.exportSymbol('proto.cloud_config.CloudConfig.AppType', null, global);
goog.exportSymbol('proto.cloud_config.CloudConfig.Pack', null, global);
goog.exportSymbol('proto.cloud_config.CloudConfigRequest', null, global);
goog.exportSymbol('proto.cloud_config.CloudConfigUpdateResponse', null, global);
goog.exportSymbol('proto.cloud_config.CloudConfigsForOrgRequest', null, global);
goog.exportSymbol('proto.cloud_config.ConfigPack', null, global);
goog.exportSymbol('proto.cloud_config.MfgCloudConfigRequest', null, global);
goog.exportSymbol('proto.cloud_config.MfgCloudConfigSignOnlyRequest', null, global);
goog.exportSymbol('proto.cloud_config.SkillsRefreshRequest', null, global);
goog.exportSymbol('proto.cloud_config.SkillsRefreshResponse', null, global);
goog.exportSymbol('proto.cloud_config.VehicleCloudConfigsRequest', null, global);
goog.exportSymbol('proto.cloud_config.VehicleCloudConfigsResponse', null, global);
goog.exportSymbol('proto.cloud_config.VehicleSignatureMap', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.CloudConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_config.CloudConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.CloudConfig.displayName = 'proto.cloud_config.CloudConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.CloudConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_config.CloudConfigRequest.repeatedFields_, null);
};
goog.inherits(proto.cloud_config.CloudConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.CloudConfigRequest.displayName = 'proto.cloud_config.CloudConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.VehicleCloudConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_config.VehicleCloudConfigsRequest.repeatedFields_, null);
};
goog.inherits(proto.cloud_config.VehicleCloudConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.VehicleCloudConfigsRequest.displayName = 'proto.cloud_config.VehicleCloudConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.ConfigPack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_config.ConfigPack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.ConfigPack.displayName = 'proto.cloud_config.ConfigPack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.CloudConfigUpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_config.CloudConfigUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.CloudConfigUpdateResponse.displayName = 'proto.cloud_config.CloudConfigUpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.SkillsRefreshRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_config.SkillsRefreshRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.SkillsRefreshRequest.displayName = 'proto.cloud_config.SkillsRefreshRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.SkillsRefreshResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_config.SkillsRefreshResponse.repeatedFields_, null);
};
goog.inherits(proto.cloud_config.SkillsRefreshResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.SkillsRefreshResponse.displayName = 'proto.cloud_config.SkillsRefreshResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.MfgCloudConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_config.MfgCloudConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.MfgCloudConfigRequest.displayName = 'proto.cloud_config.MfgCloudConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_config.MfgCloudConfigSignOnlyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.MfgCloudConfigSignOnlyRequest.displayName = 'proto.cloud_config.MfgCloudConfigSignOnlyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.VehicleSignatureMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_config.VehicleSignatureMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.VehicleSignatureMap.displayName = 'proto.cloud_config.VehicleSignatureMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.CloudConfigsForOrgRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_config.CloudConfigsForOrgRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.CloudConfigsForOrgRequest.displayName = 'proto.cloud_config.CloudConfigsForOrgRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_config.VehicleCloudConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_config.VehicleCloudConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_config.VehicleCloudConfigsResponse.displayName = 'proto.cloud_config.VehicleCloudConfigsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.CloudConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.CloudConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.CloudConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.CloudConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
uclock: jspb.Message.getFieldWithDefault(msg, 1, 0),
deviceSignature: jspb.Message.getFieldWithDefault(msg, 8, ""),
overwriteOnOfflineVehicles: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
packsMap: (f = msg.getPacksMap()) ? f.toObject(includeInstance, proto.cloud_config.ConfigPack.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.CloudConfig}
 */
proto.cloud_config.CloudConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.CloudConfig;
  return proto.cloud_config.CloudConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.CloudConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.CloudConfig}
 */
proto.cloud_config.CloudConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUclock(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceSignature(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverwriteOnOfflineVehicles(value);
      break;
    case 6:
      var value = msg.getPacksMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.cloud_config.ConfigPack.deserializeBinaryFromReader, 0, new proto.cloud_config.ConfigPack());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.CloudConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.CloudConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.CloudConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.CloudConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUclock();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDeviceSignature();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOverwriteOnOfflineVehicles();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPacksMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.cloud_config.ConfigPack.serializeBinaryToWriter);
  }
};


/**
 * @enum {number}
 */
proto.cloud_config.CloudConfig.AppType = {
  INVALID: 0,
  SKYDIO: 1,
  SDK: 2
};

/**
 * @enum {number}
 */
proto.cloud_config.CloudConfig.Pack = {
  UNKNOWN: 0,
  SKILLSETS: 1,
  SKILLSETS_UI: 2,
  SIMULATORS: 3,
  INACTIVE_RELEASES: 4,
  DCON_MANIFEST: 5,
  USER_INFO: 6,
  TELEMETRY_SPEC: 7,
  VEHICLE_SWITCHES: 9,
  MOBILE_SWITCHES: 10,
  UCON_CONFIG: 12,
  WIFI_CONFIG: 14,
  MISSION_SCHEDULE: 15,
  DOCK_CONFIG: 16,
  VEHICLE_INFO: 17,
  DEVICE_SETTINGS: 18,
  OVERRIDES: 19,
  SITE_INFO: 20,
  CLOUD_INSTANCE: 21,
  CLOUD_SIGNING_KEY: 22
};

/**
 * optional int64 uclock = 1;
 * @return {number}
 */
proto.cloud_config.CloudConfig.prototype.getUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_config.CloudConfig} returns this
 */
proto.cloud_config.CloudConfig.prototype.setUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string device_signature = 8;
 * @return {string}
 */
proto.cloud_config.CloudConfig.prototype.getDeviceSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_config.CloudConfig} returns this
 */
proto.cloud_config.CloudConfig.prototype.setDeviceSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool overwrite_on_offline_vehicles = 9;
 * @return {boolean}
 */
proto.cloud_config.CloudConfig.prototype.getOverwriteOnOfflineVehicles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_config.CloudConfig} returns this
 */
proto.cloud_config.CloudConfig.prototype.setOverwriteOnOfflineVehicles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * map<int32, ConfigPack> packs = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.cloud_config.ConfigPack>}
 */
proto.cloud_config.CloudConfig.prototype.getPacksMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.cloud_config.ConfigPack>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.cloud_config.ConfigPack));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_config.CloudConfig} returns this
 */
proto.cloud_config.CloudConfig.prototype.clearPacksMap = function() {
  this.getPacksMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_config.CloudConfigRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.CloudConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.CloudConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.CloudConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.CloudConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
signaturesMap: (f = msg.getSignaturesMap()) ? f.toObject(includeInstance, undefined) : [],
notUserSpecific: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
clientKeyOverride: jspb.Message.getFieldWithDefault(msg, 7, ""),
groupsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
organizationId: jspb.Message.getFieldWithDefault(msg, 9, ""),
organizationPermission: jspb.Message.getFieldWithDefault(msg, 10, 0),
offlineVehicleId: jspb.Message.getFieldWithDefault(msg, 11, ""),
organizationDefault: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.CloudConfigRequest}
 */
proto.cloud_config.CloudConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.CloudConfigRequest;
  return proto.cloud_config.CloudConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.CloudConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.CloudConfigRequest}
 */
proto.cloud_config.CloudConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = msg.getSignaturesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotUserSpecific(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientKeyOverride(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroups(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 10:
      var value = /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (reader.readEnum());
      msg.setOrganizationPermission(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfflineVehicleId(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrganizationDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.CloudConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.CloudConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.CloudConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.CloudConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignaturesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getNotUserSpecific();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getClientKeyOverride();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrganizationPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getOfflineVehicleId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOrganizationDefault();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * map<int32, string> signatures = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.cloud_config.CloudConfigRequest.prototype.getSignaturesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_config.CloudConfigRequest} returns this
 */
proto.cloud_config.CloudConfigRequest.prototype.clearSignaturesMap = function() {
  this.getSignaturesMap().clear();
  return this;
};


/**
 * optional bool not_user_specific = 6;
 * @return {boolean}
 */
proto.cloud_config.CloudConfigRequest.prototype.getNotUserSpecific = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_config.CloudConfigRequest} returns this
 */
proto.cloud_config.CloudConfigRequest.prototype.setNotUserSpecific = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string client_key_override = 7;
 * @return {string}
 */
proto.cloud_config.CloudConfigRequest.prototype.getClientKeyOverride = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_config.CloudConfigRequest} returns this
 */
proto.cloud_config.CloudConfigRequest.prototype.setClientKeyOverride = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string groups = 8;
 * @return {!Array<string>}
 */
proto.cloud_config.CloudConfigRequest.prototype.getGroupsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_config.CloudConfigRequest} returns this
 */
proto.cloud_config.CloudConfigRequest.prototype.setGroupsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_config.CloudConfigRequest} returns this
 */
proto.cloud_config.CloudConfigRequest.prototype.addGroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_config.CloudConfigRequest} returns this
 */
proto.cloud_config.CloudConfigRequest.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional string organization_id = 9;
 * @return {string}
 */
proto.cloud_config.CloudConfigRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_config.CloudConfigRequest} returns this
 */
proto.cloud_config.CloudConfigRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional cloud_api.OrgPermission.PermEnum organization_permission = 10;
 * @return {!proto.cloud_api.OrgPermission.PermEnum}
 */
proto.cloud_config.CloudConfigRequest.prototype.getOrganizationPermission = function() {
  return /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.cloud_api.OrgPermission.PermEnum} value
 * @return {!proto.cloud_config.CloudConfigRequest} returns this
 */
proto.cloud_config.CloudConfigRequest.prototype.setOrganizationPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string offline_vehicle_id = 11;
 * @return {string}
 */
proto.cloud_config.CloudConfigRequest.prototype.getOfflineVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_config.CloudConfigRequest} returns this
 */
proto.cloud_config.CloudConfigRequest.prototype.setOfflineVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool organization_default = 12;
 * @return {boolean}
 */
proto.cloud_config.CloudConfigRequest.prototype.getOrganizationDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_config.CloudConfigRequest} returns this
 */
proto.cloud_config.CloudConfigRequest.prototype.setOrganizationDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_config.VehicleCloudConfigsRequest.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.VehicleCloudConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.VehicleCloudConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.VehicleCloudConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
cloudConfigRequest: (f = msg.getCloudConfigRequest()) && proto.cloud_config.CloudConfigRequest.toObject(includeInstance, f),
vehicleIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
vehicleHostnamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.PaginationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest}
 */
proto.cloud_config.VehicleCloudConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.VehicleCloudConfigsRequest;
  return proto.cloud_config.VehicleCloudConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.VehicleCloudConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest}
 */
proto.cloud_config.VehicleCloudConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_config.CloudConfigRequest;
      reader.readMessage(value,proto.cloud_config.CloudConfigRequest.deserializeBinaryFromReader);
      msg.setCloudConfigRequest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addVehicleIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addVehicleHostnames(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 5:
      var value = new pbtypes_tools_cloud_api_pagination_pb.PaginationRequest;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.PaginationRequest.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.VehicleCloudConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.VehicleCloudConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.VehicleCloudConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloudConfigRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_config.CloudConfigRequest.serializeBinaryToWriter
    );
  }
  f = message.getVehicleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getVehicleHostnamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      pbtypes_tools_cloud_api_pagination_pb.PaginationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional CloudConfigRequest cloud_config_request = 1;
 * @return {?proto.cloud_config.CloudConfigRequest}
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.getCloudConfigRequest = function() {
  return /** @type{?proto.cloud_config.CloudConfigRequest} */ (
    jspb.Message.getWrapperField(this, proto.cloud_config.CloudConfigRequest, 1));
};


/**
 * @param {?proto.cloud_config.CloudConfigRequest|undefined} value
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
*/
proto.cloud_config.VehicleCloudConfigsRequest.prototype.setCloudConfigRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.clearCloudConfigRequest = function() {
  return this.setCloudConfigRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.hasCloudConfigRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string vehicle_ids = 2;
 * @return {!Array<string>}
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.getVehicleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.setVehicleIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.addVehicleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.clearVehicleIdsList = function() {
  return this.setVehicleIdsList([]);
};


/**
 * repeated string vehicle_hostnames = 3;
 * @return {!Array<string>}
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.getVehicleHostnamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.setVehicleHostnamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.addVehicleHostnames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.clearVehicleHostnamesList = function() {
  return this.setVehicleHostnamesList([]);
};


/**
 * repeated string organization_ids = 4;
 * @return {!Array<string>}
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * optional cloud_api.PaginationRequest pagination = 5;
 * @return {?proto.cloud_api.PaginationRequest}
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.PaginationRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.PaginationRequest, 5));
};


/**
 * @param {?proto.cloud_api.PaginationRequest|undefined} value
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
*/
proto.cloud_config.VehicleCloudConfigsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_config.VehicleCloudConfigsRequest} returns this
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_config.VehicleCloudConfigsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.ConfigPack.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.ConfigPack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.ConfigPack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.ConfigPack.toObject = function(includeInstance, msg) {
  var f, obj = {
signature: jspb.Message.getFieldWithDefault(msg, 1, ""),
config: msg.getConfig_asB64(),
clientType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.ConfigPack}
 */
proto.cloud_config.ConfigPack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.ConfigPack;
  return proto.cloud_config.ConfigPack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.ConfigPack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.ConfigPack}
 */
proto.cloud_config.ConfigPack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setConfig(value);
      break;
    case 3:
      var value = /** @type {!proto.cloud_config.ClientType.Enum} */ (reader.readEnum());
      msg.setClientType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.ConfigPack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.ConfigPack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.ConfigPack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.ConfigPack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfig_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getClientType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string signature = 1;
 * @return {string}
 */
proto.cloud_config.ConfigPack.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_config.ConfigPack} returns this
 */
proto.cloud_config.ConfigPack.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes config = 2;
 * @return {!(string|Uint8Array)}
 */
proto.cloud_config.ConfigPack.prototype.getConfig = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes config = 2;
 * This is a type-conversion wrapper around `getConfig()`
 * @return {string}
 */
proto.cloud_config.ConfigPack.prototype.getConfig_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getConfig()));
};


/**
 * optional bytes config = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getConfig()`
 * @return {!Uint8Array}
 */
proto.cloud_config.ConfigPack.prototype.getConfig_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getConfig()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.cloud_config.ConfigPack} returns this
 */
proto.cloud_config.ConfigPack.prototype.setConfig = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional ClientType.Enum client_type = 3;
 * @return {!proto.cloud_config.ClientType.Enum}
 */
proto.cloud_config.ConfigPack.prototype.getClientType = function() {
  return /** @type {!proto.cloud_config.ClientType.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.cloud_config.ClientType.Enum} value
 * @return {!proto.cloud_config.ConfigPack} returns this
 */
proto.cloud_config.ConfigPack.prototype.setClientType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.CloudConfigUpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.CloudConfigUpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.CloudConfigUpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.CloudConfigUpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.CloudConfigUpdateResponse}
 */
proto.cloud_config.CloudConfigUpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.CloudConfigUpdateResponse;
  return proto.cloud_config.CloudConfigUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.CloudConfigUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.CloudConfigUpdateResponse}
 */
proto.cloud_config.CloudConfigUpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.CloudConfigUpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.CloudConfigUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.CloudConfigUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.CloudConfigUpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.cloud_config.CloudConfigUpdateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_config.CloudConfigUpdateResponse} returns this
 */
proto.cloud_config.CloudConfigUpdateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.SkillsRefreshRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.SkillsRefreshRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.SkillsRefreshRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.SkillsRefreshRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
evenIfCurrent: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
onlyIfOnGround: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.SkillsRefreshRequest}
 */
proto.cloud_config.SkillsRefreshRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.SkillsRefreshRequest;
  return proto.cloud_config.SkillsRefreshRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.SkillsRefreshRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.SkillsRefreshRequest}
 */
proto.cloud_config.SkillsRefreshRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEvenIfCurrent(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyIfOnGround(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.SkillsRefreshRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.SkillsRefreshRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.SkillsRefreshRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.SkillsRefreshRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvenIfCurrent();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOnlyIfOnGround();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool even_if_current = 1;
 * @return {boolean}
 */
proto.cloud_config.SkillsRefreshRequest.prototype.getEvenIfCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_config.SkillsRefreshRequest} returns this
 */
proto.cloud_config.SkillsRefreshRequest.prototype.setEvenIfCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool only_if_on_ground = 2;
 * @return {boolean}
 */
proto.cloud_config.SkillsRefreshRequest.prototype.getOnlyIfOnGround = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_config.SkillsRefreshRequest} returns this
 */
proto.cloud_config.SkillsRefreshRequest.prototype.setOnlyIfOnGround = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_config.SkillsRefreshResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.SkillsRefreshResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.SkillsRefreshResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.SkillsRefreshResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.SkillsRefreshResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
restarted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
skillsetsHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
skillsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.SkillsRefreshResponse}
 */
proto.cloud_config.SkillsRefreshResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.SkillsRefreshResponse;
  return proto.cloud_config.SkillsRefreshResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.SkillsRefreshResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.SkillsRefreshResponse}
 */
proto.cloud_config.SkillsRefreshResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRestarted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetsHash(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkills(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.SkillsRefreshResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.SkillsRefreshResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.SkillsRefreshResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.SkillsRefreshResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRestarted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSkillsetsHash();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSkillsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional bool restarted = 1;
 * @return {boolean}
 */
proto.cloud_config.SkillsRefreshResponse.prototype.getRestarted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_config.SkillsRefreshResponse} returns this
 */
proto.cloud_config.SkillsRefreshResponse.prototype.setRestarted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string skillsets_hash = 2;
 * @return {string}
 */
proto.cloud_config.SkillsRefreshResponse.prototype.getSkillsetsHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_config.SkillsRefreshResponse} returns this
 */
proto.cloud_config.SkillsRefreshResponse.prototype.setSkillsetsHash = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string skills = 3;
 * @return {!Array<string>}
 */
proto.cloud_config.SkillsRefreshResponse.prototype.getSkillsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_config.SkillsRefreshResponse} returns this
 */
proto.cloud_config.SkillsRefreshResponse.prototype.setSkillsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_config.SkillsRefreshResponse} returns this
 */
proto.cloud_config.SkillsRefreshResponse.prototype.addSkills = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_config.SkillsRefreshResponse} returns this
 */
proto.cloud_config.SkillsRefreshResponse.prototype.clearSkillsList = function() {
  return this.setSkillsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.MfgCloudConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.MfgCloudConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.MfgCloudConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.MfgCloudConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
vehicleType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.MfgCloudConfigRequest}
 */
proto.cloud_config.MfgCloudConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.MfgCloudConfigRequest;
  return proto.cloud_config.MfgCloudConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.MfgCloudConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.MfgCloudConfigRequest}
 */
proto.cloud_config.MfgCloudConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.MfgCloudConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.MfgCloudConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.MfgCloudConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.MfgCloudConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.cloud_config.MfgCloudConfigRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_config.MfgCloudConfigRequest} returns this
 */
proto.cloud_config.MfgCloudConfigRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_type = 2;
 * @return {string}
 */
proto.cloud_config.MfgCloudConfigRequest.prototype.getVehicleType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_config.MfgCloudConfigRequest} returns this
 */
proto.cloud_config.MfgCloudConfigRequest.prototype.setVehicleType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.MfgCloudConfigSignOnlyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.MfgCloudConfigSignOnlyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
cloudConfig: (f = msg.getCloudConfig()) && proto.cloud_config.CloudConfig.toObject(includeInstance, f),
ansiSerialNumberOverride: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.MfgCloudConfigSignOnlyRequest}
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.MfgCloudConfigSignOnlyRequest;
  return proto.cloud_config.MfgCloudConfigSignOnlyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.MfgCloudConfigSignOnlyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.MfgCloudConfigSignOnlyRequest}
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = new proto.cloud_config.CloudConfig;
      reader.readMessage(value,proto.cloud_config.CloudConfig.deserializeBinaryFromReader);
      msg.setCloudConfig(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnsiSerialNumberOverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.MfgCloudConfigSignOnlyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.MfgCloudConfigSignOnlyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCloudConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.cloud_config.CloudConfig.serializeBinaryToWriter
    );
  }
  f = message.getAnsiSerialNumberOverride();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_config.MfgCloudConfigSignOnlyRequest} returns this
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CloudConfig cloud_config = 2;
 * @return {?proto.cloud_config.CloudConfig}
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.prototype.getCloudConfig = function() {
  return /** @type{?proto.cloud_config.CloudConfig} */ (
    jspb.Message.getWrapperField(this, proto.cloud_config.CloudConfig, 2));
};


/**
 * @param {?proto.cloud_config.CloudConfig|undefined} value
 * @return {!proto.cloud_config.MfgCloudConfigSignOnlyRequest} returns this
*/
proto.cloud_config.MfgCloudConfigSignOnlyRequest.prototype.setCloudConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_config.MfgCloudConfigSignOnlyRequest} returns this
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.prototype.clearCloudConfig = function() {
  return this.setCloudConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.prototype.hasCloudConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string ansi_serial_number_override = 3;
 * @return {string}
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.prototype.getAnsiSerialNumberOverride = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_config.MfgCloudConfigSignOnlyRequest} returns this
 */
proto.cloud_config.MfgCloudConfigSignOnlyRequest.prototype.setAnsiSerialNumberOverride = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.VehicleSignatureMap.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.VehicleSignatureMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.VehicleSignatureMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.VehicleSignatureMap.toObject = function(includeInstance, msg) {
  var f, obj = {
signaturesMap: (f = msg.getSignaturesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.VehicleSignatureMap}
 */
proto.cloud_config.VehicleSignatureMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.VehicleSignatureMap;
  return proto.cloud_config.VehicleSignatureMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.VehicleSignatureMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.VehicleSignatureMap}
 */
proto.cloud_config.VehicleSignatureMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSignaturesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.VehicleSignatureMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.VehicleSignatureMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.VehicleSignatureMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.VehicleSignatureMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignaturesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<int32, string> signatures = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.cloud_config.VehicleSignatureMap.prototype.getSignaturesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_config.VehicleSignatureMap} returns this
 */
proto.cloud_config.VehicleSignatureMap.prototype.clearSignaturesMap = function() {
  this.getSignaturesMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.CloudConfigsForOrgRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.CloudConfigsForOrgRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.CloudConfigsForOrgRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.CloudConfigsForOrgRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
configSignaturesMap: (f = msg.getConfigSignaturesMap()) ? f.toObject(includeInstance, proto.cloud_config.VehicleSignatureMap.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.CloudConfigsForOrgRequest}
 */
proto.cloud_config.CloudConfigsForOrgRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.CloudConfigsForOrgRequest;
  return proto.cloud_config.CloudConfigsForOrgRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.CloudConfigsForOrgRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.CloudConfigsForOrgRequest}
 */
proto.cloud_config.CloudConfigsForOrgRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = msg.getConfigSignaturesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.cloud_config.VehicleSignatureMap.deserializeBinaryFromReader, "", new proto.cloud_config.VehicleSignatureMap());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.CloudConfigsForOrgRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.CloudConfigsForOrgRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.CloudConfigsForOrgRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.CloudConfigsForOrgRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigSignaturesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.cloud_config.VehicleSignatureMap.serializeBinaryToWriter);
  }
};


/**
 * map<string, VehicleSignatureMap> config_signatures = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.cloud_config.VehicleSignatureMap>}
 */
proto.cloud_config.CloudConfigsForOrgRequest.prototype.getConfigSignaturesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.cloud_config.VehicleSignatureMap>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.cloud_config.VehicleSignatureMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_config.CloudConfigsForOrgRequest} returns this
 */
proto.cloud_config.CloudConfigsForOrgRequest.prototype.clearConfigSignaturesMap = function() {
  this.getConfigSignaturesMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_config.VehicleCloudConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_config.VehicleCloudConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_config.VehicleCloudConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.VehicleCloudConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
cloudConfigsMap: (f = msg.getCloudConfigsMap()) ? f.toObject(includeInstance, proto.cloud_config.CloudConfig.toObject) : [],
defaultConfig: (f = msg.getDefaultConfig()) && proto.cloud_config.CloudConfig.toObject(includeInstance, f),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_config.VehicleCloudConfigsResponse}
 */
proto.cloud_config.VehicleCloudConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_config.VehicleCloudConfigsResponse;
  return proto.cloud_config.VehicleCloudConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_config.VehicleCloudConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_config.VehicleCloudConfigsResponse}
 */
proto.cloud_config.VehicleCloudConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getCloudConfigsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.cloud_config.CloudConfig.deserializeBinaryFromReader, "", new proto.cloud_config.CloudConfig());
         });
      break;
    case 2:
      var value = new proto.cloud_config.CloudConfig;
      reader.readMessage(value,proto.cloud_config.CloudConfig.deserializeBinaryFromReader);
      msg.setDefaultConfig(value);
      break;
    case 3:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_config.VehicleCloudConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_config.VehicleCloudConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_config.VehicleCloudConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_config.VehicleCloudConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCloudConfigsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.cloud_config.CloudConfig.serializeBinaryToWriter);
  }
  f = message.getDefaultConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.cloud_config.CloudConfig.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * map<string, CloudConfig> cloud_configs = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.cloud_config.CloudConfig>}
 */
proto.cloud_config.VehicleCloudConfigsResponse.prototype.getCloudConfigsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.cloud_config.CloudConfig>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.cloud_config.CloudConfig));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_config.VehicleCloudConfigsResponse} returns this
 */
proto.cloud_config.VehicleCloudConfigsResponse.prototype.clearCloudConfigsMap = function() {
  this.getCloudConfigsMap().clear();
  return this;
};


/**
 * optional CloudConfig default_config = 2;
 * @return {?proto.cloud_config.CloudConfig}
 */
proto.cloud_config.VehicleCloudConfigsResponse.prototype.getDefaultConfig = function() {
  return /** @type{?proto.cloud_config.CloudConfig} */ (
    jspb.Message.getWrapperField(this, proto.cloud_config.CloudConfig, 2));
};


/**
 * @param {?proto.cloud_config.CloudConfig|undefined} value
 * @return {!proto.cloud_config.VehicleCloudConfigsResponse} returns this
*/
proto.cloud_config.VehicleCloudConfigsResponse.prototype.setDefaultConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_config.VehicleCloudConfigsResponse} returns this
 */
proto.cloud_config.VehicleCloudConfigsResponse.prototype.clearDefaultConfig = function() {
  return this.setDefaultConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_config.VehicleCloudConfigsResponse.prototype.hasDefaultConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional cloud_api.Pagination pagination = 3;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_config.VehicleCloudConfigsResponse.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 3));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_config.VehicleCloudConfigsResponse} returns this
*/
proto.cloud_config.VehicleCloudConfigsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_config.VehicleCloudConfigsResponse} returns this
 */
proto.cloud_config.VehicleCloudConfigsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_config.VehicleCloudConfigsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.cloud_config);
