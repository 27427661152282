// source: pbtypes/vehicle/skills/actions/ncpg_control/ncpg_control.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_uuid_util_uuid_pb = require('../../../../../pbtypes/gen/uuid_util/uuid_pb.js');
goog.object.extend(proto, pbtypes_gen_uuid_util_uuid_pb);
var pbtypes_vehicle_nav_cam_reconstruction_nav_cam_reconstruction_pb = require('../../../../../pbtypes/vehicle/nav_cam_reconstruction/nav_cam_reconstruction_pb.js');
goog.object.extend(proto, pbtypes_vehicle_nav_cam_reconstruction_nav_cam_reconstruction_pb);
goog.exportSymbol('proto.skills.NcpgControlActionArgs', null, global);
goog.exportSymbol('proto.skills.NcpgControlActionArgs.ControlCase', null, global);
goog.exportSymbol('proto.skills.NcpgControlActionStateData', null, global);
goog.exportSymbol('proto.skills.NcpgControlResultData', null, global);
goog.exportSymbol('proto.skills.QueueMissionNcpgTask', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.QueueMissionNcpgTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.QueueMissionNcpgTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.QueueMissionNcpgTask.displayName = 'proto.skills.QueueMissionNcpgTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.NcpgControlActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skills.NcpgControlActionArgs.oneofGroups_);
};
goog.inherits(proto.skills.NcpgControlActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.NcpgControlActionArgs.displayName = 'proto.skills.NcpgControlActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.NcpgControlResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.NcpgControlResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.NcpgControlResultData.displayName = 'proto.skills.NcpgControlResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.NcpgControlActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.NcpgControlActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.NcpgControlActionStateData.displayName = 'proto.skills.NcpgControlActionStateData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.QueueMissionNcpgTask.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.QueueMissionNcpgTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.QueueMissionNcpgTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.QueueMissionNcpgTask.toObject = function(includeInstance, msg) {
  var f, obj = {
ncpgFileId: (f = msg.getNcpgFileId()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.QueueMissionNcpgTask}
 */
proto.skills.QueueMissionNcpgTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.QueueMissionNcpgTask;
  return proto.skills.QueueMissionNcpgTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.QueueMissionNcpgTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.QueueMissionNcpgTask}
 */
proto.skills.QueueMissionNcpgTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setNcpgFileId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.QueueMissionNcpgTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.QueueMissionNcpgTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.QueueMissionNcpgTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.QueueMissionNcpgTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNcpgFileId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
};


/**
 * optional uuid_util.Uuid ncpg_file_id = 1;
 * @return {?proto.uuid_util.Uuid}
 */
proto.skills.QueueMissionNcpgTask.prototype.getNcpgFileId = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 1));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.skills.QueueMissionNcpgTask} returns this
*/
proto.skills.QueueMissionNcpgTask.prototype.setNcpgFileId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.QueueMissionNcpgTask} returns this
 */
proto.skills.QueueMissionNcpgTask.prototype.clearNcpgFileId = function() {
  return this.setNcpgFileId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.QueueMissionNcpgTask.prototype.hasNcpgFileId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.NcpgControlActionArgs.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.skills.NcpgControlActionArgs.ControlCase = {
  CONTROL_NOT_SET: 0,
  FULL_MAPPING_REQUEST: 1,
  QUEUE_MISSION_NCPG_TASK: 2
};

/**
 * @return {proto.skills.NcpgControlActionArgs.ControlCase}
 */
proto.skills.NcpgControlActionArgs.prototype.getControlCase = function() {
  return /** @type {proto.skills.NcpgControlActionArgs.ControlCase} */(jspb.Message.computeOneofCase(this, proto.skills.NcpgControlActionArgs.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.NcpgControlActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.NcpgControlActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.NcpgControlActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgControlActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
fullMappingRequest: (f = msg.getFullMappingRequest()) && pbtypes_vehicle_nav_cam_reconstruction_nav_cam_reconstruction_pb.FullMappingRequest.toObject(includeInstance, f),
queueMissionNcpgTask: (f = msg.getQueueMissionNcpgTask()) && proto.skills.QueueMissionNcpgTask.toObject(includeInstance, f),
timeoutS: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.NcpgControlActionArgs}
 */
proto.skills.NcpgControlActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.NcpgControlActionArgs;
  return proto.skills.NcpgControlActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.NcpgControlActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.NcpgControlActionArgs}
 */
proto.skills.NcpgControlActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_nav_cam_reconstruction_nav_cam_reconstruction_pb.FullMappingRequest;
      reader.readMessage(value,pbtypes_vehicle_nav_cam_reconstruction_nav_cam_reconstruction_pb.FullMappingRequest.deserializeBinaryFromReader);
      msg.setFullMappingRequest(value);
      break;
    case 2:
      var value = new proto.skills.QueueMissionNcpgTask;
      reader.readMessage(value,proto.skills.QueueMissionNcpgTask.deserializeBinaryFromReader);
      msg.setQueueMissionNcpgTask(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimeoutS(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.NcpgControlActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.NcpgControlActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.NcpgControlActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgControlActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFullMappingRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_nav_cam_reconstruction_nav_cam_reconstruction_pb.FullMappingRequest.serializeBinaryToWriter
    );
  }
  f = message.getQueueMissionNcpgTask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.QueueMissionNcpgTask.serializeBinaryToWriter
    );
  }
  f = message.getTimeoutS();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional nav_cam_reconstruction.FullMappingRequest full_mapping_request = 1;
 * @return {?proto.nav_cam_reconstruction.FullMappingRequest}
 */
proto.skills.NcpgControlActionArgs.prototype.getFullMappingRequest = function() {
  return /** @type{?proto.nav_cam_reconstruction.FullMappingRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_nav_cam_reconstruction_nav_cam_reconstruction_pb.FullMappingRequest, 1));
};


/**
 * @param {?proto.nav_cam_reconstruction.FullMappingRequest|undefined} value
 * @return {!proto.skills.NcpgControlActionArgs} returns this
*/
proto.skills.NcpgControlActionArgs.prototype.setFullMappingRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.skills.NcpgControlActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.NcpgControlActionArgs} returns this
 */
proto.skills.NcpgControlActionArgs.prototype.clearFullMappingRequest = function() {
  return this.setFullMappingRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.NcpgControlActionArgs.prototype.hasFullMappingRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional QueueMissionNcpgTask queue_mission_ncpg_task = 2;
 * @return {?proto.skills.QueueMissionNcpgTask}
 */
proto.skills.NcpgControlActionArgs.prototype.getQueueMissionNcpgTask = function() {
  return /** @type{?proto.skills.QueueMissionNcpgTask} */ (
    jspb.Message.getWrapperField(this, proto.skills.QueueMissionNcpgTask, 2));
};


/**
 * @param {?proto.skills.QueueMissionNcpgTask|undefined} value
 * @return {!proto.skills.NcpgControlActionArgs} returns this
*/
proto.skills.NcpgControlActionArgs.prototype.setQueueMissionNcpgTask = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.skills.NcpgControlActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.NcpgControlActionArgs} returns this
 */
proto.skills.NcpgControlActionArgs.prototype.clearQueueMissionNcpgTask = function() {
  return this.setQueueMissionNcpgTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.NcpgControlActionArgs.prototype.hasQueueMissionNcpgTask = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float timeout_s = 3;
 * @return {number}
 */
proto.skills.NcpgControlActionArgs.prototype.getTimeoutS = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.NcpgControlActionArgs} returns this
 */
proto.skills.NcpgControlActionArgs.prototype.setTimeoutS = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.NcpgControlResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.NcpgControlResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.NcpgControlResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgControlResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.NcpgControlResultData}
 */
proto.skills.NcpgControlResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.NcpgControlResultData;
  return proto.skills.NcpgControlResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.NcpgControlResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.NcpgControlResultData}
 */
proto.skills.NcpgControlResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.NcpgControlResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.NcpgControlResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.NcpgControlResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgControlResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.NcpgControlActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.NcpgControlActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.NcpgControlActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgControlActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.NcpgControlActionStateData}
 */
proto.skills.NcpgControlActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.NcpgControlActionStateData;
  return proto.skills.NcpgControlActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.NcpgControlActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.NcpgControlActionStateData}
 */
proto.skills.NcpgControlActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.NcpgControlActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.NcpgControlActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.NcpgControlActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.NcpgControlActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.skills);
