// source: pbtypes/gen/joysticks/joysticks_settings.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_joysticks_joysticks_flight_mode_pb = require('../../../pbtypes/gen/joysticks/joysticks_flight_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_joysticks_joysticks_flight_mode_pb);
var pbtypes_gen_joysticks_rates_pb = require('../../../pbtypes/gen/joysticks/rates_pb.js');
goog.object.extend(proto, pbtypes_gen_joysticks_rates_pb);
goog.exportSymbol('proto.joysticks.JoysticksSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joysticks.JoysticksSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joysticks.JoysticksSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joysticks.JoysticksSettings.displayName = 'proto.joysticks.JoysticksSettings';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joysticks.JoysticksSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.joysticks.JoysticksSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joysticks.JoysticksSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joysticks.JoysticksSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
rates: (f = msg.getRates()) && pbtypes_gen_joysticks_rates_pb.Rates.toObject(includeInstance, f),
flightMode: jspb.Message.getFieldWithDefault(msg, 3, 0),
horizontalGeofenceEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
horizontalGeofenceDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
flipTriggers: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joysticks.JoysticksSettings}
 */
proto.joysticks.JoysticksSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joysticks.JoysticksSettings;
  return proto.joysticks.JoysticksSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joysticks.JoysticksSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joysticks.JoysticksSettings}
 */
proto.joysticks.JoysticksSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUtime(value);
      break;
    case 12:
      var value = new pbtypes_gen_joysticks_rates_pb.Rates;
      reader.readMessage(value,pbtypes_gen_joysticks_rates_pb.Rates.deserializeBinaryFromReader);
      msg.setRates(value);
      break;
    case 3:
      var value = /** @type {!proto.joysticks.JoysticksFlightMode.Enum} */ (reader.readEnum());
      msg.setFlightMode(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHorizontalGeofenceEnabled(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHorizontalGeofenceDistance(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFlipTriggers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joysticks.JoysticksSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joysticks.JoysticksSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joysticks.JoysticksSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joysticks.JoysticksSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getRates();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      pbtypes_gen_joysticks_rates_pb.Rates.serializeBinaryToWriter
    );
  }
  f = message.getFlightMode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getHorizontalGeofenceEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHorizontalGeofenceDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getFlipTriggers();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional uint64 utime = 1;
 * @return {number}
 */
proto.joysticks.JoysticksSettings.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.joysticks.JoysticksSettings} returns this
 */
proto.joysticks.JoysticksSettings.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Rates rates = 12;
 * @return {?proto.joysticks.Rates}
 */
proto.joysticks.JoysticksSettings.prototype.getRates = function() {
  return /** @type{?proto.joysticks.Rates} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_joysticks_rates_pb.Rates, 12));
};


/**
 * @param {?proto.joysticks.Rates|undefined} value
 * @return {!proto.joysticks.JoysticksSettings} returns this
*/
proto.joysticks.JoysticksSettings.prototype.setRates = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joysticks.JoysticksSettings} returns this
 */
proto.joysticks.JoysticksSettings.prototype.clearRates = function() {
  return this.setRates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joysticks.JoysticksSettings.prototype.hasRates = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional JoysticksFlightMode.Enum flight_mode = 3;
 * @return {!proto.joysticks.JoysticksFlightMode.Enum}
 */
proto.joysticks.JoysticksSettings.prototype.getFlightMode = function() {
  return /** @type {!proto.joysticks.JoysticksFlightMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.joysticks.JoysticksFlightMode.Enum} value
 * @return {!proto.joysticks.JoysticksSettings} returns this
 */
proto.joysticks.JoysticksSettings.prototype.setFlightMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool horizontal_geofence_enabled = 4;
 * @return {boolean}
 */
proto.joysticks.JoysticksSettings.prototype.getHorizontalGeofenceEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.joysticks.JoysticksSettings} returns this
 */
proto.joysticks.JoysticksSettings.prototype.setHorizontalGeofenceEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional float horizontal_geofence_distance = 5;
 * @return {number}
 */
proto.joysticks.JoysticksSettings.prototype.getHorizontalGeofenceDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.joysticks.JoysticksSettings} returns this
 */
proto.joysticks.JoysticksSettings.prototype.setHorizontalGeofenceDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool flip_triggers = 11;
 * @return {boolean}
 */
proto.joysticks.JoysticksSettings.prototype.getFlipTriggers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.joysticks.JoysticksSettings} returns this
 */
proto.joysticks.JoysticksSettings.prototype.setFlipTriggers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


goog.object.extend(exports, proto.joysticks);
