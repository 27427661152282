// NOTE(sam): These types have some redundancy with buffer_stats_t and tunnel_stats_t; some of
// those fields aren't relevant, some don't make as much sense in the context of the web client
// (for example we don't have a concept of utime), and some can be simplified by taking advantage
// of TypeScript types. Worth a conversation if others don't think the added clarity is worth the
// duplication.

export interface TransmitStats {
  events: number;
  bytes: number;
}

export const initialTransmitStats: TransmitStats = {
  events: 0,
  bytes: 0,
};

export interface ReceiveStats {
  bytesReceived: number;
  chunksReceived: number;
  chunksMissed: number;
  chunksRecovered: number;
  eventsCompleted: number;
  eventsDropped: number;
  eventsRecovered: number;
  lastChunkReceivedTime: number;
  lastChunkRecoveredTime: number;
  lastEventCompletedTime: number;
  lastEventDroppedTime: number;
}

export const initialReceiveStats: ReceiveStats = {
  bytesReceived: 0,
  chunksReceived: 0,
  chunksMissed: 0,
  chunksRecovered: 0,
  eventsCompleted: 0,
  eventsDropped: 0,
  eventsRecovered: 0,
  lastChunkReceivedTime: 0,
  lastChunkRecoveredTime: 0,
  lastEventCompletedTime: 0,
  lastEventDroppedTime: 0,
};

export interface TunnelStats {
  sentByChannel: {
    [channel: string]: TransmitStats;
  };
  receivedByChannel: {
    [channel: string]: ReceiveStats;
  };
  totalSent: TransmitStats;
  totalReceived: ReceiveStats;
}
