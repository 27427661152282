// source: pbtypes/mobile/phone/phone_stream.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.skydio.phone_stream.ExternalDisplay', null, global);
goog.exportSymbol('proto.skydio.phone_stream.LayoutDisplayOption', null, global);
goog.exportSymbol('proto.skydio.phone_stream.StreamConfig', null, global);
goog.exportSymbol('proto.skydio.phone_stream.StreamDisplayOption', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.phone_stream.StreamConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.phone_stream.StreamConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.phone_stream.StreamConfig.displayName = 'proto.skydio.phone_stream.StreamConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skydio.phone_stream.ExternalDisplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skydio.phone_stream.ExternalDisplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skydio.phone_stream.ExternalDisplay.displayName = 'proto.skydio.phone_stream.ExternalDisplay';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.phone_stream.StreamConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.phone_stream.StreamConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.phone_stream.StreamConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.phone_stream.StreamConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
fullEoEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
fullMapEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
fullIrEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
primaryStream: jspb.Message.getFieldWithDefault(msg, 6, 0),
secondaryStream: jspb.Message.getFieldWithDefault(msg, 7, 0),
tertiaryStream: jspb.Message.getFieldWithDefault(msg, 9, 0),
layoutType: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.phone_stream.StreamConfig}
 */
proto.skydio.phone_stream.StreamConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.phone_stream.StreamConfig;
  return proto.skydio.phone_stream.StreamConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.phone_stream.StreamConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.phone_stream.StreamConfig}
 */
proto.skydio.phone_stream.StreamConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFullEoEnabled(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFullMapEnabled(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFullIrEnabled(value);
      break;
    case 6:
      var value = /** @type {!proto.skydio.phone_stream.StreamDisplayOption} */ (reader.readEnum());
      msg.setPrimaryStream(value);
      break;
    case 7:
      var value = /** @type {!proto.skydio.phone_stream.StreamDisplayOption} */ (reader.readEnum());
      msg.setSecondaryStream(value);
      break;
    case 9:
      var value = /** @type {!proto.skydio.phone_stream.StreamDisplayOption} */ (reader.readEnum());
      msg.setTertiaryStream(value);
      break;
    case 10:
      var value = /** @type {!proto.skydio.phone_stream.LayoutDisplayOption} */ (reader.readEnum());
      msg.setLayoutType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.phone_stream.StreamConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.phone_stream.StreamConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.phone_stream.StreamConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.phone_stream.StreamConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFullEoEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getFullMapEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFullIrEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPrimaryStream();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSecondaryStream();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getTertiaryStream();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getLayoutType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional bool full_eo_enabled = 3;
 * @return {boolean}
 */
proto.skydio.phone_stream.StreamConfig.prototype.getFullEoEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.phone_stream.StreamConfig} returns this
 */
proto.skydio.phone_stream.StreamConfig.prototype.setFullEoEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool full_map_enabled = 4;
 * @return {boolean}
 */
proto.skydio.phone_stream.StreamConfig.prototype.getFullMapEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.phone_stream.StreamConfig} returns this
 */
proto.skydio.phone_stream.StreamConfig.prototype.setFullMapEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool full_ir_enabled = 5;
 * @return {boolean}
 */
proto.skydio.phone_stream.StreamConfig.prototype.getFullIrEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.phone_stream.StreamConfig} returns this
 */
proto.skydio.phone_stream.StreamConfig.prototype.setFullIrEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional StreamDisplayOption primary_stream = 6;
 * @return {!proto.skydio.phone_stream.StreamDisplayOption}
 */
proto.skydio.phone_stream.StreamConfig.prototype.getPrimaryStream = function() {
  return /** @type {!proto.skydio.phone_stream.StreamDisplayOption} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.skydio.phone_stream.StreamDisplayOption} value
 * @return {!proto.skydio.phone_stream.StreamConfig} returns this
 */
proto.skydio.phone_stream.StreamConfig.prototype.setPrimaryStream = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional StreamDisplayOption secondary_stream = 7;
 * @return {!proto.skydio.phone_stream.StreamDisplayOption}
 */
proto.skydio.phone_stream.StreamConfig.prototype.getSecondaryStream = function() {
  return /** @type {!proto.skydio.phone_stream.StreamDisplayOption} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.skydio.phone_stream.StreamDisplayOption} value
 * @return {!proto.skydio.phone_stream.StreamConfig} returns this
 */
proto.skydio.phone_stream.StreamConfig.prototype.setSecondaryStream = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional StreamDisplayOption tertiary_stream = 9;
 * @return {!proto.skydio.phone_stream.StreamDisplayOption}
 */
proto.skydio.phone_stream.StreamConfig.prototype.getTertiaryStream = function() {
  return /** @type {!proto.skydio.phone_stream.StreamDisplayOption} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.skydio.phone_stream.StreamDisplayOption} value
 * @return {!proto.skydio.phone_stream.StreamConfig} returns this
 */
proto.skydio.phone_stream.StreamConfig.prototype.setTertiaryStream = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional LayoutDisplayOption layout_type = 10;
 * @return {!proto.skydio.phone_stream.LayoutDisplayOption}
 */
proto.skydio.phone_stream.StreamConfig.prototype.getLayoutType = function() {
  return /** @type {!proto.skydio.phone_stream.LayoutDisplayOption} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.skydio.phone_stream.LayoutDisplayOption} value
 * @return {!proto.skydio.phone_stream.StreamConfig} returns this
 */
proto.skydio.phone_stream.StreamConfig.prototype.setLayoutType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skydio.phone_stream.ExternalDisplay.prototype.toObject = function(opt_includeInstance) {
  return proto.skydio.phone_stream.ExternalDisplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skydio.phone_stream.ExternalDisplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.phone_stream.ExternalDisplay.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
connected: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skydio.phone_stream.ExternalDisplay}
 */
proto.skydio.phone_stream.ExternalDisplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skydio.phone_stream.ExternalDisplay;
  return proto.skydio.phone_stream.ExternalDisplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skydio.phone_stream.ExternalDisplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skydio.phone_stream.ExternalDisplay}
 */
proto.skydio.phone_stream.ExternalDisplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skydio.phone_stream.ExternalDisplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skydio.phone_stream.ExternalDisplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skydio.phone_stream.ExternalDisplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skydio.phone_stream.ExternalDisplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skydio.phone_stream.ExternalDisplay.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skydio.phone_stream.ExternalDisplay} returns this
 */
proto.skydio.phone_stream.ExternalDisplay.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool connected = 2;
 * @return {boolean}
 */
proto.skydio.phone_stream.ExternalDisplay.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skydio.phone_stream.ExternalDisplay} returns this
 */
proto.skydio.phone_stream.ExternalDisplay.prototype.setConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.skydio.phone_stream.LayoutDisplayOption = {
  SINGLE: 0,
  SPLIT: 1,
  GRID: 2
};

/**
 * @enum {number}
 */
proto.skydio.phone_stream.StreamDisplayOption = {
  EO: 0,
  MAP: 1,
  IR: 2,
  AR_OBSERVER: 3,
  NONE: 4,
  MAX: 5
};

goog.object.extend(exports, proto.skydio.phone_stream);
