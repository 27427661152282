// This file automatically generated by skymarshal.
// DO NOT MODIFY BY HAND!!!!
//
// From Source File: infrastructure/skybus_tunnel/lcmtypes/multipart_msg_t.lcm

import { int32_t } from "@skydio/lcm/src/types";
import { int8_t } from "@skydio/lcm/src/types";

import Long from "long";
import Decoder from "@skydio/lcm/src/decoder";
import Encoder from "@skydio/lcm/src/encoder";
import LcmMsg from "@skydio/lcm/src/lcm";
import ReflectionMeta from "@skydio/lcm/src/reflection";

export class multipart_msg_t extends LcmMsg {

  private static _hash = Long.fromString("8986471992976567449", true);
  private static _packed_fingerprint?: Long;

  /** @internal */
  public static override _get_packed_fingerprint(): Long {
    if (multipart_msg_t._packed_fingerprint === undefined) {
      multipart_msg_t._packed_fingerprint = multipart_msg_t._get_hash_recursive([]);
    }
    return multipart_msg_t._packed_fingerprint;
  }

  /** @internal */
  public static override _get_hash_recursive(parents: any[]): Long {
    if (parents.indexOf(multipart_msg_t) > -1) {
      return Long.fromNumber(0, true);
    }
    let tmp_hash = multipart_msg_t._hash;
    return tmp_hash.shl(1).add(tmp_hash.shru(63));
  }

  /** @internal */
  public static override _reflection_meta: ReflectionMeta = {
    id: { kind: "primitive", type: Number, typeStr: "int32_t" },
    total_size: { kind: "primitive", type: Number, typeStr: "int32_t" },
    channel: { kind: "primitive", type: String, typeStr: "string" },
    chunk_index: { kind: "primitive", type: Number, typeStr: "int8_t" },
    chunk_count: { kind: "primitive", type: Number, typeStr: "int8_t" },
    chunk_size: { kind: "primitive", type: Number, typeStr: "int32_t" },
    chunk_data: { kind: "bytes", dims: [{ kind: "dynamic", member: "chunk_size" }], nDim: 1 },
  };

  public id: int32_t;
  public total_size: int32_t;
  public channel: string;
  public chunk_index: int8_t;
  public chunk_count: int8_t;
  public chunk_size: int32_t;
  public chunk_data: Uint8Array;

  public override decode(byte_array: Uint8Array): multipart_msg_t {
    const decoder = new Decoder(new DataView(
      byte_array.buffer, byte_array.byteOffset, byte_array.byteLength
    ));
    const hash = decoder.decode_hash();
    if (!hash.equals(multipart_msg_t._get_packed_fingerprint())) {
      console.error("Hash mismatch", hash, multipart_msg_t._get_packed_fingerprint());
      throw new Error("Hash mismatch!");
    }
    this.decode_one(decoder);
    return this;
  }

  public override decode_one(decoder: Decoder): multipart_msg_t {
    this.id = decoder.decode_int32_t();
    this.total_size = decoder.decode_int32_t();
    this.channel = decoder.decode_string();
    this.chunk_index = decoder.decode_int8_t();
    this.chunk_count = decoder.decode_int8_t();
    this.chunk_size = decoder.decode_int32_t();
    this.chunk_data = decoder.decode_uint8_array([this.chunk_size]);
    return this;
  }

  /** @internal */
  public _get_encoded_size(): number {
    let total_size = 0;
    total_size += 4;
    total_size += 4;
    total_size += 4 + new TextEncoder().encode(this.channel).length + 1;
    total_size += 1;
    total_size += 1;
    total_size += 4;
    total_size += 1 * this.chunk_size;
    return total_size;
  }

  public override encode(): ArrayBuffer {
    const encoder = new Encoder(8 + this._get_encoded_size()); // hash + rest
    encoder.encode_hash(multipart_msg_t._get_packed_fingerprint());
    this.encode_one(encoder);

    return encoder.view.buffer;
  }

  public override encode_one(encoder: Encoder): void {
    encoder.encode_int32_t(this.id);
    encoder.encode_int32_t(this.total_size);
    encoder.encode_string(this.channel);
    encoder.encode_int8_t(this.chunk_index);
    encoder.encode_int8_t(this.chunk_count);
    encoder.encode_int32_t(this.chunk_size);
    encoder.encode_uint8_array(this.chunk_data);
  }

  constructor(init?: {id?: int32_t, total_size?: int32_t, channel?: string, chunk_index?: int8_t, chunk_count?: int8_t, chunk_size?: int32_t, chunk_data?: Uint8Array }) {
    super();
    this.id = 0;
    this.total_size = 0;
    this.channel = "";
    this.chunk_index = 0;
    this.chunk_count = 0;
    this.chunk_size = 0;
    this.chunk_data = new Uint8Array(0);

    // optionally override default values
    if (!init) {
      return;
    }
    this.id = init.id || this.id;
    this.total_size = init.total_size || this.total_size;
    this.channel = init.channel || this.channel;
    this.chunk_index = init.chunk_index || this.chunk_index;
    this.chunk_count = init.chunk_count || this.chunk_count;
    this.chunk_size = init.chunk_size || this.chunk_size;
    this.chunk_data = init.chunk_data || this.chunk_data;
  }
}