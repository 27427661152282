import { IWebsocketOptions } from "@skydio/uri_util/src";

import { PublishOptions, WireMsg, WireType } from "../types";
import { IConnectionStatus, ISkybusOptions, RemoteDeviceType } from "./types";
import { ChannelDefinition } from "./types";

export abstract class TransportLayer implements IConnectionStatus {
  public abstract get isConnected(): boolean;
  public readonly options: ISkybusOptions;

  // list of callbacks we notify when websocket [dis]connects
  protected connectionStatusListeners: Array<(connected: boolean) => void> = [];

  constructor(options: ISkybusOptions = {}) {
    this.options = options;
  }

  public abstract waitForConnect(): Promise<{}>;

  public abstract connect(options?: string | IWebsocketOptions): void;

  public abstract close(): void;

  // TODO(sam): map connectors by device id rather than device type to more readily support
  // multi-drone use cases
  public abstract createConnector(deviceType?: RemoteDeviceType): Connector;

  public addConnectionStatusListener = (callback: (connected: boolean) => void): (() => void) => {
    this.connectionStatusListeners.push(callback);

    return () => {
      // remove callback from listeners if it exists
      const indexOfCallback = this.connectionStatusListeners.indexOf(callback);
      if (indexOfCallback > -1) {
        this.connectionStatusListeners.splice(indexOfCallback, 1);
      }
    };
  };
}

type HandleMessageCallback = (channel: string, encodedData: Uint8Array) => boolean;

export abstract class Connector {
  private handleMessageCallback: HandleMessageCallback;
  public transport: TransportLayer;

  public abstract publish<D extends WireMsg>(
    channel: string | ChannelDefinition<WireType<D>>,
    msg: D,
    options?: PublishOptions
  ): Promise<void>;

  public get options() {
    return this.transport.options;
  }

  public handleMessage = (channel: string, encodedData: Uint8Array): boolean => {
    if (this.handleMessageCallback) {
      return this.handleMessageCallback(channel, encodedData);
    }
    return false;
  };

  public registerOnMessageCallback = (onMessage: HandleMessageCallback): void => {
    this.handleMessageCallback = onMessage;
  };
}
