// source: pbtypes/gen/user_prompts/flight_status_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.user_prompts.FlightStatusMessage', null, global);
goog.exportSymbol('proto.user_prompts.FlightStatusMessage.Enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user_prompts.FlightStatusMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user_prompts.FlightStatusMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user_prompts.FlightStatusMessage.displayName = 'proto.user_prompts.FlightStatusMessage';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user_prompts.FlightStatusMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.user_prompts.FlightStatusMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user_prompts.FlightStatusMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user_prompts.FlightStatusMessage.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user_prompts.FlightStatusMessage}
 */
proto.user_prompts.FlightStatusMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user_prompts.FlightStatusMessage;
  return proto.user_prompts.FlightStatusMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user_prompts.FlightStatusMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user_prompts.FlightStatusMessage}
 */
proto.user_prompts.FlightStatusMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user_prompts.FlightStatusMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user_prompts.FlightStatusMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user_prompts.FlightStatusMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user_prompts.FlightStatusMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.user_prompts.FlightStatusMessage.Enum = {
  INVALID: 0,
  NONE: 1,
  VEHICLE_REST: 101,
  VEHICLE_PREP: 102,
  VEHICLE_PREP_TIMEOUT: 125,
  WAIT_FOR_ARM: 103,
  READY_TO_FLY: 124,
  SOFTWARE_CHECKS: 104,
  SOFTWARE_CHECKS_TIMEOUT: 126,
  SOFTWARE_CHECKS_NEEDS_MOTION: 127,
  SOFTWARE_CHECKS_STOP_HAND_WAVE: 152,
  SOFTWARE_CHECKS_HAND_WAVE_UNAVAILABLE: 154,
  SOFTWARE_CHECKS_MAGNETOMETER_NEEDS_CAL: 132,
  LOGGING_START: 107,
  LOGGING_START_TIMEOUT: 135,
  BEACON_NEEDS_ASSISTNOW: 105,
  VEHICLE_NEEDS_ASSISTNOW: 133,
  BEACON_GPS_QUALITY_BLOCKING: 106,
  US_RID_BEACON_GPS_INOPERATIVE: 854,
  US_RID_CONTROLLER_GPS_INOPERATIVE: 853,
  US_RID_RPIC_GPS_INOPERATIVE: 859,
  US_RID_TX_FAILURE: 909,
  VEHICLE_GPS_QUALITY_BLOCKING: 130,
  VEHICLE_GPS_INOPERATIVE: 851,
  SAFETY_CHECK: 110,
  MANUAL_ARM_HINT: 156,
  NOT_STILL_OR_LEVEL: 111,
  TOO_FAST_FOR_TAKEOFF: 112,
  TOO_FAST_FOR_TAKEOFF_NIGHT: 129,
  TOO_FAST_FOR_TAKEOFF_VESSEL: 134,
  NOT_SAFE_FOR_HAND_TAKEOFF: 113,
  READY_FOR_HAND_TAKEOFF: 115,
  GIMBAL_OBSTRUCTED: 117,
  GIMBAL_FALCON_HOOD: 118,
  HAND_TAKEOFF_DANGEROUS: 119,
  HAND_TAKEOFF_DANGEROUS_DARK: 121,
  JOYSTICKS_INPUT_PRE_TAKEOFF: 123,
  TRIGGER_OR_ROLLER_INPUT_PRE_TAKEOFF: 128,
  DOCK_NOT_READY_FOR_TAKEOFF: 150,
  RETURN_ON_DISCONNECT_DISABLED: 151,
  TAKEOFF_BLOCKED_DOCK_DISCONNECTED: 850,
  TAKEOFF_BLOCKED_DOCK_VEHICLE_PAIRING_MISMATCH: 155,
  ARMED_IN_DOCK_INSPECTION: 157,
  ARMED_IN_DOCK_TAKEOFF_CANCELED: 158,
  ATTI_RTX_REQUIRES_MAG_CAL_PRE_TAKEOFF: 159,
  ATTI_RTX_REQUIRES_MAG_CAL_POST_TAKEOFF: 160,
  ATTI_RTX_MAG_REQUIRES_LOCATION_PRE_TAKEOFF: 161,
  ATTI_TAKEOFF_AWAITING_USER_INPUT: 162,
  MOTORS_SPINNING_UP: 201,
  TAKING_OFF: 202,
  TAKEOFF_SUBJECT_LOCKING: 203,
  LANDING_ETA: 301,
  LANDING_RECOMMENDED: 302,
  SMART_LAND_PREP: 303,
  LAND_WITH_AVOIDANCE: 315,
  PAUSED_FOR_FINAL_DESCENT: 317,
  LANDING: 304,
  UNCANCELLABLE_CASE_LANDING: 325,
  UNCANCELLABLE_SMART_LAND_PREP: 311,
  UNCANCELLABLE_LAND_WITH_AVOIDANCE: 316,
  UNCANCELLABLE_PAUSED_FOR_FINAL_DESCENT: 318,
  UNCANCELLABLE_LANDING: 312,
  FLIGHT_COMPLETE_PREPARING_VIDEO: 305,
  EMERGENCY_LANDING: 306,
  RESUMING_FLIGHT: 307,
  DUMB_VERTICAL_LANDING_NOTICE: 308,
  TAKEOFF_CANCELLED: 309,
  TAKEOFF_CANCELLED_MOTOR_VIBE: 333,
  BATTERY_LOW_RETURN: 865,
  BATTERY_CRITICAL_BEACON: 314,
  CASE_LANDING: 319,
  DOCK_LANDING: 326,
  IMPERFECT_CONTACT_RETRY_DOCK_LANDING: 327,
  LANDED_OUTSIDE_DOCK: 856,
  BATTERY_CRITICAL_LANDING: 338,
  BATTERY_CRITICAL_WARNING: 328,
  BATTERY_EMPTY: 339,
  BATTERY_VOLTAGE_IMBALANCE: 334,
  BATTERY_VOLTAGE_IMBALANCE_LAND: 335,
  BATTERY_LOW_DISABLE_AUTONOMOUS: 330,
  BAD_CPU_GPU_FREQUENCY: 331,
  MOTOR_CURRENT_CRITICAL: 332,
  ARMED_IN_DOCK_POST_FLIGHT: 336,
  EMERGENCY_LANDING_COMMANDED: 320,
  EMERGENCY_LANDING_STATE_ESTIMATION: 321,
  EMERGENCY_LANDING_LOST_RC: 322,
  EMERGENCY_LANDING_SYMPC_FAIL: 323,
  EMERGENCY_LANDING_TEGRA_STALE: 324,
  EMERGENCY_LANDING_STATIC_INIT_FAILED: 329,
  EMERGENCY_LANDING_PILOTED: 337,
  EMERGENCY_LANDING_INIT_FAILED_ARM_POSITION: 858,
  LAND_BUTTON_SELECTION_PROMPT: 350,
  GAP_TOO_SMALL: 401,
  LOST_SUBJECT: 404,
  RETURNING_TO_PHONE: 405,
  RTP_GAP_TOO_SMALL: 407,
  RTP_WITHIN_RANGE: 408,
  PHONE_GPS_FOLLOW: 409,
  HOME_BUTTON_SELECTION_PROMPT: 411,
  RT_HOME_WITHIN_RANGE: 412,
  RT_TAKEOFF_WITHIN_RANGE: 413,
  RT_LOW_BATTERY_WITHIN_RANGE: 867,
  OBSTACLE_AVOIDANCE_DISABLED: 420,
  TRACKING_DISABLED: 421,
  GIMBAL_OBSTRUCTED_IN_FLIGHT: 422,
  ENVIRONMENT_DANGEROUS: 423,
  POOR_STEREO_VISIBILITY: 424,
  VERY_POOR_STEREO_VISIBILITY: 425,
  HIGH_WINDS_WARN: 426,
  HIGH_WINDS_SEVERE: 427,
  HIGH_WINDS_LAND: 428,
  GETTING_DARK: 429,
  TOO_DARK: 430,
  TEGRA_STATE_ESTIMATION_HOVER: 431,
  TEGRA_STATE_ESTIMATION_FAILED: 432,
  TOO_DARK_CRITICAL: 436,
  COMMANDING_BEYOND_SAFETY_HEIGHT: 437,
  BLOCKED_BY_SAFETY_HEIGHT: 438,
  SAFETY_HEIGHT_OFF_WARNING: 439,
  BEACON_GPS_QUALITY_INSUFFICIENT_PAUSED: 440,
  BEACON_GPS_QUALITY_INSUFFICIENT_HAS_VISUAL: 441,
  RTP_NOT_AVAILABLE: 442,
  COMMANDED_PAST_INDOOR_POOR_GPS_HEIGHT: 449,
  COMMANDED_PAST_POOR_GPS_HEIGHT: 444,
  COMMANDED_PAST_MAX_HEIGHT: 445,
  VIO_UNRELIABLE_REDUCED_OBSTACLES: 446,
  RETURNING_TO_DOCK: 447,
  RETURNING_TO_LAUNCH: 855,
  TOO_DARK_FOR_VISION: 448,
  TOO_DARK_TRY_NIGHT_AUTONOMY: 878,
  HOME_POINT_SET: 450,
  HOME_POINT_CLEARED: 451,
  AT_GEOFENCE_EDGE: 452,
  RETURNING_TO_GEOFENCE: 453,
  ARM_IMPROPER_POSITION_VISION: 454,
  FAR_OUTSIDE_GEOFENCE: 455,
  GPS_AWAITING_HEADING: 456,
  GROUND_AVOIDANCE_ACTIVE: 457,
  CAMERA_FAILURE: 501,
  TOO_HOT_CRITICAL_FINAL: 502,
  TOO_HOT_CRITICAL: 513,
  TOO_HOT_WARNING: 514,
  TOO_HOT_LANDING: 511,
  SENSOR_FAILURE: 503,
  SENSOR_FAILURE_LANDING: 510,
  MEDIA_SD_SLOW: 504,
  TEGRA_REBOOT_RECOVERED: 505,
  DIGITAL_ZOOM_UNAVAILABLE: 506,
  SUPERZOOM_UNAVAILABLE_SUBJECT_TRACKING: 507,
  BACKTRACK_UNAVAILABLE_TEGRA_REBOOTED: 508,
  NAV_CAMERA_LOGGING_DEGRADED: 509,
  JOYSTICKS_INPUT_ERRATIC: 512,
  COMMUNICATION_LOST_IN_FLIGHT: 601,
  WIFI_SIGNAL_WEAK: 602,
  COMMUNICATION_LOST_RTP: 604,
  CAMERA_MODE_SWITCH: 606,
  COMMUNICATION_LOST_RTH: 610,
  COMMUNICATION_LOST_WITH_JOYSTICKS: 611,
  COMMUNICATION_LOST_WITH_BEACON: 612,
  COMMUNICATION_LOST_RTL: 613,
  DEFAULT_WIFI_AP_CREDENTIALS: 617,
  STREAMING_CONNECTION_LOST: 620,
  PILOT: 621,
  TRANSITION_TO_PILOT: 622,
  PASSENGER: 624,
  CELLULAR_SIGNAL_WEAK: 625,
  CUSTOM_CINEMATIC_UI_LAYOUT: 705,
  LOADING_SKILLS: 706,
  STOP_AT_STRUCTURE: 707,
  MINIMAL_OBSTACLES: 708,
  REDUCED_OBSTACLES: 709,
  DISABLED_OBSTACLES: 710,
  GIMBAL_FLASHLIGHT_TOO_HOT: 711,
  TAKEOFF_BLOCKED_MOTOR_FAULT: 800,
  TAKEOFF_BLOCKED_USBC_IN_USE: 801,
  TAKEOFF_BLOCKED_RESTART_AIRHAWK: 802,
  TAKEOFF_BLOCKED_BATTERY_COLD: 810,
  TAKEOFF_BLOCKED_BATTERY_LOW: 811,
  TAKEOFF_BLOCKED_RESET_BATTERY: 812,
  TAKEOFF_BLOCKED_CHARGE_PHONE: 813,
  TAKEOFF_BLOCKED_CHARGE_BEACON: 814,
  TAKEOFF_BLOCKED_CHARGE_CONTROLLER: 815,
  TAKEOFF_BLOCKED_CHARGE_PHONE_CRITICAL: 816,
  TAKEOFF_BLOCKED_SHOULD_CONNECT_THROUGH_JOYSTICKS: 817,
  TAKEOFF_BLOCKED_SHOULD_CONNECT_THROUGH_BEACON: 818,
  TAKEOFF_BLOCKED_SHOULD_CONNECT_SKYSTICKS: 819,
  TAKEOFF_BLOCKED_MEDIA_SD_MISSING: 821,
  TAKEOFF_BLOCKED_MEDIA_SD_UNFORMATTED: 822,
  TAKEOFF_BLOCKED_MEDIA_SD_CORRUPTED: 823,
  TAKEOFF_BLOCKED_MEDIA_SD_SLOW: 824,
  TAKEOFF_BLOCKED_RECORDING_MEDIA_FULL: 825,
  TAKEOFF_BLOCKED_LOGGING_SD_WRONG_FILESYSTEM: 826,
  TAKEOFF_BLOCKED_LOGGING_SD_MISSING: 828,
  TAKEOFF_BLOCKED_LOGGING_SD_DEGRADED: 831,
  TAKEOFF_BLOCKED_LOGGING_SD_SLOW: 832,
  TAKEOFF_BLOCKED_LOGGING_SPACE_FULL: 833,
  TAKEOFF_BLOCKED_VIO_DEGRADED: 830,
  TAKEOFF_BLOCKED_TOO_DARK: 840,
  TAKEOFF_BLOCKED_OVERHEATING: 841,
  TAKEOFF_BLOCKED_NIGHTSENSE_ATTACHMENT_DEGRADED: 898,
  TAKEOFF_BLOCKED_TOO_DARK_FOR_VISION: 843,
  TAKEOFF_LIMITED_TOO_DARK_TRY_NIGHT_AUTONOMY: 879,
  TAKEOFF_BLOCKED_OUTSIDE_GEOFENCE: 860,
  TAKEOFF_BLOCKED_GEOFENCE_UNRELIABLE: 861,
  TAKEOFF_BLOCKED_ATTACHMENT_INVALID_COMBO: 905,
  AUTO_GPS_INITIALIZATION_FAILED: 844,
  EXIT_4K60FPS: 845,
  EXIT_SUPERZOOM: 846,
  TAKEOFF_BLOCKED_RID_CHECK: 847,
  TAKEOFF_BLOCKED_RID_KEY_REQUIRED: 862,
  US_RID_PHONE_GPS_INOPERATIVE: 852,
  EXIT_4K30FPS: 848,
  EXIT_HD30FPS: 849,
  TAKEOFF_BLOCKED_MEDIA_SD_WRONG_ENCRYPTION_KEY: 857,
  TAKEOFF_BLOCKED_ARM_IMPROPER_POSITION: 863,
  SPURIOUS_VOXELS_DAY: 871,
  SPURIOUS_VOXELS_NIGHT: 868,
  VIRTUAL_GIMBAL_ENABLED: 864,
  TOO_COLD_LIMP_MODE: 866,
  OBSTACLE_MARGINS_CANNOT_EXPAND: 869,
  INFRARED_CAMERA_UNRELIABLE: 870,
  TAKEOFF_BLOCKED_SYSTEM_FAULT: 872,
  C18_OVERHEATING_STAGE3: 873,
  C18_OVERHEATING_STAGE4: 874,
  C18_OVERHEATING_STAGE5: 875,
  MISMATCHED_NIGHTSENSE_ATTACHMENT_DETECTED: 876,
  MIXED_NIGHTSENSE_ATTACHMENTS_DETECTED: 877,
  NIGHTSENSE_ATTACHMENT_DEGRADED: 880,
  NIGHTSENSE_DISABLED_ATTACHMENT_FAILURE: 881,
  NIGHTSENSE_DISABLED_ATTACHMENT_DISCONNECTED: 882,
  NIGHTSENSE_UNAVAILABLE_ATTACHMENT_MISSING: 883,
  MICROHARD_DYNAMIC_CHANNEL_SWITCH: 884,
  GPS_SPOOFED: 885,
  MOTOR_OSCILLATION_DETECTED: 900,
  ATTACHMENT_SPOTLIGHT_BRIGHTNESS_THROTTLING: 897,
  ATTACHMENT_SPOTLIGHT_THERMAL_SHUTDOWN: 887,
  ATTACHMENT_WRONG_CABLE_ORIENTATION: 888,
  ATTACHMENT_IN_INVALID_PORT_TOP: 889,
  ATTACHMENT_IN_INVALID_PORT_BOTTOM: 890,
  ATTACHMENT_IN_INVALID_PORT_LEFT: 891,
  ATTACHMENT_IN_INVALID_PORT_RIGHT: 892,
  ATTACHMENT_INSPECT_OR_REPLACE_TOP: 893,
  ATTACHMENT_INSPECT_OR_REPLACE_BOTTOM: 894,
  ATTACHMENT_INSPECT_OR_REPLACE_LEFT: 895,
  ATTACHMENT_INSPECT_OR_REPLACE_RIGHT: 896,
  ATTACHMENT_RTK_COM_FAILURE: 906,
  ATTACHMENT_PARACHUTE_DEPLOYED: 910,
  NIGHTSENSE_LOW_LIGHT_REQUIRED: 901,
  CONTROLLER_JOYSTICKS_DISABLED: 902,
  READY_LINK_POOR_NETWORK_CONNECTION: 903,
  LIVE_STREAM_POOR_NETWORK_CONNECTION: 904,
  NIGHTSENSE_BETTER_GPS_REQUIRED: 908,
  MOTOR_IDLE_OVERCURRENT_CRITICAL: 911,
  MOTOR_IDLE_OVERCURRENT_WARNING: 912,
  PINE_HIGH_INTERFERENCE: 914
};

goog.object.extend(exports, proto.user_prompts);
