// source: pbtypes/gen/surface_scan/inspection_parameters.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_eigen_lcm_Vector2d_pb = require('../../../pbtypes/gen/eigen_lcm/Vector2d_pb.js');
goog.object.extend(proto, pbtypes_gen_eigen_lcm_Vector2d_pb);
var pbtypes_gen_eigen_lcm_Vector3f_pb = require('../../../pbtypes/gen/eigen_lcm/Vector3f_pb.js');
goog.object.extend(proto, pbtypes_gen_eigen_lcm_Vector3f_pb);
var pbtypes_gen_scan_planner_polygon_prism_pb = require('../../../pbtypes/gen/scan_planner/polygon_prism_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_polygon_prism_pb);
var pbtypes_gen_scan_planner_single_orbit_request_pb = require('../../../pbtypes/gen/scan_planner/single_orbit_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_single_orbit_request_pb);
var pbtypes_gen_structure_structure_pb = require('../../../pbtypes/gen/structure/structure_pb.js');
goog.object.extend(proto, pbtypes_gen_structure_structure_pb);
var pbtypes_gen_surface_scan_gps_helper_state_pb = require('../../../pbtypes/gen/surface_scan/gps_helper_state_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_gps_helper_state_pb);
var pbtypes_gen_surface_scan_relocalization_strategy_pb = require('../../../pbtypes/gen/surface_scan/relocalization_strategy_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_relocalization_strategy_pb);
goog.exportSymbol('proto.surface_scan.InspectionParameters', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.surface_scan.InspectionParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.surface_scan.InspectionParameters.repeatedFields_, null);
};
goog.inherits(proto.surface_scan.InspectionParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.surface_scan.InspectionParameters.displayName = 'proto.surface_scan.InspectionParameters';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.surface_scan.InspectionParameters.repeatedFields_ = [9,14,15,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.surface_scan.InspectionParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.surface_scan.InspectionParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.surface_scan.InspectionParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.surface_scan.InspectionParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
polygonPrism: (f = msg.getPolygonPrism()) && pbtypes_gen_scan_planner_polygon_prism_pb.PolygonPrism.toObject(includeInstance, f),
focusPoint: (f = msg.getFocusPoint()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
viewpointCandidatesList: jspb.Message.toObjectList(msg.getViewpointCandidatesList(),
    pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject, includeInstance),
viewpointFocusPoint: (f = msg.getViewpointFocusPoint()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
gpsHelper: (f = msg.getGpsHelper()) && pbtypes_gen_surface_scan_gps_helper_state_pb.GpsHelperState.toObject(includeInstance, f),
relocalizationStrategy: jspb.Message.getFieldWithDefault(msg, 12, 0),
singleOrbitRequestsList: jspb.Message.toObjectList(msg.getSingleOrbitRequestsList(),
    pbtypes_gen_scan_planner_single_orbit_request_pb.SingleOrbitRequest.toObject, includeInstance),
structuresList: jspb.Message.toObjectList(msg.getStructuresList(),
    pbtypes_gen_structure_structure_pb.Structure.toObject, includeInstance),
globalMapTColumnPositionsXyList: jspb.Message.toObjectList(msg.getGlobalMapTColumnPositionsXyList(),
    pbtypes_gen_eigen_lcm_Vector2d_pb.Vector2dPb.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.surface_scan.InspectionParameters}
 */
proto.surface_scan.InspectionParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.surface_scan.InspectionParameters;
  return proto.surface_scan.InspectionParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.surface_scan.InspectionParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.surface_scan.InspectionParameters}
 */
proto.surface_scan.InspectionParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_scan_planner_polygon_prism_pb.PolygonPrism;
      reader.readMessage(value,pbtypes_gen_scan_planner_polygon_prism_pb.PolygonPrism.deserializeBinaryFromReader);
      msg.setPolygonPrism(value);
      break;
    case 6:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setFocusPoint(value);
      break;
    case 9:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.addViewpointCandidates(value);
      break;
    case 10:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setViewpointFocusPoint(value);
      break;
    case 11:
      var value = new pbtypes_gen_surface_scan_gps_helper_state_pb.GpsHelperState;
      reader.readMessage(value,pbtypes_gen_surface_scan_gps_helper_state_pb.GpsHelperState.deserializeBinaryFromReader);
      msg.setGpsHelper(value);
      break;
    case 12:
      var value = /** @type {!proto.surface_scan.RelocalizationStrategy.Enum} */ (reader.readEnum());
      msg.setRelocalizationStrategy(value);
      break;
    case 14:
      var value = new pbtypes_gen_scan_planner_single_orbit_request_pb.SingleOrbitRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_single_orbit_request_pb.SingleOrbitRequest.deserializeBinaryFromReader);
      msg.addSingleOrbitRequests(value);
      break;
    case 15:
      var value = new pbtypes_gen_structure_structure_pb.Structure;
      reader.readMessage(value,pbtypes_gen_structure_structure_pb.Structure.deserializeBinaryFromReader);
      msg.addStructures(value);
      break;
    case 16:
      var value = new pbtypes_gen_eigen_lcm_Vector2d_pb.Vector2dPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector2d_pb.Vector2dPb.deserializeBinaryFromReader);
      msg.addGlobalMapTColumnPositionsXy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.surface_scan.InspectionParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.surface_scan.InspectionParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.surface_scan.InspectionParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.surface_scan.InspectionParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolygonPrism();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_scan_planner_polygon_prism_pb.PolygonPrism.serializeBinaryToWriter
    );
  }
  f = message.getFocusPoint();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getViewpointCandidatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getViewpointFocusPoint();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getGpsHelper();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      pbtypes_gen_surface_scan_gps_helper_state_pb.GpsHelperState.serializeBinaryToWriter
    );
  }
  f = message.getRelocalizationStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getSingleOrbitRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      pbtypes_gen_scan_planner_single_orbit_request_pb.SingleOrbitRequest.serializeBinaryToWriter
    );
  }
  f = message.getStructuresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      pbtypes_gen_structure_structure_pb.Structure.serializeBinaryToWriter
    );
  }
  f = message.getGlobalMapTColumnPositionsXyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      pbtypes_gen_eigen_lcm_Vector2d_pb.Vector2dPb.serializeBinaryToWriter
    );
  }
};


/**
 * optional scan_planner.PolygonPrism polygon_prism = 1;
 * @return {?proto.scan_planner.PolygonPrism}
 */
proto.surface_scan.InspectionParameters.prototype.getPolygonPrism = function() {
  return /** @type{?proto.scan_planner.PolygonPrism} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_polygon_prism_pb.PolygonPrism, 1));
};


/**
 * @param {?proto.scan_planner.PolygonPrism|undefined} value
 * @return {!proto.surface_scan.InspectionParameters} returns this
*/
proto.surface_scan.InspectionParameters.prototype.setPolygonPrism = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.surface_scan.InspectionParameters} returns this
 */
proto.surface_scan.InspectionParameters.prototype.clearPolygonPrism = function() {
  return this.setPolygonPrism(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.surface_scan.InspectionParameters.prototype.hasPolygonPrism = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional eigen_lcm.Vector3fPb focus_point = 6;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.surface_scan.InspectionParameters.prototype.getFocusPoint = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 6));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.surface_scan.InspectionParameters} returns this
*/
proto.surface_scan.InspectionParameters.prototype.setFocusPoint = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.surface_scan.InspectionParameters} returns this
 */
proto.surface_scan.InspectionParameters.prototype.clearFocusPoint = function() {
  return this.setFocusPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.surface_scan.InspectionParameters.prototype.hasFocusPoint = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated eigen_lcm.Vector3fPb viewpoint_candidates = 9;
 * @return {!Array<!proto.eigen_lcm.Vector3fPb>}
 */
proto.surface_scan.InspectionParameters.prototype.getViewpointCandidatesList = function() {
  return /** @type{!Array<!proto.eigen_lcm.Vector3fPb>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 9));
};


/**
 * @param {!Array<!proto.eigen_lcm.Vector3fPb>} value
 * @return {!proto.surface_scan.InspectionParameters} returns this
*/
proto.surface_scan.InspectionParameters.prototype.setViewpointCandidatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.eigen_lcm.Vector3fPb=} opt_value
 * @param {number=} opt_index
 * @return {!proto.eigen_lcm.Vector3fPb}
 */
proto.surface_scan.InspectionParameters.prototype.addViewpointCandidates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.eigen_lcm.Vector3fPb, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.surface_scan.InspectionParameters} returns this
 */
proto.surface_scan.InspectionParameters.prototype.clearViewpointCandidatesList = function() {
  return this.setViewpointCandidatesList([]);
};


/**
 * optional eigen_lcm.Vector3fPb viewpoint_focus_point = 10;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.surface_scan.InspectionParameters.prototype.getViewpointFocusPoint = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 10));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.surface_scan.InspectionParameters} returns this
*/
proto.surface_scan.InspectionParameters.prototype.setViewpointFocusPoint = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.surface_scan.InspectionParameters} returns this
 */
proto.surface_scan.InspectionParameters.prototype.clearViewpointFocusPoint = function() {
  return this.setViewpointFocusPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.surface_scan.InspectionParameters.prototype.hasViewpointFocusPoint = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GpsHelperState gps_helper = 11;
 * @return {?proto.surface_scan.GpsHelperState}
 */
proto.surface_scan.InspectionParameters.prototype.getGpsHelper = function() {
  return /** @type{?proto.surface_scan.GpsHelperState} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_surface_scan_gps_helper_state_pb.GpsHelperState, 11));
};


/**
 * @param {?proto.surface_scan.GpsHelperState|undefined} value
 * @return {!proto.surface_scan.InspectionParameters} returns this
*/
proto.surface_scan.InspectionParameters.prototype.setGpsHelper = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.surface_scan.InspectionParameters} returns this
 */
proto.surface_scan.InspectionParameters.prototype.clearGpsHelper = function() {
  return this.setGpsHelper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.surface_scan.InspectionParameters.prototype.hasGpsHelper = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional RelocalizationStrategy.Enum relocalization_strategy = 12;
 * @return {!proto.surface_scan.RelocalizationStrategy.Enum}
 */
proto.surface_scan.InspectionParameters.prototype.getRelocalizationStrategy = function() {
  return /** @type {!proto.surface_scan.RelocalizationStrategy.Enum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.surface_scan.RelocalizationStrategy.Enum} value
 * @return {!proto.surface_scan.InspectionParameters} returns this
 */
proto.surface_scan.InspectionParameters.prototype.setRelocalizationStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * repeated scan_planner.SingleOrbitRequest single_orbit_requests = 14;
 * @return {!Array<!proto.scan_planner.SingleOrbitRequest>}
 */
proto.surface_scan.InspectionParameters.prototype.getSingleOrbitRequestsList = function() {
  return /** @type{!Array<!proto.scan_planner.SingleOrbitRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_scan_planner_single_orbit_request_pb.SingleOrbitRequest, 14));
};


/**
 * @param {!Array<!proto.scan_planner.SingleOrbitRequest>} value
 * @return {!proto.surface_scan.InspectionParameters} returns this
*/
proto.surface_scan.InspectionParameters.prototype.setSingleOrbitRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.scan_planner.SingleOrbitRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scan_planner.SingleOrbitRequest}
 */
proto.surface_scan.InspectionParameters.prototype.addSingleOrbitRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.scan_planner.SingleOrbitRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.surface_scan.InspectionParameters} returns this
 */
proto.surface_scan.InspectionParameters.prototype.clearSingleOrbitRequestsList = function() {
  return this.setSingleOrbitRequestsList([]);
};


/**
 * repeated structure.Structure structures = 15;
 * @return {!Array<!proto.structure.Structure>}
 */
proto.surface_scan.InspectionParameters.prototype.getStructuresList = function() {
  return /** @type{!Array<!proto.structure.Structure>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_structure_structure_pb.Structure, 15));
};


/**
 * @param {!Array<!proto.structure.Structure>} value
 * @return {!proto.surface_scan.InspectionParameters} returns this
*/
proto.surface_scan.InspectionParameters.prototype.setStructuresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.structure.Structure=} opt_value
 * @param {number=} opt_index
 * @return {!proto.structure.Structure}
 */
proto.surface_scan.InspectionParameters.prototype.addStructures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.structure.Structure, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.surface_scan.InspectionParameters} returns this
 */
proto.surface_scan.InspectionParameters.prototype.clearStructuresList = function() {
  return this.setStructuresList([]);
};


/**
 * repeated eigen_lcm.Vector2dPb global_map_t_column_positions_xy = 16;
 * @return {!Array<!proto.eigen_lcm.Vector2dPb>}
 */
proto.surface_scan.InspectionParameters.prototype.getGlobalMapTColumnPositionsXyList = function() {
  return /** @type{!Array<!proto.eigen_lcm.Vector2dPb>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_eigen_lcm_Vector2d_pb.Vector2dPb, 16));
};


/**
 * @param {!Array<!proto.eigen_lcm.Vector2dPb>} value
 * @return {!proto.surface_scan.InspectionParameters} returns this
*/
proto.surface_scan.InspectionParameters.prototype.setGlobalMapTColumnPositionsXyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.eigen_lcm.Vector2dPb=} opt_value
 * @param {number=} opt_index
 * @return {!proto.eigen_lcm.Vector2dPb}
 */
proto.surface_scan.InspectionParameters.prototype.addGlobalMapTColumnPositionsXy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.eigen_lcm.Vector2dPb, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.surface_scan.InspectionParameters} returns this
 */
proto.surface_scan.InspectionParameters.prototype.clearGlobalMapTColumnPositionsXyList = function() {
  return this.setGlobalMapTColumnPositionsXyList([]);
};


goog.object.extend(exports, proto.surface_scan);
