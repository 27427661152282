// source: pbtypes/vehicle/skills/actions/update_vehicle_settings/update_vehicle_settings.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb = require('../../../../../pbtypes/vehicle/infrastructure/flight_deck/return_settings_pb.js');
goog.object.extend(proto, pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb);
goog.exportSymbol('proto.skills.BoolSetting', null, global);
goog.exportSymbol('proto.skills.EnumSetting', null, global);
goog.exportSymbol('proto.skills.FloatSetting', null, global);
goog.exportSymbol('proto.skills.UpdateVehicleSettingsActionArgs', null, global);
goog.exportSymbol('proto.skills.UpdateVehicleSettingsActionStateData', null, global);
goog.exportSymbol('proto.skills.UpdateVehicleSettingsResultData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.FloatSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.FloatSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.FloatSetting.displayName = 'proto.skills.FloatSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.BoolSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.BoolSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.BoolSetting.displayName = 'proto.skills.BoolSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.EnumSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.EnumSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.EnumSetting.displayName = 'proto.skills.EnumSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UpdateVehicleSettingsActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.UpdateVehicleSettingsActionArgs.repeatedFields_, null);
};
goog.inherits(proto.skills.UpdateVehicleSettingsActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UpdateVehicleSettingsActionArgs.displayName = 'proto.skills.UpdateVehicleSettingsActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UpdateVehicleSettingsResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UpdateVehicleSettingsResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UpdateVehicleSettingsResultData.displayName = 'proto.skills.UpdateVehicleSettingsResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UpdateVehicleSettingsActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UpdateVehicleSettingsActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UpdateVehicleSettingsActionStateData.displayName = 'proto.skills.UpdateVehicleSettingsActionStateData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.FloatSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.FloatSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.FloatSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FloatSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, ""),
value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.FloatSetting}
 */
proto.skills.FloatSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.FloatSetting;
  return proto.skills.FloatSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.FloatSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.FloatSetting}
 */
proto.skills.FloatSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.FloatSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.FloatSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.FloatSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FloatSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.skills.FloatSetting.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.FloatSetting} returns this
 */
proto.skills.FloatSetting.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float value = 2;
 * @return {number}
 */
proto.skills.FloatSetting.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.FloatSetting} returns this
 */
proto.skills.FloatSetting.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.BoolSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.BoolSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.BoolSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.BoolSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, ""),
value: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.BoolSetting}
 */
proto.skills.BoolSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.BoolSetting;
  return proto.skills.BoolSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.BoolSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.BoolSetting}
 */
proto.skills.BoolSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.BoolSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.BoolSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.BoolSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.BoolSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.skills.BoolSetting.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.BoolSetting} returns this
 */
proto.skills.BoolSetting.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool value = 2;
 * @return {boolean}
 */
proto.skills.BoolSetting.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.BoolSetting} returns this
 */
proto.skills.BoolSetting.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.EnumSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.EnumSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.EnumSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.EnumSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, ""),
value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.EnumSetting}
 */
proto.skills.EnumSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.EnumSetting;
  return proto.skills.EnumSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.EnumSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.EnumSetting}
 */
proto.skills.EnumSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.EnumSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.EnumSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.EnumSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.EnumSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.skills.EnumSetting.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.EnumSetting} returns this
 */
proto.skills.EnumSetting.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.skills.EnumSetting.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.EnumSetting} returns this
 */
proto.skills.EnumSetting.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.UpdateVehicleSettingsActionArgs.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UpdateVehicleSettingsActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UpdateVehicleSettingsActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UpdateVehicleSettingsActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
boolSettingsList: jspb.Message.toObjectList(msg.getBoolSettingsList(),
    proto.skills.BoolSetting.toObject, includeInstance),
floatSettingsList: jspb.Message.toObjectList(msg.getFloatSettingsList(),
    proto.skills.FloatSetting.toObject, includeInstance),
enumSettingsList: jspb.Message.toObjectList(msg.getEnumSettingsList(),
    proto.skills.EnumSetting.toObject, includeInstance),
returnSettings: (f = msg.getReturnSettings()) && pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UpdateVehicleSettingsActionArgs}
 */
proto.skills.UpdateVehicleSettingsActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UpdateVehicleSettingsActionArgs;
  return proto.skills.UpdateVehicleSettingsActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UpdateVehicleSettingsActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UpdateVehicleSettingsActionArgs}
 */
proto.skills.UpdateVehicleSettingsActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.BoolSetting;
      reader.readMessage(value,proto.skills.BoolSetting.deserializeBinaryFromReader);
      msg.addBoolSettings(value);
      break;
    case 2:
      var value = new proto.skills.FloatSetting;
      reader.readMessage(value,proto.skills.FloatSetting.deserializeBinaryFromReader);
      msg.addFloatSettings(value);
      break;
    case 3:
      var value = new proto.skills.EnumSetting;
      reader.readMessage(value,proto.skills.EnumSetting.deserializeBinaryFromReader);
      msg.addEnumSettings(value);
      break;
    case 12:
      var value = new pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings;
      reader.readMessage(value,pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.deserializeBinaryFromReader);
      msg.setReturnSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UpdateVehicleSettingsActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UpdateVehicleSettingsActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UpdateVehicleSettingsActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBoolSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.skills.BoolSetting.serializeBinaryToWriter
    );
  }
  f = message.getFloatSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.skills.FloatSetting.serializeBinaryToWriter
    );
  }
  f = message.getEnumSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.skills.EnumSetting.serializeBinaryToWriter
    );
  }
  f = message.getReturnSettings();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BoolSetting bool_settings = 1;
 * @return {!Array<!proto.skills.BoolSetting>}
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.getBoolSettingsList = function() {
  return /** @type{!Array<!proto.skills.BoolSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.BoolSetting, 1));
};


/**
 * @param {!Array<!proto.skills.BoolSetting>} value
 * @return {!proto.skills.UpdateVehicleSettingsActionArgs} returns this
*/
proto.skills.UpdateVehicleSettingsActionArgs.prototype.setBoolSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.skills.BoolSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.BoolSetting}
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.addBoolSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.skills.BoolSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.UpdateVehicleSettingsActionArgs} returns this
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.clearBoolSettingsList = function() {
  return this.setBoolSettingsList([]);
};


/**
 * repeated FloatSetting float_settings = 2;
 * @return {!Array<!proto.skills.FloatSetting>}
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.getFloatSettingsList = function() {
  return /** @type{!Array<!proto.skills.FloatSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.FloatSetting, 2));
};


/**
 * @param {!Array<!proto.skills.FloatSetting>} value
 * @return {!proto.skills.UpdateVehicleSettingsActionArgs} returns this
*/
proto.skills.UpdateVehicleSettingsActionArgs.prototype.setFloatSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.skills.FloatSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.FloatSetting}
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.addFloatSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.skills.FloatSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.UpdateVehicleSettingsActionArgs} returns this
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.clearFloatSettingsList = function() {
  return this.setFloatSettingsList([]);
};


/**
 * repeated EnumSetting enum_settings = 3;
 * @return {!Array<!proto.skills.EnumSetting>}
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.getEnumSettingsList = function() {
  return /** @type{!Array<!proto.skills.EnumSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.EnumSetting, 3));
};


/**
 * @param {!Array<!proto.skills.EnumSetting>} value
 * @return {!proto.skills.UpdateVehicleSettingsActionArgs} returns this
*/
proto.skills.UpdateVehicleSettingsActionArgs.prototype.setEnumSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skills.EnumSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.EnumSetting}
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.addEnumSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skills.EnumSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.UpdateVehicleSettingsActionArgs} returns this
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.clearEnumSettingsList = function() {
  return this.setEnumSettingsList([]);
};


/**
 * optional flight_deck.ReturnSettings return_settings = 12;
 * @return {?proto.flight_deck.ReturnSettings}
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.getReturnSettings = function() {
  return /** @type{?proto.flight_deck.ReturnSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_infrastructure_flight_deck_return_settings_pb.ReturnSettings, 12));
};


/**
 * @param {?proto.flight_deck.ReturnSettings|undefined} value
 * @return {!proto.skills.UpdateVehicleSettingsActionArgs} returns this
*/
proto.skills.UpdateVehicleSettingsActionArgs.prototype.setReturnSettings = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.UpdateVehicleSettingsActionArgs} returns this
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.clearReturnSettings = function() {
  return this.setReturnSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.UpdateVehicleSettingsActionArgs.prototype.hasReturnSettings = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UpdateVehicleSettingsResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UpdateVehicleSettingsResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UpdateVehicleSettingsResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UpdateVehicleSettingsResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UpdateVehicleSettingsResultData}
 */
proto.skills.UpdateVehicleSettingsResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UpdateVehicleSettingsResultData;
  return proto.skills.UpdateVehicleSettingsResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UpdateVehicleSettingsResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UpdateVehicleSettingsResultData}
 */
proto.skills.UpdateVehicleSettingsResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UpdateVehicleSettingsResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UpdateVehicleSettingsResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UpdateVehicleSettingsResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UpdateVehicleSettingsResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UpdateVehicleSettingsActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UpdateVehicleSettingsActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UpdateVehicleSettingsActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UpdateVehicleSettingsActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UpdateVehicleSettingsActionStateData}
 */
proto.skills.UpdateVehicleSettingsActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UpdateVehicleSettingsActionStateData;
  return proto.skills.UpdateVehicleSettingsActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UpdateVehicleSettingsActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UpdateVehicleSettingsActionStateData}
 */
proto.skills.UpdateVehicleSettingsActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UpdateVehicleSettingsActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UpdateVehicleSettingsActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UpdateVehicleSettingsActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UpdateVehicleSettingsActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.skills);
