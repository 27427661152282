// source: pbtypes/infrastructure/skybus_tunnel/tunnel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_skybus_tunnel_qos_class_pb = require('../../../pbtypes/gen/skybus_tunnel/qos_class_pb.js');
goog.object.extend(proto, pbtypes_gen_skybus_tunnel_qos_class_pb);
var pbtypes_gen_skybus_tunnel_rate_ctrl_pb = require('../../../pbtypes/gen/skybus_tunnel/rate_ctrl_pb.js');
goog.object.extend(proto, pbtypes_gen_skybus_tunnel_rate_ctrl_pb);
var pbtypes_gen_skybus_tunnel_tunnel_protocol_version_pb = require('../../../pbtypes/gen/skybus_tunnel/tunnel_protocol_version_pb.js');
goog.object.extend(proto, pbtypes_gen_skybus_tunnel_tunnel_protocol_version_pb);
goog.exportSymbol('proto.skybus_tunnel.ClockSync', null, global);
goog.exportSymbol('proto.skybus_tunnel.PacketStatus', null, global);
goog.exportSymbol('proto.skybus_tunnel.SubscribedChannel', null, global);
goog.exportSymbol('proto.skybus_tunnel.SubscribedChannel.Protocol', null, global);
goog.exportSymbol('proto.skybus_tunnel.SubscribedChannelList', null, global);
goog.exportSymbol('proto.skybus_tunnel.SubscriptionAck', null, global);
goog.exportSymbol('proto.skybus_tunnel.TunnelReady', null, global);
goog.exportSymbol('proto.skybus_tunnel.ULPacketSize', null, global);
goog.exportSymbol('proto.skybus_tunnel.VehicleMode', null, global);
goog.exportSymbol('proto.skybus_tunnel.VideoFecCodeRate', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skybus_tunnel.ClockSync = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skybus_tunnel.ClockSync, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skybus_tunnel.ClockSync.displayName = 'proto.skybus_tunnel.ClockSync';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skybus_tunnel.SubscribedChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skybus_tunnel.SubscribedChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skybus_tunnel.SubscribedChannel.displayName = 'proto.skybus_tunnel.SubscribedChannel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skybus_tunnel.SubscribedChannelList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skybus_tunnel.SubscribedChannelList.repeatedFields_, null);
};
goog.inherits(proto.skybus_tunnel.SubscribedChannelList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skybus_tunnel.SubscribedChannelList.displayName = 'proto.skybus_tunnel.SubscribedChannelList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skybus_tunnel.SubscriptionAck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skybus_tunnel.SubscriptionAck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skybus_tunnel.SubscriptionAck.displayName = 'proto.skybus_tunnel.SubscriptionAck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skybus_tunnel.VideoFecCodeRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skybus_tunnel.VideoFecCodeRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skybus_tunnel.VideoFecCodeRate.displayName = 'proto.skybus_tunnel.VideoFecCodeRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skybus_tunnel.PacketStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skybus_tunnel.PacketStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skybus_tunnel.PacketStatus.displayName = 'proto.skybus_tunnel.PacketStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skybus_tunnel.VehicleMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skybus_tunnel.VehicleMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skybus_tunnel.VehicleMode.displayName = 'proto.skybus_tunnel.VehicleMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skybus_tunnel.ULPacketSize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skybus_tunnel.ULPacketSize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skybus_tunnel.ULPacketSize.displayName = 'proto.skybus_tunnel.ULPacketSize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skybus_tunnel.TunnelReady = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skybus_tunnel.TunnelReady, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skybus_tunnel.TunnelReady.displayName = 'proto.skybus_tunnel.TunnelReady';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skybus_tunnel.ClockSync.prototype.toObject = function(opt_includeInstance) {
  return proto.skybus_tunnel.ClockSync.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skybus_tunnel.ClockSync} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.ClockSync.toObject = function(includeInstance, msg) {
  var f, obj = {
masterT0: jspb.Message.getFieldWithDefault(msg, 2, 0),
slaveT1: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skybus_tunnel.ClockSync}
 */
proto.skybus_tunnel.ClockSync.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skybus_tunnel.ClockSync;
  return proto.skybus_tunnel.ClockSync.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skybus_tunnel.ClockSync} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skybus_tunnel.ClockSync}
 */
proto.skybus_tunnel.ClockSync.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setMasterT0(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setSlaveT1(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skybus_tunnel.ClockSync.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skybus_tunnel.ClockSync.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skybus_tunnel.ClockSync} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.ClockSync.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMasterT0();
  if (f !== 0) {
    writer.writeSfixed64(
      2,
      f
    );
  }
  f = message.getSlaveT1();
  if (f !== 0) {
    writer.writeSfixed64(
      3,
      f
    );
  }
};


/**
 * optional sfixed64 master_t0 = 2;
 * @return {number}
 */
proto.skybus_tunnel.ClockSync.prototype.getMasterT0 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.ClockSync} returns this
 */
proto.skybus_tunnel.ClockSync.prototype.setMasterT0 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional sfixed64 slave_t1 = 3;
 * @return {number}
 */
proto.skybus_tunnel.ClockSync.prototype.getSlaveT1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.ClockSync} returns this
 */
proto.skybus_tunnel.ClockSync.prototype.setSlaveT1 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skybus_tunnel.SubscribedChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.skybus_tunnel.SubscribedChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skybus_tunnel.SubscribedChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.SubscribedChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
channel: jspb.Message.getFieldWithDefault(msg, 1, ""),
downsampleNominalDt: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
downsampleLowThrottleDt: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
protocol: jspb.Message.getFieldWithDefault(msg, 4, 0),
qos: jspb.Message.getFieldWithDefault(msg, 5, 0),
fecCodeRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skybus_tunnel.SubscribedChannel}
 */
proto.skybus_tunnel.SubscribedChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skybus_tunnel.SubscribedChannel;
  return proto.skybus_tunnel.SubscribedChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skybus_tunnel.SubscribedChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skybus_tunnel.SubscribedChannel}
 */
proto.skybus_tunnel.SubscribedChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDownsampleNominalDt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDownsampleLowThrottleDt(value);
      break;
    case 4:
      var value = /** @type {!proto.skybus_tunnel.SubscribedChannel.Protocol} */ (reader.readEnum());
      msg.setProtocol(value);
      break;
    case 5:
      var value = /** @type {!proto.skybus_tunnel.QosClass.Enum} */ (reader.readEnum());
      msg.setQos(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFecCodeRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skybus_tunnel.SubscribedChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skybus_tunnel.SubscribedChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skybus_tunnel.SubscribedChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.SubscribedChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDownsampleNominalDt();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDownsampleLowThrottleDt();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getProtocol();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getQos();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getFecCodeRate();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skybus_tunnel.SubscribedChannel.Protocol = {
  DEFAULT_PROTOCOL: 0,
  LOGGING_ONLY: 1
};

/**
 * optional string channel = 1;
 * @return {string}
 */
proto.skybus_tunnel.SubscribedChannel.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skybus_tunnel.SubscribedChannel} returns this
 */
proto.skybus_tunnel.SubscribedChannel.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float downsample_nominal_dt = 2;
 * @return {number}
 */
proto.skybus_tunnel.SubscribedChannel.prototype.getDownsampleNominalDt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.SubscribedChannel} returns this
 */
proto.skybus_tunnel.SubscribedChannel.prototype.setDownsampleNominalDt = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float downsample_low_throttle_dt = 3;
 * @return {number}
 */
proto.skybus_tunnel.SubscribedChannel.prototype.getDownsampleLowThrottleDt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.SubscribedChannel} returns this
 */
proto.skybus_tunnel.SubscribedChannel.prototype.setDownsampleLowThrottleDt = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional Protocol protocol = 4;
 * @return {!proto.skybus_tunnel.SubscribedChannel.Protocol}
 */
proto.skybus_tunnel.SubscribedChannel.prototype.getProtocol = function() {
  return /** @type {!proto.skybus_tunnel.SubscribedChannel.Protocol} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.skybus_tunnel.SubscribedChannel.Protocol} value
 * @return {!proto.skybus_tunnel.SubscribedChannel} returns this
 */
proto.skybus_tunnel.SubscribedChannel.prototype.setProtocol = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional QosClass.Enum qos = 5;
 * @return {!proto.skybus_tunnel.QosClass.Enum}
 */
proto.skybus_tunnel.SubscribedChannel.prototype.getQos = function() {
  return /** @type {!proto.skybus_tunnel.QosClass.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.skybus_tunnel.QosClass.Enum} value
 * @return {!proto.skybus_tunnel.SubscribedChannel} returns this
 */
proto.skybus_tunnel.SubscribedChannel.prototype.setQos = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional float fec_code_rate = 6;
 * @return {number}
 */
proto.skybus_tunnel.SubscribedChannel.prototype.getFecCodeRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.SubscribedChannel} returns this
 */
proto.skybus_tunnel.SubscribedChannel.prototype.setFecCodeRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skybus_tunnel.SubscribedChannelList.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.toObject = function(opt_includeInstance) {
  return proto.skybus_tunnel.SubscribedChannelList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skybus_tunnel.SubscribedChannelList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.SubscribedChannelList.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
nonce: jspb.Message.getFieldWithDefault(msg, 2, 0),
channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    proto.skybus_tunnel.SubscribedChannel.toObject, includeInstance),
clientId: jspb.Message.getFieldWithDefault(msg, 4, ""),
minSupportedVersion: jspb.Message.getFieldWithDefault(msg, 5, 0),
maxSupportedVersion: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skybus_tunnel.SubscribedChannelList}
 */
proto.skybus_tunnel.SubscribedChannelList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skybus_tunnel.SubscribedChannelList;
  return proto.skybus_tunnel.SubscribedChannelList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skybus_tunnel.SubscribedChannelList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skybus_tunnel.SubscribedChannelList}
 */
proto.skybus_tunnel.SubscribedChannelList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setNonce(value);
      break;
    case 3:
      var value = new proto.skybus_tunnel.SubscribedChannel;
      reader.readMessage(value,proto.skybus_tunnel.SubscribedChannel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 5:
      var value = /** @type {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} */ (reader.readEnum());
      msg.setMinSupportedVersion(value);
      break;
    case 6:
      var value = /** @type {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} */ (reader.readEnum());
      msg.setMaxSupportedVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skybus_tunnel.SubscribedChannelList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skybus_tunnel.SubscribedChannelList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.SubscribedChannelList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeFixed32(
      2,
      f
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.skybus_tunnel.SubscribedChannel.serializeBinaryToWriter
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMinSupportedVersion();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getMaxSupportedVersion();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.SubscribedChannelList} returns this
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional fixed32 nonce = 2;
 * @return {number}
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.SubscribedChannelList} returns this
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated SubscribedChannel channels = 3;
 * @return {!Array<!proto.skybus_tunnel.SubscribedChannel>}
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.skybus_tunnel.SubscribedChannel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skybus_tunnel.SubscribedChannel, 3));
};


/**
 * @param {!Array<!proto.skybus_tunnel.SubscribedChannel>} value
 * @return {!proto.skybus_tunnel.SubscribedChannelList} returns this
*/
proto.skybus_tunnel.SubscribedChannelList.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skybus_tunnel.SubscribedChannel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skybus_tunnel.SubscribedChannel}
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skybus_tunnel.SubscribedChannel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skybus_tunnel.SubscribedChannelList} returns this
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional string client_id = 4;
 * @return {string}
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skybus_tunnel.SubscribedChannelList} returns this
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional TunnelProtocolVersion.Enum min_supported_version = 5;
 * @return {!proto.skybus_tunnel.TunnelProtocolVersion.Enum}
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.getMinSupportedVersion = function() {
  return /** @type {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} value
 * @return {!proto.skybus_tunnel.SubscribedChannelList} returns this
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.setMinSupportedVersion = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional TunnelProtocolVersion.Enum max_supported_version = 6;
 * @return {!proto.skybus_tunnel.TunnelProtocolVersion.Enum}
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.getMaxSupportedVersion = function() {
  return /** @type {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} value
 * @return {!proto.skybus_tunnel.SubscribedChannelList} returns this
 */
proto.skybus_tunnel.SubscribedChannelList.prototype.setMaxSupportedVersion = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skybus_tunnel.SubscriptionAck.prototype.toObject = function(opt_includeInstance) {
  return proto.skybus_tunnel.SubscriptionAck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skybus_tunnel.SubscriptionAck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.SubscriptionAck.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
nonce: jspb.Message.getFieldWithDefault(msg, 2, 0),
minSupportedVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
maxSupportedVersion: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skybus_tunnel.SubscriptionAck}
 */
proto.skybus_tunnel.SubscriptionAck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skybus_tunnel.SubscriptionAck;
  return proto.skybus_tunnel.SubscriptionAck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skybus_tunnel.SubscriptionAck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skybus_tunnel.SubscriptionAck}
 */
proto.skybus_tunnel.SubscriptionAck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setNonce(value);
      break;
    case 3:
      var value = /** @type {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} */ (reader.readEnum());
      msg.setMinSupportedVersion(value);
      break;
    case 4:
      var value = /** @type {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} */ (reader.readEnum());
      msg.setMaxSupportedVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skybus_tunnel.SubscriptionAck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skybus_tunnel.SubscriptionAck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skybus_tunnel.SubscriptionAck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.SubscriptionAck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeFixed32(
      2,
      f
    );
  }
  f = message.getMinSupportedVersion();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMaxSupportedVersion();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skybus_tunnel.SubscriptionAck.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.SubscriptionAck} returns this
 */
proto.skybus_tunnel.SubscriptionAck.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional fixed32 nonce = 2;
 * @return {number}
 */
proto.skybus_tunnel.SubscriptionAck.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.SubscriptionAck} returns this
 */
proto.skybus_tunnel.SubscriptionAck.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TunnelProtocolVersion.Enum min_supported_version = 3;
 * @return {!proto.skybus_tunnel.TunnelProtocolVersion.Enum}
 */
proto.skybus_tunnel.SubscriptionAck.prototype.getMinSupportedVersion = function() {
  return /** @type {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} value
 * @return {!proto.skybus_tunnel.SubscriptionAck} returns this
 */
proto.skybus_tunnel.SubscriptionAck.prototype.setMinSupportedVersion = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional TunnelProtocolVersion.Enum max_supported_version = 4;
 * @return {!proto.skybus_tunnel.TunnelProtocolVersion.Enum}
 */
proto.skybus_tunnel.SubscriptionAck.prototype.getMaxSupportedVersion = function() {
  return /** @type {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.skybus_tunnel.TunnelProtocolVersion.Enum} value
 * @return {!proto.skybus_tunnel.SubscriptionAck} returns this
 */
proto.skybus_tunnel.SubscriptionAck.prototype.setMaxSupportedVersion = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skybus_tunnel.VideoFecCodeRate.prototype.toObject = function(opt_includeInstance) {
  return proto.skybus_tunnel.VideoFecCodeRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skybus_tunnel.VideoFecCodeRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.VideoFecCodeRate.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
fecCodeRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skybus_tunnel.VideoFecCodeRate}
 */
proto.skybus_tunnel.VideoFecCodeRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skybus_tunnel.VideoFecCodeRate;
  return proto.skybus_tunnel.VideoFecCodeRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skybus_tunnel.VideoFecCodeRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skybus_tunnel.VideoFecCodeRate}
 */
proto.skybus_tunnel.VideoFecCodeRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFecCodeRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skybus_tunnel.VideoFecCodeRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skybus_tunnel.VideoFecCodeRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skybus_tunnel.VideoFecCodeRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.VideoFecCodeRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFecCodeRate();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skybus_tunnel.VideoFecCodeRate.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.VideoFecCodeRate} returns this
 */
proto.skybus_tunnel.VideoFecCodeRate.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float fec_code_rate = 2;
 * @return {number}
 */
proto.skybus_tunnel.VideoFecCodeRate.prototype.getFecCodeRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.VideoFecCodeRate} returns this
 */
proto.skybus_tunnel.VideoFecCodeRate.prototype.setFecCodeRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skybus_tunnel.PacketStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.skybus_tunnel.PacketStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skybus_tunnel.PacketStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.PacketStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
packetSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
rateCtrlMethod: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skybus_tunnel.PacketStatus}
 */
proto.skybus_tunnel.PacketStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skybus_tunnel.PacketStatus;
  return proto.skybus_tunnel.PacketStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skybus_tunnel.PacketStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skybus_tunnel.PacketStatus}
 */
proto.skybus_tunnel.PacketStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPacketSize(value);
      break;
    case 3:
      var value = /** @type {!proto.skybus_tunnel.RateCtrl.Enum} */ (reader.readEnum());
      msg.setRateCtrlMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skybus_tunnel.PacketStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skybus_tunnel.PacketStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skybus_tunnel.PacketStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.PacketStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPacketSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRateCtrlMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skybus_tunnel.PacketStatus.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.PacketStatus} returns this
 */
proto.skybus_tunnel.PacketStatus.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 packet_size = 2;
 * @return {number}
 */
proto.skybus_tunnel.PacketStatus.prototype.getPacketSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.PacketStatus} returns this
 */
proto.skybus_tunnel.PacketStatus.prototype.setPacketSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional RateCtrl.Enum rate_ctrl_method = 3;
 * @return {!proto.skybus_tunnel.RateCtrl.Enum}
 */
proto.skybus_tunnel.PacketStatus.prototype.getRateCtrlMethod = function() {
  return /** @type {!proto.skybus_tunnel.RateCtrl.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.skybus_tunnel.RateCtrl.Enum} value
 * @return {!proto.skybus_tunnel.PacketStatus} returns this
 */
proto.skybus_tunnel.PacketStatus.prototype.setRateCtrlMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skybus_tunnel.VehicleMode.prototype.toObject = function(opt_includeInstance) {
  return proto.skybus_tunnel.VehicleMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skybus_tunnel.VehicleMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.VehicleMode.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
lrWifiMode: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skybus_tunnel.VehicleMode}
 */
proto.skybus_tunnel.VehicleMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skybus_tunnel.VehicleMode;
  return proto.skybus_tunnel.VehicleMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skybus_tunnel.VehicleMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skybus_tunnel.VehicleMode}
 */
proto.skybus_tunnel.VehicleMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLrWifiMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skybus_tunnel.VehicleMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skybus_tunnel.VehicleMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skybus_tunnel.VehicleMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.VehicleMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLrWifiMode();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skybus_tunnel.VehicleMode.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.VehicleMode} returns this
 */
proto.skybus_tunnel.VehicleMode.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool lr_wifi_mode = 2;
 * @return {boolean}
 */
proto.skybus_tunnel.VehicleMode.prototype.getLrWifiMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skybus_tunnel.VehicleMode} returns this
 */
proto.skybus_tunnel.VehicleMode.prototype.setLrWifiMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skybus_tunnel.ULPacketSize.prototype.toObject = function(opt_includeInstance) {
  return proto.skybus_tunnel.ULPacketSize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skybus_tunnel.ULPacketSize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.ULPacketSize.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
packetSize: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skybus_tunnel.ULPacketSize}
 */
proto.skybus_tunnel.ULPacketSize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skybus_tunnel.ULPacketSize;
  return proto.skybus_tunnel.ULPacketSize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skybus_tunnel.ULPacketSize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skybus_tunnel.ULPacketSize}
 */
proto.skybus_tunnel.ULPacketSize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPacketSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skybus_tunnel.ULPacketSize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skybus_tunnel.ULPacketSize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skybus_tunnel.ULPacketSize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.ULPacketSize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPacketSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skybus_tunnel.ULPacketSize.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.ULPacketSize} returns this
 */
proto.skybus_tunnel.ULPacketSize.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 packet_size = 2;
 * @return {number}
 */
proto.skybus_tunnel.ULPacketSize.prototype.getPacketSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.ULPacketSize} returns this
 */
proto.skybus_tunnel.ULPacketSize.prototype.setPacketSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skybus_tunnel.TunnelReady.prototype.toObject = function(opt_includeInstance) {
  return proto.skybus_tunnel.TunnelReady.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skybus_tunnel.TunnelReady} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.TunnelReady.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skybus_tunnel.TunnelReady}
 */
proto.skybus_tunnel.TunnelReady.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skybus_tunnel.TunnelReady;
  return proto.skybus_tunnel.TunnelReady.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skybus_tunnel.TunnelReady} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skybus_tunnel.TunnelReady}
 */
proto.skybus_tunnel.TunnelReady.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skybus_tunnel.TunnelReady.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skybus_tunnel.TunnelReady.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skybus_tunnel.TunnelReady} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skybus_tunnel.TunnelReady.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skybus_tunnel.TunnelReady.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skybus_tunnel.TunnelReady} returns this
 */
proto.skybus_tunnel.TunnelReady.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.skybus_tunnel);
