// source: pbtypes/tools/cloud_api/data_access.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.cloud_api.CompleteMultipartUploadRequest', null, global);
goog.exportSymbol('proto.cloud_api.CompleteMultipartUploadRequestPart', null, global);
goog.exportSymbol('proto.cloud_api.CompleteMultipartUploadResponse', null, global);
goog.exportSymbol('proto.cloud_api.CreateMultipartUploadRequest', null, global);
goog.exportSymbol('proto.cloud_api.CreateMultipartUploadResponse', null, global);
goog.exportSymbol('proto.cloud_api.CreateMultipartUploadResponsePart', null, global);
goog.exportSymbol('proto.cloud_api.DataFileAccessRequest', null, global);
goog.exportSymbol('proto.cloud_api.DataFileAccessResponse', null, global);
goog.exportSymbol('proto.cloud_api.FileAccessErrorInfo', null, global);
goog.exportSymbol('proto.cloud_api.FileUploadRequest', null, global);
goog.exportSymbol('proto.cloud_api.FileUploadResponse', null, global);
goog.exportSymbol('proto.cloud_api.FileUploadResponseBatch', null, global);
goog.exportSymbol('proto.cloud_api.OrganizationFileUploadRequest', null, global);
goog.exportSymbol('proto.cloud_api.OrganizationFileUploadResponse', null, global);
goog.exportSymbol('proto.cloud_api.OrganizationFilesUploadRequest', null, global);
goog.exportSymbol('proto.cloud_api.OrganizationFilesUploadResponse', null, global);
goog.exportSymbol('proto.cloud_api.StsTemporaryCredentials', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DataFileAccessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.DataFileAccessRequest.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.DataFileAccessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DataFileAccessRequest.displayName = 'proto.cloud_api.DataFileAccessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DataFileAccessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.DataFileAccessResponse.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.DataFileAccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DataFileAccessResponse.displayName = 'proto.cloud_api.DataFileAccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FileAccessErrorInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FileAccessErrorInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FileAccessErrorInfo.displayName = 'proto.cloud_api.FileAccessErrorInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.StsTemporaryCredentials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.StsTemporaryCredentials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.StsTemporaryCredentials.displayName = 'proto.cloud_api.StsTemporaryCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FileUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FileUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FileUploadRequest.displayName = 'proto.cloud_api.FileUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FileUploadResponseBatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.FileUploadResponseBatch.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.FileUploadResponseBatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FileUploadResponseBatch.displayName = 'proto.cloud_api.FileUploadResponseBatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FileUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FileUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FileUploadResponse.displayName = 'proto.cloud_api.FileUploadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.OrganizationFilesUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.OrganizationFilesUploadRequest.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.OrganizationFilesUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.OrganizationFilesUploadRequest.displayName = 'proto.cloud_api.OrganizationFilesUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.OrganizationFilesUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.OrganizationFilesUploadResponse.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.OrganizationFilesUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.OrganizationFilesUploadResponse.displayName = 'proto.cloud_api.OrganizationFilesUploadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.OrganizationFileUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.OrganizationFileUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.OrganizationFileUploadRequest.displayName = 'proto.cloud_api.OrganizationFileUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.OrganizationFileUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.OrganizationFileUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.OrganizationFileUploadResponse.displayName = 'proto.cloud_api.OrganizationFileUploadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.CreateMultipartUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.CreateMultipartUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.CreateMultipartUploadRequest.displayName = 'proto.cloud_api.CreateMultipartUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.CreateMultipartUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.CreateMultipartUploadResponse.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.CreateMultipartUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.CreateMultipartUploadResponse.displayName = 'proto.cloud_api.CreateMultipartUploadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.CreateMultipartUploadResponsePart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.CreateMultipartUploadResponsePart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.CreateMultipartUploadResponsePart.displayName = 'proto.cloud_api.CreateMultipartUploadResponsePart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.CompleteMultipartUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.CompleteMultipartUploadRequest.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.CompleteMultipartUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.CompleteMultipartUploadRequest.displayName = 'proto.cloud_api.CompleteMultipartUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.CompleteMultipartUploadRequestPart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.CompleteMultipartUploadRequestPart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.CompleteMultipartUploadRequestPart.displayName = 'proto.cloud_api.CompleteMultipartUploadRequestPart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.CompleteMultipartUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.CompleteMultipartUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.CompleteMultipartUploadResponse.displayName = 'proto.cloud_api.CompleteMultipartUploadResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.DataFileAccessRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DataFileAccessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DataFileAccessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DataFileAccessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DataFileAccessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
fileUuidsForCredentialsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
fileUuidsForPublicUrlsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
returnFileUuidErrors: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DataFileAccessRequest}
 */
proto.cloud_api.DataFileAccessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DataFileAccessRequest;
  return proto.cloud_api.DataFileAccessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DataFileAccessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DataFileAccessRequest}
 */
proto.cloud_api.DataFileAccessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addFileUuidsForCredentials(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFileUuidsForPublicUrls(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnFileUuidErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DataFileAccessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DataFileAccessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DataFileAccessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DataFileAccessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileUuidsForCredentialsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getFileUuidsForPublicUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getReturnFileUuidErrors();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated string file_uuids_for_credentials = 1;
 * @return {!Array<string>}
 */
proto.cloud_api.DataFileAccessRequest.prototype.getFileUuidsForCredentialsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.DataFileAccessRequest} returns this
 */
proto.cloud_api.DataFileAccessRequest.prototype.setFileUuidsForCredentialsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.DataFileAccessRequest} returns this
 */
proto.cloud_api.DataFileAccessRequest.prototype.addFileUuidsForCredentials = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.DataFileAccessRequest} returns this
 */
proto.cloud_api.DataFileAccessRequest.prototype.clearFileUuidsForCredentialsList = function() {
  return this.setFileUuidsForCredentialsList([]);
};


/**
 * repeated string file_uuids_for_public_urls = 2;
 * @return {!Array<string>}
 */
proto.cloud_api.DataFileAccessRequest.prototype.getFileUuidsForPublicUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.DataFileAccessRequest} returns this
 */
proto.cloud_api.DataFileAccessRequest.prototype.setFileUuidsForPublicUrlsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.DataFileAccessRequest} returns this
 */
proto.cloud_api.DataFileAccessRequest.prototype.addFileUuidsForPublicUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.DataFileAccessRequest} returns this
 */
proto.cloud_api.DataFileAccessRequest.prototype.clearFileUuidsForPublicUrlsList = function() {
  return this.setFileUuidsForPublicUrlsList([]);
};


/**
 * optional bool return_file_uuid_errors = 3;
 * @return {boolean}
 */
proto.cloud_api.DataFileAccessRequest.prototype.getReturnFileUuidErrors = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.DataFileAccessRequest} returns this
 */
proto.cloud_api.DataFileAccessRequest.prototype.setReturnFileUuidErrors = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.DataFileAccessResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DataFileAccessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DataFileAccessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DataFileAccessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DataFileAccessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
credentials: (f = msg.getCredentials()) && proto.cloud_api.StsTemporaryCredentials.toObject(includeInstance, f),
fileUuidsGrantedList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
fileUuidPublicUrlsMap: (f = msg.getFileUuidPublicUrlsMap()) ? f.toObject(includeInstance, undefined) : [],
fileUuidErrorsMap: (f = msg.getFileUuidErrorsMap()) ? f.toObject(includeInstance, proto.cloud_api.FileAccessErrorInfo.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DataFileAccessResponse}
 */
proto.cloud_api.DataFileAccessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DataFileAccessResponse;
  return proto.cloud_api.DataFileAccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DataFileAccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DataFileAccessResponse}
 */
proto.cloud_api.DataFileAccessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.StsTemporaryCredentials;
      reader.readMessage(value,proto.cloud_api.StsTemporaryCredentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFileUuidsGranted(value);
      break;
    case 3:
      var value = msg.getFileUuidPublicUrlsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = msg.getFileUuidErrorsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.cloud_api.FileAccessErrorInfo.deserializeBinaryFromReader, "", new proto.cloud_api.FileAccessErrorInfo());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DataFileAccessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DataFileAccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DataFileAccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DataFileAccessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.StsTemporaryCredentials.serializeBinaryToWriter
    );
  }
  f = message.getFileUuidsGrantedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getFileUuidPublicUrlsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getFileUuidErrorsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.cloud_api.FileAccessErrorInfo.serializeBinaryToWriter);
  }
};


/**
 * optional StsTemporaryCredentials credentials = 1;
 * @return {?proto.cloud_api.StsTemporaryCredentials}
 */
proto.cloud_api.DataFileAccessResponse.prototype.getCredentials = function() {
  return /** @type{?proto.cloud_api.StsTemporaryCredentials} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.StsTemporaryCredentials, 1));
};


/**
 * @param {?proto.cloud_api.StsTemporaryCredentials|undefined} value
 * @return {!proto.cloud_api.DataFileAccessResponse} returns this
*/
proto.cloud_api.DataFileAccessResponse.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.DataFileAccessResponse} returns this
 */
proto.cloud_api.DataFileAccessResponse.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.DataFileAccessResponse.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string file_uuids_granted = 2;
 * @return {!Array<string>}
 */
proto.cloud_api.DataFileAccessResponse.prototype.getFileUuidsGrantedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.DataFileAccessResponse} returns this
 */
proto.cloud_api.DataFileAccessResponse.prototype.setFileUuidsGrantedList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.DataFileAccessResponse} returns this
 */
proto.cloud_api.DataFileAccessResponse.prototype.addFileUuidsGranted = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.DataFileAccessResponse} returns this
 */
proto.cloud_api.DataFileAccessResponse.prototype.clearFileUuidsGrantedList = function() {
  return this.setFileUuidsGrantedList([]);
};


/**
 * map<string, string> file_uuid_public_urls = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.cloud_api.DataFileAccessResponse.prototype.getFileUuidPublicUrlsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_api.DataFileAccessResponse} returns this
 */
proto.cloud_api.DataFileAccessResponse.prototype.clearFileUuidPublicUrlsMap = function() {
  this.getFileUuidPublicUrlsMap().clear();
  return this;
};


/**
 * map<string, FileAccessErrorInfo> file_uuid_errors = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.cloud_api.FileAccessErrorInfo>}
 */
proto.cloud_api.DataFileAccessResponse.prototype.getFileUuidErrorsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.cloud_api.FileAccessErrorInfo>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.cloud_api.FileAccessErrorInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_api.DataFileAccessResponse} returns this
 */
proto.cloud_api.DataFileAccessResponse.prototype.clearFileUuidErrorsMap = function() {
  this.getFileUuidErrorsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FileAccessErrorInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FileAccessErrorInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FileAccessErrorInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileAccessErrorInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
code: jspb.Message.getFieldWithDefault(msg, 1, 0),
message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FileAccessErrorInfo}
 */
proto.cloud_api.FileAccessErrorInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FileAccessErrorInfo;
  return proto.cloud_api.FileAccessErrorInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FileAccessErrorInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FileAccessErrorInfo}
 */
proto.cloud_api.FileAccessErrorInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FileAccessErrorInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FileAccessErrorInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FileAccessErrorInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileAccessErrorInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.cloud_api.FileAccessErrorInfo.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.FileAccessErrorInfo} returns this
 */
proto.cloud_api.FileAccessErrorInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.cloud_api.FileAccessErrorInfo.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileAccessErrorInfo} returns this
 */
proto.cloud_api.FileAccessErrorInfo.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.StsTemporaryCredentials.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.StsTemporaryCredentials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.StsTemporaryCredentials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.StsTemporaryCredentials.toObject = function(includeInstance, msg) {
  var f, obj = {
accessKeyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
secretAccessKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
sessionToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
expiration: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.StsTemporaryCredentials}
 */
proto.cloud_api.StsTemporaryCredentials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.StsTemporaryCredentials;
  return proto.cloud_api.StsTemporaryCredentials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.StsTemporaryCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.StsTemporaryCredentials}
 */
proto.cloud_api.StsTemporaryCredentials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessKeyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretAccessKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionToken(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.StsTemporaryCredentials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.StsTemporaryCredentials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.StsTemporaryCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.StsTemporaryCredentials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessKeyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecretAccessKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessionToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExpiration();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string access_key_id = 1;
 * @return {string}
 */
proto.cloud_api.StsTemporaryCredentials.prototype.getAccessKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.StsTemporaryCredentials} returns this
 */
proto.cloud_api.StsTemporaryCredentials.prototype.setAccessKeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secret_access_key = 2;
 * @return {string}
 */
proto.cloud_api.StsTemporaryCredentials.prototype.getSecretAccessKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.StsTemporaryCredentials} returns this
 */
proto.cloud_api.StsTemporaryCredentials.prototype.setSecretAccessKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string session_token = 3;
 * @return {string}
 */
proto.cloud_api.StsTemporaryCredentials.prototype.getSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.StsTemporaryCredentials} returns this
 */
proto.cloud_api.StsTemporaryCredentials.prototype.setSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 expiration = 4;
 * @return {number}
 */
proto.cloud_api.StsTemporaryCredentials.prototype.getExpiration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.StsTemporaryCredentials} returns this
 */
proto.cloud_api.StsTemporaryCredentials.prototype.setExpiration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FileUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FileUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FileUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
fileId: jspb.Message.getFieldWithDefault(msg, 1, ""),
filename: jspb.Message.getFieldWithDefault(msg, 2, ""),
size: jspb.Message.getFieldWithDefault(msg, 3, 0),
sha1: jspb.Message.getFieldWithDefault(msg, 4, ""),
metadataJson: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FileUploadRequest}
 */
proto.cloud_api.FileUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FileUploadRequest;
  return proto.cloud_api.FileUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FileUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FileUploadRequest}
 */
proto.cloud_api.FileUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSha1(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadataJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FileUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FileUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FileUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSha1();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMetadataJson();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string file_id = 1;
 * @return {string}
 */
proto.cloud_api.FileUploadRequest.prototype.getFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileUploadRequest} returns this
 */
proto.cloud_api.FileUploadRequest.prototype.setFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.cloud_api.FileUploadRequest.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileUploadRequest} returns this
 */
proto.cloud_api.FileUploadRequest.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 size = 3;
 * @return {number}
 */
proto.cloud_api.FileUploadRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.FileUploadRequest} returns this
 */
proto.cloud_api.FileUploadRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sha1 = 4;
 * @return {string}
 */
proto.cloud_api.FileUploadRequest.prototype.getSha1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileUploadRequest} returns this
 */
proto.cloud_api.FileUploadRequest.prototype.setSha1 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string metadata_json = 5;
 * @return {string}
 */
proto.cloud_api.FileUploadRequest.prototype.getMetadataJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileUploadRequest} returns this
 */
proto.cloud_api.FileUploadRequest.prototype.setMetadataJson = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.FileUploadResponseBatch.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FileUploadResponseBatch.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FileUploadResponseBatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FileUploadResponseBatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileUploadResponseBatch.toObject = function(includeInstance, msg) {
  var f, obj = {
uploadCredentials: (f = msg.getUploadCredentials()) && proto.cloud_api.StsTemporaryCredentials.toObject(includeInstance, f),
fileUploadResponsesList: jspb.Message.toObjectList(msg.getFileUploadResponsesList(),
    proto.cloud_api.FileUploadResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FileUploadResponseBatch}
 */
proto.cloud_api.FileUploadResponseBatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FileUploadResponseBatch;
  return proto.cloud_api.FileUploadResponseBatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FileUploadResponseBatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FileUploadResponseBatch}
 */
proto.cloud_api.FileUploadResponseBatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.StsTemporaryCredentials;
      reader.readMessage(value,proto.cloud_api.StsTemporaryCredentials.deserializeBinaryFromReader);
      msg.setUploadCredentials(value);
      break;
    case 2:
      var value = new proto.cloud_api.FileUploadResponse;
      reader.readMessage(value,proto.cloud_api.FileUploadResponse.deserializeBinaryFromReader);
      msg.addFileUploadResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FileUploadResponseBatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FileUploadResponseBatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FileUploadResponseBatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileUploadResponseBatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadCredentials();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.StsTemporaryCredentials.serializeBinaryToWriter
    );
  }
  f = message.getFileUploadResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cloud_api.FileUploadResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional StsTemporaryCredentials upload_credentials = 1;
 * @return {?proto.cloud_api.StsTemporaryCredentials}
 */
proto.cloud_api.FileUploadResponseBatch.prototype.getUploadCredentials = function() {
  return /** @type{?proto.cloud_api.StsTemporaryCredentials} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.StsTemporaryCredentials, 1));
};


/**
 * @param {?proto.cloud_api.StsTemporaryCredentials|undefined} value
 * @return {!proto.cloud_api.FileUploadResponseBatch} returns this
*/
proto.cloud_api.FileUploadResponseBatch.prototype.setUploadCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.FileUploadResponseBatch} returns this
 */
proto.cloud_api.FileUploadResponseBatch.prototype.clearUploadCredentials = function() {
  return this.setUploadCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.FileUploadResponseBatch.prototype.hasUploadCredentials = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FileUploadResponse file_upload_responses = 2;
 * @return {!Array<!proto.cloud_api.FileUploadResponse>}
 */
proto.cloud_api.FileUploadResponseBatch.prototype.getFileUploadResponsesList = function() {
  return /** @type{!Array<!proto.cloud_api.FileUploadResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.FileUploadResponse, 2));
};


/**
 * @param {!Array<!proto.cloud_api.FileUploadResponse>} value
 * @return {!proto.cloud_api.FileUploadResponseBatch} returns this
*/
proto.cloud_api.FileUploadResponseBatch.prototype.setFileUploadResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cloud_api.FileUploadResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.FileUploadResponse}
 */
proto.cloud_api.FileUploadResponseBatch.prototype.addFileUploadResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cloud_api.FileUploadResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.FileUploadResponseBatch} returns this
 */
proto.cloud_api.FileUploadResponseBatch.prototype.clearFileUploadResponsesList = function() {
  return this.setFileUploadResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FileUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FileUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FileUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
fileId: jspb.Message.getFieldWithDefault(msg, 1, ""),
bucket: jspb.Message.getFieldWithDefault(msg, 2, ""),
key: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FileUploadResponse}
 */
proto.cloud_api.FileUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FileUploadResponse;
  return proto.cloud_api.FileUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FileUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FileUploadResponse}
 */
proto.cloud_api.FileUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucket(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FileUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FileUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FileUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBucket();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string file_id = 1;
 * @return {string}
 */
proto.cloud_api.FileUploadResponse.prototype.getFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileUploadResponse} returns this
 */
proto.cloud_api.FileUploadResponse.prototype.setFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bucket = 2;
 * @return {string}
 */
proto.cloud_api.FileUploadResponse.prototype.getBucket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileUploadResponse} returns this
 */
proto.cloud_api.FileUploadResponse.prototype.setBucket = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 3;
 * @return {string}
 */
proto.cloud_api.FileUploadResponse.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileUploadResponse} returns this
 */
proto.cloud_api.FileUploadResponse.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.OrganizationFilesUploadRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.OrganizationFilesUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.OrganizationFilesUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.OrganizationFilesUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationFilesUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
uploadRequestsList: jspb.Message.toObjectList(msg.getUploadRequestsList(),
    proto.cloud_api.OrganizationFileUploadRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.OrganizationFilesUploadRequest}
 */
proto.cloud_api.OrganizationFilesUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.OrganizationFilesUploadRequest;
  return proto.cloud_api.OrganizationFilesUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.OrganizationFilesUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.OrganizationFilesUploadRequest}
 */
proto.cloud_api.OrganizationFilesUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = new proto.cloud_api.OrganizationFileUploadRequest;
      reader.readMessage(value,proto.cloud_api.OrganizationFileUploadRequest.deserializeBinaryFromReader);
      msg.addUploadRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.OrganizationFilesUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.OrganizationFilesUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.OrganizationFilesUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationFilesUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUploadRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cloud_api.OrganizationFileUploadRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.cloud_api.OrganizationFilesUploadRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationFilesUploadRequest} returns this
 */
proto.cloud_api.OrganizationFilesUploadRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated OrganizationFileUploadRequest upload_requests = 2;
 * @return {!Array<!proto.cloud_api.OrganizationFileUploadRequest>}
 */
proto.cloud_api.OrganizationFilesUploadRequest.prototype.getUploadRequestsList = function() {
  return /** @type{!Array<!proto.cloud_api.OrganizationFileUploadRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.OrganizationFileUploadRequest, 2));
};


/**
 * @param {!Array<!proto.cloud_api.OrganizationFileUploadRequest>} value
 * @return {!proto.cloud_api.OrganizationFilesUploadRequest} returns this
*/
proto.cloud_api.OrganizationFilesUploadRequest.prototype.setUploadRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cloud_api.OrganizationFileUploadRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.OrganizationFileUploadRequest}
 */
proto.cloud_api.OrganizationFilesUploadRequest.prototype.addUploadRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cloud_api.OrganizationFileUploadRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.OrganizationFilesUploadRequest} returns this
 */
proto.cloud_api.OrganizationFilesUploadRequest.prototype.clearUploadRequestsList = function() {
  return this.setUploadRequestsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.OrganizationFilesUploadResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.OrganizationFilesUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.OrganizationFilesUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.OrganizationFilesUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationFilesUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
uploadResponsesList: jspb.Message.toObjectList(msg.getUploadResponsesList(),
    proto.cloud_api.OrganizationFileUploadResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.OrganizationFilesUploadResponse}
 */
proto.cloud_api.OrganizationFilesUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.OrganizationFilesUploadResponse;
  return proto.cloud_api.OrganizationFilesUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.OrganizationFilesUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.OrganizationFilesUploadResponse}
 */
proto.cloud_api.OrganizationFilesUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = new proto.cloud_api.OrganizationFileUploadResponse;
      reader.readMessage(value,proto.cloud_api.OrganizationFileUploadResponse.deserializeBinaryFromReader);
      msg.addUploadResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.OrganizationFilesUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.OrganizationFilesUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.OrganizationFilesUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationFilesUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUploadResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cloud_api.OrganizationFileUploadResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.cloud_api.OrganizationFilesUploadResponse.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationFilesUploadResponse} returns this
 */
proto.cloud_api.OrganizationFilesUploadResponse.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated OrganizationFileUploadResponse upload_responses = 2;
 * @return {!Array<!proto.cloud_api.OrganizationFileUploadResponse>}
 */
proto.cloud_api.OrganizationFilesUploadResponse.prototype.getUploadResponsesList = function() {
  return /** @type{!Array<!proto.cloud_api.OrganizationFileUploadResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.OrganizationFileUploadResponse, 2));
};


/**
 * @param {!Array<!proto.cloud_api.OrganizationFileUploadResponse>} value
 * @return {!proto.cloud_api.OrganizationFilesUploadResponse} returns this
*/
proto.cloud_api.OrganizationFilesUploadResponse.prototype.setUploadResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cloud_api.OrganizationFileUploadResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.OrganizationFileUploadResponse}
 */
proto.cloud_api.OrganizationFilesUploadResponse.prototype.addUploadResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cloud_api.OrganizationFileUploadResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.OrganizationFilesUploadResponse} returns this
 */
proto.cloud_api.OrganizationFilesUploadResponse.prototype.clearUploadResponsesList = function() {
  return this.setUploadResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.OrganizationFileUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.OrganizationFileUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationFileUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
fileId: jspb.Message.getFieldWithDefault(msg, 1, ""),
filename: jspb.Message.getFieldWithDefault(msg, 2, ""),
size: jspb.Message.getFieldWithDefault(msg, 3, 0),
sha1: jspb.Message.getFieldWithDefault(msg, 4, ""),
kind: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.OrganizationFileUploadRequest}
 */
proto.cloud_api.OrganizationFileUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.OrganizationFileUploadRequest;
  return proto.cloud_api.OrganizationFileUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.OrganizationFileUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.OrganizationFileUploadRequest}
 */
proto.cloud_api.OrganizationFileUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSha1(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.OrganizationFileUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.OrganizationFileUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationFileUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSha1();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string file_id = 1;
 * @return {string}
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.getFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationFileUploadRequest} returns this
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.setFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationFileUploadRequest} returns this
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 size = 3;
 * @return {number}
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.OrganizationFileUploadRequest} returns this
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sha1 = 4;
 * @return {string}
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.getSha1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationFileUploadRequest} returns this
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.setSha1 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string kind = 5;
 * @return {string}
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationFileUploadRequest} returns this
 */
proto.cloud_api.OrganizationFileUploadRequest.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.OrganizationFileUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.OrganizationFileUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.OrganizationFileUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationFileUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
fileId: jspb.Message.getFieldWithDefault(msg, 1, ""),
bucket: jspb.Message.getFieldWithDefault(msg, 2, ""),
key: jspb.Message.getFieldWithDefault(msg, 3, ""),
presignedUploadUrl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.OrganizationFileUploadResponse}
 */
proto.cloud_api.OrganizationFileUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.OrganizationFileUploadResponse;
  return proto.cloud_api.OrganizationFileUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.OrganizationFileUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.OrganizationFileUploadResponse}
 */
proto.cloud_api.OrganizationFileUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucket(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresignedUploadUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.OrganizationFileUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.OrganizationFileUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.OrganizationFileUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationFileUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBucket();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPresignedUploadUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string file_id = 1;
 * @return {string}
 */
proto.cloud_api.OrganizationFileUploadResponse.prototype.getFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationFileUploadResponse} returns this
 */
proto.cloud_api.OrganizationFileUploadResponse.prototype.setFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bucket = 2;
 * @return {string}
 */
proto.cloud_api.OrganizationFileUploadResponse.prototype.getBucket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationFileUploadResponse} returns this
 */
proto.cloud_api.OrganizationFileUploadResponse.prototype.setBucket = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 3;
 * @return {string}
 */
proto.cloud_api.OrganizationFileUploadResponse.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationFileUploadResponse} returns this
 */
proto.cloud_api.OrganizationFileUploadResponse.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string presigned_upload_url = 4;
 * @return {string}
 */
proto.cloud_api.OrganizationFileUploadResponse.prototype.getPresignedUploadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationFileUploadResponse} returns this
 */
proto.cloud_api.OrganizationFileUploadResponse.prototype.setPresignedUploadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.CreateMultipartUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.CreateMultipartUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.CreateMultipartUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CreateMultipartUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
numParts: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.CreateMultipartUploadRequest}
 */
proto.cloud_api.CreateMultipartUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.CreateMultipartUploadRequest;
  return proto.cloud_api.CreateMultipartUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.CreateMultipartUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.CreateMultipartUploadRequest}
 */
proto.cloud_api.CreateMultipartUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.CreateMultipartUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.CreateMultipartUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.CreateMultipartUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CreateMultipartUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumParts();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 num_parts = 1;
 * @return {number}
 */
proto.cloud_api.CreateMultipartUploadRequest.prototype.getNumParts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.CreateMultipartUploadRequest} returns this
 */
proto.cloud_api.CreateMultipartUploadRequest.prototype.setNumParts = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.CreateMultipartUploadResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.CreateMultipartUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.CreateMultipartUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.CreateMultipartUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CreateMultipartUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
uploadId: jspb.Message.getFieldWithDefault(msg, 1, ""),
partsList: jspb.Message.toObjectList(msg.getPartsList(),
    proto.cloud_api.CreateMultipartUploadResponsePart.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.CreateMultipartUploadResponse}
 */
proto.cloud_api.CreateMultipartUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.CreateMultipartUploadResponse;
  return proto.cloud_api.CreateMultipartUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.CreateMultipartUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.CreateMultipartUploadResponse}
 */
proto.cloud_api.CreateMultipartUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    case 2:
      var value = new proto.cloud_api.CreateMultipartUploadResponsePart;
      reader.readMessage(value,proto.cloud_api.CreateMultipartUploadResponsePart.deserializeBinaryFromReader);
      msg.addParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.CreateMultipartUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.CreateMultipartUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.CreateMultipartUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CreateMultipartUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cloud_api.CreateMultipartUploadResponsePart.serializeBinaryToWriter
    );
  }
};


/**
 * optional string upload_id = 1;
 * @return {string}
 */
proto.cloud_api.CreateMultipartUploadResponse.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.CreateMultipartUploadResponse} returns this
 */
proto.cloud_api.CreateMultipartUploadResponse.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CreateMultipartUploadResponsePart parts = 2;
 * @return {!Array<!proto.cloud_api.CreateMultipartUploadResponsePart>}
 */
proto.cloud_api.CreateMultipartUploadResponse.prototype.getPartsList = function() {
  return /** @type{!Array<!proto.cloud_api.CreateMultipartUploadResponsePart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.CreateMultipartUploadResponsePart, 2));
};


/**
 * @param {!Array<!proto.cloud_api.CreateMultipartUploadResponsePart>} value
 * @return {!proto.cloud_api.CreateMultipartUploadResponse} returns this
*/
proto.cloud_api.CreateMultipartUploadResponse.prototype.setPartsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cloud_api.CreateMultipartUploadResponsePart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.CreateMultipartUploadResponsePart}
 */
proto.cloud_api.CreateMultipartUploadResponse.prototype.addParts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cloud_api.CreateMultipartUploadResponsePart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.CreateMultipartUploadResponse} returns this
 */
proto.cloud_api.CreateMultipartUploadResponse.prototype.clearPartsList = function() {
  return this.setPartsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.CreateMultipartUploadResponsePart.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.CreateMultipartUploadResponsePart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.CreateMultipartUploadResponsePart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CreateMultipartUploadResponsePart.toObject = function(includeInstance, msg) {
  var f, obj = {
partNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
presignedUploadUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.CreateMultipartUploadResponsePart}
 */
proto.cloud_api.CreateMultipartUploadResponsePart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.CreateMultipartUploadResponsePart;
  return proto.cloud_api.CreateMultipartUploadResponsePart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.CreateMultipartUploadResponsePart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.CreateMultipartUploadResponsePart}
 */
proto.cloud_api.CreateMultipartUploadResponsePart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPartNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresignedUploadUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.CreateMultipartUploadResponsePart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.CreateMultipartUploadResponsePart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.CreateMultipartUploadResponsePart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CreateMultipartUploadResponsePart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPresignedUploadUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 part_number = 1;
 * @return {number}
 */
proto.cloud_api.CreateMultipartUploadResponsePart.prototype.getPartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.CreateMultipartUploadResponsePart} returns this
 */
proto.cloud_api.CreateMultipartUploadResponsePart.prototype.setPartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string presigned_upload_url = 2;
 * @return {string}
 */
proto.cloud_api.CreateMultipartUploadResponsePart.prototype.getPresignedUploadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.CreateMultipartUploadResponsePart} returns this
 */
proto.cloud_api.CreateMultipartUploadResponsePart.prototype.setPresignedUploadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.CompleteMultipartUploadRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.CompleteMultipartUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.CompleteMultipartUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.CompleteMultipartUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CompleteMultipartUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
uploadId: jspb.Message.getFieldWithDefault(msg, 1, ""),
partsList: jspb.Message.toObjectList(msg.getPartsList(),
    proto.cloud_api.CompleteMultipartUploadRequestPart.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.CompleteMultipartUploadRequest}
 */
proto.cloud_api.CompleteMultipartUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.CompleteMultipartUploadRequest;
  return proto.cloud_api.CompleteMultipartUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.CompleteMultipartUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.CompleteMultipartUploadRequest}
 */
proto.cloud_api.CompleteMultipartUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    case 2:
      var value = new proto.cloud_api.CompleteMultipartUploadRequestPart;
      reader.readMessage(value,proto.cloud_api.CompleteMultipartUploadRequestPart.deserializeBinaryFromReader);
      msg.addParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.CompleteMultipartUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.CompleteMultipartUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.CompleteMultipartUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CompleteMultipartUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cloud_api.CompleteMultipartUploadRequestPart.serializeBinaryToWriter
    );
  }
};


/**
 * optional string upload_id = 1;
 * @return {string}
 */
proto.cloud_api.CompleteMultipartUploadRequest.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.CompleteMultipartUploadRequest} returns this
 */
proto.cloud_api.CompleteMultipartUploadRequest.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CompleteMultipartUploadRequestPart parts = 2;
 * @return {!Array<!proto.cloud_api.CompleteMultipartUploadRequestPart>}
 */
proto.cloud_api.CompleteMultipartUploadRequest.prototype.getPartsList = function() {
  return /** @type{!Array<!proto.cloud_api.CompleteMultipartUploadRequestPart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.CompleteMultipartUploadRequestPart, 2));
};


/**
 * @param {!Array<!proto.cloud_api.CompleteMultipartUploadRequestPart>} value
 * @return {!proto.cloud_api.CompleteMultipartUploadRequest} returns this
*/
proto.cloud_api.CompleteMultipartUploadRequest.prototype.setPartsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cloud_api.CompleteMultipartUploadRequestPart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.CompleteMultipartUploadRequestPart}
 */
proto.cloud_api.CompleteMultipartUploadRequest.prototype.addParts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cloud_api.CompleteMultipartUploadRequestPart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.CompleteMultipartUploadRequest} returns this
 */
proto.cloud_api.CompleteMultipartUploadRequest.prototype.clearPartsList = function() {
  return this.setPartsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.CompleteMultipartUploadRequestPart.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.CompleteMultipartUploadRequestPart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.CompleteMultipartUploadRequestPart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CompleteMultipartUploadRequestPart.toObject = function(includeInstance, msg) {
  var f, obj = {
partNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
etag: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.CompleteMultipartUploadRequestPart}
 */
proto.cloud_api.CompleteMultipartUploadRequestPart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.CompleteMultipartUploadRequestPart;
  return proto.cloud_api.CompleteMultipartUploadRequestPart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.CompleteMultipartUploadRequestPart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.CompleteMultipartUploadRequestPart}
 */
proto.cloud_api.CompleteMultipartUploadRequestPart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPartNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEtag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.CompleteMultipartUploadRequestPart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.CompleteMultipartUploadRequestPart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.CompleteMultipartUploadRequestPart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CompleteMultipartUploadRequestPart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEtag();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 part_number = 1;
 * @return {number}
 */
proto.cloud_api.CompleteMultipartUploadRequestPart.prototype.getPartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.CompleteMultipartUploadRequestPart} returns this
 */
proto.cloud_api.CompleteMultipartUploadRequestPart.prototype.setPartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string etag = 2;
 * @return {string}
 */
proto.cloud_api.CompleteMultipartUploadRequestPart.prototype.getEtag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.CompleteMultipartUploadRequestPart} returns this
 */
proto.cloud_api.CompleteMultipartUploadRequestPart.prototype.setEtag = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.CompleteMultipartUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.CompleteMultipartUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.CompleteMultipartUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CompleteMultipartUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
etag: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.CompleteMultipartUploadResponse}
 */
proto.cloud_api.CompleteMultipartUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.CompleteMultipartUploadResponse;
  return proto.cloud_api.CompleteMultipartUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.CompleteMultipartUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.CompleteMultipartUploadResponse}
 */
proto.cloud_api.CompleteMultipartUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEtag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.CompleteMultipartUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.CompleteMultipartUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.CompleteMultipartUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CompleteMultipartUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEtag();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string etag = 1;
 * @return {string}
 */
proto.cloud_api.CompleteMultipartUploadResponse.prototype.getEtag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.CompleteMultipartUploadResponse} returns this
 */
proto.cloud_api.CompleteMultipartUploadResponse.prototype.setEtag = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.cloud_api);
