import { multipart_msg_t } from "@skydio/lcm/types/skybus_tunnel/multipart_msg_t";

// Storage for an incoming event that is being reconstructed
export default class EventChunks {
  private chunks: Array<multipart_msg_t | null>;
  public numRecovered: number = 0;

  constructor(chunkCount: number) {
    this.chunks = new Array(chunkCount).fill(null);
  }

  get length() {
    return this.chunks.length;
  }

  get numReceived() {
    return this.chunks.filter(chunk => chunk !== null).length;
  }

  get receivedAllChunks() {
    return this.numReceived === this.length;
  }

  public hasChunk(index: number) {
    return this.chunks[index] !== null;
  }

  public receiveChunk(chunk: multipart_msg_t) {
    this.chunks[chunk.chunk_index] = chunk;
  }

  public getData() {
    if (!this.receivedAllChunks) {
      throw new Error("EventChunks has not yet received all chunks");
    }
    const mergedArray = new Uint8Array(
      this.chunks.reduce((total, chunk) => total + chunk!.chunk_size, 0)
    );
    let offset = 0;
    // @ts-ignore
    this.chunks.forEach((chunk: multipart_msg_t) => {
      mergedArray.set(chunk.chunk_data, offset);
      offset += chunk.chunk_size;
    });
    return mergedArray;
  }
}
