import { LcmMsg, LcmType } from "@skydio/lcm/src";
import { Message } from "google-protobuf";

export type MakesMessage<D extends Message> = (buf: Uint8Array) => D;
export type WireMsg = Message | LcmMsg;
export type WireType<D extends WireMsg> =
  | LcmType<Exclude<D, Message>>
  | MakesMessage<Exclude<D, LcmMsg>>;
export enum QOS {
  // send traffic on lossy data channel (default)
  UNRELIABLE = 1,

  // send traffic on reliable data channel; this should be avoided in almost every scenario! retries
  // are very problematic very devices with poor network connections, particularly those over LTE.
  // unreliable traffic and application-level ACK mechanisms are strongly preferred, and are
  // ultimately _more_ reliable since even reliable delivery is only best effort.
  RELIABLE__DO_NOT_USE = 0,
}
export type PublishOptions = {
  qos?: QOS;
};

export function isLcmType<D extends WireType<WireMsg>>(
  x: D
): x is Exclude<D, MakesMessage<Message>> {
  return "decode" in x.prototype;
}

export function isPbType<D extends WireType<WireMsg>>(x: D): x is Exclude<D, LcmType<LcmMsg>> {
  return !isLcmType(x);
}

export function isLcmMsg(x: unknown): x is LcmMsg {
  if (typeof x !== "object" || x === null) {
    return false;
  } else if (!("decode" in x)) {
    return false;
  } else if (!("decode_one" in x)) {
    return false;
  } else if (!("encode" in x)) {
    return false;
  } else if (!("encode_one" in x)) {
    return false;
  }
  return true;
}

export function isPbMsg(x: unknown): x is Message {
  return !isLcmMsg(x);
}
