// source: pbtypes/tools/cloud_api/release.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var pbtypes_gen_phone_mobile_platform_pb = require('../../../pbtypes/gen/phone/mobile_platform_pb.js');
goog.object.extend(proto, pbtypes_gen_phone_mobile_platform_pb);
var pbtypes_tools_cloud_api_files_pb = require('../../../pbtypes/tools/cloud_api/files_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_files_pb);
var pbtypes_tools_cloud_api_user_pb = require('../../../pbtypes/tools/cloud_api/user_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_user_pb);
var pbtypes_tools_cloud_api_enums_pb = require('../../../pbtypes/tools/cloud_api/enums_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_enums_pb);
var pbtypes_tools_cloud_api_pagination_pb = require('../../../pbtypes/tools/cloud_api/pagination_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_pagination_pb);
var pbtypes_tools_cloud_api_data_access_pb = require('../../../pbtypes/tools/cloud_api/data_access_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_data_access_pb);
goog.exportSymbol('proto.cloud_api.ControllerReleaseFile', null, global);
goog.exportSymbol('proto.cloud_api.ControllerReleaseOverride', null, global);
goog.exportSymbol('proto.cloud_api.ControllerReleaseOverrideRequest', null, global);
goog.exportSymbol('proto.cloud_api.ControllerReleaseOverrides', null, global);
goog.exportSymbol('proto.cloud_api.ControllerReleaseSignatureResponse', null, global);
goog.exportSymbol('proto.cloud_api.ControllerReleases', null, global);
goog.exportSymbol('proto.cloud_api.ControllerReleasesRequest', null, global);
goog.exportSymbol('proto.cloud_api.DeleteControllerReleaseOverrideRequest', null, global);
goog.exportSymbol('proto.cloud_api.DeviceReleaseOverride', null, global);
goog.exportSymbol('proto.cloud_api.DeviceReleaseOverrideRequest', null, global);
goog.exportSymbol('proto.cloud_api.DeviceReleaseOverrides', null, global);
goog.exportSymbol('proto.cloud_api.FindControllerReleaseRequest', null, global);
goog.exportSymbol('proto.cloud_api.FindControllerReleaseResponse', null, global);
goog.exportSymbol('proto.cloud_api.FindReleaseRequest', null, global);
goog.exportSymbol('proto.cloud_api.FindReleaseResponse', null, global);
goog.exportSymbol('proto.cloud_api.FindVehicleReleaseRequest', null, global);
goog.exportSymbol('proto.cloud_api.GetControllerReleaseRequest', null, global);
goog.exportSymbol('proto.cloud_api.GetReleaseRequest', null, global);
goog.exportSymbol('proto.cloud_api.ReleaseFile', null, global);
goog.exportSymbol('proto.cloud_api.ReleaseFiles', null, global);
goog.exportSymbol('proto.cloud_api.ReleaseMfgSignatureRequest', null, global);
goog.exportSymbol('proto.cloud_api.ReleaseMfgSignatureResponse', null, global);
goog.exportSymbol('proto.cloud_api.ReleaseSignatureRequest', null, global);
goog.exportSymbol('proto.cloud_api.ReleaseSignatureResponse', null, global);
goog.exportSymbol('proto.cloud_api.Releases', null, global);
goog.exportSymbol('proto.cloud_api.UpdateControllerReleaseRequest', null, global);
goog.exportSymbol('proto.cloud_api.UpdateControllerReleaseResponse', null, global);
goog.exportSymbol('proto.cloud_api.UpdateReleaseRequest', null, global);
goog.exportSymbol('proto.cloud_api.UserReleaseOverrideRequest', null, global);
goog.exportSymbol('proto.cloud_api.UserReleaseOverrides', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ReleaseFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.ReleaseFile.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.ReleaseFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ReleaseFile.displayName = 'proto.cloud_api.ReleaseFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ControllerReleaseFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.ControllerReleaseFile.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.ControllerReleaseFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ControllerReleaseFile.displayName = 'proto.cloud_api.ControllerReleaseFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ControllerReleaseOverride = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.ControllerReleaseOverride, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ControllerReleaseOverride.displayName = 'proto.cloud_api.ControllerReleaseOverride';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ControllerReleaseOverrideRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.ControllerReleaseOverrideRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ControllerReleaseOverrideRequest.displayName = 'proto.cloud_api.ControllerReleaseOverrideRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.DeleteControllerReleaseOverrideRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DeleteControllerReleaseOverrideRequest.displayName = 'proto.cloud_api.DeleteControllerReleaseOverrideRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ControllerReleaseOverrides = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.ControllerReleaseOverrides.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.ControllerReleaseOverrides, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ControllerReleaseOverrides.displayName = 'proto.cloud_api.ControllerReleaseOverrides';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Releases = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Releases.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Releases, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Releases.displayName = 'proto.cloud_api.Releases';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ReleaseFiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.ReleaseFiles.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.ReleaseFiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ReleaseFiles.displayName = 'proto.cloud_api.ReleaseFiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ControllerReleases = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.ControllerReleases.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.ControllerReleases, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ControllerReleases.displayName = 'proto.cloud_api.ControllerReleases';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ControllerReleasesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.ControllerReleasesRequest.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.ControllerReleasesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ControllerReleasesRequest.displayName = 'proto.cloud_api.ControllerReleasesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UpdateReleaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UpdateReleaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UpdateReleaseRequest.displayName = 'proto.cloud_api.UpdateReleaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UpdateControllerReleaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UpdateControllerReleaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UpdateControllerReleaseRequest.displayName = 'proto.cloud_api.UpdateControllerReleaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UpdateControllerReleaseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UpdateControllerReleaseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UpdateControllerReleaseResponse.displayName = 'proto.cloud_api.UpdateControllerReleaseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ReleaseMfgSignatureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.ReleaseMfgSignatureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ReleaseMfgSignatureRequest.displayName = 'proto.cloud_api.ReleaseMfgSignatureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ReleaseMfgSignatureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.ReleaseMfgSignatureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ReleaseMfgSignatureResponse.displayName = 'proto.cloud_api.ReleaseMfgSignatureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FindReleaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FindReleaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FindReleaseRequest.displayName = 'proto.cloud_api.FindReleaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FindVehicleReleaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FindVehicleReleaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FindVehicleReleaseRequest.displayName = 'proto.cloud_api.FindVehicleReleaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FindControllerReleaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FindControllerReleaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FindControllerReleaseRequest.displayName = 'proto.cloud_api.FindControllerReleaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FindControllerReleaseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FindControllerReleaseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FindControllerReleaseResponse.displayName = 'proto.cloud_api.FindControllerReleaseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.GetReleaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.GetReleaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.GetReleaseRequest.displayName = 'proto.cloud_api.GetReleaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.GetControllerReleaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.GetControllerReleaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.GetControllerReleaseRequest.displayName = 'proto.cloud_api.GetControllerReleaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FindReleaseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FindReleaseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FindReleaseResponse.displayName = 'proto.cloud_api.FindReleaseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ReleaseSignatureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.ReleaseSignatureRequest.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.ReleaseSignatureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ReleaseSignatureRequest.displayName = 'proto.cloud_api.ReleaseSignatureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ReleaseSignatureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.ReleaseSignatureResponse.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.ReleaseSignatureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ReleaseSignatureResponse.displayName = 'proto.cloud_api.ReleaseSignatureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ControllerReleaseSignatureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.ControllerReleaseSignatureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ControllerReleaseSignatureResponse.displayName = 'proto.cloud_api.ControllerReleaseSignatureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UserReleaseOverrideRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UserReleaseOverrideRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UserReleaseOverrideRequest.displayName = 'proto.cloud_api.UserReleaseOverrideRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UserReleaseOverrides = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.UserReleaseOverrides.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.UserReleaseOverrides, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UserReleaseOverrides.displayName = 'proto.cloud_api.UserReleaseOverrides';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DeviceReleaseOverrideRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.DeviceReleaseOverrideRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DeviceReleaseOverrideRequest.displayName = 'proto.cloud_api.DeviceReleaseOverrideRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DeviceReleaseOverride = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.DeviceReleaseOverride, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DeviceReleaseOverride.displayName = 'proto.cloud_api.DeviceReleaseOverride';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DeviceReleaseOverrides = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.DeviceReleaseOverrides.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.DeviceReleaseOverrides, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DeviceReleaseOverrides.displayName = 'proto.cloud_api.DeviceReleaseOverrides';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.ReleaseFile.repeatedFields_ = [11,21,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ReleaseFile.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ReleaseFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ReleaseFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseFile.toObject = function(includeInstance, msg) {
  var f, obj = {
file: (f = msg.getFile()) && pbtypes_tools_cloud_api_files_pb.DataFilePb.toObject(includeInstance, f),
vehicleType: jspb.Message.getFieldWithDefault(msg, 2, 0),
name: jspb.Message.getFieldWithDefault(msg, 3, ""),
releaseKey: jspb.Message.getFieldWithDefault(msg, 19, ""),
description: jspb.Message.getFieldWithDefault(msg, 4, ""),
version: jspb.Message.getFieldWithDefault(msg, 5, ""),
releaseFilesSignature: jspb.Message.getFieldWithDefault(msg, 25, ""),
fromReleaseVersion: jspb.Message.getFieldWithDefault(msg, 20, ""),
active: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
warn: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
comment: jspb.Message.getFieldWithDefault(msg, 8, ""),
groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    pbtypes_tools_cloud_api_user_pb.Group.toObject, includeInstance),
minIosVersion: jspb.Message.getFieldWithDefault(msg, 12, ""),
maxIosVersion: jspb.Message.getFieldWithDefault(msg, 13, ""),
minAndroidVersion: jspb.Message.getFieldWithDefault(msg, 14, ""),
maxAndroidVersion: jspb.Message.getFieldWithDefault(msg, 15, ""),
userReleaseOverridesList: jspb.Message.toObjectList(msg.getUserReleaseOverridesList(),
    pbtypes_tools_cloud_api_user_pb.UserReleaseOverride.toObject, includeInstance),
deviceReleaseOverridesList: jspb.Message.toObjectList(msg.getDeviceReleaseOverridesList(),
    proto.cloud_api.DeviceReleaseOverride.toObject, includeInstance),
fpinfo: jspb.Message.getFieldWithDefault(msg, 6, ""),
otaMeta: jspb.Message.getFieldWithDefault(msg, 7, ""),
toReleaseKey: jspb.Message.getFieldWithDefault(msg, 16, ""),
fromReleaseKey: jspb.Message.getFieldWithDefault(msg, 17, ""),
smallEnoughSize: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
ubuntuSuite: jspb.Message.getFieldWithDefault(msg, 22, ""),
isTemporary: jspb.Message.getBooleanFieldWithDefault(msg, 24, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ReleaseFile}
 */
proto.cloud_api.ReleaseFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ReleaseFile;
  return proto.cloud_api.ReleaseFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ReleaseFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ReleaseFile}
 */
proto.cloud_api.ReleaseFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_tools_cloud_api_files_pb.DataFilePb;
      reader.readMessage(value,pbtypes_tools_cloud_api_files_pb.DataFilePb.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.VehicleType.VehicleTypeEnum} */ (reader.readEnum());
      msg.setVehicleType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseFilesSignature(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromReleaseVersion(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWarn(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 11:
      var value = new pbtypes_tools_cloud_api_user_pb.Group;
      reader.readMessage(value,pbtypes_tools_cloud_api_user_pb.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinIosVersion(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxIosVersion(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinAndroidVersion(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxAndroidVersion(value);
      break;
    case 21:
      var value = new pbtypes_tools_cloud_api_user_pb.UserReleaseOverride;
      reader.readMessage(value,pbtypes_tools_cloud_api_user_pb.UserReleaseOverride.deserializeBinaryFromReader);
      msg.addUserReleaseOverrides(value);
      break;
    case 23:
      var value = new proto.cloud_api.DeviceReleaseOverride;
      reader.readMessage(value,proto.cloud_api.DeviceReleaseOverride.deserializeBinaryFromReader);
      msg.addDeviceReleaseOverrides(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFpinfo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtaMeta(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setToReleaseKey(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromReleaseKey(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSmallEnoughSize(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setUbuntuSuite(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTemporary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ReleaseFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ReleaseFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ReleaseFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_tools_cloud_api_files_pb.DataFilePb.serializeBinaryToWriter
    );
  }
  f = message.getVehicleType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReleaseFilesSignature();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getFromReleaseVersion();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getWarn();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      pbtypes_tools_cloud_api_user_pb.Group.serializeBinaryToWriter
    );
  }
  f = message.getMinIosVersion();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMaxIosVersion();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMinAndroidVersion();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMaxAndroidVersion();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUserReleaseOverridesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      pbtypes_tools_cloud_api_user_pb.UserReleaseOverride.serializeBinaryToWriter
    );
  }
  f = message.getDeviceReleaseOverridesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.cloud_api.DeviceReleaseOverride.serializeBinaryToWriter
    );
  }
  f = message.getFpinfo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOtaMeta();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getToReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFromReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSmallEnoughSize();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getUbuntuSuite();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getIsTemporary();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
};


/**
 * optional DataFilePb file = 1;
 * @return {?proto.cloud_api.DataFilePb}
 */
proto.cloud_api.ReleaseFile.prototype.getFile = function() {
  return /** @type{?proto.cloud_api.DataFilePb} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_files_pb.DataFilePb, 1));
};


/**
 * @param {?proto.cloud_api.DataFilePb|undefined} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
*/
proto.cloud_api.ReleaseFile.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.ReleaseFile.prototype.hasFile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional VehicleType.VehicleTypeEnum vehicle_type = 2;
 * @return {!proto.cloud_api.VehicleType.VehicleTypeEnum}
 */
proto.cloud_api.ReleaseFile.prototype.getVehicleType = function() {
  return /** @type {!proto.cloud_api.VehicleType.VehicleTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.VehicleType.VehicleTypeEnum} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setVehicleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string release_key = 19;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string version = 5;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string release_files_signature = 25;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getReleaseFilesSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setReleaseFilesSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string from_release_version = 20;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getFromReleaseVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setFromReleaseVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool active = 9;
 * @return {boolean}
 */
proto.cloud_api.ReleaseFile.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool warn = 10;
 * @return {boolean}
 */
proto.cloud_api.ReleaseFile.prototype.getWarn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setWarn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string comment = 8;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated Group groups = 11;
 * @return {!Array<!proto.cloud_api.Group>}
 */
proto.cloud_api.ReleaseFile.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.cloud_api.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_user_pb.Group, 11));
};


/**
 * @param {!Array<!proto.cloud_api.Group>} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
*/
proto.cloud_api.ReleaseFile.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.cloud_api.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Group}
 */
proto.cloud_api.ReleaseFile.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.cloud_api.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional string min_ios_version = 12;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getMinIosVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setMinIosVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string max_ios_version = 13;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getMaxIosVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setMaxIosVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string min_android_version = 14;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getMinAndroidVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setMinAndroidVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string max_android_version = 15;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getMaxAndroidVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setMaxAndroidVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated UserReleaseOverride user_release_overrides = 21;
 * @return {!Array<!proto.cloud_api.UserReleaseOverride>}
 */
proto.cloud_api.ReleaseFile.prototype.getUserReleaseOverridesList = function() {
  return /** @type{!Array<!proto.cloud_api.UserReleaseOverride>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_user_pb.UserReleaseOverride, 21));
};


/**
 * @param {!Array<!proto.cloud_api.UserReleaseOverride>} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
*/
proto.cloud_api.ReleaseFile.prototype.setUserReleaseOverridesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.cloud_api.UserReleaseOverride=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.UserReleaseOverride}
 */
proto.cloud_api.ReleaseFile.prototype.addUserReleaseOverrides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.cloud_api.UserReleaseOverride, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.clearUserReleaseOverridesList = function() {
  return this.setUserReleaseOverridesList([]);
};


/**
 * repeated DeviceReleaseOverride device_release_overrides = 23;
 * @return {!Array<!proto.cloud_api.DeviceReleaseOverride>}
 */
proto.cloud_api.ReleaseFile.prototype.getDeviceReleaseOverridesList = function() {
  return /** @type{!Array<!proto.cloud_api.DeviceReleaseOverride>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.DeviceReleaseOverride, 23));
};


/**
 * @param {!Array<!proto.cloud_api.DeviceReleaseOverride>} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
*/
proto.cloud_api.ReleaseFile.prototype.setDeviceReleaseOverridesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.cloud_api.DeviceReleaseOverride=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.DeviceReleaseOverride}
 */
proto.cloud_api.ReleaseFile.prototype.addDeviceReleaseOverrides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.cloud_api.DeviceReleaseOverride, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.clearDeviceReleaseOverridesList = function() {
  return this.setDeviceReleaseOverridesList([]);
};


/**
 * optional string fpinfo = 6;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getFpinfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setFpinfo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ota_meta = 7;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getOtaMeta = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setOtaMeta = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string to_release_key = 16;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getToReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setToReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string from_release_key = 17;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getFromReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setFromReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool small_enough_size = 18;
 * @return {boolean}
 */
proto.cloud_api.ReleaseFile.prototype.getSmallEnoughSize = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setSmallEnoughSize = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string ubuntu_suite = 22;
 * @return {string}
 */
proto.cloud_api.ReleaseFile.prototype.getUbuntuSuite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setUbuntuSuite = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional bool is_temporary = 24;
 * @return {boolean}
 */
proto.cloud_api.ReleaseFile.prototype.getIsTemporary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.ReleaseFile} returns this
 */
proto.cloud_api.ReleaseFile.prototype.setIsTemporary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.ControllerReleaseFile.repeatedFields_ = [6,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ControllerReleaseFile.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ControllerReleaseFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ControllerReleaseFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleaseFile.toObject = function(includeInstance, msg) {
  var f, obj = {
file: (f = msg.getFile()) && pbtypes_tools_cloud_api_files_pb.DataFilePb.toObject(includeInstance, f),
releaseKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
description: jspb.Message.getFieldWithDefault(msg, 3, ""),
version: jspb.Message.getFieldWithDefault(msg, 4, ""),
active: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    pbtypes_tools_cloud_api_user_pb.Group.toObject, includeInstance),
manifest: jspb.Message.getFieldWithDefault(msg, 7, ""),
otaMeta: jspb.Message.getFieldWithDefault(msg, 8, ""),
meta: jspb.Message.getFieldWithDefault(msg, 10, ""),
controllerType: jspb.Message.getFieldWithDefault(msg, 9, 0),
controllerReleaseOverridesList: jspb.Message.toObjectList(msg.getControllerReleaseOverridesList(),
    proto.cloud_api.ControllerReleaseOverride.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ControllerReleaseFile}
 */
proto.cloud_api.ControllerReleaseFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ControllerReleaseFile;
  return proto.cloud_api.ControllerReleaseFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ControllerReleaseFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ControllerReleaseFile}
 */
proto.cloud_api.ControllerReleaseFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_tools_cloud_api_files_pb.DataFilePb;
      reader.readMessage(value,pbtypes_tools_cloud_api_files_pb.DataFilePb.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 6:
      var value = new pbtypes_tools_cloud_api_user_pb.Group;
      reader.readMessage(value,pbtypes_tools_cloud_api_user_pb.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setManifest(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtaMeta(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeta(value);
      break;
    case 9:
      var value = /** @type {!proto.cloud_api.ControllerType.ControllerTypeEnum} */ (reader.readEnum());
      msg.setControllerType(value);
      break;
    case 11:
      var value = new proto.cloud_api.ControllerReleaseOverride;
      reader.readMessage(value,proto.cloud_api.ControllerReleaseOverride.deserializeBinaryFromReader);
      msg.addControllerReleaseOverrides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ControllerReleaseFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ControllerReleaseFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ControllerReleaseFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleaseFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_tools_cloud_api_files_pb.DataFilePb.serializeBinaryToWriter
    );
  }
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      pbtypes_tools_cloud_api_user_pb.Group.serializeBinaryToWriter
    );
  }
  f = message.getManifest();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOtaMeta();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMeta();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getControllerType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getControllerReleaseOverridesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.cloud_api.ControllerReleaseOverride.serializeBinaryToWriter
    );
  }
};


/**
 * optional DataFilePb file = 1;
 * @return {?proto.cloud_api.DataFilePb}
 */
proto.cloud_api.ControllerReleaseFile.prototype.getFile = function() {
  return /** @type{?proto.cloud_api.DataFilePb} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_files_pb.DataFilePb, 1));
};


/**
 * @param {?proto.cloud_api.DataFilePb|undefined} value
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
*/
proto.cloud_api.ControllerReleaseFile.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
 */
proto.cloud_api.ControllerReleaseFile.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.ControllerReleaseFile.prototype.hasFile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string release_key = 2;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseFile.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
 */
proto.cloud_api.ControllerReleaseFile.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseFile.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
 */
proto.cloud_api.ControllerReleaseFile.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string version = 4;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseFile.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
 */
proto.cloud_api.ControllerReleaseFile.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool active = 5;
 * @return {boolean}
 */
proto.cloud_api.ControllerReleaseFile.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
 */
proto.cloud_api.ControllerReleaseFile.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated Group groups = 6;
 * @return {!Array<!proto.cloud_api.Group>}
 */
proto.cloud_api.ControllerReleaseFile.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.cloud_api.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_user_pb.Group, 6));
};


/**
 * @param {!Array<!proto.cloud_api.Group>} value
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
*/
proto.cloud_api.ControllerReleaseFile.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.cloud_api.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Group}
 */
proto.cloud_api.ControllerReleaseFile.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.cloud_api.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
 */
proto.cloud_api.ControllerReleaseFile.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional string manifest = 7;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseFile.prototype.getManifest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
 */
proto.cloud_api.ControllerReleaseFile.prototype.setManifest = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string ota_meta = 8;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseFile.prototype.getOtaMeta = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
 */
proto.cloud_api.ControllerReleaseFile.prototype.setOtaMeta = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string meta = 10;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseFile.prototype.getMeta = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
 */
proto.cloud_api.ControllerReleaseFile.prototype.setMeta = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional ControllerType.ControllerTypeEnum controller_type = 9;
 * @return {!proto.cloud_api.ControllerType.ControllerTypeEnum}
 */
proto.cloud_api.ControllerReleaseFile.prototype.getControllerType = function() {
  return /** @type {!proto.cloud_api.ControllerType.ControllerTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.cloud_api.ControllerType.ControllerTypeEnum} value
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
 */
proto.cloud_api.ControllerReleaseFile.prototype.setControllerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated ControllerReleaseOverride controller_release_overrides = 11;
 * @return {!Array<!proto.cloud_api.ControllerReleaseOverride>}
 */
proto.cloud_api.ControllerReleaseFile.prototype.getControllerReleaseOverridesList = function() {
  return /** @type{!Array<!proto.cloud_api.ControllerReleaseOverride>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.ControllerReleaseOverride, 11));
};


/**
 * @param {!Array<!proto.cloud_api.ControllerReleaseOverride>} value
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
*/
proto.cloud_api.ControllerReleaseFile.prototype.setControllerReleaseOverridesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.cloud_api.ControllerReleaseOverride=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ControllerReleaseOverride}
 */
proto.cloud_api.ControllerReleaseFile.prototype.addControllerReleaseOverrides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.cloud_api.ControllerReleaseOverride, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ControllerReleaseFile} returns this
 */
proto.cloud_api.ControllerReleaseFile.prototype.clearControllerReleaseOverridesList = function() {
  return this.setControllerReleaseOverridesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ControllerReleaseOverride.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ControllerReleaseOverride.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ControllerReleaseOverride} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleaseOverride.toObject = function(includeInstance, msg) {
  var f, obj = {
controllerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
email: jspb.Message.getFieldWithDefault(msg, 5, ""),
releaseKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
validUntil: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ControllerReleaseOverride}
 */
proto.cloud_api.ControllerReleaseOverride.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ControllerReleaseOverride;
  return proto.cloud_api.ControllerReleaseOverride.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ControllerReleaseOverride} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ControllerReleaseOverride}
 */
proto.cloud_api.ControllerReleaseOverride.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ControllerReleaseOverride.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ControllerReleaseOverride.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ControllerReleaseOverride} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleaseOverride.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControllerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValidUntil();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string controller_id = 1;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseOverride.prototype.getControllerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseOverride} returns this
 */
proto.cloud_api.ControllerReleaseOverride.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseOverride.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseOverride} returns this
 */
proto.cloud_api.ControllerReleaseOverride.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseOverride.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseOverride} returns this
 */
proto.cloud_api.ControllerReleaseOverride.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string release_key = 3;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseOverride.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseOverride} returns this
 */
proto.cloud_api.ControllerReleaseOverride.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 valid_until = 4;
 * @return {number}
 */
proto.cloud_api.ControllerReleaseOverride.prototype.getValidUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.ControllerReleaseOverride} returns this
 */
proto.cloud_api.ControllerReleaseOverride.prototype.setValidUntil = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ControllerReleaseOverrideRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ControllerReleaseOverrideRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleaseOverrideRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
controllerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
email: jspb.Message.getFieldWithDefault(msg, 2, ""),
userUuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
organizationUuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
validDuration: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ControllerReleaseOverrideRequest}
 */
proto.cloud_api.ControllerReleaseOverrideRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ControllerReleaseOverrideRequest;
  return proto.cloud_api.ControllerReleaseOverrideRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ControllerReleaseOverrideRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ControllerReleaseOverrideRequest}
 */
proto.cloud_api.ControllerReleaseOverrideRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserUuid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationUuid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ControllerReleaseOverrideRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ControllerReleaseOverrideRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleaseOverrideRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControllerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValidDuration();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string controller_id = 1;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.getControllerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseOverrideRequest} returns this
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseOverrideRequest} returns this
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_uuid = 3;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.getUserUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseOverrideRequest} returns this
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.setUserUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organization_uuid = 4;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.getOrganizationUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseOverrideRequest} returns this
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.setOrganizationUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 valid_duration = 5;
 * @return {number}
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.getValidDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.ControllerReleaseOverrideRequest} returns this
 */
proto.cloud_api.ControllerReleaseOverrideRequest.prototype.setValidDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DeleteControllerReleaseOverrideRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DeleteControllerReleaseOverrideRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
controllerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
email: jspb.Message.getFieldWithDefault(msg, 2, ""),
userUuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
organizationUuid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DeleteControllerReleaseOverrideRequest}
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DeleteControllerReleaseOverrideRequest;
  return proto.cloud_api.DeleteControllerReleaseOverrideRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DeleteControllerReleaseOverrideRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DeleteControllerReleaseOverrideRequest}
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserUuid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DeleteControllerReleaseOverrideRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DeleteControllerReleaseOverrideRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControllerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string controller_id = 1;
 * @return {string}
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.prototype.getControllerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeleteControllerReleaseOverrideRequest} returns this
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeleteControllerReleaseOverrideRequest} returns this
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_uuid = 3;
 * @return {string}
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.prototype.getUserUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeleteControllerReleaseOverrideRequest} returns this
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.prototype.setUserUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organization_uuid = 4;
 * @return {string}
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.prototype.getOrganizationUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeleteControllerReleaseOverrideRequest} returns this
 */
proto.cloud_api.DeleteControllerReleaseOverrideRequest.prototype.setOrganizationUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.ControllerReleaseOverrides.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ControllerReleaseOverrides.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ControllerReleaseOverrides.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ControllerReleaseOverrides} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleaseOverrides.toObject = function(includeInstance, msg) {
  var f, obj = {
releaseKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
overridesList: jspb.Message.toObjectList(msg.getOverridesList(),
    proto.cloud_api.ControllerReleaseOverride.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ControllerReleaseOverrides}
 */
proto.cloud_api.ControllerReleaseOverrides.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ControllerReleaseOverrides;
  return proto.cloud_api.ControllerReleaseOverrides.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ControllerReleaseOverrides} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ControllerReleaseOverrides}
 */
proto.cloud_api.ControllerReleaseOverrides.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 2:
      var value = new proto.cloud_api.ControllerReleaseOverride;
      reader.readMessage(value,proto.cloud_api.ControllerReleaseOverride.deserializeBinaryFromReader);
      msg.addOverrides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ControllerReleaseOverrides.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ControllerReleaseOverrides.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ControllerReleaseOverrides} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleaseOverrides.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOverridesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cloud_api.ControllerReleaseOverride.serializeBinaryToWriter
    );
  }
};


/**
 * optional string release_key = 1;
 * @return {string}
 */
proto.cloud_api.ControllerReleaseOverrides.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleaseOverrides} returns this
 */
proto.cloud_api.ControllerReleaseOverrides.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ControllerReleaseOverride overrides = 2;
 * @return {!Array<!proto.cloud_api.ControllerReleaseOverride>}
 */
proto.cloud_api.ControllerReleaseOverrides.prototype.getOverridesList = function() {
  return /** @type{!Array<!proto.cloud_api.ControllerReleaseOverride>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.ControllerReleaseOverride, 2));
};


/**
 * @param {!Array<!proto.cloud_api.ControllerReleaseOverride>} value
 * @return {!proto.cloud_api.ControllerReleaseOverrides} returns this
*/
proto.cloud_api.ControllerReleaseOverrides.prototype.setOverridesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cloud_api.ControllerReleaseOverride=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ControllerReleaseOverride}
 */
proto.cloud_api.ControllerReleaseOverrides.prototype.addOverrides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cloud_api.ControllerReleaseOverride, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ControllerReleaseOverrides} returns this
 */
proto.cloud_api.ControllerReleaseOverrides.prototype.clearOverridesList = function() {
  return this.setOverridesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Releases.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Releases.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Releases.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Releases} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Releases.toObject = function(includeInstance, msg) {
  var f, obj = {
releasesList: jspb.Message.toObjectList(msg.getReleasesList(),
    proto.cloud_api.ReleaseFile.toObject, includeInstance),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Releases}
 */
proto.cloud_api.Releases.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Releases;
  return proto.cloud_api.Releases.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Releases} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Releases}
 */
proto.cloud_api.Releases.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.ReleaseFile;
      reader.readMessage(value,proto.cloud_api.ReleaseFile.deserializeBinaryFromReader);
      msg.addReleases(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Releases.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Releases.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Releases} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Releases.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReleasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.ReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReleaseFile releases = 1;
 * @return {!Array<!proto.cloud_api.ReleaseFile>}
 */
proto.cloud_api.Releases.prototype.getReleasesList = function() {
  return /** @type{!Array<!proto.cloud_api.ReleaseFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.ReleaseFile, 1));
};


/**
 * @param {!Array<!proto.cloud_api.ReleaseFile>} value
 * @return {!proto.cloud_api.Releases} returns this
*/
proto.cloud_api.Releases.prototype.setReleasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.ReleaseFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ReleaseFile}
 */
proto.cloud_api.Releases.prototype.addReleases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.ReleaseFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Releases} returns this
 */
proto.cloud_api.Releases.prototype.clearReleasesList = function() {
  return this.setReleasesList([]);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.Releases.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.Releases} returns this
*/
proto.cloud_api.Releases.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Releases} returns this
 */
proto.cloud_api.Releases.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Releases.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.ReleaseFiles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ReleaseFiles.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ReleaseFiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ReleaseFiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseFiles.toObject = function(includeInstance, msg) {
  var f, obj = {
releaseFilesList: jspb.Message.toObjectList(msg.getReleaseFilesList(),
    proto.cloud_api.ReleaseFile.toObject, includeInstance),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ReleaseFiles}
 */
proto.cloud_api.ReleaseFiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ReleaseFiles;
  return proto.cloud_api.ReleaseFiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ReleaseFiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ReleaseFiles}
 */
proto.cloud_api.ReleaseFiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.ReleaseFile;
      reader.readMessage(value,proto.cloud_api.ReleaseFile.deserializeBinaryFromReader);
      msg.addReleaseFiles(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ReleaseFiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ReleaseFiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ReleaseFiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseFiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReleaseFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.ReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReleaseFile release_files = 1;
 * @return {!Array<!proto.cloud_api.ReleaseFile>}
 */
proto.cloud_api.ReleaseFiles.prototype.getReleaseFilesList = function() {
  return /** @type{!Array<!proto.cloud_api.ReleaseFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.ReleaseFile, 1));
};


/**
 * @param {!Array<!proto.cloud_api.ReleaseFile>} value
 * @return {!proto.cloud_api.ReleaseFiles} returns this
*/
proto.cloud_api.ReleaseFiles.prototype.setReleaseFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.ReleaseFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ReleaseFile}
 */
proto.cloud_api.ReleaseFiles.prototype.addReleaseFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.ReleaseFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ReleaseFiles} returns this
 */
proto.cloud_api.ReleaseFiles.prototype.clearReleaseFilesList = function() {
  return this.setReleaseFilesList([]);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.ReleaseFiles.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.ReleaseFiles} returns this
*/
proto.cloud_api.ReleaseFiles.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.ReleaseFiles} returns this
 */
proto.cloud_api.ReleaseFiles.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.ReleaseFiles.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.ControllerReleases.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ControllerReleases.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ControllerReleases.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ControllerReleases} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleases.toObject = function(includeInstance, msg) {
  var f, obj = {
controllerReleasesList: jspb.Message.toObjectList(msg.getControllerReleasesList(),
    proto.cloud_api.ControllerReleaseFile.toObject, includeInstance),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ControllerReleases}
 */
proto.cloud_api.ControllerReleases.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ControllerReleases;
  return proto.cloud_api.ControllerReleases.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ControllerReleases} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ControllerReleases}
 */
proto.cloud_api.ControllerReleases.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.ControllerReleaseFile;
      reader.readMessage(value,proto.cloud_api.ControllerReleaseFile.deserializeBinaryFromReader);
      msg.addControllerReleases(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ControllerReleases.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ControllerReleases.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ControllerReleases} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleases.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControllerReleasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.ControllerReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ControllerReleaseFile controller_releases = 1;
 * @return {!Array<!proto.cloud_api.ControllerReleaseFile>}
 */
proto.cloud_api.ControllerReleases.prototype.getControllerReleasesList = function() {
  return /** @type{!Array<!proto.cloud_api.ControllerReleaseFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.ControllerReleaseFile, 1));
};


/**
 * @param {!Array<!proto.cloud_api.ControllerReleaseFile>} value
 * @return {!proto.cloud_api.ControllerReleases} returns this
*/
proto.cloud_api.ControllerReleases.prototype.setControllerReleasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.ControllerReleaseFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ControllerReleaseFile}
 */
proto.cloud_api.ControllerReleases.prototype.addControllerReleases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.ControllerReleaseFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ControllerReleases} returns this
 */
proto.cloud_api.ControllerReleases.prototype.clearControllerReleasesList = function() {
  return this.setControllerReleasesList([]);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.ControllerReleases.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.ControllerReleases} returns this
*/
proto.cloud_api.ControllerReleases.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.ControllerReleases} returns this
 */
proto.cloud_api.ControllerReleases.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.ControllerReleases.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.ControllerReleasesRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ControllerReleasesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ControllerReleasesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleasesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
releaseKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
version: jspb.Message.getFieldWithDefault(msg, 9, ""),
minVersion: jspb.Message.getFieldWithDefault(msg, 2, ""),
maxVersion: jspb.Message.getFieldWithDefault(msg, 3, ""),
groupList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
notGroupList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
description: jspb.Message.getFieldWithDefault(msg, 6, ""),
limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f),
controllerType: jspb.Message.getFieldWithDefault(msg, 10, 0),
devReleasesOnly: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
branch: jspb.Message.getFieldWithDefault(msg, 12, ""),
builder: jspb.Message.getFieldWithDefault(msg, 13, ""),
commit: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ControllerReleasesRequest}
 */
proto.cloud_api.ControllerReleasesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ControllerReleasesRequest;
  return proto.cloud_api.ControllerReleasesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ControllerReleasesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ControllerReleasesRequest}
 */
proto.cloud_api.ControllerReleasesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroup(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addNotGroup(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 10:
      var value = /** @type {!proto.cloud_api.ControllerType.ControllerTypeEnum} */ (reader.readEnum());
      msg.setControllerType(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDevReleasesOnly(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuilder(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ControllerReleasesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ControllerReleasesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleasesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMinVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGroupList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getNotGroupList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getControllerType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getDevReleasesOnly();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBuilder();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCommit();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string release_key = 1;
 * @return {string}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 9;
 * @return {string}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string min_version = 2;
 * @return {string}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getMinVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setMinVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string max_version = 3;
 * @return {string}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getMaxVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setMaxVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string group = 4;
 * @return {!Array<string>}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getGroupList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setGroupList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.addGroup = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.clearGroupList = function() {
  return this.setGroupList([]);
};


/**
 * repeated string not_group = 5;
 * @return {!Array<string>}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getNotGroupList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setNotGroupList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.addNotGroup = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.clearNotGroupList = function() {
  return this.setNotGroupList([]);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Pagination pagination = 8;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 8));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
*/
proto.cloud_api.ControllerReleasesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ControllerType.ControllerTypeEnum controller_type = 10;
 * @return {!proto.cloud_api.ControllerType.ControllerTypeEnum}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getControllerType = function() {
  return /** @type {!proto.cloud_api.ControllerType.ControllerTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.cloud_api.ControllerType.ControllerTypeEnum} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setControllerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional bool dev_releases_only = 11;
 * @return {boolean}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getDevReleasesOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setDevReleasesOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string branch = 12;
 * @return {string}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string builder = 13;
 * @return {string}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getBuilder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setBuilder = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string commit = 14;
 * @return {string}
 */
proto.cloud_api.ControllerReleasesRequest.prototype.getCommit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ControllerReleasesRequest} returns this
 */
proto.cloud_api.ControllerReleasesRequest.prototype.setCommit = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UpdateReleaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UpdateReleaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UpdateReleaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateReleaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
release: (f = msg.getRelease()) && proto.cloud_api.ReleaseFile.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UpdateReleaseRequest}
 */
proto.cloud_api.UpdateReleaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UpdateReleaseRequest;
  return proto.cloud_api.UpdateReleaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UpdateReleaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UpdateReleaseRequest}
 */
proto.cloud_api.UpdateReleaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.ReleaseFile;
      reader.readMessage(value,proto.cloud_api.ReleaseFile.deserializeBinaryFromReader);
      msg.setRelease(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UpdateReleaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UpdateReleaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UpdateReleaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateReleaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelease();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.ReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReleaseFile release = 1;
 * @return {?proto.cloud_api.ReleaseFile}
 */
proto.cloud_api.UpdateReleaseRequest.prototype.getRelease = function() {
  return /** @type{?proto.cloud_api.ReleaseFile} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.ReleaseFile, 1));
};


/**
 * @param {?proto.cloud_api.ReleaseFile|undefined} value
 * @return {!proto.cloud_api.UpdateReleaseRequest} returns this
*/
proto.cloud_api.UpdateReleaseRequest.prototype.setRelease = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateReleaseRequest} returns this
 */
proto.cloud_api.UpdateReleaseRequest.prototype.clearRelease = function() {
  return this.setRelease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateReleaseRequest.prototype.hasRelease = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cloud_api.UpdateReleaseRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.cloud_api.UpdateReleaseRequest} returns this
*/
proto.cloud_api.UpdateReleaseRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateReleaseRequest} returns this
 */
proto.cloud_api.UpdateReleaseRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateReleaseRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UpdateControllerReleaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UpdateControllerReleaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UpdateControllerReleaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateControllerReleaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
controllerRelease: (f = msg.getControllerRelease()) && proto.cloud_api.ControllerReleaseFile.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UpdateControllerReleaseRequest}
 */
proto.cloud_api.UpdateControllerReleaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UpdateControllerReleaseRequest;
  return proto.cloud_api.UpdateControllerReleaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UpdateControllerReleaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UpdateControllerReleaseRequest}
 */
proto.cloud_api.UpdateControllerReleaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.ControllerReleaseFile;
      reader.readMessage(value,proto.cloud_api.ControllerReleaseFile.deserializeBinaryFromReader);
      msg.setControllerRelease(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UpdateControllerReleaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UpdateControllerReleaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UpdateControllerReleaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateControllerReleaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControllerRelease();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.ControllerReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional ControllerReleaseFile controller_release = 1;
 * @return {?proto.cloud_api.ControllerReleaseFile}
 */
proto.cloud_api.UpdateControllerReleaseRequest.prototype.getControllerRelease = function() {
  return /** @type{?proto.cloud_api.ControllerReleaseFile} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.ControllerReleaseFile, 1));
};


/**
 * @param {?proto.cloud_api.ControllerReleaseFile|undefined} value
 * @return {!proto.cloud_api.UpdateControllerReleaseRequest} returns this
*/
proto.cloud_api.UpdateControllerReleaseRequest.prototype.setControllerRelease = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateControllerReleaseRequest} returns this
 */
proto.cloud_api.UpdateControllerReleaseRequest.prototype.clearControllerRelease = function() {
  return this.setControllerRelease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateControllerReleaseRequest.prototype.hasControllerRelease = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cloud_api.UpdateControllerReleaseRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.cloud_api.UpdateControllerReleaseRequest} returns this
*/
proto.cloud_api.UpdateControllerReleaseRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateControllerReleaseRequest} returns this
 */
proto.cloud_api.UpdateControllerReleaseRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateControllerReleaseRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UpdateControllerReleaseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UpdateControllerReleaseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UpdateControllerReleaseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateControllerReleaseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
controllerRelease: (f = msg.getControllerRelease()) && proto.cloud_api.ControllerReleaseFile.toObject(includeInstance, f),
credentials: (f = msg.getCredentials()) && pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UpdateControllerReleaseResponse}
 */
proto.cloud_api.UpdateControllerReleaseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UpdateControllerReleaseResponse;
  return proto.cloud_api.UpdateControllerReleaseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UpdateControllerReleaseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UpdateControllerReleaseResponse}
 */
proto.cloud_api.UpdateControllerReleaseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.ControllerReleaseFile;
      reader.readMessage(value,proto.cloud_api.ControllerReleaseFile.deserializeBinaryFromReader);
      msg.setControllerRelease(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials;
      reader.readMessage(value,pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UpdateControllerReleaseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UpdateControllerReleaseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UpdateControllerReleaseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateControllerReleaseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControllerRelease();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.ControllerReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.serializeBinaryToWriter
    );
  }
};


/**
 * optional ControllerReleaseFile controller_release = 1;
 * @return {?proto.cloud_api.ControllerReleaseFile}
 */
proto.cloud_api.UpdateControllerReleaseResponse.prototype.getControllerRelease = function() {
  return /** @type{?proto.cloud_api.ControllerReleaseFile} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.ControllerReleaseFile, 1));
};


/**
 * @param {?proto.cloud_api.ControllerReleaseFile|undefined} value
 * @return {!proto.cloud_api.UpdateControllerReleaseResponse} returns this
*/
proto.cloud_api.UpdateControllerReleaseResponse.prototype.setControllerRelease = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateControllerReleaseResponse} returns this
 */
proto.cloud_api.UpdateControllerReleaseResponse.prototype.clearControllerRelease = function() {
  return this.setControllerRelease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateControllerReleaseResponse.prototype.hasControllerRelease = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StsTemporaryCredentials credentials = 2;
 * @return {?proto.cloud_api.StsTemporaryCredentials}
 */
proto.cloud_api.UpdateControllerReleaseResponse.prototype.getCredentials = function() {
  return /** @type{?proto.cloud_api.StsTemporaryCredentials} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials, 2));
};


/**
 * @param {?proto.cloud_api.StsTemporaryCredentials|undefined} value
 * @return {!proto.cloud_api.UpdateControllerReleaseResponse} returns this
*/
proto.cloud_api.UpdateControllerReleaseResponse.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateControllerReleaseResponse} returns this
 */
proto.cloud_api.UpdateControllerReleaseResponse.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateControllerReleaseResponse.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ReleaseMfgSignatureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ReleaseMfgSignatureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ReleaseMfgSignatureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseMfgSignatureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicleId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ReleaseMfgSignatureRequest}
 */
proto.cloud_api.ReleaseMfgSignatureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ReleaseMfgSignatureRequest;
  return proto.cloud_api.ReleaseMfgSignatureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ReleaseMfgSignatureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ReleaseMfgSignatureRequest}
 */
proto.cloud_api.ReleaseMfgSignatureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ReleaseMfgSignatureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ReleaseMfgSignatureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ReleaseMfgSignatureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseMfgSignatureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.cloud_api.ReleaseMfgSignatureRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseMfgSignatureRequest} returns this
 */
proto.cloud_api.ReleaseMfgSignatureRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ReleaseMfgSignatureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ReleaseMfgSignatureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ReleaseMfgSignatureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseMfgSignatureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
signature: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ReleaseMfgSignatureResponse}
 */
proto.cloud_api.ReleaseMfgSignatureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ReleaseMfgSignatureResponse;
  return proto.cloud_api.ReleaseMfgSignatureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ReleaseMfgSignatureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ReleaseMfgSignatureResponse}
 */
proto.cloud_api.ReleaseMfgSignatureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ReleaseMfgSignatureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ReleaseMfgSignatureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ReleaseMfgSignatureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseMfgSignatureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string signature = 1;
 * @return {string}
 */
proto.cloud_api.ReleaseMfgSignatureResponse.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ReleaseMfgSignatureResponse} returns this
 */
proto.cloud_api.ReleaseMfgSignatureResponse.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FindReleaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FindReleaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FindReleaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindReleaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
deviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
appVersion: jspb.Message.getFieldWithDefault(msg, 3, ""),
deviceOs: jspb.Message.getFieldWithDefault(msg, 4, 0),
vehicleReleaseKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
otaRelatedMetadata: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FindReleaseRequest}
 */
proto.cloud_api.FindReleaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FindReleaseRequest;
  return proto.cloud_api.FindReleaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FindReleaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FindReleaseRequest}
 */
proto.cloud_api.FindReleaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppVersion(value);
      break;
    case 4:
      var value = /** @type {!proto.phone.MobilePlatform.Enum} */ (reader.readEnum());
      msg.setDeviceOs(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleReleaseKey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtaRelatedMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FindReleaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FindReleaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FindReleaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindReleaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAppVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeviceOs();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVehicleReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOtaRelatedMetadata();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.cloud_api.FindReleaseRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindReleaseRequest} returns this
 */
proto.cloud_api.FindReleaseRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.cloud_api.FindReleaseRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindReleaseRequest} returns this
 */
proto.cloud_api.FindReleaseRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string app_version = 3;
 * @return {string}
 */
proto.cloud_api.FindReleaseRequest.prototype.getAppVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindReleaseRequest} returns this
 */
proto.cloud_api.FindReleaseRequest.prototype.setAppVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional phone.MobilePlatform.Enum device_os = 4;
 * @return {!proto.phone.MobilePlatform.Enum}
 */
proto.cloud_api.FindReleaseRequest.prototype.getDeviceOs = function() {
  return /** @type {!proto.phone.MobilePlatform.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.phone.MobilePlatform.Enum} value
 * @return {!proto.cloud_api.FindReleaseRequest} returns this
 */
proto.cloud_api.FindReleaseRequest.prototype.setDeviceOs = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string vehicle_release_key = 5;
 * @return {string}
 */
proto.cloud_api.FindReleaseRequest.prototype.getVehicleReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindReleaseRequest} returns this
 */
proto.cloud_api.FindReleaseRequest.prototype.setVehicleReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ota_related_metadata = 6;
 * @return {string}
 */
proto.cloud_api.FindReleaseRequest.prototype.getOtaRelatedMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindReleaseRequest} returns this
 */
proto.cloud_api.FindReleaseRequest.prototype.setOtaRelatedMetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FindVehicleReleaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FindVehicleReleaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FindVehicleReleaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindVehicleReleaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicleReleaseKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
otaRelatedMetadata: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FindVehicleReleaseRequest}
 */
proto.cloud_api.FindVehicleReleaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FindVehicleReleaseRequest;
  return proto.cloud_api.FindVehicleReleaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FindVehicleReleaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FindVehicleReleaseRequest}
 */
proto.cloud_api.FindVehicleReleaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleReleaseKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtaRelatedMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FindVehicleReleaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FindVehicleReleaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FindVehicleReleaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindVehicleReleaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOtaRelatedMetadata();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_release_key = 1;
 * @return {string}
 */
proto.cloud_api.FindVehicleReleaseRequest.prototype.getVehicleReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindVehicleReleaseRequest} returns this
 */
proto.cloud_api.FindVehicleReleaseRequest.prototype.setVehicleReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ota_related_metadata = 2;
 * @return {string}
 */
proto.cloud_api.FindVehicleReleaseRequest.prototype.getOtaRelatedMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindVehicleReleaseRequest} returns this
 */
proto.cloud_api.FindVehicleReleaseRequest.prototype.setOtaRelatedMetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FindControllerReleaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FindControllerReleaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FindControllerReleaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindControllerReleaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
controllerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
controllerReleaseKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FindControllerReleaseRequest}
 */
proto.cloud_api.FindControllerReleaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FindControllerReleaseRequest;
  return proto.cloud_api.FindControllerReleaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FindControllerReleaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FindControllerReleaseRequest}
 */
proto.cloud_api.FindControllerReleaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerReleaseKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FindControllerReleaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FindControllerReleaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FindControllerReleaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindControllerReleaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControllerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getControllerReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string controller_id = 1;
 * @return {string}
 */
proto.cloud_api.FindControllerReleaseRequest.prototype.getControllerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindControllerReleaseRequest} returns this
 */
proto.cloud_api.FindControllerReleaseRequest.prototype.setControllerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string controller_release_key = 2;
 * @return {string}
 */
proto.cloud_api.FindControllerReleaseRequest.prototype.getControllerReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindControllerReleaseRequest} returns this
 */
proto.cloud_api.FindControllerReleaseRequest.prototype.setControllerReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FindControllerReleaseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FindControllerReleaseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindControllerReleaseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
controllerRelease: (f = msg.getControllerRelease()) && proto.cloud_api.ControllerReleaseFile.toObject(includeInstance, f),
credentials: (f = msg.getCredentials()) && pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.toObject(includeInstance, f),
downloadUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
signature: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FindControllerReleaseResponse}
 */
proto.cloud_api.FindControllerReleaseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FindControllerReleaseResponse;
  return proto.cloud_api.FindControllerReleaseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FindControllerReleaseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FindControllerReleaseResponse}
 */
proto.cloud_api.FindControllerReleaseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.ControllerReleaseFile;
      reader.readMessage(value,proto.cloud_api.ControllerReleaseFile.deserializeBinaryFromReader);
      msg.setControllerRelease(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials;
      reader.readMessage(value,pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FindControllerReleaseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FindControllerReleaseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindControllerReleaseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControllerRelease();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.ControllerReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.serializeBinaryToWriter
    );
  }
  f = message.getDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ControllerReleaseFile controller_release = 1;
 * @return {?proto.cloud_api.ControllerReleaseFile}
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.getControllerRelease = function() {
  return /** @type{?proto.cloud_api.ControllerReleaseFile} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.ControllerReleaseFile, 1));
};


/**
 * @param {?proto.cloud_api.ControllerReleaseFile|undefined} value
 * @return {!proto.cloud_api.FindControllerReleaseResponse} returns this
*/
proto.cloud_api.FindControllerReleaseResponse.prototype.setControllerRelease = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.FindControllerReleaseResponse} returns this
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.clearControllerRelease = function() {
  return this.setControllerRelease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.hasControllerRelease = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StsTemporaryCredentials credentials = 2;
 * @return {?proto.cloud_api.StsTemporaryCredentials}
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.getCredentials = function() {
  return /** @type{?proto.cloud_api.StsTemporaryCredentials} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials, 2));
};


/**
 * @param {?proto.cloud_api.StsTemporaryCredentials|undefined} value
 * @return {!proto.cloud_api.FindControllerReleaseResponse} returns this
*/
proto.cloud_api.FindControllerReleaseResponse.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.FindControllerReleaseResponse} returns this
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string download_url = 3;
 * @return {string}
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.getDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindControllerReleaseResponse} returns this
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.setDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string signature = 4;
 * @return {string}
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindControllerReleaseResponse} returns this
 */
proto.cloud_api.FindControllerReleaseResponse.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.GetReleaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.GetReleaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.GetReleaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.GetReleaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
encrypted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.GetReleaseRequest}
 */
proto.cloud_api.GetReleaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.GetReleaseRequest;
  return proto.cloud_api.GetReleaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.GetReleaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.GetReleaseRequest}
 */
proto.cloud_api.GetReleaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEncrypted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.GetReleaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.GetReleaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.GetReleaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.GetReleaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEncrypted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool encrypted = 1;
 * @return {boolean}
 */
proto.cloud_api.GetReleaseRequest.prototype.getEncrypted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.GetReleaseRequest} returns this
 */
proto.cloud_api.GetReleaseRequest.prototype.setEncrypted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.GetControllerReleaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.GetControllerReleaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.GetControllerReleaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.GetControllerReleaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
releaseKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.GetControllerReleaseRequest}
 */
proto.cloud_api.GetControllerReleaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.GetControllerReleaseRequest;
  return proto.cloud_api.GetControllerReleaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.GetControllerReleaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.GetControllerReleaseRequest}
 */
proto.cloud_api.GetControllerReleaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.GetControllerReleaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.GetControllerReleaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.GetControllerReleaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.GetControllerReleaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string release_key = 1;
 * @return {string}
 */
proto.cloud_api.GetControllerReleaseRequest.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.GetControllerReleaseRequest} returns this
 */
proto.cloud_api.GetControllerReleaseRequest.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.cloud_api.GetControllerReleaseRequest.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.GetControllerReleaseRequest} returns this
 */
proto.cloud_api.GetControllerReleaseRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FindReleaseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FindReleaseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FindReleaseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindReleaseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
release: (f = msg.getRelease()) && proto.cloud_api.ReleaseFile.toObject(includeInstance, f),
credentials: (f = msg.getCredentials()) && pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.toObject(includeInstance, f),
warnToUpdate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
downloadUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
signature: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FindReleaseResponse}
 */
proto.cloud_api.FindReleaseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FindReleaseResponse;
  return proto.cloud_api.FindReleaseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FindReleaseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FindReleaseResponse}
 */
proto.cloud_api.FindReleaseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.ReleaseFile;
      reader.readMessage(value,proto.cloud_api.ReleaseFile.deserializeBinaryFromReader);
      msg.setRelease(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials;
      reader.readMessage(value,pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWarnToUpdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FindReleaseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FindReleaseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FindReleaseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FindReleaseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelease();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.ReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.serializeBinaryToWriter
    );
  }
  f = message.getWarnToUpdate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional ReleaseFile release = 1;
 * @return {?proto.cloud_api.ReleaseFile}
 */
proto.cloud_api.FindReleaseResponse.prototype.getRelease = function() {
  return /** @type{?proto.cloud_api.ReleaseFile} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.ReleaseFile, 1));
};


/**
 * @param {?proto.cloud_api.ReleaseFile|undefined} value
 * @return {!proto.cloud_api.FindReleaseResponse} returns this
*/
proto.cloud_api.FindReleaseResponse.prototype.setRelease = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.FindReleaseResponse} returns this
 */
proto.cloud_api.FindReleaseResponse.prototype.clearRelease = function() {
  return this.setRelease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.FindReleaseResponse.prototype.hasRelease = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StsTemporaryCredentials credentials = 2;
 * @return {?proto.cloud_api.StsTemporaryCredentials}
 */
proto.cloud_api.FindReleaseResponse.prototype.getCredentials = function() {
  return /** @type{?proto.cloud_api.StsTemporaryCredentials} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials, 2));
};


/**
 * @param {?proto.cloud_api.StsTemporaryCredentials|undefined} value
 * @return {!proto.cloud_api.FindReleaseResponse} returns this
*/
proto.cloud_api.FindReleaseResponse.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.FindReleaseResponse} returns this
 */
proto.cloud_api.FindReleaseResponse.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.FindReleaseResponse.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool warn_to_update = 3;
 * @return {boolean}
 */
proto.cloud_api.FindReleaseResponse.prototype.getWarnToUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FindReleaseResponse} returns this
 */
proto.cloud_api.FindReleaseResponse.prototype.setWarnToUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string download_url = 4;
 * @return {string}
 */
proto.cloud_api.FindReleaseResponse.prototype.getDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindReleaseResponse} returns this
 */
proto.cloud_api.FindReleaseResponse.prototype.setDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string signature = 5;
 * @return {string}
 */
proto.cloud_api.FindReleaseResponse.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FindReleaseResponse} returns this
 */
proto.cloud_api.FindReleaseResponse.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.ReleaseSignatureRequest.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ReleaseSignatureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ReleaseSignatureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseSignatureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
vehicleIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
vehicleHostnamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
getZipSignature: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
unencrypted: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.PaginationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ReleaseSignatureRequest}
 */
proto.cloud_api.ReleaseSignatureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ReleaseSignatureRequest;
  return proto.cloud_api.ReleaseSignatureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ReleaseSignatureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ReleaseSignatureRequest}
 */
proto.cloud_api.ReleaseSignatureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addVehicleIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addVehicleHostnames(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetZipSignature(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnencrypted(value);
      break;
    case 6:
      var value = new pbtypes_tools_cloud_api_pagination_pb.PaginationRequest;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.PaginationRequest.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ReleaseSignatureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ReleaseSignatureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseSignatureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getVehicleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getVehicleHostnamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getGetZipSignature();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getUnencrypted();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pbtypes_tools_cloud_api_pagination_pb.PaginationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string organization_ids = 1;
 * @return {!Array<string>}
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * repeated string vehicle_ids = 2;
 * @return {!Array<string>}
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.getVehicleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.setVehicleIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.addVehicleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.clearVehicleIdsList = function() {
  return this.setVehicleIdsList([]);
};


/**
 * repeated string vehicle_hostnames = 3;
 * @return {!Array<string>}
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.getVehicleHostnamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.setVehicleHostnamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.addVehicleHostnames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.clearVehicleHostnamesList = function() {
  return this.setVehicleHostnamesList([]);
};


/**
 * optional bool get_zip_signature = 4;
 * @return {boolean}
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.getGetZipSignature = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.setGetZipSignature = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool unencrypted = 5;
 * @return {boolean}
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.getUnencrypted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.setUnencrypted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional PaginationRequest pagination = 6;
 * @return {?proto.cloud_api.PaginationRequest}
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.PaginationRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.PaginationRequest, 6));
};


/**
 * @param {?proto.cloud_api.PaginationRequest|undefined} value
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
*/
proto.cloud_api.ReleaseSignatureRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.ReleaseSignatureRequest} returns this
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.ReleaseSignatureRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.ReleaseSignatureResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ReleaseSignatureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ReleaseSignatureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ReleaseSignatureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseSignatureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
signaturesMap: (f = msg.getSignaturesMap()) ? f.toObject(includeInstance, undefined) : [],
failedVehicleIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ReleaseSignatureResponse}
 */
proto.cloud_api.ReleaseSignatureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ReleaseSignatureResponse;
  return proto.cloud_api.ReleaseSignatureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ReleaseSignatureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ReleaseSignatureResponse}
 */
proto.cloud_api.ReleaseSignatureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSignaturesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFailedVehicleIds(value);
      break;
    case 3:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ReleaseSignatureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ReleaseSignatureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ReleaseSignatureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ReleaseSignatureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignaturesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getFailedVehicleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * map<string, string> signatures = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.cloud_api.ReleaseSignatureResponse.prototype.getSignaturesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_api.ReleaseSignatureResponse} returns this
 */
proto.cloud_api.ReleaseSignatureResponse.prototype.clearSignaturesMap = function() {
  this.getSignaturesMap().clear();
  return this;
};


/**
 * repeated string failed_vehicle_ids = 2;
 * @return {!Array<string>}
 */
proto.cloud_api.ReleaseSignatureResponse.prototype.getFailedVehicleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.ReleaseSignatureResponse} returns this
 */
proto.cloud_api.ReleaseSignatureResponse.prototype.setFailedVehicleIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ReleaseSignatureResponse} returns this
 */
proto.cloud_api.ReleaseSignatureResponse.prototype.addFailedVehicleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.ReleaseSignatureResponse} returns this
 */
proto.cloud_api.ReleaseSignatureResponse.prototype.clearFailedVehicleIdsList = function() {
  return this.setFailedVehicleIdsList([]);
};


/**
 * optional Pagination pagination = 3;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.ReleaseSignatureResponse.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 3));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.ReleaseSignatureResponse} returns this
*/
proto.cloud_api.ReleaseSignatureResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.ReleaseSignatureResponse} returns this
 */
proto.cloud_api.ReleaseSignatureResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.ReleaseSignatureResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ControllerReleaseSignatureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ControllerReleaseSignatureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ControllerReleaseSignatureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleaseSignatureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
signaturesMap: (f = msg.getSignaturesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ControllerReleaseSignatureResponse}
 */
proto.cloud_api.ControllerReleaseSignatureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ControllerReleaseSignatureResponse;
  return proto.cloud_api.ControllerReleaseSignatureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ControllerReleaseSignatureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ControllerReleaseSignatureResponse}
 */
proto.cloud_api.ControllerReleaseSignatureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSignaturesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ControllerReleaseSignatureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ControllerReleaseSignatureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ControllerReleaseSignatureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ControllerReleaseSignatureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignaturesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> signatures = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.cloud_api.ControllerReleaseSignatureResponse.prototype.getSignaturesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_api.ControllerReleaseSignatureResponse} returns this
 */
proto.cloud_api.ControllerReleaseSignatureResponse.prototype.clearSignaturesMap = function() {
  this.getSignaturesMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UserReleaseOverrideRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UserReleaseOverrideRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UserReleaseOverrideRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
email: jspb.Message.getFieldWithDefault(msg, 1, ""),
validDuration: jspb.Message.getFieldWithDefault(msg, 2, 0),
remove: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
userUuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
organizationUuid: jspb.Message.getFieldWithDefault(msg, 5, ""),
forceFullFlashpack: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UserReleaseOverrideRequest}
 */
proto.cloud_api.UserReleaseOverrideRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UserReleaseOverrideRequest;
  return proto.cloud_api.UserReleaseOverrideRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UserReleaseOverrideRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UserReleaseOverrideRequest}
 */
proto.cloud_api.UserReleaseOverrideRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidDuration(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemove(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserUuid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationUuid(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceFullFlashpack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UserReleaseOverrideRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UserReleaseOverrideRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UserReleaseOverrideRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValidDuration();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRemove();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getUserUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrganizationUuid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getForceFullFlashpack();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.UserReleaseOverrideRequest} returns this
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 valid_duration = 2;
 * @return {number}
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.getValidDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.UserReleaseOverrideRequest} returns this
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.setValidDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool remove = 3;
 * @return {boolean}
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.getRemove = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.UserReleaseOverrideRequest} returns this
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.setRemove = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string user_uuid = 4;
 * @return {string}
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.getUserUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.UserReleaseOverrideRequest} returns this
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.setUserUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string organization_uuid = 5;
 * @return {string}
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.getOrganizationUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.UserReleaseOverrideRequest} returns this
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.setOrganizationUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool force_full_flashpack = 6;
 * @return {boolean}
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.getForceFullFlashpack = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.UserReleaseOverrideRequest} returns this
 */
proto.cloud_api.UserReleaseOverrideRequest.prototype.setForceFullFlashpack = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.UserReleaseOverrides.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UserReleaseOverrides.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UserReleaseOverrides.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UserReleaseOverrides} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UserReleaseOverrides.toObject = function(includeInstance, msg) {
  var f, obj = {
releaseKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
userReleaseOverridesList: jspb.Message.toObjectList(msg.getUserReleaseOverridesList(),
    pbtypes_tools_cloud_api_user_pb.UserReleaseOverride.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UserReleaseOverrides}
 */
proto.cloud_api.UserReleaseOverrides.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UserReleaseOverrides;
  return proto.cloud_api.UserReleaseOverrides.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UserReleaseOverrides} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UserReleaseOverrides}
 */
proto.cloud_api.UserReleaseOverrides.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_user_pb.UserReleaseOverride;
      reader.readMessage(value,pbtypes_tools_cloud_api_user_pb.UserReleaseOverride.deserializeBinaryFromReader);
      msg.addUserReleaseOverrides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UserReleaseOverrides.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UserReleaseOverrides.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UserReleaseOverrides} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UserReleaseOverrides.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserReleaseOverridesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      pbtypes_tools_cloud_api_user_pb.UserReleaseOverride.serializeBinaryToWriter
    );
  }
};


/**
 * optional string release_key = 1;
 * @return {string}
 */
proto.cloud_api.UserReleaseOverrides.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.UserReleaseOverrides} returns this
 */
proto.cloud_api.UserReleaseOverrides.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated UserReleaseOverride user_release_overrides = 2;
 * @return {!Array<!proto.cloud_api.UserReleaseOverride>}
 */
proto.cloud_api.UserReleaseOverrides.prototype.getUserReleaseOverridesList = function() {
  return /** @type{!Array<!proto.cloud_api.UserReleaseOverride>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_user_pb.UserReleaseOverride, 2));
};


/**
 * @param {!Array<!proto.cloud_api.UserReleaseOverride>} value
 * @return {!proto.cloud_api.UserReleaseOverrides} returns this
*/
proto.cloud_api.UserReleaseOverrides.prototype.setUserReleaseOverridesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cloud_api.UserReleaseOverride=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.UserReleaseOverride}
 */
proto.cloud_api.UserReleaseOverrides.prototype.addUserReleaseOverrides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cloud_api.UserReleaseOverride, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.UserReleaseOverrides} returns this
 */
proto.cloud_api.UserReleaseOverrides.prototype.clearUserReleaseOverridesList = function() {
  return this.setUserReleaseOverridesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DeviceReleaseOverrideRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DeviceReleaseOverrideRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DeviceReleaseOverrideRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeviceReleaseOverrideRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
validDuration: jspb.Message.getFieldWithDefault(msg, 2, 0),
remove: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
forceFullFlashpack: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DeviceReleaseOverrideRequest}
 */
proto.cloud_api.DeviceReleaseOverrideRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DeviceReleaseOverrideRequest;
  return proto.cloud_api.DeviceReleaseOverrideRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DeviceReleaseOverrideRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DeviceReleaseOverrideRequest}
 */
proto.cloud_api.DeviceReleaseOverrideRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidDuration(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemove(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceFullFlashpack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DeviceReleaseOverrideRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DeviceReleaseOverrideRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DeviceReleaseOverrideRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeviceReleaseOverrideRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValidDuration();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRemove();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getForceFullFlashpack();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.cloud_api.DeviceReleaseOverrideRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeviceReleaseOverrideRequest} returns this
 */
proto.cloud_api.DeviceReleaseOverrideRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 valid_duration = 2;
 * @return {number}
 */
proto.cloud_api.DeviceReleaseOverrideRequest.prototype.getValidDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.DeviceReleaseOverrideRequest} returns this
 */
proto.cloud_api.DeviceReleaseOverrideRequest.prototype.setValidDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool remove = 3;
 * @return {boolean}
 */
proto.cloud_api.DeviceReleaseOverrideRequest.prototype.getRemove = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.DeviceReleaseOverrideRequest} returns this
 */
proto.cloud_api.DeviceReleaseOverrideRequest.prototype.setRemove = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool force_full_flashpack = 4;
 * @return {boolean}
 */
proto.cloud_api.DeviceReleaseOverrideRequest.prototype.getForceFullFlashpack = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.DeviceReleaseOverrideRequest} returns this
 */
proto.cloud_api.DeviceReleaseOverrideRequest.prototype.setForceFullFlashpack = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DeviceReleaseOverride.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DeviceReleaseOverride.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DeviceReleaseOverride} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeviceReleaseOverride.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
vehicleType: jspb.Message.getFieldWithDefault(msg, 2, 0),
releaseKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
validUntil: jspb.Message.getFieldWithDefault(msg, 4, 0),
forceFullFlashpack: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DeviceReleaseOverride}
 */
proto.cloud_api.DeviceReleaseOverride.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DeviceReleaseOverride;
  return proto.cloud_api.DeviceReleaseOverride.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DeviceReleaseOverride} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DeviceReleaseOverride}
 */
proto.cloud_api.DeviceReleaseOverride.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.VehicleType.VehicleTypeEnum} */ (reader.readEnum());
      msg.setVehicleType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidUntil(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceFullFlashpack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DeviceReleaseOverride.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DeviceReleaseOverride.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DeviceReleaseOverride} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeviceReleaseOverride.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValidUntil();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getForceFullFlashpack();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.cloud_api.DeviceReleaseOverride.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeviceReleaseOverride} returns this
 */
proto.cloud_api.DeviceReleaseOverride.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional VehicleType.VehicleTypeEnum vehicle_type = 2;
 * @return {!proto.cloud_api.VehicleType.VehicleTypeEnum}
 */
proto.cloud_api.DeviceReleaseOverride.prototype.getVehicleType = function() {
  return /** @type {!proto.cloud_api.VehicleType.VehicleTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.VehicleType.VehicleTypeEnum} value
 * @return {!proto.cloud_api.DeviceReleaseOverride} returns this
 */
proto.cloud_api.DeviceReleaseOverride.prototype.setVehicleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string release_key = 3;
 * @return {string}
 */
proto.cloud_api.DeviceReleaseOverride.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeviceReleaseOverride} returns this
 */
proto.cloud_api.DeviceReleaseOverride.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 valid_until = 4;
 * @return {number}
 */
proto.cloud_api.DeviceReleaseOverride.prototype.getValidUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.DeviceReleaseOverride} returns this
 */
proto.cloud_api.DeviceReleaseOverride.prototype.setValidUntil = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool force_full_flashpack = 5;
 * @return {boolean}
 */
proto.cloud_api.DeviceReleaseOverride.prototype.getForceFullFlashpack = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.DeviceReleaseOverride} returns this
 */
proto.cloud_api.DeviceReleaseOverride.prototype.setForceFullFlashpack = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.DeviceReleaseOverrides.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DeviceReleaseOverrides.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DeviceReleaseOverrides.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DeviceReleaseOverrides} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeviceReleaseOverrides.toObject = function(includeInstance, msg) {
  var f, obj = {
releaseKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
deviceReleaseOverridesList: jspb.Message.toObjectList(msg.getDeviceReleaseOverridesList(),
    proto.cloud_api.DeviceReleaseOverride.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DeviceReleaseOverrides}
 */
proto.cloud_api.DeviceReleaseOverrides.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DeviceReleaseOverrides;
  return proto.cloud_api.DeviceReleaseOverrides.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DeviceReleaseOverrides} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DeviceReleaseOverrides}
 */
proto.cloud_api.DeviceReleaseOverrides.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 2:
      var value = new proto.cloud_api.DeviceReleaseOverride;
      reader.readMessage(value,proto.cloud_api.DeviceReleaseOverride.deserializeBinaryFromReader);
      msg.addDeviceReleaseOverrides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DeviceReleaseOverrides.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DeviceReleaseOverrides.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DeviceReleaseOverrides} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeviceReleaseOverrides.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceReleaseOverridesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cloud_api.DeviceReleaseOverride.serializeBinaryToWriter
    );
  }
};


/**
 * optional string release_key = 1;
 * @return {string}
 */
proto.cloud_api.DeviceReleaseOverrides.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeviceReleaseOverrides} returns this
 */
proto.cloud_api.DeviceReleaseOverrides.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DeviceReleaseOverride device_release_overrides = 2;
 * @return {!Array<!proto.cloud_api.DeviceReleaseOverride>}
 */
proto.cloud_api.DeviceReleaseOverrides.prototype.getDeviceReleaseOverridesList = function() {
  return /** @type{!Array<!proto.cloud_api.DeviceReleaseOverride>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.DeviceReleaseOverride, 2));
};


/**
 * @param {!Array<!proto.cloud_api.DeviceReleaseOverride>} value
 * @return {!proto.cloud_api.DeviceReleaseOverrides} returns this
*/
proto.cloud_api.DeviceReleaseOverrides.prototype.setDeviceReleaseOverridesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cloud_api.DeviceReleaseOverride=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.DeviceReleaseOverride}
 */
proto.cloud_api.DeviceReleaseOverrides.prototype.addDeviceReleaseOverrides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cloud_api.DeviceReleaseOverride, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.DeviceReleaseOverrides} returns this
 */
proto.cloud_api.DeviceReleaseOverrides.prototype.clearDeviceReleaseOverridesList = function() {
  return this.setDeviceReleaseOverridesList([]);
};


goog.object.extend(exports, proto.cloud_api);
