// source: pbtypes/vehicle/skills/actions/start_video/start_video.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_user_camera_awb_mode_pb = require('../../../../../pbtypes/gen/user_camera/awb_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_awb_mode_pb);
var pbtypes_gen_user_camera_exposure_mode_pb = require('../../../../../pbtypes/gen/user_camera/exposure_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_exposure_mode_pb);
var pbtypes_gen_user_camera_iso_mode_pb = require('../../../../../pbtypes/gen/user_camera/iso_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_iso_mode_pb);
var pbtypes_vehicle_skills_mission_camera_settings_pb = require('../../../../../pbtypes/vehicle/skills/mission_camera_settings_pb.js');
goog.object.extend(proto, pbtypes_vehicle_skills_mission_camera_settings_pb);
goog.exportSymbol('proto.skills.StartVideoActionArgs', null, global);
goog.exportSymbol('proto.skills.StartVideoActionStateData', null, global);
goog.exportSymbol('proto.skills.StartVideoResultData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.StartVideoActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.StartVideoActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.StartVideoActionArgs.displayName = 'proto.skills.StartVideoActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.StartVideoResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.StartVideoResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.StartVideoResultData.displayName = 'proto.skills.StartVideoResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.StartVideoActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.StartVideoActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.StartVideoActionStateData.displayName = 'proto.skills.StartVideoActionStateData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.StartVideoActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.StartVideoActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.StartVideoActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.StartVideoActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
isoMode: jspb.Message.getFieldWithDefault(msg, 1, 0),
exposureMode: jspb.Message.getFieldWithDefault(msg, 2, 0),
awbMode: jspb.Message.getFieldWithDefault(msg, 3, 0),
cameraSettings: (f = msg.getCameraSettings()) && pbtypes_vehicle_skills_mission_camera_settings_pb.CameraSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.StartVideoActionArgs}
 */
proto.skills.StartVideoActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.StartVideoActionArgs;
  return proto.skills.StartVideoActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.StartVideoActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.StartVideoActionArgs}
 */
proto.skills.StartVideoActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.user_camera.IsoMode.Enum} */ (reader.readEnum());
      msg.setIsoMode(value);
      break;
    case 2:
      var value = /** @type {!proto.user_camera.ExposureMode.Enum} */ (reader.readEnum());
      msg.setExposureMode(value);
      break;
    case 3:
      var value = /** @type {!proto.user_camera.AwbMode.Enum} */ (reader.readEnum());
      msg.setAwbMode(value);
      break;
    case 4:
      var value = new pbtypes_vehicle_skills_mission_camera_settings_pb.CameraSettings;
      reader.readMessage(value,pbtypes_vehicle_skills_mission_camera_settings_pb.CameraSettings.deserializeBinaryFromReader);
      msg.setCameraSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.StartVideoActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.StartVideoActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.StartVideoActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.StartVideoActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsoMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getExposureMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAwbMode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCameraSettings();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_vehicle_skills_mission_camera_settings_pb.CameraSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional user_camera.IsoMode.Enum iso_mode = 1;
 * @return {!proto.user_camera.IsoMode.Enum}
 */
proto.skills.StartVideoActionArgs.prototype.getIsoMode = function() {
  return /** @type {!proto.user_camera.IsoMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.user_camera.IsoMode.Enum} value
 * @return {!proto.skills.StartVideoActionArgs} returns this
 */
proto.skills.StartVideoActionArgs.prototype.setIsoMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional user_camera.ExposureMode.Enum exposure_mode = 2;
 * @return {!proto.user_camera.ExposureMode.Enum}
 */
proto.skills.StartVideoActionArgs.prototype.getExposureMode = function() {
  return /** @type {!proto.user_camera.ExposureMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.user_camera.ExposureMode.Enum} value
 * @return {!proto.skills.StartVideoActionArgs} returns this
 */
proto.skills.StartVideoActionArgs.prototype.setExposureMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional user_camera.AwbMode.Enum awb_mode = 3;
 * @return {!proto.user_camera.AwbMode.Enum}
 */
proto.skills.StartVideoActionArgs.prototype.getAwbMode = function() {
  return /** @type {!proto.user_camera.AwbMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.user_camera.AwbMode.Enum} value
 * @return {!proto.skills.StartVideoActionArgs} returns this
 */
proto.skills.StartVideoActionArgs.prototype.setAwbMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional CameraSettings camera_settings = 4;
 * @return {?proto.skills.CameraSettings}
 */
proto.skills.StartVideoActionArgs.prototype.getCameraSettings = function() {
  return /** @type{?proto.skills.CameraSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_skills_mission_camera_settings_pb.CameraSettings, 4));
};


/**
 * @param {?proto.skills.CameraSettings|undefined} value
 * @return {!proto.skills.StartVideoActionArgs} returns this
*/
proto.skills.StartVideoActionArgs.prototype.setCameraSettings = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.StartVideoActionArgs} returns this
 */
proto.skills.StartVideoActionArgs.prototype.clearCameraSettings = function() {
  return this.setCameraSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.StartVideoActionArgs.prototype.hasCameraSettings = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.StartVideoResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.StartVideoResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.StartVideoResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.StartVideoResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.StartVideoResultData}
 */
proto.skills.StartVideoResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.StartVideoResultData;
  return proto.skills.StartVideoResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.StartVideoResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.StartVideoResultData}
 */
proto.skills.StartVideoResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.StartVideoResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.StartVideoResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.StartVideoResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.StartVideoResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.StartVideoActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.StartVideoActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.StartVideoActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.StartVideoActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.StartVideoActionStateData}
 */
proto.skills.StartVideoActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.StartVideoActionStateData;
  return proto.skills.StartVideoActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.StartVideoActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.StartVideoActionStateData}
 */
proto.skills.StartVideoActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.StartVideoActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.StartVideoActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.StartVideoActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.StartVideoActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.skills);
