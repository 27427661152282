// source: pbtypes/tools/cloud_api/ucon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_tools_cloud_api_data_access_pb = require('../../../pbtypes/tools/cloud_api/data_access_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_data_access_pb);
var pbtypes_tools_cloud_api_enums_pb = require('../../../pbtypes/tools/cloud_api/enums_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_enums_pb);
goog.exportSymbol('proto.cloud_api.AvailableFile', null, global);
goog.exportSymbol('proto.cloud_api.AvailableFiles', null, global);
goog.exportSymbol('proto.cloud_api.DeliveredFiles', null, global);
goog.exportSymbol('proto.cloud_api.DeliveryUploadInfo', null, global);
goog.exportSymbol('proto.cloud_api.DeliveryUploadInfo.Preset', null, global);
goog.exportSymbol('proto.cloud_api.FileDeliveryCheckRequest', null, global);
goog.exportSymbol('proto.cloud_api.FileDeliveryCheckResponse', null, global);
goog.exportSymbol('proto.cloud_api.FileDeliveryInfo', null, global);
goog.exportSymbol('proto.cloud_api.FileDeliveryRequest', null, global);
goog.exportSymbol('proto.cloud_api.FileDeliveryResponse', null, global);
goog.exportSymbol('proto.cloud_api.LogProxyFileDeliveryRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.AvailableFiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.AvailableFiles.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.AvailableFiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.AvailableFiles.displayName = 'proto.cloud_api.AvailableFiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.AvailableFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.AvailableFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.AvailableFile.displayName = 'proto.cloud_api.AvailableFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DeliveredFiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.DeliveredFiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DeliveredFiles.displayName = 'proto.cloud_api.DeliveredFiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FileDeliveryInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FileDeliveryInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FileDeliveryInfo.displayName = 'proto.cloud_api.FileDeliveryInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FileDeliveryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FileDeliveryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FileDeliveryRequest.displayName = 'proto.cloud_api.FileDeliveryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FileDeliveryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FileDeliveryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FileDeliveryResponse.displayName = 'proto.cloud_api.FileDeliveryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.DeliveryUploadInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.DeliveryUploadInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.DeliveryUploadInfo.displayName = 'proto.cloud_api.DeliveryUploadInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FileDeliveryCheckRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.FileDeliveryCheckRequest.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.FileDeliveryCheckRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FileDeliveryCheckRequest.displayName = 'proto.cloud_api.FileDeliveryCheckRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FileDeliveryCheckResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.FileDeliveryCheckResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FileDeliveryCheckResponse.displayName = 'proto.cloud_api.FileDeliveryCheckResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.LogProxyFileDeliveryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.LogProxyFileDeliveryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.LogProxyFileDeliveryRequest.displayName = 'proto.cloud_api.LogProxyFileDeliveryRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.AvailableFiles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.AvailableFiles.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.AvailableFiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.AvailableFiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AvailableFiles.toObject = function(includeInstance, msg) {
  var f, obj = {
filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.cloud_api.AvailableFile.toObject, includeInstance),
totalAvailableFilesCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
totalAvailableFilesSize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.AvailableFiles}
 */
proto.cloud_api.AvailableFiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.AvailableFiles;
  return proto.cloud_api.AvailableFiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.AvailableFiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.AvailableFiles}
 */
proto.cloud_api.AvailableFiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.AvailableFile;
      reader.readMessage(value,proto.cloud_api.AvailableFile.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAvailableFilesCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalAvailableFilesSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.AvailableFiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.AvailableFiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.AvailableFiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AvailableFiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.AvailableFile.serializeBinaryToWriter
    );
  }
  f = message.getTotalAvailableFilesCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalAvailableFilesSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated AvailableFile files = 1;
 * @return {!Array<!proto.cloud_api.AvailableFile>}
 */
proto.cloud_api.AvailableFiles.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.cloud_api.AvailableFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.AvailableFile, 1));
};


/**
 * @param {!Array<!proto.cloud_api.AvailableFile>} value
 * @return {!proto.cloud_api.AvailableFiles} returns this
*/
proto.cloud_api.AvailableFiles.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.AvailableFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.AvailableFile}
 */
proto.cloud_api.AvailableFiles.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.AvailableFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.AvailableFiles} returns this
 */
proto.cloud_api.AvailableFiles.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};


/**
 * optional int32 total_available_files_count = 2;
 * @return {number}
 */
proto.cloud_api.AvailableFiles.prototype.getTotalAvailableFilesCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.AvailableFiles} returns this
 */
proto.cloud_api.AvailableFiles.prototype.setTotalAvailableFilesCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_available_files_size = 3;
 * @return {number}
 */
proto.cloud_api.AvailableFiles.prototype.getTotalAvailableFilesSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.AvailableFiles} returns this
 */
proto.cloud_api.AvailableFiles.prototype.setTotalAvailableFilesSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.AvailableFile.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.AvailableFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.AvailableFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AvailableFile.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
downloadPath: jspb.Message.getFieldWithDefault(msg, 2, ""),
downloadHeadersMap: (f = msg.getDownloadHeadersMap()) ? f.toObject(includeInstance, undefined) : [],
onDeviceFilepath: jspb.Message.getFieldWithDefault(msg, 8, ""),
deliveryInfo: (f = msg.getDeliveryInfo()) && proto.cloud_api.FileDeliveryInfo.toObject(includeInstance, f),
pendingSha1: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
priority: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.AvailableFile}
 */
proto.cloud_api.AvailableFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.AvailableFile;
  return proto.cloud_api.AvailableFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.AvailableFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.AvailableFile}
 */
proto.cloud_api.AvailableFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadPath(value);
      break;
    case 4:
      var value = msg.getDownloadHeadersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnDeviceFilepath(value);
      break;
    case 3:
      var value = new proto.cloud_api.FileDeliveryInfo;
      reader.readMessage(value,proto.cloud_api.FileDeliveryInfo.deserializeBinaryFromReader);
      msg.setDeliveryInfo(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPendingSha1(value);
      break;
    case 7:
      var value = /** @type {!proto.cloud_api.UconUploadPriority.PriorityEnum} */ (reader.readEnum());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.AvailableFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.AvailableFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.AvailableFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AvailableFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDownloadPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDownloadHeadersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getOnDeviceFilepath();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDeliveryInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cloud_api.FileDeliveryInfo.serializeBinaryToWriter
    );
  }
  f = message.getPendingSha1();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.cloud_api.AvailableFile.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AvailableFile} returns this
 */
proto.cloud_api.AvailableFile.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string download_path = 2;
 * @return {string}
 */
proto.cloud_api.AvailableFile.prototype.getDownloadPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AvailableFile} returns this
 */
proto.cloud_api.AvailableFile.prototype.setDownloadPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> download_headers = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.cloud_api.AvailableFile.prototype.getDownloadHeadersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_api.AvailableFile} returns this
 */
proto.cloud_api.AvailableFile.prototype.clearDownloadHeadersMap = function() {
  this.getDownloadHeadersMap().clear();
  return this;
};


/**
 * optional string on_device_filepath = 8;
 * @return {string}
 */
proto.cloud_api.AvailableFile.prototype.getOnDeviceFilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AvailableFile} returns this
 */
proto.cloud_api.AvailableFile.prototype.setOnDeviceFilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional FileDeliveryInfo delivery_info = 3;
 * @return {?proto.cloud_api.FileDeliveryInfo}
 */
proto.cloud_api.AvailableFile.prototype.getDeliveryInfo = function() {
  return /** @type{?proto.cloud_api.FileDeliveryInfo} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.FileDeliveryInfo, 3));
};


/**
 * @param {?proto.cloud_api.FileDeliveryInfo|undefined} value
 * @return {!proto.cloud_api.AvailableFile} returns this
*/
proto.cloud_api.AvailableFile.prototype.setDeliveryInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.AvailableFile} returns this
 */
proto.cloud_api.AvailableFile.prototype.clearDeliveryInfo = function() {
  return this.setDeliveryInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.AvailableFile.prototype.hasDeliveryInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool pending_sha1 = 6;
 * @return {boolean}
 */
proto.cloud_api.AvailableFile.prototype.getPendingSha1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.AvailableFile} returns this
 */
proto.cloud_api.AvailableFile.prototype.setPendingSha1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional UconUploadPriority.PriorityEnum priority = 7;
 * @return {!proto.cloud_api.UconUploadPriority.PriorityEnum}
 */
proto.cloud_api.AvailableFile.prototype.getPriority = function() {
  return /** @type {!proto.cloud_api.UconUploadPriority.PriorityEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.cloud_api.UconUploadPriority.PriorityEnum} value
 * @return {!proto.cloud_api.AvailableFile} returns this
 */
proto.cloud_api.AvailableFile.prototype.setPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DeliveredFiles.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DeliveredFiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DeliveredFiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeliveredFiles.toObject = function(includeInstance, msg) {
  var f, obj = {
deliveryInfoByUuidMap: (f = msg.getDeliveryInfoByUuidMap()) ? f.toObject(includeInstance, proto.cloud_api.FileDeliveryInfo.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DeliveredFiles}
 */
proto.cloud_api.DeliveredFiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DeliveredFiles;
  return proto.cloud_api.DeliveredFiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DeliveredFiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DeliveredFiles}
 */
proto.cloud_api.DeliveredFiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getDeliveryInfoByUuidMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.cloud_api.FileDeliveryInfo.deserializeBinaryFromReader, "", new proto.cloud_api.FileDeliveryInfo());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DeliveredFiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DeliveredFiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DeliveredFiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeliveredFiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeliveryInfoByUuidMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.cloud_api.FileDeliveryInfo.serializeBinaryToWriter);
  }
};


/**
 * map<string, FileDeliveryInfo> delivery_info_by_uuid = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>}
 */
proto.cloud_api.DeliveredFiles.prototype.getDeliveryInfoByUuidMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.cloud_api.FileDeliveryInfo>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.cloud_api.FileDeliveryInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_api.DeliveredFiles} returns this
 */
proto.cloud_api.DeliveredFiles.prototype.clearDeliveryInfoByUuidMap = function() {
  this.getDeliveryInfoByUuidMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FileDeliveryInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FileDeliveryInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FileDeliveryInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileDeliveryInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
onlyUseDeviceIdForSignature: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
internalUserSkipSignatureVerify: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
flightId: jspb.Message.getFieldWithDefault(msg, 2, ""),
scanId: jspb.Message.getFieldWithDefault(msg, 11, ""),
filename: jspb.Message.getFieldWithDefault(msg, 3, ""),
kind: jspb.Message.getFieldWithDefault(msg, 4, ""),
size: jspb.Message.getFieldWithDefault(msg, 5, 0),
sha1: jspb.Message.getFieldWithDefault(msg, 6, ""),
signature: jspb.Message.getFieldWithDefault(msg, 7, ""),
fileUsage: jspb.Message.getFieldWithDefault(msg, 9, 0),
vehicleType: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FileDeliveryInfo}
 */
proto.cloud_api.FileDeliveryInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FileDeliveryInfo;
  return proto.cloud_api.FileDeliveryInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FileDeliveryInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FileDeliveryInfo}
 */
proto.cloud_api.FileDeliveryInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyUseDeviceIdForSignature(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInternalUserSkipSignatureVerify(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSha1(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    case 9:
      var value = /** @type {!proto.cloud_api.FileUsage.FileUsageReason} */ (reader.readEnum());
      msg.setFileUsage(value);
      break;
    case 12:
      var value = /** @type {!proto.cloud_api.VehicleType.VehicleTypeEnum} */ (reader.readEnum());
      msg.setVehicleType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FileDeliveryInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FileDeliveryInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FileDeliveryInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileDeliveryInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOnlyUseDeviceIdForSignature();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getInternalUserSkipSignatureVerify();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScanId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSha1();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFileUsage();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getVehicleType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool only_use_device_id_for_signature = 8;
 * @return {boolean}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getOnlyUseDeviceIdForSignature = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setOnlyUseDeviceIdForSignature = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool internal_user_skip_signature_verify = 10;
 * @return {boolean}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getInternalUserSkipSignatureVerify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setInternalUserSkipSignatureVerify = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scan_id = 11;
 * @return {string}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getScanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setScanId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string filename = 3;
 * @return {string}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string kind = 4;
 * @return {string}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 size = 5;
 * @return {number}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string sha1 = 6;
 * @return {string}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getSha1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setSha1 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string signature = 7;
 * @return {string}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional FileUsage.FileUsageReason file_usage = 9;
 * @return {!proto.cloud_api.FileUsage.FileUsageReason}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getFileUsage = function() {
  return /** @type {!proto.cloud_api.FileUsage.FileUsageReason} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.cloud_api.FileUsage.FileUsageReason} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setFileUsage = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional VehicleType.VehicleTypeEnum vehicle_type = 12;
 * @return {!proto.cloud_api.VehicleType.VehicleTypeEnum}
 */
proto.cloud_api.FileDeliveryInfo.prototype.getVehicleType = function() {
  return /** @type {!proto.cloud_api.VehicleType.VehicleTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.cloud_api.VehicleType.VehicleTypeEnum} value
 * @return {!proto.cloud_api.FileDeliveryInfo} returns this
 */
proto.cloud_api.FileDeliveryInfo.prototype.setVehicleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FileDeliveryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FileDeliveryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FileDeliveryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileDeliveryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deliveryInfo: (f = msg.getDeliveryInfo()) && proto.cloud_api.FileDeliveryInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FileDeliveryRequest}
 */
proto.cloud_api.FileDeliveryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FileDeliveryRequest;
  return proto.cloud_api.FileDeliveryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FileDeliveryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FileDeliveryRequest}
 */
proto.cloud_api.FileDeliveryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.FileDeliveryInfo;
      reader.readMessage(value,proto.cloud_api.FileDeliveryInfo.deserializeBinaryFromReader);
      msg.setDeliveryInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FileDeliveryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FileDeliveryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FileDeliveryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileDeliveryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeliveryInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.FileDeliveryInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional FileDeliveryInfo delivery_info = 1;
 * @return {?proto.cloud_api.FileDeliveryInfo}
 */
proto.cloud_api.FileDeliveryRequest.prototype.getDeliveryInfo = function() {
  return /** @type{?proto.cloud_api.FileDeliveryInfo} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.FileDeliveryInfo, 1));
};


/**
 * @param {?proto.cloud_api.FileDeliveryInfo|undefined} value
 * @return {!proto.cloud_api.FileDeliveryRequest} returns this
*/
proto.cloud_api.FileDeliveryRequest.prototype.setDeliveryInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.FileDeliveryRequest} returns this
 */
proto.cloud_api.FileDeliveryRequest.prototype.clearDeliveryInfo = function() {
  return this.setDeliveryInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.FileDeliveryRequest.prototype.hasDeliveryInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FileDeliveryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FileDeliveryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FileDeliveryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileDeliveryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
uploadInfo: (f = msg.getUploadInfo()) && proto.cloud_api.DeliveryUploadInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FileDeliveryResponse}
 */
proto.cloud_api.FileDeliveryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FileDeliveryResponse;
  return proto.cloud_api.FileDeliveryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FileDeliveryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FileDeliveryResponse}
 */
proto.cloud_api.FileDeliveryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.DeliveryUploadInfo;
      reader.readMessage(value,proto.cloud_api.DeliveryUploadInfo.deserializeBinaryFromReader);
      msg.setUploadInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FileDeliveryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FileDeliveryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FileDeliveryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileDeliveryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.DeliveryUploadInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeliveryUploadInfo upload_info = 1;
 * @return {?proto.cloud_api.DeliveryUploadInfo}
 */
proto.cloud_api.FileDeliveryResponse.prototype.getUploadInfo = function() {
  return /** @type{?proto.cloud_api.DeliveryUploadInfo} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.DeliveryUploadInfo, 1));
};


/**
 * @param {?proto.cloud_api.DeliveryUploadInfo|undefined} value
 * @return {!proto.cloud_api.FileDeliveryResponse} returns this
*/
proto.cloud_api.FileDeliveryResponse.prototype.setUploadInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.FileDeliveryResponse} returns this
 */
proto.cloud_api.FileDeliveryResponse.prototype.clearUploadInfo = function() {
  return this.setUploadInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.FileDeliveryResponse.prototype.hasUploadInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.DeliveryUploadInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.DeliveryUploadInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.DeliveryUploadInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeliveryUploadInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
uploadUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
bucket: jspb.Message.getFieldWithDefault(msg, 5, ""),
key: jspb.Message.getFieldWithDefault(msg, 6, ""),
preset: jspb.Message.getFieldWithDefault(msg, 14, 0),
headersMap: (f = msg.getHeadersMap()) ? f.toObject(includeInstance, undefined) : [],
authRequired: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
credentials: (f = msg.getCredentials()) && pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.DeliveryUploadInfo}
 */
proto.cloud_api.DeliveryUploadInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.DeliveryUploadInfo;
  return proto.cloud_api.DeliveryUploadInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.DeliveryUploadInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.DeliveryUploadInfo}
 */
proto.cloud_api.DeliveryUploadInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucket(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 14:
      var value = /** @type {!proto.cloud_api.DeliveryUploadInfo.Preset} */ (reader.readEnum());
      msg.setPreset(value);
      break;
    case 15:
      var value = msg.getHeadersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuthRequired(value);
      break;
    case 17:
      var value = new pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials;
      reader.readMessage(value,pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.DeliveryUploadInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.DeliveryUploadInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.DeliveryUploadInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.DeliveryUploadInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUploadUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBucket();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPreset();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getHeadersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(15, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getAuthRequired();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.cloud_api.DeliveryUploadInfo.Preset = {
  S3: 0,
  CLOUD: 1
};

/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.cloud_api.DeliveryUploadInfo.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeliveryUploadInfo} returns this
 */
proto.cloud_api.DeliveryUploadInfo.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string upload_url = 4;
 * @return {string}
 */
proto.cloud_api.DeliveryUploadInfo.prototype.getUploadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeliveryUploadInfo} returns this
 */
proto.cloud_api.DeliveryUploadInfo.prototype.setUploadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bucket = 5;
 * @return {string}
 */
proto.cloud_api.DeliveryUploadInfo.prototype.getBucket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeliveryUploadInfo} returns this
 */
proto.cloud_api.DeliveryUploadInfo.prototype.setBucket = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string key = 6;
 * @return {string}
 */
proto.cloud_api.DeliveryUploadInfo.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.DeliveryUploadInfo} returns this
 */
proto.cloud_api.DeliveryUploadInfo.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Preset preset = 14;
 * @return {!proto.cloud_api.DeliveryUploadInfo.Preset}
 */
proto.cloud_api.DeliveryUploadInfo.prototype.getPreset = function() {
  return /** @type {!proto.cloud_api.DeliveryUploadInfo.Preset} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.cloud_api.DeliveryUploadInfo.Preset} value
 * @return {!proto.cloud_api.DeliveryUploadInfo} returns this
 */
proto.cloud_api.DeliveryUploadInfo.prototype.setPreset = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * map<string, string> headers = 15;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.cloud_api.DeliveryUploadInfo.prototype.getHeadersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 15, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_api.DeliveryUploadInfo} returns this
 */
proto.cloud_api.DeliveryUploadInfo.prototype.clearHeadersMap = function() {
  this.getHeadersMap().clear();
  return this;
};


/**
 * optional bool auth_required = 16;
 * @return {boolean}
 */
proto.cloud_api.DeliveryUploadInfo.prototype.getAuthRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.DeliveryUploadInfo} returns this
 */
proto.cloud_api.DeliveryUploadInfo.prototype.setAuthRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional StsTemporaryCredentials credentials = 17;
 * @return {?proto.cloud_api.StsTemporaryCredentials}
 */
proto.cloud_api.DeliveryUploadInfo.prototype.getCredentials = function() {
  return /** @type{?proto.cloud_api.StsTemporaryCredentials} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_data_access_pb.StsTemporaryCredentials, 17));
};


/**
 * @param {?proto.cloud_api.StsTemporaryCredentials|undefined} value
 * @return {!proto.cloud_api.DeliveryUploadInfo} returns this
*/
proto.cloud_api.DeliveryUploadInfo.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.DeliveryUploadInfo} returns this
 */
proto.cloud_api.DeliveryUploadInfo.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.DeliveryUploadInfo.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 17) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.FileDeliveryCheckRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FileDeliveryCheckRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FileDeliveryCheckRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FileDeliveryCheckRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileDeliveryCheckRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
uuidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FileDeliveryCheckRequest}
 */
proto.cloud_api.FileDeliveryCheckRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FileDeliveryCheckRequest;
  return proto.cloud_api.FileDeliveryCheckRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FileDeliveryCheckRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FileDeliveryCheckRequest}
 */
proto.cloud_api.FileDeliveryCheckRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUuids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FileDeliveryCheckRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FileDeliveryCheckRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FileDeliveryCheckRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileDeliveryCheckRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string uuids = 1;
 * @return {!Array<string>}
 */
proto.cloud_api.FileDeliveryCheckRequest.prototype.getUuidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.FileDeliveryCheckRequest} returns this
 */
proto.cloud_api.FileDeliveryCheckRequest.prototype.setUuidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.FileDeliveryCheckRequest} returns this
 */
proto.cloud_api.FileDeliveryCheckRequest.prototype.addUuids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.FileDeliveryCheckRequest} returns this
 */
proto.cloud_api.FileDeliveryCheckRequest.prototype.clearUuidsList = function() {
  return this.setUuidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FileDeliveryCheckResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FileDeliveryCheckResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FileDeliveryCheckResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileDeliveryCheckResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
deliveredByUuidMap: (f = msg.getDeliveredByUuidMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FileDeliveryCheckResponse}
 */
proto.cloud_api.FileDeliveryCheckResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FileDeliveryCheckResponse;
  return proto.cloud_api.FileDeliveryCheckResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FileDeliveryCheckResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FileDeliveryCheckResponse}
 */
proto.cloud_api.FileDeliveryCheckResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getDeliveredByUuidMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FileDeliveryCheckResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FileDeliveryCheckResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FileDeliveryCheckResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FileDeliveryCheckResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeliveredByUuidMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
};


/**
 * map<string, bool> delivered_by_uuid = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.cloud_api.FileDeliveryCheckResponse.prototype.getDeliveredByUuidMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cloud_api.FileDeliveryCheckResponse} returns this
 */
proto.cloud_api.FileDeliveryCheckResponse.prototype.clearDeliveredByUuidMap = function() {
  this.getDeliveredByUuidMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.LogProxyFileDeliveryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.LogProxyFileDeliveryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.LogProxyFileDeliveryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LogProxyFileDeliveryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
deliveryInfo: (f = msg.getDeliveryInfo()) && proto.cloud_api.FileDeliveryInfo.toObject(includeInstance, f),
bucket: jspb.Message.getFieldWithDefault(msg, 2, ""),
key: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.LogProxyFileDeliveryRequest}
 */
proto.cloud_api.LogProxyFileDeliveryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.LogProxyFileDeliveryRequest;
  return proto.cloud_api.LogProxyFileDeliveryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.LogProxyFileDeliveryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.LogProxyFileDeliveryRequest}
 */
proto.cloud_api.LogProxyFileDeliveryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.FileDeliveryInfo;
      reader.readMessage(value,proto.cloud_api.FileDeliveryInfo.deserializeBinaryFromReader);
      msg.setDeliveryInfo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucket(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.LogProxyFileDeliveryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.LogProxyFileDeliveryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.LogProxyFileDeliveryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LogProxyFileDeliveryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeliveryInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.FileDeliveryInfo.serializeBinaryToWriter
    );
  }
  f = message.getBucket();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional FileDeliveryInfo delivery_info = 1;
 * @return {?proto.cloud_api.FileDeliveryInfo}
 */
proto.cloud_api.LogProxyFileDeliveryRequest.prototype.getDeliveryInfo = function() {
  return /** @type{?proto.cloud_api.FileDeliveryInfo} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.FileDeliveryInfo, 1));
};


/**
 * @param {?proto.cloud_api.FileDeliveryInfo|undefined} value
 * @return {!proto.cloud_api.LogProxyFileDeliveryRequest} returns this
*/
proto.cloud_api.LogProxyFileDeliveryRequest.prototype.setDeliveryInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.LogProxyFileDeliveryRequest} returns this
 */
proto.cloud_api.LogProxyFileDeliveryRequest.prototype.clearDeliveryInfo = function() {
  return this.setDeliveryInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.LogProxyFileDeliveryRequest.prototype.hasDeliveryInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string bucket = 2;
 * @return {string}
 */
proto.cloud_api.LogProxyFileDeliveryRequest.prototype.getBucket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LogProxyFileDeliveryRequest} returns this
 */
proto.cloud_api.LogProxyFileDeliveryRequest.prototype.setBucket = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string key = 3;
 * @return {string}
 */
proto.cloud_api.LogProxyFileDeliveryRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LogProxyFileDeliveryRequest} returns this
 */
proto.cloud_api.LogProxyFileDeliveryRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.cloud_api);
