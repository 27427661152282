// source: pbtypes/gen/flir_boson_driver/agc_settings.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_flir_boson_driver_agc_mode_pb = require('../../../pbtypes/gen/flir_boson_driver/agc_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_flir_boson_driver_agc_mode_pb);
goog.exportSymbol('proto.flir_boson_driver.AgcSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flir_boson_driver.AgcSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flir_boson_driver.AgcSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flir_boson_driver.AgcSettings.displayName = 'proto.flir_boson_driver.AgcSettings';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flir_boson_driver.AgcSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.flir_boson_driver.AgcSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flir_boson_driver.AgcSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flir_boson_driver.AgcSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
plateauValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
linearPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
tailRejection: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
maxGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
dampingFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
gamma: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
detailGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
detailHeadroom: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
smoothingFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
adeAlphaNoise: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
adeBetaLf: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
adeBetaHf: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
adeBetaMix: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
adeLutMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
adeSigmaShift: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
adeHpBendingAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
adeLowLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
adeHighLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
adeHeadRoom: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
adeFootRoom: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
adeGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
adeLinearMix: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
adePaletteLowClipLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
adePaletteHighClipLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
mode: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flir_boson_driver.AgcSettings}
 */
proto.flir_boson_driver.AgcSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flir_boson_driver.AgcSettings;
  return proto.flir_boson_driver.AgcSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flir_boson_driver.AgcSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flir_boson_driver.AgcSettings}
 */
proto.flir_boson_driver.AgcSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPlateauValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLinearPercent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTailRejection(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxGain(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDampingFactor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGamma(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDetailGain(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDetailHeadroom(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSmoothingFactor(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeAlphaNoise(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeBetaLf(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeBetaHf(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeBetaMix(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeLutMax(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeSigmaShift(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeHpBendingAmount(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeLowLimit(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeHighLimit(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeHeadRoom(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeFootRoom(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeGain(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdeLinearMix(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdePaletteLowClipLimit(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAdePaletteHighClipLimit(value);
      break;
    case 25:
      var value = /** @type {!proto.flir_boson_driver.AgcMode.Enum} */ (reader.readEnum());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flir_boson_driver.AgcSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flir_boson_driver.AgcSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flir_boson_driver.AgcSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flir_boson_driver.AgcSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlateauValue();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLinearPercent();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getTailRejection();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getMaxGain();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDampingFactor();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getGamma();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getDetailGain();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getDetailHeadroom();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getSmoothingFactor();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getAdeAlphaNoise();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getAdeBetaLf();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getAdeBetaHf();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getAdeBetaMix();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getAdeLutMax();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getAdeSigmaShift();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getAdeHpBendingAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getAdeLowLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getAdeHighLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getAdeHeadRoom();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getAdeFootRoom();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getAdeGain();
  if (f !== 0.0) {
    writer.writeFloat(
      21,
      f
    );
  }
  f = message.getAdeLinearMix();
  if (f !== 0.0) {
    writer.writeFloat(
      22,
      f
    );
  }
  f = message.getAdePaletteLowClipLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      23,
      f
    );
  }
  f = message.getAdePaletteHighClipLimit();
  if (f !== 0.0) {
    writer.writeFloat(
      24,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
};


/**
 * optional float plateau_value = 1;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getPlateauValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setPlateauValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float linear_percent = 2;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getLinearPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setLinearPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float tail_rejection = 3;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getTailRejection = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setTailRejection = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float max_gain = 4;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getMaxGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setMaxGain = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float damping_factor = 5;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getDampingFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setDampingFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float gamma = 6;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getGamma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setGamma = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float detail_gain = 7;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getDetailGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setDetailGain = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float detail_headroom = 8;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getDetailHeadroom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setDetailHeadroom = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float smoothing_factor = 9;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getSmoothingFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setSmoothingFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float ade_alpha_noise = 10;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeAlphaNoise = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeAlphaNoise = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float ade_beta_lf = 11;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeBetaLf = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeBetaLf = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float ade_beta_hf = 12;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeBetaHf = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeBetaHf = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float ade_beta_mix = 13;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeBetaMix = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeBetaMix = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float ade_lut_max = 14;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeLutMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeLutMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float ade_sigma_shift = 15;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeSigmaShift = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeSigmaShift = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float ade_hp_bending_amount = 16;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeHpBendingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeHpBendingAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float ade_low_limit = 17;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeLowLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeLowLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float ade_high_limit = 18;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeHighLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeHighLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float ade_head_room = 19;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeHeadRoom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeHeadRoom = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float ade_foot_room = 20;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeFootRoom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeFootRoom = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional float ade_gain = 21;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeGain = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional float ade_linear_mix = 22;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdeLinearMix = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdeLinearMix = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional float ade_palette_low_clip_limit = 23;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdePaletteLowClipLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdePaletteLowClipLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional float ade_palette_high_clip_limit = 24;
 * @return {number}
 */
proto.flir_boson_driver.AgcSettings.prototype.getAdePaletteHighClipLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setAdePaletteHighClipLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional AgcMode.Enum mode = 25;
 * @return {!proto.flir_boson_driver.AgcMode.Enum}
 */
proto.flir_boson_driver.AgcSettings.prototype.getMode = function() {
  return /** @type {!proto.flir_boson_driver.AgcMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.flir_boson_driver.AgcMode.Enum} value
 * @return {!proto.flir_boson_driver.AgcSettings} returns this
 */
proto.flir_boson_driver.AgcSettings.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


goog.object.extend(exports, proto.flir_boson_driver);
