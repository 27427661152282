// source: pbtypes/gen/structure/structure.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_structure_category_pb = require('../../../pbtypes/gen/structure/category_pb.js');
goog.object.extend(proto, pbtypes_gen_structure_category_pb);
var pbtypes_gen_structure_object_pb = require('../../../pbtypes/gen/structure/object_pb.js');
goog.object.extend(proto, pbtypes_gen_structure_object_pb);
var pbtypes_gen_uuid_util_uuid_pb = require('../../../pbtypes/gen/uuid_util/uuid_pb.js');
goog.object.extend(proto, pbtypes_gen_uuid_util_uuid_pb);
goog.exportSymbol('proto.structure.Structure', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.structure.Structure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.structure.Structure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.structure.Structure.displayName = 'proto.structure.Structure';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.structure.Structure.prototype.toObject = function(opt_includeInstance) {
  return proto.structure.Structure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.structure.Structure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.structure.Structure.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
uuid: (f = msg.getUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
name: jspb.Message.getFieldWithDefault(msg, 3, ""),
siteId: (f = msg.getSiteId()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
category: jspb.Message.getFieldWithDefault(msg, 5, 0),
description: jspb.Message.getFieldWithDefault(msg, 6, ""),
object: (f = msg.getObject()) && pbtypes_gen_structure_object_pb.Object.toObject(includeInstance, f),
metadata: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.structure.Structure}
 */
proto.structure.Structure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.structure.Structure;
  return proto.structure.Structure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.structure.Structure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.structure.Structure}
 */
proto.structure.Structure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 5:
      var value = /** @type {!proto.structure.Category.Enum} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = new pbtypes_gen_structure_object_pb.Object;
      reader.readMessage(value,pbtypes_gen_structure_object_pb.Object.deserializeBinaryFromReader);
      msg.setObject(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.structure.Structure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.structure.Structure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.structure.Structure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.structure.Structure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getObject();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pbtypes_gen_structure_object_pb.Object.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional sint64 utime = 1;
 * @return {number}
 */
proto.structure.Structure.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.structure.Structure} returns this
 */
proto.structure.Structure.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.structure.Structure.prototype.getUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.structure.Structure} returns this
*/
proto.structure.Structure.prototype.setUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.structure.Structure} returns this
 */
proto.structure.Structure.prototype.clearUuid = function() {
  return this.setUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.structure.Structure.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.structure.Structure.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.structure.Structure} returns this
 */
proto.structure.Structure.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uuid_util.Uuid site_id = 4;
 * @return {?proto.uuid_util.Uuid}
 */
proto.structure.Structure.prototype.getSiteId = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 4));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.structure.Structure} returns this
*/
proto.structure.Structure.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.structure.Structure} returns this
 */
proto.structure.Structure.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.structure.Structure.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Category.Enum category = 5;
 * @return {!proto.structure.Category.Enum}
 */
proto.structure.Structure.prototype.getCategory = function() {
  return /** @type {!proto.structure.Category.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.structure.Category.Enum} value
 * @return {!proto.structure.Structure} returns this
 */
proto.structure.Structure.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.structure.Structure.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.structure.Structure} returns this
 */
proto.structure.Structure.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Object object = 7;
 * @return {?proto.structure.Object}
 */
proto.structure.Structure.prototype.getObject = function() {
  return /** @type{?proto.structure.Object} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_structure_object_pb.Object, 7));
};


/**
 * @param {?proto.structure.Object|undefined} value
 * @return {!proto.structure.Structure} returns this
*/
proto.structure.Structure.prototype.setObject = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.structure.Structure} returns this
 */
proto.structure.Structure.prototype.clearObject = function() {
  return this.setObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.structure.Structure.prototype.hasObject = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string metadata = 8;
 * @return {string}
 */
proto.structure.Structure.prototype.getMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.structure.Structure} returns this
 */
proto.structure.Structure.prototype.setMetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


goog.object.extend(exports, proto.structure);
