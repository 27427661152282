// source: pbtypes/gen/scan_planner/scan_waypoint.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_eigen_lcm_Vector3f_pb = require('../../../pbtypes/gen/eigen_lcm/Vector3f_pb.js');
goog.object.extend(proto, pbtypes_gen_eigen_lcm_Vector3f_pb);
var pbtypes_gen_scan_planner_plan_point_pb = require('../../../pbtypes/gen/scan_planner/plan_point_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_plan_point_pb);
var pbtypes_gen_scan_planner_scan_waypoint_type_pb = require('../../../pbtypes/gen/scan_planner/scan_waypoint_type_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_scan_waypoint_type_pb);
var pbtypes_gen_scan_planner_trajectory_motion_info_pb = require('../../../pbtypes/gen/scan_planner/trajectory_motion_info_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_trajectory_motion_info_pb);
var pbtypes_gen_scan_planner_vertical_viewpoint_data_pb = require('../../../pbtypes/gen/scan_planner/vertical_viewpoint_data_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_vertical_viewpoint_data_pb);
var pbtypes_gen_scan_planner_wide_baseline_range_info_pb = require('../../../pbtypes/gen/scan_planner/wide_baseline_range_info_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_wide_baseline_range_info_pb);
var pbtypes_gen_uuid_util_uuid_pb = require('../../../pbtypes/gen/uuid_util/uuid_pb.js');
goog.object.extend(proto, pbtypes_gen_uuid_util_uuid_pb);
goog.exportSymbol('proto.scan_planner.ScanWaypoint', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scan_planner.ScanWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scan_planner.ScanWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scan_planner.ScanWaypoint.displayName = 'proto.scan_planner.ScanWaypoint';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scan_planner.ScanWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.scan_planner.ScanWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scan_planner.ScanWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_planner.ScanWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
globalMapTCamera: (f = msg.getGlobalMapTCamera()) && pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.toObject(includeInstance, f),
lookAtPointNormal: (f = msg.getLookAtPointNormal()) && pbtypes_gen_scan_planner_plan_point_pb.PlanPoint.toObject(includeInstance, f),
verticalViewpointData: (f = msg.getVerticalViewpointData()) && pbtypes_gen_scan_planner_vertical_viewpoint_data_pb.VerticalViewpointData.toObject(includeInstance, f),
type: jspb.Message.getFieldWithDefault(msg, 4, 0),
trajInfo: (f = msg.getTrajInfo()) && pbtypes_gen_scan_planner_trajectory_motion_info_pb.TrajectoryMotionInfo.toObject(includeInstance, f),
wideBaselineRangeInfo: (f = msg.getWideBaselineRangeInfo()) && pbtypes_gen_scan_planner_wide_baseline_range_info_pb.WideBaselineRangeInfo.toObject(includeInstance, f),
uuid: (f = msg.getUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scan_planner.ScanWaypoint}
 */
proto.scan_planner.ScanWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scan_planner.ScanWaypoint;
  return proto.scan_planner.ScanWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scan_planner.ScanWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scan_planner.ScanWaypoint}
 */
proto.scan_planner.ScanWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.deserializeBinaryFromReader);
      msg.setGlobalMapTCamera(value);
      break;
    case 2:
      var value = new pbtypes_gen_scan_planner_plan_point_pb.PlanPoint;
      reader.readMessage(value,pbtypes_gen_scan_planner_plan_point_pb.PlanPoint.deserializeBinaryFromReader);
      msg.setLookAtPointNormal(value);
      break;
    case 3:
      var value = new pbtypes_gen_scan_planner_vertical_viewpoint_data_pb.VerticalViewpointData;
      reader.readMessage(value,pbtypes_gen_scan_planner_vertical_viewpoint_data_pb.VerticalViewpointData.deserializeBinaryFromReader);
      msg.setVerticalViewpointData(value);
      break;
    case 4:
      var value = /** @type {!proto.scan_planner.ScanWaypointType.Enum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = new pbtypes_gen_scan_planner_trajectory_motion_info_pb.TrajectoryMotionInfo;
      reader.readMessage(value,pbtypes_gen_scan_planner_trajectory_motion_info_pb.TrajectoryMotionInfo.deserializeBinaryFromReader);
      msg.setTrajInfo(value);
      break;
    case 7:
      var value = new pbtypes_gen_scan_planner_wide_baseline_range_info_pb.WideBaselineRangeInfo;
      reader.readMessage(value,pbtypes_gen_scan_planner_wide_baseline_range_info_pb.WideBaselineRangeInfo.deserializeBinaryFromReader);
      msg.setWideBaselineRangeInfo(value);
      break;
    case 8:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scan_planner.ScanWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scan_planner.ScanWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scan_planner.ScanWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_planner.ScanWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGlobalMapTCamera();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb.serializeBinaryToWriter
    );
  }
  f = message.getLookAtPointNormal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_scan_planner_plan_point_pb.PlanPoint.serializeBinaryToWriter
    );
  }
  f = message.getVerticalViewpointData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_scan_planner_vertical_viewpoint_data_pb.VerticalViewpointData.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTrajInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pbtypes_gen_scan_planner_trajectory_motion_info_pb.TrajectoryMotionInfo.serializeBinaryToWriter
    );
  }
  f = message.getWideBaselineRangeInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pbtypes_gen_scan_planner_wide_baseline_range_info_pb.WideBaselineRangeInfo.serializeBinaryToWriter
    );
  }
  f = message.getUuid();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
};


/**
 * optional eigen_lcm.Vector3fPb global_map_t_camera = 1;
 * @return {?proto.eigen_lcm.Vector3fPb}
 */
proto.scan_planner.ScanWaypoint.prototype.getGlobalMapTCamera = function() {
  return /** @type{?proto.eigen_lcm.Vector3fPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3f_pb.Vector3fPb, 1));
};


/**
 * @param {?proto.eigen_lcm.Vector3fPb|undefined} value
 * @return {!proto.scan_planner.ScanWaypoint} returns this
*/
proto.scan_planner.ScanWaypoint.prototype.setGlobalMapTCamera = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ScanWaypoint} returns this
 */
proto.scan_planner.ScanWaypoint.prototype.clearGlobalMapTCamera = function() {
  return this.setGlobalMapTCamera(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ScanWaypoint.prototype.hasGlobalMapTCamera = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PlanPoint look_at_point_normal = 2;
 * @return {?proto.scan_planner.PlanPoint}
 */
proto.scan_planner.ScanWaypoint.prototype.getLookAtPointNormal = function() {
  return /** @type{?proto.scan_planner.PlanPoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_plan_point_pb.PlanPoint, 2));
};


/**
 * @param {?proto.scan_planner.PlanPoint|undefined} value
 * @return {!proto.scan_planner.ScanWaypoint} returns this
*/
proto.scan_planner.ScanWaypoint.prototype.setLookAtPointNormal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ScanWaypoint} returns this
 */
proto.scan_planner.ScanWaypoint.prototype.clearLookAtPointNormal = function() {
  return this.setLookAtPointNormal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ScanWaypoint.prototype.hasLookAtPointNormal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional VerticalViewpointData vertical_viewpoint_data = 3;
 * @return {?proto.scan_planner.VerticalViewpointData}
 */
proto.scan_planner.ScanWaypoint.prototype.getVerticalViewpointData = function() {
  return /** @type{?proto.scan_planner.VerticalViewpointData} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_vertical_viewpoint_data_pb.VerticalViewpointData, 3));
};


/**
 * @param {?proto.scan_planner.VerticalViewpointData|undefined} value
 * @return {!proto.scan_planner.ScanWaypoint} returns this
*/
proto.scan_planner.ScanWaypoint.prototype.setVerticalViewpointData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ScanWaypoint} returns this
 */
proto.scan_planner.ScanWaypoint.prototype.clearVerticalViewpointData = function() {
  return this.setVerticalViewpointData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ScanWaypoint.prototype.hasVerticalViewpointData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ScanWaypointType.Enum type = 4;
 * @return {!proto.scan_planner.ScanWaypointType.Enum}
 */
proto.scan_planner.ScanWaypoint.prototype.getType = function() {
  return /** @type {!proto.scan_planner.ScanWaypointType.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.scan_planner.ScanWaypointType.Enum} value
 * @return {!proto.scan_planner.ScanWaypoint} returns this
 */
proto.scan_planner.ScanWaypoint.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional TrajectoryMotionInfo traj_info = 6;
 * @return {?proto.scan_planner.TrajectoryMotionInfo}
 */
proto.scan_planner.ScanWaypoint.prototype.getTrajInfo = function() {
  return /** @type{?proto.scan_planner.TrajectoryMotionInfo} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_trajectory_motion_info_pb.TrajectoryMotionInfo, 6));
};


/**
 * @param {?proto.scan_planner.TrajectoryMotionInfo|undefined} value
 * @return {!proto.scan_planner.ScanWaypoint} returns this
*/
proto.scan_planner.ScanWaypoint.prototype.setTrajInfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ScanWaypoint} returns this
 */
proto.scan_planner.ScanWaypoint.prototype.clearTrajInfo = function() {
  return this.setTrajInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ScanWaypoint.prototype.hasTrajInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional WideBaselineRangeInfo wide_baseline_range_info = 7;
 * @return {?proto.scan_planner.WideBaselineRangeInfo}
 */
proto.scan_planner.ScanWaypoint.prototype.getWideBaselineRangeInfo = function() {
  return /** @type{?proto.scan_planner.WideBaselineRangeInfo} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_wide_baseline_range_info_pb.WideBaselineRangeInfo, 7));
};


/**
 * @param {?proto.scan_planner.WideBaselineRangeInfo|undefined} value
 * @return {!proto.scan_planner.ScanWaypoint} returns this
*/
proto.scan_planner.ScanWaypoint.prototype.setWideBaselineRangeInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ScanWaypoint} returns this
 */
proto.scan_planner.ScanWaypoint.prototype.clearWideBaselineRangeInfo = function() {
  return this.setWideBaselineRangeInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ScanWaypoint.prototype.hasWideBaselineRangeInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uuid_util.Uuid uuid = 8;
 * @return {?proto.uuid_util.Uuid}
 */
proto.scan_planner.ScanWaypoint.prototype.getUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 8));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.scan_planner.ScanWaypoint} returns this
*/
proto.scan_planner.ScanWaypoint.prototype.setUuid = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ScanWaypoint} returns this
 */
proto.scan_planner.ScanWaypoint.prototype.clearUuid = function() {
  return this.setUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ScanWaypoint.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 8) != null;
};


goog.object.extend(exports, proto.scan_planner);
