// source: pbtypes/infrastructure/remote_streaming/livekit_streaming.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb = require('../../../pbtypes/experimental/webrtc_c_impl/webrtc_stats_pb.js');
goog.object.extend(proto, pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb);
var pbtypes_cloud_services_webrtc_signal_proxy_signal_proxy_pb = require('../../../pbtypes/cloud/services/webrtc_signal_proxy/signal_proxy_pb.js');
goog.object.extend(proto, pbtypes_cloud_services_webrtc_signal_proxy_signal_proxy_pb);
var pbtypes_infrastructure_skybus_tunnel_tunnel_pb = require('../../../pbtypes/infrastructure/skybus_tunnel/tunnel_pb.js');
goog.object.extend(proto, pbtypes_infrastructure_skybus_tunnel_tunnel_pb);
var pbtypes_gen_remote_streaming_tunnel_type_pb = require('../../../pbtypes/gen/remote_streaming/tunnel_type_pb.js');
goog.object.extend(proto, pbtypes_gen_remote_streaming_tunnel_type_pb);
var pbtypes_gen_remote_streaming_video_type_pb = require('../../../pbtypes/gen/remote_streaming/video_type_pb.js');
goog.object.extend(proto, pbtypes_gen_remote_streaming_video_type_pb);
goog.exportSymbol('proto.remote_streaming.LivekitConfigurationRequest', null, global);
goog.exportSymbol('proto.remote_streaming.LivekitConnectionStatus', null, global);
goog.exportSymbol('proto.remote_streaming.LivekitRoomInfo', null, global);
goog.exportSymbol('proto.remote_streaming.LivekitTrackParams', null, global);
goog.exportSymbol('proto.remote_streaming.LivekitTunnelChannelInfo', null, global);
goog.exportSymbol('proto.remote_streaming.LivekitTunnelInfo', null, global);
goog.exportSymbol('proto.remote_streaming.LivekitTunnelParams', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.remote_streaming.LivekitRoomInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.remote_streaming.LivekitRoomInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.remote_streaming.LivekitRoomInfo.displayName = 'proto.remote_streaming.LivekitRoomInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.remote_streaming.LivekitTunnelParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.remote_streaming.LivekitTunnelParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.remote_streaming.LivekitTunnelParams.displayName = 'proto.remote_streaming.LivekitTunnelParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.remote_streaming.LivekitTrackParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.remote_streaming.LivekitTrackParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.remote_streaming.LivekitTrackParams.displayName = 'proto.remote_streaming.LivekitTrackParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.remote_streaming.LivekitConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.remote_streaming.LivekitConfigurationRequest.repeatedFields_, null);
};
goog.inherits(proto.remote_streaming.LivekitConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.remote_streaming.LivekitConfigurationRequest.displayName = 'proto.remote_streaming.LivekitConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.remote_streaming.LivekitTunnelChannelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.remote_streaming.LivekitTunnelChannelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.remote_streaming.LivekitTunnelChannelInfo.displayName = 'proto.remote_streaming.LivekitTunnelChannelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.remote_streaming.LivekitTunnelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.remote_streaming.LivekitTunnelInfo.repeatedFields_, null);
};
goog.inherits(proto.remote_streaming.LivekitTunnelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.remote_streaming.LivekitTunnelInfo.displayName = 'proto.remote_streaming.LivekitTunnelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.remote_streaming.LivekitConnectionStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.remote_streaming.LivekitConnectionStatus.repeatedFields_, null);
};
goog.inherits(proto.remote_streaming.LivekitConnectionStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.remote_streaming.LivekitConnectionStatus.displayName = 'proto.remote_streaming.LivekitConnectionStatus';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.remote_streaming.LivekitRoomInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.remote_streaming.LivekitRoomInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.remote_streaming.LivekitRoomInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitRoomInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
uclock: jspb.Message.getFieldWithDefault(msg, 1, 0),
shouldConnect: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
signalProxyCredentials: (f = msg.getSignalProxyCredentials()) && pbtypes_cloud_services_webrtc_signal_proxy_signal_proxy_pb.SignalProxyConnectionParams.toObject(includeInstance, f),
roomName: jspb.Message.getFieldWithDefault(msg, 4, ""),
clientId: jspb.Message.getFieldWithDefault(msg, 5, ""),
orgId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.remote_streaming.LivekitRoomInfo}
 */
proto.remote_streaming.LivekitRoomInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.remote_streaming.LivekitRoomInfo;
  return proto.remote_streaming.LivekitRoomInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.remote_streaming.LivekitRoomInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.remote_streaming.LivekitRoomInfo}
 */
proto.remote_streaming.LivekitRoomInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUclock(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldConnect(value);
      break;
    case 3:
      var value = new pbtypes_cloud_services_webrtc_signal_proxy_signal_proxy_pb.SignalProxyConnectionParams;
      reader.readMessage(value,pbtypes_cloud_services_webrtc_signal_proxy_signal_proxy_pb.SignalProxyConnectionParams.deserializeBinaryFromReader);
      msg.setSignalProxyCredentials(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.remote_streaming.LivekitRoomInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.remote_streaming.LivekitRoomInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.remote_streaming.LivekitRoomInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitRoomInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUclock();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getShouldConnect();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSignalProxyCredentials();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_cloud_services_webrtc_signal_proxy_signal_proxy_pb.SignalProxyConnectionParams.serializeBinaryToWriter
    );
  }
  f = message.getRoomName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrgId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 uclock = 1;
 * @return {number}
 */
proto.remote_streaming.LivekitRoomInfo.prototype.getUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.remote_streaming.LivekitRoomInfo} returns this
 */
proto.remote_streaming.LivekitRoomInfo.prototype.setUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool should_connect = 2;
 * @return {boolean}
 */
proto.remote_streaming.LivekitRoomInfo.prototype.getShouldConnect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.remote_streaming.LivekitRoomInfo} returns this
 */
proto.remote_streaming.LivekitRoomInfo.prototype.setShouldConnect = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional signal_proxy.SignalProxyConnectionParams signal_proxy_credentials = 3;
 * @return {?proto.signal_proxy.SignalProxyConnectionParams}
 */
proto.remote_streaming.LivekitRoomInfo.prototype.getSignalProxyCredentials = function() {
  return /** @type{?proto.signal_proxy.SignalProxyConnectionParams} */ (
    jspb.Message.getWrapperField(this, pbtypes_cloud_services_webrtc_signal_proxy_signal_proxy_pb.SignalProxyConnectionParams, 3));
};


/**
 * @param {?proto.signal_proxy.SignalProxyConnectionParams|undefined} value
 * @return {!proto.remote_streaming.LivekitRoomInfo} returns this
*/
proto.remote_streaming.LivekitRoomInfo.prototype.setSignalProxyCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.remote_streaming.LivekitRoomInfo} returns this
 */
proto.remote_streaming.LivekitRoomInfo.prototype.clearSignalProxyCredentials = function() {
  return this.setSignalProxyCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.remote_streaming.LivekitRoomInfo.prototype.hasSignalProxyCredentials = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string room_name = 4;
 * @return {string}
 */
proto.remote_streaming.LivekitRoomInfo.prototype.getRoomName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.remote_streaming.LivekitRoomInfo} returns this
 */
proto.remote_streaming.LivekitRoomInfo.prototype.setRoomName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string client_id = 5;
 * @return {string}
 */
proto.remote_streaming.LivekitRoomInfo.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.remote_streaming.LivekitRoomInfo} returns this
 */
proto.remote_streaming.LivekitRoomInfo.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string org_id = 6;
 * @return {string}
 */
proto.remote_streaming.LivekitRoomInfo.prototype.getOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.remote_streaming.LivekitRoomInfo} returns this
 */
proto.remote_streaming.LivekitRoomInfo.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.remote_streaming.LivekitTunnelParams.prototype.toObject = function(opt_includeInstance) {
  return proto.remote_streaming.LivekitTunnelParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.remote_streaming.LivekitTunnelParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitTunnelParams.toObject = function(includeInstance, msg) {
  var f, obj = {
clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
tunnelType: jspb.Message.getFieldWithDefault(msg, 2, 0),
isPilot: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.remote_streaming.LivekitTunnelParams}
 */
proto.remote_streaming.LivekitTunnelParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.remote_streaming.LivekitTunnelParams;
  return proto.remote_streaming.LivekitTunnelParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.remote_streaming.LivekitTunnelParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.remote_streaming.LivekitTunnelParams}
 */
proto.remote_streaming.LivekitTunnelParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {!proto.remote_streaming.TunnelType.Enum} */ (reader.readEnum());
      msg.setTunnelType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPilot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.remote_streaming.LivekitTunnelParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.remote_streaming.LivekitTunnelParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.remote_streaming.LivekitTunnelParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitTunnelParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTunnelType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIsPilot();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.remote_streaming.LivekitTunnelParams.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.remote_streaming.LivekitTunnelParams} returns this
 */
proto.remote_streaming.LivekitTunnelParams.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TunnelType.Enum tunnel_type = 2;
 * @return {!proto.remote_streaming.TunnelType.Enum}
 */
proto.remote_streaming.LivekitTunnelParams.prototype.getTunnelType = function() {
  return /** @type {!proto.remote_streaming.TunnelType.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.remote_streaming.TunnelType.Enum} value
 * @return {!proto.remote_streaming.LivekitTunnelParams} returns this
 */
proto.remote_streaming.LivekitTunnelParams.prototype.setTunnelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool is_pilot = 3;
 * @return {boolean}
 */
proto.remote_streaming.LivekitTunnelParams.prototype.getIsPilot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.remote_streaming.LivekitTunnelParams} returns this
 */
proto.remote_streaming.LivekitTunnelParams.prototype.setIsPilot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.remote_streaming.LivekitTrackParams.prototype.toObject = function(opt_includeInstance) {
  return proto.remote_streaming.LivekitTrackParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.remote_streaming.LivekitTrackParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitTrackParams.toObject = function(includeInstance, msg) {
  var f, obj = {
videoType: jspb.Message.getFieldWithDefault(msg, 1, 0),
dynacastEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.remote_streaming.LivekitTrackParams}
 */
proto.remote_streaming.LivekitTrackParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.remote_streaming.LivekitTrackParams;
  return proto.remote_streaming.LivekitTrackParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.remote_streaming.LivekitTrackParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.remote_streaming.LivekitTrackParams}
 */
proto.remote_streaming.LivekitTrackParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.remote_streaming.VideoType.Enum} */ (reader.readEnum());
      msg.setVideoType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDynacastEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.remote_streaming.LivekitTrackParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.remote_streaming.LivekitTrackParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.remote_streaming.LivekitTrackParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitTrackParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideoType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDynacastEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional VideoType.Enum video_type = 1;
 * @return {!proto.remote_streaming.VideoType.Enum}
 */
proto.remote_streaming.LivekitTrackParams.prototype.getVideoType = function() {
  return /** @type {!proto.remote_streaming.VideoType.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.remote_streaming.VideoType.Enum} value
 * @return {!proto.remote_streaming.LivekitTrackParams} returns this
 */
proto.remote_streaming.LivekitTrackParams.prototype.setVideoType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool dynacast_enabled = 2;
 * @return {boolean}
 */
proto.remote_streaming.LivekitTrackParams.prototype.getDynacastEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.remote_streaming.LivekitTrackParams} returns this
 */
proto.remote_streaming.LivekitTrackParams.prototype.setDynacastEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.remote_streaming.LivekitConfigurationRequest.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.remote_streaming.LivekitConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.remote_streaming.LivekitConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
uclock: jspb.Message.getFieldWithDefault(msg, 1, 0),
enableChannelBroadcast: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
broadcastChannels: (f = msg.getBroadcastChannels()) && pbtypes_infrastructure_skybus_tunnel_tunnel_pb.SubscribedChannelList.toObject(includeInstance, f),
broadcastVideoList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
openTunnelsList: jspb.Message.toObjectList(msg.getOpenTunnelsList(),
    proto.remote_streaming.LivekitTunnelParams.toObject, includeInstance),
broadcastVideoParamsList: jspb.Message.toObjectList(msg.getBroadcastVideoParamsList(),
    proto.remote_streaming.LivekitTrackParams.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.remote_streaming.LivekitConfigurationRequest}
 */
proto.remote_streaming.LivekitConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.remote_streaming.LivekitConfigurationRequest;
  return proto.remote_streaming.LivekitConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.remote_streaming.LivekitConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.remote_streaming.LivekitConfigurationRequest}
 */
proto.remote_streaming.LivekitConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUclock(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableChannelBroadcast(value);
      break;
    case 3:
      var value = new pbtypes_infrastructure_skybus_tunnel_tunnel_pb.SubscribedChannelList;
      reader.readMessage(value,pbtypes_infrastructure_skybus_tunnel_tunnel_pb.SubscribedChannelList.deserializeBinaryFromReader);
      msg.setBroadcastChannels(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.remote_streaming.VideoType.Enum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBroadcastVideo(values[i]);
      }
      break;
    case 5:
      var value = new proto.remote_streaming.LivekitTunnelParams;
      reader.readMessage(value,proto.remote_streaming.LivekitTunnelParams.deserializeBinaryFromReader);
      msg.addOpenTunnels(value);
      break;
    case 6:
      var value = new proto.remote_streaming.LivekitTrackParams;
      reader.readMessage(value,proto.remote_streaming.LivekitTrackParams.deserializeBinaryFromReader);
      msg.addBroadcastVideoParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.remote_streaming.LivekitConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.remote_streaming.LivekitConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUclock();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEnableChannelBroadcast();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getBroadcastChannels();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_infrastructure_skybus_tunnel_tunnel_pb.SubscribedChannelList.serializeBinaryToWriter
    );
  }
  f = message.getBroadcastVideoList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getOpenTunnelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.remote_streaming.LivekitTunnelParams.serializeBinaryToWriter
    );
  }
  f = message.getBroadcastVideoParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.remote_streaming.LivekitTrackParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 uclock = 1;
 * @return {number}
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.getUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.remote_streaming.LivekitConfigurationRequest} returns this
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.setUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool enable_channel_broadcast = 2;
 * @return {boolean}
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.getEnableChannelBroadcast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.remote_streaming.LivekitConfigurationRequest} returns this
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.setEnableChannelBroadcast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional skybus_tunnel.SubscribedChannelList broadcast_channels = 3;
 * @return {?proto.skybus_tunnel.SubscribedChannelList}
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.getBroadcastChannels = function() {
  return /** @type{?proto.skybus_tunnel.SubscribedChannelList} */ (
    jspb.Message.getWrapperField(this, pbtypes_infrastructure_skybus_tunnel_tunnel_pb.SubscribedChannelList, 3));
};


/**
 * @param {?proto.skybus_tunnel.SubscribedChannelList|undefined} value
 * @return {!proto.remote_streaming.LivekitConfigurationRequest} returns this
*/
proto.remote_streaming.LivekitConfigurationRequest.prototype.setBroadcastChannels = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.remote_streaming.LivekitConfigurationRequest} returns this
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.clearBroadcastChannels = function() {
  return this.setBroadcastChannels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.hasBroadcastChannels = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated VideoType.Enum broadcast_video = 4;
 * @return {!Array<!proto.remote_streaming.VideoType.Enum>}
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.getBroadcastVideoList = function() {
  return /** @type {!Array<!proto.remote_streaming.VideoType.Enum>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.remote_streaming.VideoType.Enum>} value
 * @return {!proto.remote_streaming.LivekitConfigurationRequest} returns this
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.setBroadcastVideoList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.remote_streaming.VideoType.Enum} value
 * @param {number=} opt_index
 * @return {!proto.remote_streaming.LivekitConfigurationRequest} returns this
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.addBroadcastVideo = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.remote_streaming.LivekitConfigurationRequest} returns this
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.clearBroadcastVideoList = function() {
  return this.setBroadcastVideoList([]);
};


/**
 * repeated LivekitTunnelParams open_tunnels = 5;
 * @return {!Array<!proto.remote_streaming.LivekitTunnelParams>}
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.getOpenTunnelsList = function() {
  return /** @type{!Array<!proto.remote_streaming.LivekitTunnelParams>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.remote_streaming.LivekitTunnelParams, 5));
};


/**
 * @param {!Array<!proto.remote_streaming.LivekitTunnelParams>} value
 * @return {!proto.remote_streaming.LivekitConfigurationRequest} returns this
*/
proto.remote_streaming.LivekitConfigurationRequest.prototype.setOpenTunnelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.remote_streaming.LivekitTunnelParams=} opt_value
 * @param {number=} opt_index
 * @return {!proto.remote_streaming.LivekitTunnelParams}
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.addOpenTunnels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.remote_streaming.LivekitTunnelParams, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.remote_streaming.LivekitConfigurationRequest} returns this
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.clearOpenTunnelsList = function() {
  return this.setOpenTunnelsList([]);
};


/**
 * repeated LivekitTrackParams broadcast_video_params = 6;
 * @return {!Array<!proto.remote_streaming.LivekitTrackParams>}
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.getBroadcastVideoParamsList = function() {
  return /** @type{!Array<!proto.remote_streaming.LivekitTrackParams>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.remote_streaming.LivekitTrackParams, 6));
};


/**
 * @param {!Array<!proto.remote_streaming.LivekitTrackParams>} value
 * @return {!proto.remote_streaming.LivekitConfigurationRequest} returns this
*/
proto.remote_streaming.LivekitConfigurationRequest.prototype.setBroadcastVideoParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.remote_streaming.LivekitTrackParams=} opt_value
 * @param {number=} opt_index
 * @return {!proto.remote_streaming.LivekitTrackParams}
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.addBroadcastVideoParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.remote_streaming.LivekitTrackParams, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.remote_streaming.LivekitConfigurationRequest} returns this
 */
proto.remote_streaming.LivekitConfigurationRequest.prototype.clearBroadcastVideoParamsList = function() {
  return this.setBroadcastVideoParamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.remote_streaming.LivekitTunnelChannelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.remote_streaming.LivekitTunnelChannelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitTunnelChannelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
numBytesReceived: jspb.Message.getFieldWithDefault(msg, 2, 0),
eventsCompleted: jspb.Message.getFieldWithDefault(msg, 3, 0),
eventsDropped: jspb.Message.getFieldWithDefault(msg, 4, 0),
bytesSent: jspb.Message.getFieldWithDefault(msg, 5, 0),
eventsSent: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.remote_streaming.LivekitTunnelChannelInfo}
 */
proto.remote_streaming.LivekitTunnelChannelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.remote_streaming.LivekitTunnelChannelInfo;
  return proto.remote_streaming.LivekitTunnelChannelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.remote_streaming.LivekitTunnelChannelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.remote_streaming.LivekitTunnelChannelInfo}
 */
proto.remote_streaming.LivekitTunnelChannelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumBytesReceived(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventsCompleted(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventsDropped(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBytesSent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventsSent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.remote_streaming.LivekitTunnelChannelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.remote_streaming.LivekitTunnelChannelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitTunnelChannelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumBytesReceived();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEventsCompleted();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getEventsDropped();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBytesSent();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEventsSent();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.remote_streaming.LivekitTunnelChannelInfo} returns this
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 num_bytes_received = 2;
 * @return {number}
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.getNumBytesReceived = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.remote_streaming.LivekitTunnelChannelInfo} returns this
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.setNumBytesReceived = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 events_completed = 3;
 * @return {number}
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.getEventsCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.remote_streaming.LivekitTunnelChannelInfo} returns this
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.setEventsCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 events_dropped = 4;
 * @return {number}
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.getEventsDropped = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.remote_streaming.LivekitTunnelChannelInfo} returns this
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.setEventsDropped = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 bytes_sent = 5;
 * @return {number}
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.getBytesSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.remote_streaming.LivekitTunnelChannelInfo} returns this
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.setBytesSent = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 events_sent = 6;
 * @return {number}
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.getEventsSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.remote_streaming.LivekitTunnelChannelInfo} returns this
 */
proto.remote_streaming.LivekitTunnelChannelInfo.prototype.setEventsSent = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.remote_streaming.LivekitTunnelInfo.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.remote_streaming.LivekitTunnelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.remote_streaming.LivekitTunnelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitTunnelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
tunnelType: jspb.Message.getFieldWithDefault(msg, 1, 0),
clientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
isPilot: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
writeChannelsList: jspb.Message.toObjectList(msg.getWriteChannelsList(),
    proto.remote_streaming.LivekitTunnelChannelInfo.toObject, includeInstance),
readChannelsList: jspb.Message.toObjectList(msg.getReadChannelsList(),
    proto.remote_streaming.LivekitTunnelChannelInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.remote_streaming.LivekitTunnelInfo}
 */
proto.remote_streaming.LivekitTunnelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.remote_streaming.LivekitTunnelInfo;
  return proto.remote_streaming.LivekitTunnelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.remote_streaming.LivekitTunnelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.remote_streaming.LivekitTunnelInfo}
 */
proto.remote_streaming.LivekitTunnelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.remote_streaming.TunnelType.Enum} */ (reader.readEnum());
      msg.setTunnelType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPilot(value);
      break;
    case 4:
      var value = new proto.remote_streaming.LivekitTunnelChannelInfo;
      reader.readMessage(value,proto.remote_streaming.LivekitTunnelChannelInfo.deserializeBinaryFromReader);
      msg.addWriteChannels(value);
      break;
    case 5:
      var value = new proto.remote_streaming.LivekitTunnelChannelInfo;
      reader.readMessage(value,proto.remote_streaming.LivekitTunnelChannelInfo.deserializeBinaryFromReader);
      msg.addReadChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.remote_streaming.LivekitTunnelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.remote_streaming.LivekitTunnelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitTunnelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTunnelType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsPilot();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getWriteChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.remote_streaming.LivekitTunnelChannelInfo.serializeBinaryToWriter
    );
  }
  f = message.getReadChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.remote_streaming.LivekitTunnelChannelInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional TunnelType.Enum tunnel_type = 1;
 * @return {!proto.remote_streaming.TunnelType.Enum}
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.getTunnelType = function() {
  return /** @type {!proto.remote_streaming.TunnelType.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.remote_streaming.TunnelType.Enum} value
 * @return {!proto.remote_streaming.LivekitTunnelInfo} returns this
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.setTunnelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string client_id = 2;
 * @return {string}
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.remote_streaming.LivekitTunnelInfo} returns this
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_pilot = 3;
 * @return {boolean}
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.getIsPilot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.remote_streaming.LivekitTunnelInfo} returns this
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.setIsPilot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated LivekitTunnelChannelInfo write_channels = 4;
 * @return {!Array<!proto.remote_streaming.LivekitTunnelChannelInfo>}
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.getWriteChannelsList = function() {
  return /** @type{!Array<!proto.remote_streaming.LivekitTunnelChannelInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.remote_streaming.LivekitTunnelChannelInfo, 4));
};


/**
 * @param {!Array<!proto.remote_streaming.LivekitTunnelChannelInfo>} value
 * @return {!proto.remote_streaming.LivekitTunnelInfo} returns this
*/
proto.remote_streaming.LivekitTunnelInfo.prototype.setWriteChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.remote_streaming.LivekitTunnelChannelInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.remote_streaming.LivekitTunnelChannelInfo}
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.addWriteChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.remote_streaming.LivekitTunnelChannelInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.remote_streaming.LivekitTunnelInfo} returns this
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.clearWriteChannelsList = function() {
  return this.setWriteChannelsList([]);
};


/**
 * repeated LivekitTunnelChannelInfo read_channels = 5;
 * @return {!Array<!proto.remote_streaming.LivekitTunnelChannelInfo>}
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.getReadChannelsList = function() {
  return /** @type{!Array<!proto.remote_streaming.LivekitTunnelChannelInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.remote_streaming.LivekitTunnelChannelInfo, 5));
};


/**
 * @param {!Array<!proto.remote_streaming.LivekitTunnelChannelInfo>} value
 * @return {!proto.remote_streaming.LivekitTunnelInfo} returns this
*/
proto.remote_streaming.LivekitTunnelInfo.prototype.setReadChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.remote_streaming.LivekitTunnelChannelInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.remote_streaming.LivekitTunnelChannelInfo}
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.addReadChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.remote_streaming.LivekitTunnelChannelInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.remote_streaming.LivekitTunnelInfo} returns this
 */
proto.remote_streaming.LivekitTunnelInfo.prototype.clearReadChannelsList = function() {
  return this.setReadChannelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.remote_streaming.LivekitConnectionStatus.repeatedFields_ = [7,8,9,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.remote_streaming.LivekitConnectionStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.remote_streaming.LivekitConnectionStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitConnectionStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
enabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
connected: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
clientId: jspb.Message.getFieldWithDefault(msg, 3, ""),
roomId: jspb.Message.getFieldWithDefault(msg, 4, ""),
signalProxyUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
streaming: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
streamingConfigList: jspb.Message.toObjectList(msg.getStreamingConfigList(),
    proto.remote_streaming.LivekitConfigurationRequest.toObject, includeInstance),
streamedVideoChannelsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
openTunnelsList: jspb.Message.toObjectList(msg.getOpenTunnelsList(),
    proto.remote_streaming.LivekitTunnelInfo.toObject, includeInstance),
livekitStats: (f = msg.getLivekitStats()) && pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.LivekitClientStats.toObject(includeInstance, f),
utime: jspb.Message.getFieldWithDefault(msg, 11, 0),
tickTimeMs: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
supportedProtocolMessagesList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.remote_streaming.LivekitConnectionStatus}
 */
proto.remote_streaming.LivekitConnectionStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.remote_streaming.LivekitConnectionStatus;
  return proto.remote_streaming.LivekitConnectionStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.remote_streaming.LivekitConnectionStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.remote_streaming.LivekitConnectionStatus}
 */
proto.remote_streaming.LivekitConnectionStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignalProxyUrl(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStreaming(value);
      break;
    case 7:
      var value = new proto.remote_streaming.LivekitConfigurationRequest;
      reader.readMessage(value,proto.remote_streaming.LivekitConfigurationRequest.deserializeBinaryFromReader);
      msg.addStreamingConfig(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addStreamedVideoChannels(value);
      break;
    case 9:
      var value = new proto.remote_streaming.LivekitTunnelInfo;
      reader.readMessage(value,proto.remote_streaming.LivekitTunnelInfo.deserializeBinaryFromReader);
      msg.addOpenTunnels(value);
      break;
    case 10:
      var value = new pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.LivekitClientStats;
      reader.readMessage(value,pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.LivekitClientStats.deserializeBinaryFromReader);
      msg.setLivekitStats(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTickTimeMs(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addSupportedProtocolMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.remote_streaming.LivekitConnectionStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.remote_streaming.LivekitConnectionStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.remote_streaming.LivekitConnectionStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSignalProxyUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStreaming();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getStreamingConfigList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.remote_streaming.LivekitConfigurationRequest.serializeBinaryToWriter
    );
  }
  f = message.getStreamedVideoChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getOpenTunnelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.remote_streaming.LivekitTunnelInfo.serializeBinaryToWriter
    );
  }
  f = message.getLivekitStats();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.LivekitClientStats.serializeBinaryToWriter
    );
  }
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getTickTimeMs();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getSupportedProtocolMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool connected = 2;
 * @return {boolean}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.setConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string client_id = 3;
 * @return {string}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string room_id = 4;
 * @return {string}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string signal_proxy_url = 5;
 * @return {string}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getSignalProxyUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.setSignalProxyUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool streaming = 6;
 * @return {boolean}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getStreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.setStreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated LivekitConfigurationRequest streaming_config = 7;
 * @return {!Array<!proto.remote_streaming.LivekitConfigurationRequest>}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getStreamingConfigList = function() {
  return /** @type{!Array<!proto.remote_streaming.LivekitConfigurationRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.remote_streaming.LivekitConfigurationRequest, 7));
};


/**
 * @param {!Array<!proto.remote_streaming.LivekitConfigurationRequest>} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
*/
proto.remote_streaming.LivekitConnectionStatus.prototype.setStreamingConfigList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.remote_streaming.LivekitConfigurationRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.remote_streaming.LivekitConfigurationRequest}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.addStreamingConfig = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.remote_streaming.LivekitConfigurationRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.clearStreamingConfigList = function() {
  return this.setStreamingConfigList([]);
};


/**
 * repeated string streamed_video_channels = 8;
 * @return {!Array<string>}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getStreamedVideoChannelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.setStreamedVideoChannelsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.addStreamedVideoChannels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.clearStreamedVideoChannelsList = function() {
  return this.setStreamedVideoChannelsList([]);
};


/**
 * repeated LivekitTunnelInfo open_tunnels = 9;
 * @return {!Array<!proto.remote_streaming.LivekitTunnelInfo>}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getOpenTunnelsList = function() {
  return /** @type{!Array<!proto.remote_streaming.LivekitTunnelInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.remote_streaming.LivekitTunnelInfo, 9));
};


/**
 * @param {!Array<!proto.remote_streaming.LivekitTunnelInfo>} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
*/
proto.remote_streaming.LivekitConnectionStatus.prototype.setOpenTunnelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.remote_streaming.LivekitTunnelInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.remote_streaming.LivekitTunnelInfo}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.addOpenTunnels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.remote_streaming.LivekitTunnelInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.clearOpenTunnelsList = function() {
  return this.setOpenTunnelsList([]);
};


/**
 * optional webrtc.LivekitClientStats livekit_stats = 10;
 * @return {?proto.webrtc.LivekitClientStats}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getLivekitStats = function() {
  return /** @type{?proto.webrtc.LivekitClientStats} */ (
    jspb.Message.getWrapperField(this, pbtypes_experimental_webrtc_c_impl_webrtc_stats_pb.LivekitClientStats, 10));
};


/**
 * @param {?proto.webrtc.LivekitClientStats|undefined} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
*/
proto.remote_streaming.LivekitConnectionStatus.prototype.setLivekitStats = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.clearLivekitStats = function() {
  return this.setLivekitStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.hasLivekitStats = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 utime = 11;
 * @return {number}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional double tick_time_ms = 12;
 * @return {number}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getTickTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.setTickTimeMs = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * repeated string supported_protocol_messages = 13;
 * @return {!Array<string>}
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.getSupportedProtocolMessagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.setSupportedProtocolMessagesList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.addSupportedProtocolMessages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.remote_streaming.LivekitConnectionStatus} returns this
 */
proto.remote_streaming.LivekitConnectionStatus.prototype.clearSupportedProtocolMessagesList = function() {
  return this.setSupportedProtocolMessagesList([]);
};


goog.object.extend(exports, proto.remote_streaming);
