// source: pbtypes/vehicle/skills/actions/modify_sim_state_estimation/modify_sim_state_estimation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_eigen_lcm_Vector3d_pb = require('../../../../../pbtypes/gen/eigen_lcm/Vector3d_pb.js');
goog.object.extend(proto, pbtypes_gen_eigen_lcm_Vector3d_pb);
goog.exportSymbol('proto.skills.ModifySimStateEstimationActionArgs', null, global);
goog.exportSymbol('proto.skills.ModifySimStateEstimationActionStateData', null, global);
goog.exportSymbol('proto.skills.ModifySimStateEstimationResultData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.ModifySimStateEstimationActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.ModifySimStateEstimationActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.ModifySimStateEstimationActionArgs.displayName = 'proto.skills.ModifySimStateEstimationActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.ModifySimStateEstimationResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.ModifySimStateEstimationResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.ModifySimStateEstimationResultData.displayName = 'proto.skills.ModifySimStateEstimationResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.ModifySimStateEstimationActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.ModifySimStateEstimationActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.ModifySimStateEstimationActionStateData.displayName = 'proto.skills.ModifySimStateEstimationActionStateData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.ModifySimStateEstimationActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.ModifySimStateEstimationActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.ModifySimStateEstimationActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ModifySimStateEstimationActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
positionOffset: (f = msg.getPositionOffset()) && pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.toObject(includeInstance, f),
velocityOffset: (f = msg.getVelocityOffset()) && pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.ModifySimStateEstimationActionArgs}
 */
proto.skills.ModifySimStateEstimationActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.ModifySimStateEstimationActionArgs;
  return proto.skills.ModifySimStateEstimationActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.ModifySimStateEstimationActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.ModifySimStateEstimationActionArgs}
 */
proto.skills.ModifySimStateEstimationActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.deserializeBinaryFromReader);
      msg.setPositionOffset(value);
      break;
    case 2:
      var value = new pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.deserializeBinaryFromReader);
      msg.setVelocityOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.ModifySimStateEstimationActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.ModifySimStateEstimationActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.ModifySimStateEstimationActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ModifySimStateEstimationActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionOffset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.serializeBinaryToWriter
    );
  }
  f = message.getVelocityOffset();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.serializeBinaryToWriter
    );
  }
};


/**
 * optional eigen_lcm.Vector3dPb position_offset = 1;
 * @return {?proto.eigen_lcm.Vector3dPb}
 */
proto.skills.ModifySimStateEstimationActionArgs.prototype.getPositionOffset = function() {
  return /** @type{?proto.eigen_lcm.Vector3dPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb, 1));
};


/**
 * @param {?proto.eigen_lcm.Vector3dPb|undefined} value
 * @return {!proto.skills.ModifySimStateEstimationActionArgs} returns this
*/
proto.skills.ModifySimStateEstimationActionArgs.prototype.setPositionOffset = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ModifySimStateEstimationActionArgs} returns this
 */
proto.skills.ModifySimStateEstimationActionArgs.prototype.clearPositionOffset = function() {
  return this.setPositionOffset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ModifySimStateEstimationActionArgs.prototype.hasPositionOffset = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional eigen_lcm.Vector3dPb velocity_offset = 2;
 * @return {?proto.eigen_lcm.Vector3dPb}
 */
proto.skills.ModifySimStateEstimationActionArgs.prototype.getVelocityOffset = function() {
  return /** @type{?proto.eigen_lcm.Vector3dPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb, 2));
};


/**
 * @param {?proto.eigen_lcm.Vector3dPb|undefined} value
 * @return {!proto.skills.ModifySimStateEstimationActionArgs} returns this
*/
proto.skills.ModifySimStateEstimationActionArgs.prototype.setVelocityOffset = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.ModifySimStateEstimationActionArgs} returns this
 */
proto.skills.ModifySimStateEstimationActionArgs.prototype.clearVelocityOffset = function() {
  return this.setVelocityOffset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.ModifySimStateEstimationActionArgs.prototype.hasVelocityOffset = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.ModifySimStateEstimationResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.ModifySimStateEstimationResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.ModifySimStateEstimationResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ModifySimStateEstimationResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.ModifySimStateEstimationResultData}
 */
proto.skills.ModifySimStateEstimationResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.ModifySimStateEstimationResultData;
  return proto.skills.ModifySimStateEstimationResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.ModifySimStateEstimationResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.ModifySimStateEstimationResultData}
 */
proto.skills.ModifySimStateEstimationResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.ModifySimStateEstimationResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.ModifySimStateEstimationResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.ModifySimStateEstimationResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ModifySimStateEstimationResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.ModifySimStateEstimationActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.ModifySimStateEstimationActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.ModifySimStateEstimationActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ModifySimStateEstimationActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.ModifySimStateEstimationActionStateData}
 */
proto.skills.ModifySimStateEstimationActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.ModifySimStateEstimationActionStateData;
  return proto.skills.ModifySimStateEstimationActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.ModifySimStateEstimationActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.ModifySimStateEstimationActionStateData}
 */
proto.skills.ModifySimStateEstimationActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.ModifySimStateEstimationActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.ModifySimStateEstimationActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.ModifySimStateEstimationActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.ModifySimStateEstimationActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.skills);
