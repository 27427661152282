// source: pbtypes/vehicle/skills/ui_workflow.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_skills_ui_slider_pb = require('../../../pbtypes/gen/skills/ui_slider_pb.js');
goog.object.extend(proto, pbtypes_gen_skills_ui_slider_pb);
var pbtypes_gen_skills_ui_button_pb = require('../../../pbtypes/gen/skills/ui_button_pb.js');
goog.object.extend(proto, pbtypes_gen_skills_ui_button_pb);
var pbtypes_gen_skills_ui_prompt_pb = require('../../../pbtypes/gen/skills/ui_prompt_pb.js');
goog.object.extend(proto, pbtypes_gen_skills_ui_prompt_pb);
var pbtypes_gen_skills_ui_radio_group_pb = require('../../../pbtypes/gen/skills/ui_radio_group_pb.js');
goog.object.extend(proto, pbtypes_gen_skills_ui_radio_group_pb);
var pbtypes_gen_skills_ui_toggle_pb = require('../../../pbtypes/gen/skills/ui_toggle_pb.js');
goog.object.extend(proto, pbtypes_gen_skills_ui_toggle_pb);
goog.exportSymbol('proto.skills.AttributeStyle', null, global);
goog.exportSymbol('proto.skills.UiCheckbox', null, global);
goog.exportSymbol('proto.skills.UiFlexibleSpace', null, global);
goog.exportSymbol('proto.skills.UiImage', null, global);
goog.exportSymbol('proto.skills.UiImageSegmentControl', null, global);
goog.exportSymbol('proto.skills.UiLabel', null, global);
goog.exportSymbol('proto.skills.UiProgressBar', null, global);
goog.exportSymbol('proto.skills.UiScreenBasic', null, global);
goog.exportSymbol('proto.skills.UiScreenPostFlight', null, global);
goog.exportSymbol('proto.skills.UiSegmentControlOption', null, global);
goog.exportSymbol('proto.skills.UiStat', null, global);
goog.exportSymbol('proto.skills.UiStatsView', null, global);
goog.exportSymbol('proto.skills.UiSubmenu', null, global);
goog.exportSymbol('proto.skills.UiTextField', null, global);
goog.exportSymbol('proto.skills.WorkflowClientState', null, global);
goog.exportSymbol('proto.skills.WorkflowElement', null, global);
goog.exportSymbol('proto.skills.WorkflowElement.ElementCase', null, global);
goog.exportSymbol('proto.skills.WorkflowHUD', null, global);
goog.exportSymbol('proto.skills.WorkflowIconType', null, global);
goog.exportSymbol('proto.skills.WorkflowLayout', null, global);
goog.exportSymbol('proto.skills.WorkflowMenuDisplayPreference', null, global);
goog.exportSymbol('proto.skills.WorkflowMenuDisplayState', null, global);
goog.exportSymbol('proto.skills.WorkflowMenuDisplayStateRequest', null, global);
goog.exportSymbol('proto.skills.WorkflowScreen', null, global);
goog.exportSymbol('proto.skills.WorkflowScreen.ScreenCase', null, global);
goog.exportSymbol('proto.skills.WorkflowSettingsPage', null, global);
goog.exportSymbol('proto.skills.WorkflowStep', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WorkflowElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skills.WorkflowElement.oneofGroups_);
};
goog.inherits(proto.skills.WorkflowElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WorkflowElement.displayName = 'proto.skills.WorkflowElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiImageSegmentControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.UiImageSegmentControl.repeatedFields_, null);
};
goog.inherits(proto.skills.UiImageSegmentControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiImageSegmentControl.displayName = 'proto.skills.UiImageSegmentControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiSegmentControlOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UiSegmentControlOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiSegmentControlOption.displayName = 'proto.skills.UiSegmentControlOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiFlexibleSpace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UiFlexibleSpace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiFlexibleSpace.displayName = 'proto.skills.UiFlexibleSpace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UiImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiImage.displayName = 'proto.skills.UiImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiLabel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UiLabel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiLabel.displayName = 'proto.skills.UiLabel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiTextField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UiTextField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiTextField.displayName = 'proto.skills.UiTextField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WorkflowStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.WorkflowStep.repeatedFields_, null);
};
goog.inherits(proto.skills.WorkflowStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WorkflowStep.displayName = 'proto.skills.WorkflowStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UiStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiStat.displayName = 'proto.skills.UiStat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiSubmenu = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UiSubmenu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiSubmenu.displayName = 'proto.skills.UiSubmenu';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WorkflowMenuDisplayStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.WorkflowMenuDisplayStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WorkflowMenuDisplayStateRequest.displayName = 'proto.skills.WorkflowMenuDisplayStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WorkflowClientState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.WorkflowClientState.repeatedFields_, null);
};
goog.inherits(proto.skills.WorkflowClientState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WorkflowClientState.displayName = 'proto.skills.WorkflowClientState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiStatsView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.UiStatsView.repeatedFields_, null);
};
goog.inherits(proto.skills.UiStatsView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiStatsView.displayName = 'proto.skills.UiStatsView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WorkflowHUD = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.WorkflowHUD.repeatedFields_, null);
};
goog.inherits(proto.skills.WorkflowHUD, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WorkflowHUD.displayName = 'proto.skills.WorkflowHUD';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WorkflowSettingsPage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.WorkflowSettingsPage.repeatedFields_, null);
};
goog.inherits(proto.skills.WorkflowSettingsPage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WorkflowSettingsPage.displayName = 'proto.skills.WorkflowSettingsPage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WorkflowScreen = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skills.WorkflowScreen.oneofGroups_);
};
goog.inherits(proto.skills.WorkflowScreen, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WorkflowScreen.displayName = 'proto.skills.WorkflowScreen';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiScreenPostFlight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.UiScreenPostFlight.repeatedFields_, null);
};
goog.inherits(proto.skills.UiScreenPostFlight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiScreenPostFlight.displayName = 'proto.skills.UiScreenPostFlight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiScreenBasic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.UiScreenBasic.repeatedFields_, null);
};
goog.inherits(proto.skills.UiScreenBasic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiScreenBasic.displayName = 'proto.skills.UiScreenBasic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiCheckbox = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UiCheckbox, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiCheckbox.displayName = 'proto.skills.UiCheckbox';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UiProgressBar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UiProgressBar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UiProgressBar.displayName = 'proto.skills.UiProgressBar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WorkflowLayout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.WorkflowLayout.repeatedFields_, null);
};
goog.inherits(proto.skills.WorkflowLayout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WorkflowLayout.displayName = 'proto.skills.WorkflowLayout';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.WorkflowElement.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13]];

/**
 * @enum {number}
 */
proto.skills.WorkflowElement.ElementCase = {
  ELEMENT_NOT_SET: 0,
  SLIDER: 1,
  TOGGLE: 2,
  BUTTON: 3,
  LABEL: 4,
  STATS: 5,
  TEXT_FIELD: 6,
  IMAGE: 7,
  SUBMENU: 8,
  PICKER: 9,
  SPACE: 10,
  PROGRESS_BAR: 11,
  CHECKBOX: 12,
  IMAGE_SEGMENT_CONTROL: 13
};

/**
 * @return {proto.skills.WorkflowElement.ElementCase}
 */
proto.skills.WorkflowElement.prototype.getElementCase = function() {
  return /** @type {proto.skills.WorkflowElement.ElementCase} */(jspb.Message.computeOneofCase(this, proto.skills.WorkflowElement.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WorkflowElement.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WorkflowElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WorkflowElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowElement.toObject = function(includeInstance, msg) {
  var f, obj = {
slider: (f = msg.getSlider()) && pbtypes_gen_skills_ui_slider_pb.UiSlider.toObject(includeInstance, f),
toggle: (f = msg.getToggle()) && pbtypes_gen_skills_ui_toggle_pb.UiToggle.toObject(includeInstance, f),
button: (f = msg.getButton()) && pbtypes_gen_skills_ui_button_pb.UiButton.toObject(includeInstance, f),
label: (f = msg.getLabel()) && proto.skills.UiLabel.toObject(includeInstance, f),
stats: (f = msg.getStats()) && proto.skills.UiStatsView.toObject(includeInstance, f),
textField: (f = msg.getTextField()) && proto.skills.UiTextField.toObject(includeInstance, f),
image: (f = msg.getImage()) && proto.skills.UiImage.toObject(includeInstance, f),
submenu: (f = msg.getSubmenu()) && proto.skills.UiSubmenu.toObject(includeInstance, f),
picker: (f = msg.getPicker()) && pbtypes_gen_skills_ui_radio_group_pb.UiRadioGroup.toObject(includeInstance, f),
space: (f = msg.getSpace()) && proto.skills.UiFlexibleSpace.toObject(includeInstance, f),
progressBar: (f = msg.getProgressBar()) && proto.skills.UiProgressBar.toObject(includeInstance, f),
checkbox: (f = msg.getCheckbox()) && proto.skills.UiCheckbox.toObject(includeInstance, f),
imageSegmentControl: (f = msg.getImageSegmentControl()) && proto.skills.UiImageSegmentControl.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WorkflowElement}
 */
proto.skills.WorkflowElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WorkflowElement;
  return proto.skills.WorkflowElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WorkflowElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WorkflowElement}
 */
proto.skills.WorkflowElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_skills_ui_slider_pb.UiSlider;
      reader.readMessage(value,pbtypes_gen_skills_ui_slider_pb.UiSlider.deserializeBinaryFromReader);
      msg.setSlider(value);
      break;
    case 2:
      var value = new pbtypes_gen_skills_ui_toggle_pb.UiToggle;
      reader.readMessage(value,pbtypes_gen_skills_ui_toggle_pb.UiToggle.deserializeBinaryFromReader);
      msg.setToggle(value);
      break;
    case 3:
      var value = new pbtypes_gen_skills_ui_button_pb.UiButton;
      reader.readMessage(value,pbtypes_gen_skills_ui_button_pb.UiButton.deserializeBinaryFromReader);
      msg.setButton(value);
      break;
    case 4:
      var value = new proto.skills.UiLabel;
      reader.readMessage(value,proto.skills.UiLabel.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    case 5:
      var value = new proto.skills.UiStatsView;
      reader.readMessage(value,proto.skills.UiStatsView.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    case 6:
      var value = new proto.skills.UiTextField;
      reader.readMessage(value,proto.skills.UiTextField.deserializeBinaryFromReader);
      msg.setTextField(value);
      break;
    case 7:
      var value = new proto.skills.UiImage;
      reader.readMessage(value,proto.skills.UiImage.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 8:
      var value = new proto.skills.UiSubmenu;
      reader.readMessage(value,proto.skills.UiSubmenu.deserializeBinaryFromReader);
      msg.setSubmenu(value);
      break;
    case 9:
      var value = new pbtypes_gen_skills_ui_radio_group_pb.UiRadioGroup;
      reader.readMessage(value,pbtypes_gen_skills_ui_radio_group_pb.UiRadioGroup.deserializeBinaryFromReader);
      msg.setPicker(value);
      break;
    case 10:
      var value = new proto.skills.UiFlexibleSpace;
      reader.readMessage(value,proto.skills.UiFlexibleSpace.deserializeBinaryFromReader);
      msg.setSpace(value);
      break;
    case 11:
      var value = new proto.skills.UiProgressBar;
      reader.readMessage(value,proto.skills.UiProgressBar.deserializeBinaryFromReader);
      msg.setProgressBar(value);
      break;
    case 12:
      var value = new proto.skills.UiCheckbox;
      reader.readMessage(value,proto.skills.UiCheckbox.deserializeBinaryFromReader);
      msg.setCheckbox(value);
      break;
    case 13:
      var value = new proto.skills.UiImageSegmentControl;
      reader.readMessage(value,proto.skills.UiImageSegmentControl.deserializeBinaryFromReader);
      msg.setImageSegmentControl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WorkflowElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WorkflowElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WorkflowElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlider();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_skills_ui_slider_pb.UiSlider.serializeBinaryToWriter
    );
  }
  f = message.getToggle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_skills_ui_toggle_pb.UiToggle.serializeBinaryToWriter
    );
  }
  f = message.getButton();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_skills_ui_button_pb.UiButton.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.UiLabel.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.skills.UiStatsView.serializeBinaryToWriter
    );
  }
  f = message.getTextField();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skills.UiTextField.serializeBinaryToWriter
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.skills.UiImage.serializeBinaryToWriter
    );
  }
  f = message.getSubmenu();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.skills.UiSubmenu.serializeBinaryToWriter
    );
  }
  f = message.getPicker();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      pbtypes_gen_skills_ui_radio_group_pb.UiRadioGroup.serializeBinaryToWriter
    );
  }
  f = message.getSpace();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.skills.UiFlexibleSpace.serializeBinaryToWriter
    );
  }
  f = message.getProgressBar();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.skills.UiProgressBar.serializeBinaryToWriter
    );
  }
  f = message.getCheckbox();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.skills.UiCheckbox.serializeBinaryToWriter
    );
  }
  f = message.getImageSegmentControl();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.skills.UiImageSegmentControl.serializeBinaryToWriter
    );
  }
};


/**
 * optional UiSlider slider = 1;
 * @return {?proto.skills.UiSlider}
 */
proto.skills.WorkflowElement.prototype.getSlider = function() {
  return /** @type{?proto.skills.UiSlider} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_ui_slider_pb.UiSlider, 1));
};


/**
 * @param {?proto.skills.UiSlider|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setSlider = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearSlider = function() {
  return this.setSlider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasSlider = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UiToggle toggle = 2;
 * @return {?proto.skills.UiToggle}
 */
proto.skills.WorkflowElement.prototype.getToggle = function() {
  return /** @type{?proto.skills.UiToggle} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_ui_toggle_pb.UiToggle, 2));
};


/**
 * @param {?proto.skills.UiToggle|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setToggle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearToggle = function() {
  return this.setToggle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasToggle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UiButton button = 3;
 * @return {?proto.skills.UiButton}
 */
proto.skills.WorkflowElement.prototype.getButton = function() {
  return /** @type{?proto.skills.UiButton} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_ui_button_pb.UiButton, 3));
};


/**
 * @param {?proto.skills.UiButton|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setButton = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearButton = function() {
  return this.setButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasButton = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UiLabel label = 4;
 * @return {?proto.skills.UiLabel}
 */
proto.skills.WorkflowElement.prototype.getLabel = function() {
  return /** @type{?proto.skills.UiLabel} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiLabel, 4));
};


/**
 * @param {?proto.skills.UiLabel|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setLabel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UiStatsView stats = 5;
 * @return {?proto.skills.UiStatsView}
 */
proto.skills.WorkflowElement.prototype.getStats = function() {
  return /** @type{?proto.skills.UiStatsView} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiStatsView, 5));
};


/**
 * @param {?proto.skills.UiStatsView|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setStats = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasStats = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UiTextField text_field = 6;
 * @return {?proto.skills.UiTextField}
 */
proto.skills.WorkflowElement.prototype.getTextField = function() {
  return /** @type{?proto.skills.UiTextField} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiTextField, 6));
};


/**
 * @param {?proto.skills.UiTextField|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setTextField = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearTextField = function() {
  return this.setTextField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasTextField = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UiImage image = 7;
 * @return {?proto.skills.UiImage}
 */
proto.skills.WorkflowElement.prototype.getImage = function() {
  return /** @type{?proto.skills.UiImage} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiImage, 7));
};


/**
 * @param {?proto.skills.UiImage|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setImage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasImage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional UiSubmenu submenu = 8;
 * @return {?proto.skills.UiSubmenu}
 */
proto.skills.WorkflowElement.prototype.getSubmenu = function() {
  return /** @type{?proto.skills.UiSubmenu} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiSubmenu, 8));
};


/**
 * @param {?proto.skills.UiSubmenu|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setSubmenu = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearSubmenu = function() {
  return this.setSubmenu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasSubmenu = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional UiRadioGroup picker = 9;
 * @return {?proto.skills.UiRadioGroup}
 */
proto.skills.WorkflowElement.prototype.getPicker = function() {
  return /** @type{?proto.skills.UiRadioGroup} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_ui_radio_group_pb.UiRadioGroup, 9));
};


/**
 * @param {?proto.skills.UiRadioGroup|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setPicker = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearPicker = function() {
  return this.setPicker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasPicker = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional UiFlexibleSpace space = 10;
 * @return {?proto.skills.UiFlexibleSpace}
 */
proto.skills.WorkflowElement.prototype.getSpace = function() {
  return /** @type{?proto.skills.UiFlexibleSpace} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiFlexibleSpace, 10));
};


/**
 * @param {?proto.skills.UiFlexibleSpace|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setSpace = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearSpace = function() {
  return this.setSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasSpace = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional UiProgressBar progress_bar = 11;
 * @return {?proto.skills.UiProgressBar}
 */
proto.skills.WorkflowElement.prototype.getProgressBar = function() {
  return /** @type{?proto.skills.UiProgressBar} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiProgressBar, 11));
};


/**
 * @param {?proto.skills.UiProgressBar|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setProgressBar = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearProgressBar = function() {
  return this.setProgressBar(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasProgressBar = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional UiCheckbox checkbox = 12;
 * @return {?proto.skills.UiCheckbox}
 */
proto.skills.WorkflowElement.prototype.getCheckbox = function() {
  return /** @type{?proto.skills.UiCheckbox} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiCheckbox, 12));
};


/**
 * @param {?proto.skills.UiCheckbox|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setCheckbox = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearCheckbox = function() {
  return this.setCheckbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasCheckbox = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional UiImageSegmentControl image_segment_control = 13;
 * @return {?proto.skills.UiImageSegmentControl}
 */
proto.skills.WorkflowElement.prototype.getImageSegmentControl = function() {
  return /** @type{?proto.skills.UiImageSegmentControl} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiImageSegmentControl, 13));
};


/**
 * @param {?proto.skills.UiImageSegmentControl|undefined} value
 * @return {!proto.skills.WorkflowElement} returns this
*/
proto.skills.WorkflowElement.prototype.setImageSegmentControl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.skills.WorkflowElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowElement} returns this
 */
proto.skills.WorkflowElement.prototype.clearImageSegmentControl = function() {
  return this.setImageSegmentControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowElement.prototype.hasImageSegmentControl = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.UiImageSegmentControl.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiImageSegmentControl.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiImageSegmentControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiImageSegmentControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiImageSegmentControl.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
label: jspb.Message.getFieldWithDefault(msg, 2, ""),
imageId: jspb.Message.getFieldWithDefault(msg, 3, ""),
numColumns: jspb.Message.getFieldWithDefault(msg, 4, 0),
optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.skills.UiSegmentControlOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiImageSegmentControl}
 */
proto.skills.UiImageSegmentControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiImageSegmentControl;
  return proto.skills.UiImageSegmentControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiImageSegmentControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiImageSegmentControl}
 */
proto.skills.UiImageSegmentControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumColumns(value);
      break;
    case 5:
      var value = new proto.skills.UiSegmentControlOption;
      reader.readMessage(value,proto.skills.UiSegmentControlOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiImageSegmentControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiImageSegmentControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiImageSegmentControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiImageSegmentControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNumColumns();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.skills.UiSegmentControlOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skills.UiImageSegmentControl.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiImageSegmentControl} returns this
 */
proto.skills.UiImageSegmentControl.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.skills.UiImageSegmentControl.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiImageSegmentControl} returns this
 */
proto.skills.UiImageSegmentControl.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_id = 3;
 * @return {string}
 */
proto.skills.UiImageSegmentControl.prototype.getImageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiImageSegmentControl} returns this
 */
proto.skills.UiImageSegmentControl.prototype.setImageId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 num_columns = 4;
 * @return {number}
 */
proto.skills.UiImageSegmentControl.prototype.getNumColumns = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.UiImageSegmentControl} returns this
 */
proto.skills.UiImageSegmentControl.prototype.setNumColumns = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated UiSegmentControlOption options = 5;
 * @return {!Array<!proto.skills.UiSegmentControlOption>}
 */
proto.skills.UiImageSegmentControl.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.skills.UiSegmentControlOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.UiSegmentControlOption, 5));
};


/**
 * @param {!Array<!proto.skills.UiSegmentControlOption>} value
 * @return {!proto.skills.UiImageSegmentControl} returns this
*/
proto.skills.UiImageSegmentControl.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.skills.UiSegmentControlOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.UiSegmentControlOption}
 */
proto.skills.UiImageSegmentControl.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.skills.UiSegmentControlOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.UiImageSegmentControl} returns this
 */
proto.skills.UiImageSegmentControl.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiSegmentControlOption.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiSegmentControlOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiSegmentControlOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiSegmentControlOption.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
selected: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiSegmentControlOption}
 */
proto.skills.UiSegmentControlOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiSegmentControlOption;
  return proto.skills.UiSegmentControlOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiSegmentControlOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiSegmentControlOption}
 */
proto.skills.UiSegmentControlOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiSegmentControlOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiSegmentControlOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiSegmentControlOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiSegmentControlOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skills.UiSegmentControlOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiSegmentControlOption} returns this
 */
proto.skills.UiSegmentControlOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool selected = 4;
 * @return {boolean}
 */
proto.skills.UiSegmentControlOption.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.UiSegmentControlOption} returns this
 */
proto.skills.UiSegmentControlOption.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiFlexibleSpace.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiFlexibleSpace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiFlexibleSpace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiFlexibleSpace.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiFlexibleSpace}
 */
proto.skills.UiFlexibleSpace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiFlexibleSpace;
  return proto.skills.UiFlexibleSpace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiFlexibleSpace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiFlexibleSpace}
 */
proto.skills.UiFlexibleSpace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiFlexibleSpace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiFlexibleSpace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiFlexibleSpace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiFlexibleSpace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiImage.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiImage.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
imageId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiImage}
 */
proto.skills.UiImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiImage;
  return proto.skills.UiImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiImage}
 */
proto.skills.UiImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skills.UiImage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiImage} returns this
 */
proto.skills.UiImage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_id = 2;
 * @return {string}
 */
proto.skills.UiImage.prototype.getImageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiImage} returns this
 */
proto.skills.UiImage.prototype.setImageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiLabel.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiLabel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiLabel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiLabel.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
text: jspb.Message.getFieldWithDefault(msg, 2, ""),
warningText: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiLabel}
 */
proto.skills.UiLabel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiLabel;
  return proto.skills.UiLabel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiLabel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiLabel}
 */
proto.skills.UiLabel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWarningText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiLabel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiLabel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiLabel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiLabel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWarningText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skills.UiLabel.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiLabel} returns this
 */
proto.skills.UiLabel.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.skills.UiLabel.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiLabel} returns this
 */
proto.skills.UiLabel.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string warning_text = 3;
 * @return {string}
 */
proto.skills.UiLabel.prototype.getWarningText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiLabel} returns this
 */
proto.skills.UiLabel.prototype.setWarningText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiTextField.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiTextField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiTextField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiTextField.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
value: jspb.Message.getFieldWithDefault(msg, 2, ""),
placeholder: jspb.Message.getFieldWithDefault(msg, 3, ""),
disabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
isNumerical: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiTextField}
 */
proto.skills.UiTextField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiTextField;
  return proto.skills.UiTextField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiTextField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiTextField}
 */
proto.skills.UiTextField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceholder(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNumerical(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiTextField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiTextField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiTextField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiTextField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlaceholder();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsNumerical();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skills.UiTextField.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiTextField} returns this
 */
proto.skills.UiTextField.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.skills.UiTextField.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiTextField} returns this
 */
proto.skills.UiTextField.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string placeholder = 3;
 * @return {string}
 */
proto.skills.UiTextField.prototype.getPlaceholder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiTextField} returns this
 */
proto.skills.UiTextField.prototype.setPlaceholder = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool disabled = 4;
 * @return {boolean}
 */
proto.skills.UiTextField.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.UiTextField} returns this
 */
proto.skills.UiTextField.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_numerical = 5;
 * @return {boolean}
 */
proto.skills.UiTextField.prototype.getIsNumerical = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.UiTextField} returns this
 */
proto.skills.UiTextField.prototype.setIsNumerical = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.WorkflowStep.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WorkflowStep.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WorkflowStep.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WorkflowStep} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowStep.toObject = function(includeInstance, msg) {
  var f, obj = {
stepId: jspb.Message.getFieldWithDefault(msg, 1, ""),
stepTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
completed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
isEditable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
elementsList: jspb.Message.toObjectList(msg.getElementsList(),
    proto.skills.WorkflowElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WorkflowStep}
 */
proto.skills.WorkflowStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WorkflowStep;
  return proto.skills.WorkflowStep.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WorkflowStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WorkflowStep}
 */
proto.skills.WorkflowStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStepId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStepTitle(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEditable(value);
      break;
    case 5:
      var value = new proto.skills.WorkflowElement;
      reader.readMessage(value,proto.skills.WorkflowElement.deserializeBinaryFromReader);
      msg.addElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WorkflowStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WorkflowStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WorkflowStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStepTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsEditable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.skills.WorkflowElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string step_id = 1;
 * @return {string}
 */
proto.skills.WorkflowStep.prototype.getStepId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.WorkflowStep} returns this
 */
proto.skills.WorkflowStep.prototype.setStepId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string step_title = 2;
 * @return {string}
 */
proto.skills.WorkflowStep.prototype.getStepTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.WorkflowStep} returns this
 */
proto.skills.WorkflowStep.prototype.setStepTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool completed = 3;
 * @return {boolean}
 */
proto.skills.WorkflowStep.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.WorkflowStep} returns this
 */
proto.skills.WorkflowStep.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_editable = 4;
 * @return {boolean}
 */
proto.skills.WorkflowStep.prototype.getIsEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.WorkflowStep} returns this
 */
proto.skills.WorkflowStep.prototype.setIsEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated WorkflowElement elements = 5;
 * @return {!Array<!proto.skills.WorkflowElement>}
 */
proto.skills.WorkflowStep.prototype.getElementsList = function() {
  return /** @type{!Array<!proto.skills.WorkflowElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.WorkflowElement, 5));
};


/**
 * @param {!Array<!proto.skills.WorkflowElement>} value
 * @return {!proto.skills.WorkflowStep} returns this
*/
proto.skills.WorkflowStep.prototype.setElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.skills.WorkflowElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.WorkflowElement}
 */
proto.skills.WorkflowStep.prototype.addElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.skills.WorkflowElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.WorkflowStep} returns this
 */
proto.skills.WorkflowStep.prototype.clearElementsList = function() {
  return this.setElementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiStat.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiStat.toObject = function(includeInstance, msg) {
  var f, obj = {
label: jspb.Message.getFieldWithDefault(msg, 1, ""),
value: jspb.Message.getFieldWithDefault(msg, 2, ""),
style: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiStat}
 */
proto.skills.UiStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiStat;
  return proto.skills.UiStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiStat}
 */
proto.skills.UiStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {!proto.skills.AttributeStyle} */ (reader.readEnum());
      msg.setStyle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStyle();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.skills.UiStat.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiStat} returns this
 */
proto.skills.UiStat.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.skills.UiStat.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiStat} returns this
 */
proto.skills.UiStat.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AttributeStyle style = 3;
 * @return {!proto.skills.AttributeStyle}
 */
proto.skills.UiStat.prototype.getStyle = function() {
  return /** @type {!proto.skills.AttributeStyle} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.skills.AttributeStyle} value
 * @return {!proto.skills.UiStat} returns this
 */
proto.skills.UiStat.prototype.setStyle = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiSubmenu.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiSubmenu.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiSubmenu} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiSubmenu.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
pageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
textLabel: jspb.Message.getFieldWithDefault(msg, 3, ""),
detailLabel: jspb.Message.getFieldWithDefault(msg, 4, ""),
showDangerIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
disabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
tag: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiSubmenu}
 */
proto.skills.UiSubmenu.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiSubmenu;
  return proto.skills.UiSubmenu.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiSubmenu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiSubmenu}
 */
proto.skills.UiSubmenu.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailLabel(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowDangerIndicator(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiSubmenu.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiSubmenu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiSubmenu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiSubmenu.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTextLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDetailLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getShowDangerIndicator();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skills.UiSubmenu.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiSubmenu} returns this
 */
proto.skills.UiSubmenu.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string page_id = 2;
 * @return {string}
 */
proto.skills.UiSubmenu.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiSubmenu} returns this
 */
proto.skills.UiSubmenu.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text_label = 3;
 * @return {string}
 */
proto.skills.UiSubmenu.prototype.getTextLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiSubmenu} returns this
 */
proto.skills.UiSubmenu.prototype.setTextLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string detail_label = 4;
 * @return {string}
 */
proto.skills.UiSubmenu.prototype.getDetailLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiSubmenu} returns this
 */
proto.skills.UiSubmenu.prototype.setDetailLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool show_danger_indicator = 5;
 * @return {boolean}
 */
proto.skills.UiSubmenu.prototype.getShowDangerIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.UiSubmenu} returns this
 */
proto.skills.UiSubmenu.prototype.setShowDangerIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool disabled = 6;
 * @return {boolean}
 */
proto.skills.UiSubmenu.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.UiSubmenu} returns this
 */
proto.skills.UiSubmenu.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string tag = 7;
 * @return {string}
 */
proto.skills.UiSubmenu.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiSubmenu} returns this
 */
proto.skills.UiSubmenu.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WorkflowMenuDisplayStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WorkflowMenuDisplayStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WorkflowMenuDisplayStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowMenuDisplayStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
state: jspb.Message.getFieldWithDefault(msg, 1, 0),
preference: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WorkflowMenuDisplayStateRequest}
 */
proto.skills.WorkflowMenuDisplayStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WorkflowMenuDisplayStateRequest;
  return proto.skills.WorkflowMenuDisplayStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WorkflowMenuDisplayStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WorkflowMenuDisplayStateRequest}
 */
proto.skills.WorkflowMenuDisplayStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.skills.WorkflowMenuDisplayState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {!proto.skills.WorkflowMenuDisplayPreference} */ (reader.readEnum());
      msg.setPreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WorkflowMenuDisplayStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WorkflowMenuDisplayStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WorkflowMenuDisplayStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowMenuDisplayStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPreference();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional WorkflowMenuDisplayState state = 1;
 * @return {!proto.skills.WorkflowMenuDisplayState}
 */
proto.skills.WorkflowMenuDisplayStateRequest.prototype.getState = function() {
  return /** @type {!proto.skills.WorkflowMenuDisplayState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.skills.WorkflowMenuDisplayState} value
 * @return {!proto.skills.WorkflowMenuDisplayStateRequest} returns this
 */
proto.skills.WorkflowMenuDisplayStateRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional WorkflowMenuDisplayPreference preference = 2;
 * @return {!proto.skills.WorkflowMenuDisplayPreference}
 */
proto.skills.WorkflowMenuDisplayStateRequest.prototype.getPreference = function() {
  return /** @type {!proto.skills.WorkflowMenuDisplayPreference} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.skills.WorkflowMenuDisplayPreference} value
 * @return {!proto.skills.WorkflowMenuDisplayStateRequest} returns this
 */
proto.skills.WorkflowMenuDisplayStateRequest.prototype.setPreference = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.WorkflowClientState.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WorkflowClientState.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WorkflowClientState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WorkflowClientState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowClientState.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
nonce: jspb.Message.getFieldWithDefault(msg, 2, 0),
primaryStepId: jspb.Message.getFieldWithDefault(msg, 3, ""),
secondaryStepId: jspb.Message.getFieldWithDefault(msg, 7, ""),
lastUpdateUtime: jspb.Message.getFieldWithDefault(msg, 4, 0),
pageStackList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
displayState: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WorkflowClientState}
 */
proto.skills.WorkflowClientState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WorkflowClientState;
  return proto.skills.WorkflowClientState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WorkflowClientState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WorkflowClientState}
 */
proto.skills.WorkflowClientState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNonce(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryStepId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryStepId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdateUtime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addPageStack(value);
      break;
    case 6:
      var value = /** @type {!proto.skills.WorkflowMenuDisplayState} */ (reader.readEnum());
      msg.setDisplayState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WorkflowClientState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WorkflowClientState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WorkflowClientState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowClientState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPrimaryStepId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSecondaryStepId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastUpdateUtime();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getPageStackList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getDisplayState();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional uint64 utime = 1;
 * @return {number}
 */
proto.skills.WorkflowClientState.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WorkflowClientState} returns this
 */
proto.skills.WorkflowClientState.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 nonce = 2;
 * @return {number}
 */
proto.skills.WorkflowClientState.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WorkflowClientState} returns this
 */
proto.skills.WorkflowClientState.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string primary_step_id = 3;
 * @return {string}
 */
proto.skills.WorkflowClientState.prototype.getPrimaryStepId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.WorkflowClientState} returns this
 */
proto.skills.WorkflowClientState.prototype.setPrimaryStepId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string secondary_step_id = 7;
 * @return {string}
 */
proto.skills.WorkflowClientState.prototype.getSecondaryStepId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.WorkflowClientState} returns this
 */
proto.skills.WorkflowClientState.prototype.setSecondaryStepId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint64 last_update_utime = 4;
 * @return {number}
 */
proto.skills.WorkflowClientState.prototype.getLastUpdateUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WorkflowClientState} returns this
 */
proto.skills.WorkflowClientState.prototype.setLastUpdateUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated string page_stack = 5;
 * @return {!Array<string>}
 */
proto.skills.WorkflowClientState.prototype.getPageStackList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.skills.WorkflowClientState} returns this
 */
proto.skills.WorkflowClientState.prototype.setPageStackList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.skills.WorkflowClientState} returns this
 */
proto.skills.WorkflowClientState.prototype.addPageStack = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.WorkflowClientState} returns this
 */
proto.skills.WorkflowClientState.prototype.clearPageStackList = function() {
  return this.setPageStackList([]);
};


/**
 * optional WorkflowMenuDisplayState display_state = 6;
 * @return {!proto.skills.WorkflowMenuDisplayState}
 */
proto.skills.WorkflowClientState.prototype.getDisplayState = function() {
  return /** @type {!proto.skills.WorkflowMenuDisplayState} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.skills.WorkflowMenuDisplayState} value
 * @return {!proto.skills.WorkflowClientState} returns this
 */
proto.skills.WorkflowClientState.prototype.setDisplayState = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.UiStatsView.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiStatsView.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiStatsView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiStatsView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiStatsView.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 2, ""),
statsList: jspb.Message.toObjectList(msg.getStatsList(),
    proto.skills.UiStat.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiStatsView}
 */
proto.skills.UiStatsView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiStatsView;
  return proto.skills.UiStatsView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiStatsView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiStatsView}
 */
proto.skills.UiStatsView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 1:
      var value = new proto.skills.UiStat;
      reader.readMessage(value,proto.skills.UiStat.deserializeBinaryFromReader);
      msg.addStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiStatsView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiStatsView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiStatsView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiStatsView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.skills.UiStat.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.skills.UiStatsView.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiStatsView} returns this
 */
proto.skills.UiStatsView.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated UiStat stats = 1;
 * @return {!Array<!proto.skills.UiStat>}
 */
proto.skills.UiStatsView.prototype.getStatsList = function() {
  return /** @type{!Array<!proto.skills.UiStat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.UiStat, 1));
};


/**
 * @param {!Array<!proto.skills.UiStat>} value
 * @return {!proto.skills.UiStatsView} returns this
*/
proto.skills.UiStatsView.prototype.setStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.skills.UiStat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.UiStat}
 */
proto.skills.UiStatsView.prototype.addStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.skills.UiStat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.UiStatsView} returns this
 */
proto.skills.UiStatsView.prototype.clearStatsList = function() {
  return this.setStatsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.WorkflowHUD.repeatedFields_ = [1,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WorkflowHUD.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WorkflowHUD.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WorkflowHUD} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowHUD.toObject = function(includeInstance, msg) {
  var f, obj = {
hudButtonsList: jspb.Message.toObjectList(msg.getHudButtonsList(),
    pbtypes_gen_skills_ui_button_pb.UiButton.toObject, includeInstance),
statistics: (f = msg.getStatistics()) && proto.skills.UiStatsView.toObject(includeInstance, f),
photoCapture: (f = msg.getPhotoCapture()) && pbtypes_gen_skills_ui_button_pb.UiButton.toObject(includeInstance, f),
toggleCoverageMesh: (f = msg.getToggleCoverageMesh()) && pbtypes_gen_skills_ui_button_pb.UiButton.toObject(includeInstance, f),
viewpointSelection: (f = msg.getViewpointSelection()) && pbtypes_gen_skills_ui_button_pb.UiButton.toObject(includeInstance, f),
rthButton: (f = msg.getRthButton()) && pbtypes_gen_skills_ui_button_pb.UiButton.toObject(includeInstance, f),
mapToggleButton: (f = msg.getMapToggleButton()) && pbtypes_gen_skills_ui_button_pb.UiButton.toObject(includeInstance, f),
enableHwButtons: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
promptsList: jspb.Message.toObjectList(msg.getPromptsList(),
    pbtypes_gen_skills_ui_prompt_pb.UiPrompt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WorkflowHUD}
 */
proto.skills.WorkflowHUD.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WorkflowHUD;
  return proto.skills.WorkflowHUD.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WorkflowHUD} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WorkflowHUD}
 */
proto.skills.WorkflowHUD.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_skills_ui_button_pb.UiButton;
      reader.readMessage(value,pbtypes_gen_skills_ui_button_pb.UiButton.deserializeBinaryFromReader);
      msg.addHudButtons(value);
      break;
    case 2:
      var value = new proto.skills.UiStatsView;
      reader.readMessage(value,proto.skills.UiStatsView.deserializeBinaryFromReader);
      msg.setStatistics(value);
      break;
    case 3:
      var value = new pbtypes_gen_skills_ui_button_pb.UiButton;
      reader.readMessage(value,pbtypes_gen_skills_ui_button_pb.UiButton.deserializeBinaryFromReader);
      msg.setPhotoCapture(value);
      break;
    case 4:
      var value = new pbtypes_gen_skills_ui_button_pb.UiButton;
      reader.readMessage(value,pbtypes_gen_skills_ui_button_pb.UiButton.deserializeBinaryFromReader);
      msg.setToggleCoverageMesh(value);
      break;
    case 5:
      var value = new pbtypes_gen_skills_ui_button_pb.UiButton;
      reader.readMessage(value,pbtypes_gen_skills_ui_button_pb.UiButton.deserializeBinaryFromReader);
      msg.setViewpointSelection(value);
      break;
    case 6:
      var value = new pbtypes_gen_skills_ui_button_pb.UiButton;
      reader.readMessage(value,pbtypes_gen_skills_ui_button_pb.UiButton.deserializeBinaryFromReader);
      msg.setRthButton(value);
      break;
    case 7:
      var value = new pbtypes_gen_skills_ui_button_pb.UiButton;
      reader.readMessage(value,pbtypes_gen_skills_ui_button_pb.UiButton.deserializeBinaryFromReader);
      msg.setMapToggleButton(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableHwButtons(value);
      break;
    case 9:
      var value = new pbtypes_gen_skills_ui_prompt_pb.UiPrompt;
      reader.readMessage(value,pbtypes_gen_skills_ui_prompt_pb.UiPrompt.deserializeBinaryFromReader);
      msg.addPrompts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WorkflowHUD.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WorkflowHUD.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WorkflowHUD} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowHUD.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHudButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      pbtypes_gen_skills_ui_button_pb.UiButton.serializeBinaryToWriter
    );
  }
  f = message.getStatistics();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.UiStatsView.serializeBinaryToWriter
    );
  }
  f = message.getPhotoCapture();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_skills_ui_button_pb.UiButton.serializeBinaryToWriter
    );
  }
  f = message.getToggleCoverageMesh();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_gen_skills_ui_button_pb.UiButton.serializeBinaryToWriter
    );
  }
  f = message.getViewpointSelection();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      pbtypes_gen_skills_ui_button_pb.UiButton.serializeBinaryToWriter
    );
  }
  f = message.getRthButton();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pbtypes_gen_skills_ui_button_pb.UiButton.serializeBinaryToWriter
    );
  }
  f = message.getMapToggleButton();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pbtypes_gen_skills_ui_button_pb.UiButton.serializeBinaryToWriter
    );
  }
  f = message.getEnableHwButtons();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPromptsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      pbtypes_gen_skills_ui_prompt_pb.UiPrompt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UiButton hud_buttons = 1;
 * @return {!Array<!proto.skills.UiButton>}
 */
proto.skills.WorkflowHUD.prototype.getHudButtonsList = function() {
  return /** @type{!Array<!proto.skills.UiButton>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_skills_ui_button_pb.UiButton, 1));
};


/**
 * @param {!Array<!proto.skills.UiButton>} value
 * @return {!proto.skills.WorkflowHUD} returns this
*/
proto.skills.WorkflowHUD.prototype.setHudButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.skills.UiButton=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.UiButton}
 */
proto.skills.WorkflowHUD.prototype.addHudButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.skills.UiButton, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.WorkflowHUD} returns this
 */
proto.skills.WorkflowHUD.prototype.clearHudButtonsList = function() {
  return this.setHudButtonsList([]);
};


/**
 * optional UiStatsView statistics = 2;
 * @return {?proto.skills.UiStatsView}
 */
proto.skills.WorkflowHUD.prototype.getStatistics = function() {
  return /** @type{?proto.skills.UiStatsView} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiStatsView, 2));
};


/**
 * @param {?proto.skills.UiStatsView|undefined} value
 * @return {!proto.skills.WorkflowHUD} returns this
*/
proto.skills.WorkflowHUD.prototype.setStatistics = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowHUD} returns this
 */
proto.skills.WorkflowHUD.prototype.clearStatistics = function() {
  return this.setStatistics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowHUD.prototype.hasStatistics = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UiButton photo_capture = 3;
 * @return {?proto.skills.UiButton}
 */
proto.skills.WorkflowHUD.prototype.getPhotoCapture = function() {
  return /** @type{?proto.skills.UiButton} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_ui_button_pb.UiButton, 3));
};


/**
 * @param {?proto.skills.UiButton|undefined} value
 * @return {!proto.skills.WorkflowHUD} returns this
*/
proto.skills.WorkflowHUD.prototype.setPhotoCapture = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowHUD} returns this
 */
proto.skills.WorkflowHUD.prototype.clearPhotoCapture = function() {
  return this.setPhotoCapture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowHUD.prototype.hasPhotoCapture = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UiButton toggle_coverage_mesh = 4;
 * @return {?proto.skills.UiButton}
 */
proto.skills.WorkflowHUD.prototype.getToggleCoverageMesh = function() {
  return /** @type{?proto.skills.UiButton} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_ui_button_pb.UiButton, 4));
};


/**
 * @param {?proto.skills.UiButton|undefined} value
 * @return {!proto.skills.WorkflowHUD} returns this
*/
proto.skills.WorkflowHUD.prototype.setToggleCoverageMesh = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowHUD} returns this
 */
proto.skills.WorkflowHUD.prototype.clearToggleCoverageMesh = function() {
  return this.setToggleCoverageMesh(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowHUD.prototype.hasToggleCoverageMesh = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UiButton viewpoint_selection = 5;
 * @return {?proto.skills.UiButton}
 */
proto.skills.WorkflowHUD.prototype.getViewpointSelection = function() {
  return /** @type{?proto.skills.UiButton} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_ui_button_pb.UiButton, 5));
};


/**
 * @param {?proto.skills.UiButton|undefined} value
 * @return {!proto.skills.WorkflowHUD} returns this
*/
proto.skills.WorkflowHUD.prototype.setViewpointSelection = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowHUD} returns this
 */
proto.skills.WorkflowHUD.prototype.clearViewpointSelection = function() {
  return this.setViewpointSelection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowHUD.prototype.hasViewpointSelection = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UiButton rth_button = 6;
 * @return {?proto.skills.UiButton}
 */
proto.skills.WorkflowHUD.prototype.getRthButton = function() {
  return /** @type{?proto.skills.UiButton} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_ui_button_pb.UiButton, 6));
};


/**
 * @param {?proto.skills.UiButton|undefined} value
 * @return {!proto.skills.WorkflowHUD} returns this
*/
proto.skills.WorkflowHUD.prototype.setRthButton = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowHUD} returns this
 */
proto.skills.WorkflowHUD.prototype.clearRthButton = function() {
  return this.setRthButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowHUD.prototype.hasRthButton = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UiButton map_toggle_button = 7;
 * @return {?proto.skills.UiButton}
 */
proto.skills.WorkflowHUD.prototype.getMapToggleButton = function() {
  return /** @type{?proto.skills.UiButton} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_ui_button_pb.UiButton, 7));
};


/**
 * @param {?proto.skills.UiButton|undefined} value
 * @return {!proto.skills.WorkflowHUD} returns this
*/
proto.skills.WorkflowHUD.prototype.setMapToggleButton = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowHUD} returns this
 */
proto.skills.WorkflowHUD.prototype.clearMapToggleButton = function() {
  return this.setMapToggleButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowHUD.prototype.hasMapToggleButton = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool enable_hw_buttons = 8;
 * @return {boolean}
 */
proto.skills.WorkflowHUD.prototype.getEnableHwButtons = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.WorkflowHUD} returns this
 */
proto.skills.WorkflowHUD.prototype.setEnableHwButtons = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated UiPrompt prompts = 9;
 * @return {!Array<!proto.skills.UiPrompt>}
 */
proto.skills.WorkflowHUD.prototype.getPromptsList = function() {
  return /** @type{!Array<!proto.skills.UiPrompt>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_skills_ui_prompt_pb.UiPrompt, 9));
};


/**
 * @param {!Array<!proto.skills.UiPrompt>} value
 * @return {!proto.skills.WorkflowHUD} returns this
*/
proto.skills.WorkflowHUD.prototype.setPromptsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.skills.UiPrompt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.UiPrompt}
 */
proto.skills.WorkflowHUD.prototype.addPrompts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.skills.UiPrompt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.WorkflowHUD} returns this
 */
proto.skills.WorkflowHUD.prototype.clearPromptsList = function() {
  return this.setPromptsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.WorkflowSettingsPage.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WorkflowSettingsPage.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WorkflowSettingsPage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WorkflowSettingsPage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowSettingsPage.toObject = function(includeInstance, msg) {
  var f, obj = {
pageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
pageTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
elementsList: jspb.Message.toObjectList(msg.getElementsList(),
    proto.skills.WorkflowElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WorkflowSettingsPage}
 */
proto.skills.WorkflowSettingsPage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WorkflowSettingsPage;
  return proto.skills.WorkflowSettingsPage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WorkflowSettingsPage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WorkflowSettingsPage}
 */
proto.skills.WorkflowSettingsPage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageTitle(value);
      break;
    case 3:
      var value = new proto.skills.WorkflowElement;
      reader.readMessage(value,proto.skills.WorkflowElement.deserializeBinaryFromReader);
      msg.addElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WorkflowSettingsPage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WorkflowSettingsPage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WorkflowSettingsPage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowSettingsPage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.skills.WorkflowElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string page_id = 1;
 * @return {string}
 */
proto.skills.WorkflowSettingsPage.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.WorkflowSettingsPage} returns this
 */
proto.skills.WorkflowSettingsPage.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string page_title = 2;
 * @return {string}
 */
proto.skills.WorkflowSettingsPage.prototype.getPageTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.WorkflowSettingsPage} returns this
 */
proto.skills.WorkflowSettingsPage.prototype.setPageTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated WorkflowElement elements = 3;
 * @return {!Array<!proto.skills.WorkflowElement>}
 */
proto.skills.WorkflowSettingsPage.prototype.getElementsList = function() {
  return /** @type{!Array<!proto.skills.WorkflowElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.WorkflowElement, 3));
};


/**
 * @param {!Array<!proto.skills.WorkflowElement>} value
 * @return {!proto.skills.WorkflowSettingsPage} returns this
*/
proto.skills.WorkflowSettingsPage.prototype.setElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skills.WorkflowElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.WorkflowElement}
 */
proto.skills.WorkflowSettingsPage.prototype.addElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skills.WorkflowElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.WorkflowSettingsPage} returns this
 */
proto.skills.WorkflowSettingsPage.prototype.clearElementsList = function() {
  return this.setElementsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.WorkflowScreen.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.skills.WorkflowScreen.ScreenCase = {
  SCREEN_NOT_SET: 0,
  BASIC: 1,
  POST_FLIGHT: 2
};

/**
 * @return {proto.skills.WorkflowScreen.ScreenCase}
 */
proto.skills.WorkflowScreen.prototype.getScreenCase = function() {
  return /** @type {proto.skills.WorkflowScreen.ScreenCase} */(jspb.Message.computeOneofCase(this, proto.skills.WorkflowScreen.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WorkflowScreen.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WorkflowScreen.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WorkflowScreen} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowScreen.toObject = function(includeInstance, msg) {
  var f, obj = {
basic: (f = msg.getBasic()) && proto.skills.UiScreenBasic.toObject(includeInstance, f),
postFlight: (f = msg.getPostFlight()) && proto.skills.UiScreenPostFlight.toObject(includeInstance, f),
exitCockpit: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WorkflowScreen}
 */
proto.skills.WorkflowScreen.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WorkflowScreen;
  return proto.skills.WorkflowScreen.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WorkflowScreen} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WorkflowScreen}
 */
proto.skills.WorkflowScreen.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.UiScreenBasic;
      reader.readMessage(value,proto.skills.UiScreenBasic.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    case 2:
      var value = new proto.skills.UiScreenPostFlight;
      reader.readMessage(value,proto.skills.UiScreenPostFlight.deserializeBinaryFromReader);
      msg.setPostFlight(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExitCockpit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WorkflowScreen.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WorkflowScreen.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WorkflowScreen} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowScreen.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.UiScreenBasic.serializeBinaryToWriter
    );
  }
  f = message.getPostFlight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.UiScreenPostFlight.serializeBinaryToWriter
    );
  }
  f = message.getExitCockpit();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional UiScreenBasic basic = 1;
 * @return {?proto.skills.UiScreenBasic}
 */
proto.skills.WorkflowScreen.prototype.getBasic = function() {
  return /** @type{?proto.skills.UiScreenBasic} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiScreenBasic, 1));
};


/**
 * @param {?proto.skills.UiScreenBasic|undefined} value
 * @return {!proto.skills.WorkflowScreen} returns this
*/
proto.skills.WorkflowScreen.prototype.setBasic = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.skills.WorkflowScreen.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowScreen} returns this
 */
proto.skills.WorkflowScreen.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowScreen.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UiScreenPostFlight post_flight = 2;
 * @return {?proto.skills.UiScreenPostFlight}
 */
proto.skills.WorkflowScreen.prototype.getPostFlight = function() {
  return /** @type{?proto.skills.UiScreenPostFlight} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiScreenPostFlight, 2));
};


/**
 * @param {?proto.skills.UiScreenPostFlight|undefined} value
 * @return {!proto.skills.WorkflowScreen} returns this
*/
proto.skills.WorkflowScreen.prototype.setPostFlight = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.skills.WorkflowScreen.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowScreen} returns this
 */
proto.skills.WorkflowScreen.prototype.clearPostFlight = function() {
  return this.setPostFlight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowScreen.prototype.hasPostFlight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool exit_cockpit = 3;
 * @return {boolean}
 */
proto.skills.WorkflowScreen.prototype.getExitCockpit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.WorkflowScreen} returns this
 */
proto.skills.WorkflowScreen.prototype.setExitCockpit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.UiScreenPostFlight.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiScreenPostFlight.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiScreenPostFlight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiScreenPostFlight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiScreenPostFlight.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
title: jspb.Message.getFieldWithDefault(msg, 2, ""),
imagePath: jspb.Message.getFieldWithDefault(msg, 3, ""),
heading: jspb.Message.getFieldWithDefault(msg, 4, ""),
subheading: jspb.Message.getFieldWithDefault(msg, 5, ""),
stats: (f = msg.getStats()) && proto.skills.UiStatsView.toObject(includeInstance, f),
buttonsList: jspb.Message.toObjectList(msg.getButtonsList(),
    pbtypes_gen_skills_ui_button_pb.UiButton.toObject, includeInstance),
checkbox: (f = msg.getCheckbox()) && proto.skills.UiCheckbox.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiScreenPostFlight}
 */
proto.skills.UiScreenPostFlight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiScreenPostFlight;
  return proto.skills.UiScreenPostFlight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiScreenPostFlight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiScreenPostFlight}
 */
proto.skills.UiScreenPostFlight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImagePath(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeading(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubheading(value);
      break;
    case 6:
      var value = new proto.skills.UiStatsView;
      reader.readMessage(value,proto.skills.UiStatsView.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    case 7:
      var value = new pbtypes_gen_skills_ui_button_pb.UiButton;
      reader.readMessage(value,pbtypes_gen_skills_ui_button_pb.UiButton.deserializeBinaryFromReader);
      msg.addButtons(value);
      break;
    case 8:
      var value = new proto.skills.UiCheckbox;
      reader.readMessage(value,proto.skills.UiCheckbox.deserializeBinaryFromReader);
      msg.setCheckbox(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiScreenPostFlight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiScreenPostFlight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiScreenPostFlight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiScreenPostFlight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImagePath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHeading();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSubheading();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skills.UiStatsView.serializeBinaryToWriter
    );
  }
  f = message.getButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      pbtypes_gen_skills_ui_button_pb.UiButton.serializeBinaryToWriter
    );
  }
  f = message.getCheckbox();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.skills.UiCheckbox.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skills.UiScreenPostFlight.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiScreenPostFlight} returns this
 */
proto.skills.UiScreenPostFlight.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.skills.UiScreenPostFlight.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiScreenPostFlight} returns this
 */
proto.skills.UiScreenPostFlight.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_path = 3;
 * @return {string}
 */
proto.skills.UiScreenPostFlight.prototype.getImagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiScreenPostFlight} returns this
 */
proto.skills.UiScreenPostFlight.prototype.setImagePath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string heading = 4;
 * @return {string}
 */
proto.skills.UiScreenPostFlight.prototype.getHeading = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiScreenPostFlight} returns this
 */
proto.skills.UiScreenPostFlight.prototype.setHeading = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string subheading = 5;
 * @return {string}
 */
proto.skills.UiScreenPostFlight.prototype.getSubheading = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiScreenPostFlight} returns this
 */
proto.skills.UiScreenPostFlight.prototype.setSubheading = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional UiStatsView stats = 6;
 * @return {?proto.skills.UiStatsView}
 */
proto.skills.UiScreenPostFlight.prototype.getStats = function() {
  return /** @type{?proto.skills.UiStatsView} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiStatsView, 6));
};


/**
 * @param {?proto.skills.UiStatsView|undefined} value
 * @return {!proto.skills.UiScreenPostFlight} returns this
*/
proto.skills.UiScreenPostFlight.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.UiScreenPostFlight} returns this
 */
proto.skills.UiScreenPostFlight.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.UiScreenPostFlight.prototype.hasStats = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated UiButton buttons = 7;
 * @return {!Array<!proto.skills.UiButton>}
 */
proto.skills.UiScreenPostFlight.prototype.getButtonsList = function() {
  return /** @type{!Array<!proto.skills.UiButton>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_skills_ui_button_pb.UiButton, 7));
};


/**
 * @param {!Array<!proto.skills.UiButton>} value
 * @return {!proto.skills.UiScreenPostFlight} returns this
*/
proto.skills.UiScreenPostFlight.prototype.setButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.skills.UiButton=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.UiButton}
 */
proto.skills.UiScreenPostFlight.prototype.addButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.skills.UiButton, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.UiScreenPostFlight} returns this
 */
proto.skills.UiScreenPostFlight.prototype.clearButtonsList = function() {
  return this.setButtonsList([]);
};


/**
 * optional UiCheckbox checkbox = 8;
 * @return {?proto.skills.UiCheckbox}
 */
proto.skills.UiScreenPostFlight.prototype.getCheckbox = function() {
  return /** @type{?proto.skills.UiCheckbox} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiCheckbox, 8));
};


/**
 * @param {?proto.skills.UiCheckbox|undefined} value
 * @return {!proto.skills.UiScreenPostFlight} returns this
*/
proto.skills.UiScreenPostFlight.prototype.setCheckbox = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.UiScreenPostFlight} returns this
 */
proto.skills.UiScreenPostFlight.prototype.clearCheckbox = function() {
  return this.setCheckbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.UiScreenPostFlight.prototype.hasCheckbox = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.UiScreenBasic.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiScreenBasic.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiScreenBasic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiScreenBasic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiScreenBasic.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
imageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
iconType: jspb.Message.getFieldWithDefault(msg, 10, 0),
title: jspb.Message.getFieldWithDefault(msg, 3, ""),
subtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
buttonsList: jspb.Message.toObjectList(msg.getButtonsList(),
    pbtypes_gen_skills_ui_button_pb.UiButton.toObject, includeInstance),
checkbox: (f = msg.getCheckbox()) && proto.skills.UiCheckbox.toObject(includeInstance, f),
progressRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
leftPaneImageId: jspb.Message.getFieldWithDefault(msg, 8, ""),
scanId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiScreenBasic}
 */
proto.skills.UiScreenBasic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiScreenBasic;
  return proto.skills.UiScreenBasic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiScreenBasic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiScreenBasic}
 */
proto.skills.UiScreenBasic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageId(value);
      break;
    case 10:
      var value = /** @type {!proto.skills.WorkflowIconType} */ (reader.readEnum());
      msg.setIconType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 5:
      var value = new pbtypes_gen_skills_ui_button_pb.UiButton;
      reader.readMessage(value,pbtypes_gen_skills_ui_button_pb.UiButton.deserializeBinaryFromReader);
      msg.addButtons(value);
      break;
    case 6:
      var value = new proto.skills.UiCheckbox;
      reader.readMessage(value,proto.skills.UiCheckbox.deserializeBinaryFromReader);
      msg.setCheckbox(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgressRatio(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftPaneImageId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiScreenBasic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiScreenBasic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiScreenBasic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiScreenBasic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIconType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      pbtypes_gen_skills_ui_button_pb.UiButton.serializeBinaryToWriter
    );
  }
  f = message.getCheckbox();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skills.UiCheckbox.serializeBinaryToWriter
    );
  }
  f = message.getProgressRatio();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLeftPaneImageId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getScanId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skills.UiScreenBasic.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiScreenBasic} returns this
 */
proto.skills.UiScreenBasic.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_id = 2;
 * @return {string}
 */
proto.skills.UiScreenBasic.prototype.getImageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiScreenBasic} returns this
 */
proto.skills.UiScreenBasic.prototype.setImageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional WorkflowIconType icon_type = 10;
 * @return {!proto.skills.WorkflowIconType}
 */
proto.skills.UiScreenBasic.prototype.getIconType = function() {
  return /** @type {!proto.skills.WorkflowIconType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.skills.WorkflowIconType} value
 * @return {!proto.skills.UiScreenBasic} returns this
 */
proto.skills.UiScreenBasic.prototype.setIconType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.skills.UiScreenBasic.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiScreenBasic} returns this
 */
proto.skills.UiScreenBasic.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subtitle = 4;
 * @return {string}
 */
proto.skills.UiScreenBasic.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiScreenBasic} returns this
 */
proto.skills.UiScreenBasic.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated UiButton buttons = 5;
 * @return {!Array<!proto.skills.UiButton>}
 */
proto.skills.UiScreenBasic.prototype.getButtonsList = function() {
  return /** @type{!Array<!proto.skills.UiButton>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_skills_ui_button_pb.UiButton, 5));
};


/**
 * @param {!Array<!proto.skills.UiButton>} value
 * @return {!proto.skills.UiScreenBasic} returns this
*/
proto.skills.UiScreenBasic.prototype.setButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.skills.UiButton=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.UiButton}
 */
proto.skills.UiScreenBasic.prototype.addButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.skills.UiButton, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.UiScreenBasic} returns this
 */
proto.skills.UiScreenBasic.prototype.clearButtonsList = function() {
  return this.setButtonsList([]);
};


/**
 * optional UiCheckbox checkbox = 6;
 * @return {?proto.skills.UiCheckbox}
 */
proto.skills.UiScreenBasic.prototype.getCheckbox = function() {
  return /** @type{?proto.skills.UiCheckbox} */ (
    jspb.Message.getWrapperField(this, proto.skills.UiCheckbox, 6));
};


/**
 * @param {?proto.skills.UiCheckbox|undefined} value
 * @return {!proto.skills.UiScreenBasic} returns this
*/
proto.skills.UiScreenBasic.prototype.setCheckbox = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.UiScreenBasic} returns this
 */
proto.skills.UiScreenBasic.prototype.clearCheckbox = function() {
  return this.setCheckbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.UiScreenBasic.prototype.hasCheckbox = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional float progress_ratio = 7;
 * @return {number}
 */
proto.skills.UiScreenBasic.prototype.getProgressRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.UiScreenBasic} returns this
 */
proto.skills.UiScreenBasic.prototype.setProgressRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string left_pane_image_id = 8;
 * @return {string}
 */
proto.skills.UiScreenBasic.prototype.getLeftPaneImageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiScreenBasic} returns this
 */
proto.skills.UiScreenBasic.prototype.setLeftPaneImageId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string scan_id = 9;
 * @return {string}
 */
proto.skills.UiScreenBasic.prototype.getScanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiScreenBasic} returns this
 */
proto.skills.UiScreenBasic.prototype.setScanId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiCheckbox.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiCheckbox.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiCheckbox} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiCheckbox.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
text: jspb.Message.getFieldWithDefault(msg, 2, ""),
value: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiCheckbox}
 */
proto.skills.UiCheckbox.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiCheckbox;
  return proto.skills.UiCheckbox.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiCheckbox} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiCheckbox}
 */
proto.skills.UiCheckbox.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiCheckbox.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiCheckbox.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiCheckbox} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiCheckbox.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skills.UiCheckbox.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiCheckbox} returns this
 */
proto.skills.UiCheckbox.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.skills.UiCheckbox.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiCheckbox} returns this
 */
proto.skills.UiCheckbox.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool value = 3;
 * @return {boolean}
 */
proto.skills.UiCheckbox.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.UiCheckbox} returns this
 */
proto.skills.UiCheckbox.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UiProgressBar.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UiProgressBar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UiProgressBar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiProgressBar.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
progressRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UiProgressBar}
 */
proto.skills.UiProgressBar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UiProgressBar;
  return proto.skills.UiProgressBar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UiProgressBar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UiProgressBar}
 */
proto.skills.UiProgressBar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgressRatio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UiProgressBar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UiProgressBar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UiProgressBar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UiProgressBar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProgressRatio();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skills.UiProgressBar.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.UiProgressBar} returns this
 */
proto.skills.UiProgressBar.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float progress_ratio = 2;
 * @return {number}
 */
proto.skills.UiProgressBar.prototype.getProgressRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.UiProgressBar} returns this
 */
proto.skills.UiProgressBar.prototype.setProgressRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.WorkflowLayout.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WorkflowLayout.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WorkflowLayout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WorkflowLayout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowLayout.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
statusTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
statusDetail: jspb.Message.getFieldWithDefault(msg, 3, ""),
currentStep: jspb.Message.getFieldWithDefault(msg, 4, 0),
stepsList: jspb.Message.toObjectList(msg.getStepsList(),
    proto.skills.WorkflowStep.toObject, includeInstance),
pagesList: jspb.Message.toObjectList(msg.getPagesList(),
    proto.skills.WorkflowSettingsPage.toObject, includeInstance),
screen: (f = msg.getScreen()) && proto.skills.WorkflowScreen.toObject(includeInstance, f),
request: (f = msg.getRequest()) && proto.skills.WorkflowMenuDisplayStateRequest.toObject(includeInstance, f),
hud: (f = msg.getHud()) && proto.skills.WorkflowHUD.toObject(includeInstance, f),
headerAccessoryButton: (f = msg.getHeaderAccessoryButton()) && pbtypes_gen_skills_ui_button_pb.UiButton.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WorkflowLayout}
 */
proto.skills.WorkflowLayout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WorkflowLayout;
  return proto.skills.WorkflowLayout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WorkflowLayout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WorkflowLayout}
 */
proto.skills.WorkflowLayout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusDetail(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentStep(value);
      break;
    case 5:
      var value = new proto.skills.WorkflowStep;
      reader.readMessage(value,proto.skills.WorkflowStep.deserializeBinaryFromReader);
      msg.addSteps(value);
      break;
    case 6:
      var value = new proto.skills.WorkflowSettingsPage;
      reader.readMessage(value,proto.skills.WorkflowSettingsPage.deserializeBinaryFromReader);
      msg.addPages(value);
      break;
    case 7:
      var value = new proto.skills.WorkflowScreen;
      reader.readMessage(value,proto.skills.WorkflowScreen.deserializeBinaryFromReader);
      msg.setScreen(value);
      break;
    case 8:
      var value = new proto.skills.WorkflowMenuDisplayStateRequest;
      reader.readMessage(value,proto.skills.WorkflowMenuDisplayStateRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 9:
      var value = new proto.skills.WorkflowHUD;
      reader.readMessage(value,proto.skills.WorkflowHUD.deserializeBinaryFromReader);
      msg.setHud(value);
      break;
    case 10:
      var value = new pbtypes_gen_skills_ui_button_pb.UiButton;
      reader.readMessage(value,pbtypes_gen_skills_ui_button_pb.UiButton.deserializeBinaryFromReader);
      msg.setHeaderAccessoryButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WorkflowLayout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WorkflowLayout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WorkflowLayout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WorkflowLayout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatusTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusDetail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrentStep();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.skills.WorkflowStep.serializeBinaryToWriter
    );
  }
  f = message.getPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.skills.WorkflowSettingsPage.serializeBinaryToWriter
    );
  }
  f = message.getScreen();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.skills.WorkflowScreen.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.skills.WorkflowMenuDisplayStateRequest.serializeBinaryToWriter
    );
  }
  f = message.getHud();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.skills.WorkflowHUD.serializeBinaryToWriter
    );
  }
  f = message.getHeaderAccessoryButton();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      pbtypes_gen_skills_ui_button_pb.UiButton.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.WorkflowLayout.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WorkflowLayout} returns this
 */
proto.skills.WorkflowLayout.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string status_title = 2;
 * @return {string}
 */
proto.skills.WorkflowLayout.prototype.getStatusTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.WorkflowLayout} returns this
 */
proto.skills.WorkflowLayout.prototype.setStatusTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status_detail = 3;
 * @return {string}
 */
proto.skills.WorkflowLayout.prototype.getStatusDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.WorkflowLayout} returns this
 */
proto.skills.WorkflowLayout.prototype.setStatusDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 current_step = 4;
 * @return {number}
 */
proto.skills.WorkflowLayout.prototype.getCurrentStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WorkflowLayout} returns this
 */
proto.skills.WorkflowLayout.prototype.setCurrentStep = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated WorkflowStep steps = 5;
 * @return {!Array<!proto.skills.WorkflowStep>}
 */
proto.skills.WorkflowLayout.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.skills.WorkflowStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.WorkflowStep, 5));
};


/**
 * @param {!Array<!proto.skills.WorkflowStep>} value
 * @return {!proto.skills.WorkflowLayout} returns this
*/
proto.skills.WorkflowLayout.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.skills.WorkflowStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.WorkflowStep}
 */
proto.skills.WorkflowLayout.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.skills.WorkflowStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.WorkflowLayout} returns this
 */
proto.skills.WorkflowLayout.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};


/**
 * repeated WorkflowSettingsPage pages = 6;
 * @return {!Array<!proto.skills.WorkflowSettingsPage>}
 */
proto.skills.WorkflowLayout.prototype.getPagesList = function() {
  return /** @type{!Array<!proto.skills.WorkflowSettingsPage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.WorkflowSettingsPage, 6));
};


/**
 * @param {!Array<!proto.skills.WorkflowSettingsPage>} value
 * @return {!proto.skills.WorkflowLayout} returns this
*/
proto.skills.WorkflowLayout.prototype.setPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.skills.WorkflowSettingsPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.WorkflowSettingsPage}
 */
proto.skills.WorkflowLayout.prototype.addPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.skills.WorkflowSettingsPage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.WorkflowLayout} returns this
 */
proto.skills.WorkflowLayout.prototype.clearPagesList = function() {
  return this.setPagesList([]);
};


/**
 * optional WorkflowScreen screen = 7;
 * @return {?proto.skills.WorkflowScreen}
 */
proto.skills.WorkflowLayout.prototype.getScreen = function() {
  return /** @type{?proto.skills.WorkflowScreen} */ (
    jspb.Message.getWrapperField(this, proto.skills.WorkflowScreen, 7));
};


/**
 * @param {?proto.skills.WorkflowScreen|undefined} value
 * @return {!proto.skills.WorkflowLayout} returns this
*/
proto.skills.WorkflowLayout.prototype.setScreen = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowLayout} returns this
 */
proto.skills.WorkflowLayout.prototype.clearScreen = function() {
  return this.setScreen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowLayout.prototype.hasScreen = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional WorkflowMenuDisplayStateRequest request = 8;
 * @return {?proto.skills.WorkflowMenuDisplayStateRequest}
 */
proto.skills.WorkflowLayout.prototype.getRequest = function() {
  return /** @type{?proto.skills.WorkflowMenuDisplayStateRequest} */ (
    jspb.Message.getWrapperField(this, proto.skills.WorkflowMenuDisplayStateRequest, 8));
};


/**
 * @param {?proto.skills.WorkflowMenuDisplayStateRequest|undefined} value
 * @return {!proto.skills.WorkflowLayout} returns this
*/
proto.skills.WorkflowLayout.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowLayout} returns this
 */
proto.skills.WorkflowLayout.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowLayout.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional WorkflowHUD hud = 9;
 * @return {?proto.skills.WorkflowHUD}
 */
proto.skills.WorkflowLayout.prototype.getHud = function() {
  return /** @type{?proto.skills.WorkflowHUD} */ (
    jspb.Message.getWrapperField(this, proto.skills.WorkflowHUD, 9));
};


/**
 * @param {?proto.skills.WorkflowHUD|undefined} value
 * @return {!proto.skills.WorkflowLayout} returns this
*/
proto.skills.WorkflowLayout.prototype.setHud = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowLayout} returns this
 */
proto.skills.WorkflowLayout.prototype.clearHud = function() {
  return this.setHud(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowLayout.prototype.hasHud = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional UiButton header_accessory_button = 10;
 * @return {?proto.skills.UiButton}
 */
proto.skills.WorkflowLayout.prototype.getHeaderAccessoryButton = function() {
  return /** @type{?proto.skills.UiButton} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_ui_button_pb.UiButton, 10));
};


/**
 * @param {?proto.skills.UiButton|undefined} value
 * @return {!proto.skills.WorkflowLayout} returns this
*/
proto.skills.WorkflowLayout.prototype.setHeaderAccessoryButton = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WorkflowLayout} returns this
 */
proto.skills.WorkflowLayout.prototype.clearHeaderAccessoryButton = function() {
  return this.setHeaderAccessoryButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WorkflowLayout.prototype.hasHeaderAccessoryButton = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * @enum {number}
 */
proto.skills.AttributeStyle = {
  INVALID: 0,
  NORMAL: 1,
  WARNING: 2,
  DANGER: 3
};

/**
 * @enum {number}
 */
proto.skills.WorkflowIconType = {
  UNSPECIFIED: 0,
  STATIC_IMAGE: 1,
  LOTTIE_ANIMATION: 2
};

/**
 * @enum {number}
 */
proto.skills.WorkflowMenuDisplayPreference = {
  PREF_UNSPECIFIED: 0,
  PREF_SKILL_OWNED: 1,
  PREF_CLIENT_OWNED: 2
};

/**
 * @enum {number}
 */
proto.skills.WorkflowMenuDisplayState = {
  DISPLAY_UNSPECIFIED: 0,
  DISPLAY_MAXIMIZED: 1,
  DISPLAY_MINIMIZED: 2
};

goog.object.extend(exports, proto.skills);
