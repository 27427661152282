// source: pbtypes/cloud/services/skygateway/cloud_node.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_infrastructure_remote_streaming_livekit_streaming_pb = require('../../../../pbtypes/infrastructure/remote_streaming/livekit_streaming_pb.js');
goog.object.extend(proto, pbtypes_infrastructure_remote_streaming_livekit_streaming_pb);
var pbtypes_gen_flight_connect_device_node_health_pb = require('../../../../pbtypes/gen/flight_connect/device_node_health_pb.js');
goog.object.extend(proto, pbtypes_gen_flight_connect_device_node_health_pb);
goog.exportSymbol('proto.skygateway.CloudNodeWrapper', null, global);
goog.exportSymbol('proto.skygateway.CloudNodeWrapper.KindCase', null, global);
goog.exportSymbol('proto.skygateway.DataChannelPacket', null, global);
goog.exportSymbol('proto.skygateway.DataChannelPacket.Type', null, global);
goog.exportSymbol('proto.skygateway.IOTSkybusWrapper', null, global);
goog.exportSymbol('proto.skygateway.LiveKitWrapper', null, global);
goog.exportSymbol('proto.skygateway.LiveKitWrapper.KindCase', null, global);
goog.exportSymbol('proto.skygateway.TrackPacket', null, global);
goog.exportSymbol('proto.skygateway.TrackPacket.Type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skygateway.CloudNodeWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skygateway.CloudNodeWrapper.oneofGroups_);
};
goog.inherits(proto.skygateway.CloudNodeWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skygateway.CloudNodeWrapper.displayName = 'proto.skygateway.CloudNodeWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skygateway.IOTSkybusWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skygateway.IOTSkybusWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skygateway.IOTSkybusWrapper.displayName = 'proto.skygateway.IOTSkybusWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skygateway.LiveKitWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skygateway.LiveKitWrapper.oneofGroups_);
};
goog.inherits(proto.skygateway.LiveKitWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skygateway.LiveKitWrapper.displayName = 'proto.skygateway.LiveKitWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skygateway.DataChannelPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skygateway.DataChannelPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skygateway.DataChannelPacket.displayName = 'proto.skygateway.DataChannelPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skygateway.TrackPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skygateway.TrackPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skygateway.TrackPacket.displayName = 'proto.skygateway.TrackPacket';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skygateway.CloudNodeWrapper.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.skygateway.CloudNodeWrapper.KindCase = {
  KIND_NOT_SET: 0,
  HEALTH_SUMMARY: 1,
  DATAGRAM: 2
};

/**
 * @return {proto.skygateway.CloudNodeWrapper.KindCase}
 */
proto.skygateway.CloudNodeWrapper.prototype.getKindCase = function() {
  return /** @type {proto.skygateway.CloudNodeWrapper.KindCase} */(jspb.Message.computeOneofCase(this, proto.skygateway.CloudNodeWrapper.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skygateway.CloudNodeWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.skygateway.CloudNodeWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skygateway.CloudNodeWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skygateway.CloudNodeWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
healthSummary: (f = msg.getHealthSummary()) && pbtypes_gen_flight_connect_device_node_health_pb.DeviceNodeHealth.toObject(includeInstance, f),
datagram: msg.getDatagram_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skygateway.CloudNodeWrapper}
 */
proto.skygateway.CloudNodeWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skygateway.CloudNodeWrapper;
  return proto.skygateway.CloudNodeWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skygateway.CloudNodeWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skygateway.CloudNodeWrapper}
 */
proto.skygateway.CloudNodeWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_flight_connect_device_node_health_pb.DeviceNodeHealth;
      reader.readMessage(value,pbtypes_gen_flight_connect_device_node_health_pb.DeviceNodeHealth.deserializeBinaryFromReader);
      msg.setHealthSummary(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDatagram(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skygateway.CloudNodeWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skygateway.CloudNodeWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skygateway.CloudNodeWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skygateway.CloudNodeWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHealthSummary();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_flight_connect_device_node_health_pb.DeviceNodeHealth.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional flight_connect.DeviceNodeHealth health_summary = 1;
 * @return {?proto.flight_connect.DeviceNodeHealth}
 */
proto.skygateway.CloudNodeWrapper.prototype.getHealthSummary = function() {
  return /** @type{?proto.flight_connect.DeviceNodeHealth} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_flight_connect_device_node_health_pb.DeviceNodeHealth, 1));
};


/**
 * @param {?proto.flight_connect.DeviceNodeHealth|undefined} value
 * @return {!proto.skygateway.CloudNodeWrapper} returns this
*/
proto.skygateway.CloudNodeWrapper.prototype.setHealthSummary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.skygateway.CloudNodeWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skygateway.CloudNodeWrapper} returns this
 */
proto.skygateway.CloudNodeWrapper.prototype.clearHealthSummary = function() {
  return this.setHealthSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skygateway.CloudNodeWrapper.prototype.hasHealthSummary = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes datagram = 2;
 * @return {!(string|Uint8Array)}
 */
proto.skygateway.CloudNodeWrapper.prototype.getDatagram = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes datagram = 2;
 * This is a type-conversion wrapper around `getDatagram()`
 * @return {string}
 */
proto.skygateway.CloudNodeWrapper.prototype.getDatagram_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDatagram()));
};


/**
 * optional bytes datagram = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDatagram()`
 * @return {!Uint8Array}
 */
proto.skygateway.CloudNodeWrapper.prototype.getDatagram_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDatagram()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.skygateway.CloudNodeWrapper} returns this
 */
proto.skygateway.CloudNodeWrapper.prototype.setDatagram = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.skygateway.CloudNodeWrapper.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.skygateway.CloudNodeWrapper} returns this
 */
proto.skygateway.CloudNodeWrapper.prototype.clearDatagram = function() {
  return jspb.Message.setOneofField(this, 2, proto.skygateway.CloudNodeWrapper.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skygateway.CloudNodeWrapper.prototype.hasDatagram = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skygateway.IOTSkybusWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.skygateway.IOTSkybusWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skygateway.IOTSkybusWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skygateway.IOTSkybusWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
channel: jspb.Message.getFieldWithDefault(msg, 2, ""),
message: msg.getMessage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skygateway.IOTSkybusWrapper}
 */
proto.skygateway.IOTSkybusWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skygateway.IOTSkybusWrapper;
  return proto.skygateway.IOTSkybusWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skygateway.IOTSkybusWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skygateway.IOTSkybusWrapper}
 */
proto.skygateway.IOTSkybusWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skygateway.IOTSkybusWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skygateway.IOTSkybusWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skygateway.IOTSkybusWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skygateway.IOTSkybusWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.skygateway.IOTSkybusWrapper.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skygateway.IOTSkybusWrapper} returns this
 */
proto.skygateway.IOTSkybusWrapper.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string channel = 2;
 * @return {string}
 */
proto.skygateway.IOTSkybusWrapper.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skygateway.IOTSkybusWrapper} returns this
 */
proto.skygateway.IOTSkybusWrapper.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes message = 3;
 * @return {!(string|Uint8Array)}
 */
proto.skygateway.IOTSkybusWrapper.prototype.getMessage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes message = 3;
 * This is a type-conversion wrapper around `getMessage()`
 * @return {string}
 */
proto.skygateway.IOTSkybusWrapper.prototype.getMessage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMessage()));
};


/**
 * optional bytes message = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMessage()`
 * @return {!Uint8Array}
 */
proto.skygateway.IOTSkybusWrapper.prototype.getMessage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMessage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.skygateway.IOTSkybusWrapper} returns this
 */
proto.skygateway.IOTSkybusWrapper.prototype.setMessage = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skygateway.LiveKitWrapper.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.skygateway.LiveKitWrapper.KindCase = {
  KIND_NOT_SET: 0,
  CONFIG: 2,
  DATA: 3,
  TRACK: 4
};

/**
 * @return {proto.skygateway.LiveKitWrapper.KindCase}
 */
proto.skygateway.LiveKitWrapper.prototype.getKindCase = function() {
  return /** @type {proto.skygateway.LiveKitWrapper.KindCase} */(jspb.Message.computeOneofCase(this, proto.skygateway.LiveKitWrapper.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skygateway.LiveKitWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.skygateway.LiveKitWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skygateway.LiveKitWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skygateway.LiveKitWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
config: (f = msg.getConfig()) && pbtypes_infrastructure_remote_streaming_livekit_streaming_pb.LivekitRoomInfo.toObject(includeInstance, f),
data: (f = msg.getData()) && proto.skygateway.DataChannelPacket.toObject(includeInstance, f),
track: (f = msg.getTrack()) && proto.skygateway.TrackPacket.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skygateway.LiveKitWrapper}
 */
proto.skygateway.LiveKitWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skygateway.LiveKitWrapper;
  return proto.skygateway.LiveKitWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skygateway.LiveKitWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skygateway.LiveKitWrapper}
 */
proto.skygateway.LiveKitWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = new pbtypes_infrastructure_remote_streaming_livekit_streaming_pb.LivekitRoomInfo;
      reader.readMessage(value,pbtypes_infrastructure_remote_streaming_livekit_streaming_pb.LivekitRoomInfo.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 3:
      var value = new proto.skygateway.DataChannelPacket;
      reader.readMessage(value,proto.skygateway.DataChannelPacket.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 4:
      var value = new proto.skygateway.TrackPacket;
      reader.readMessage(value,proto.skygateway.TrackPacket.deserializeBinaryFromReader);
      msg.setTrack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skygateway.LiveKitWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skygateway.LiveKitWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skygateway.LiveKitWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skygateway.LiveKitWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_infrastructure_remote_streaming_livekit_streaming_pb.LivekitRoomInfo.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skygateway.DataChannelPacket.serializeBinaryToWriter
    );
  }
  f = message.getTrack();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skygateway.TrackPacket.serializeBinaryToWriter
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.skygateway.LiveKitWrapper.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skygateway.LiveKitWrapper} returns this
 */
proto.skygateway.LiveKitWrapper.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional remote_streaming.LivekitRoomInfo config = 2;
 * @return {?proto.remote_streaming.LivekitRoomInfo}
 */
proto.skygateway.LiveKitWrapper.prototype.getConfig = function() {
  return /** @type{?proto.remote_streaming.LivekitRoomInfo} */ (
    jspb.Message.getWrapperField(this, pbtypes_infrastructure_remote_streaming_livekit_streaming_pb.LivekitRoomInfo, 2));
};


/**
 * @param {?proto.remote_streaming.LivekitRoomInfo|undefined} value
 * @return {!proto.skygateway.LiveKitWrapper} returns this
*/
proto.skygateway.LiveKitWrapper.prototype.setConfig = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.skygateway.LiveKitWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skygateway.LiveKitWrapper} returns this
 */
proto.skygateway.LiveKitWrapper.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skygateway.LiveKitWrapper.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DataChannelPacket data = 3;
 * @return {?proto.skygateway.DataChannelPacket}
 */
proto.skygateway.LiveKitWrapper.prototype.getData = function() {
  return /** @type{?proto.skygateway.DataChannelPacket} */ (
    jspb.Message.getWrapperField(this, proto.skygateway.DataChannelPacket, 3));
};


/**
 * @param {?proto.skygateway.DataChannelPacket|undefined} value
 * @return {!proto.skygateway.LiveKitWrapper} returns this
*/
proto.skygateway.LiveKitWrapper.prototype.setData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.skygateway.LiveKitWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skygateway.LiveKitWrapper} returns this
 */
proto.skygateway.LiveKitWrapper.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skygateway.LiveKitWrapper.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TrackPacket track = 4;
 * @return {?proto.skygateway.TrackPacket}
 */
proto.skygateway.LiveKitWrapper.prototype.getTrack = function() {
  return /** @type{?proto.skygateway.TrackPacket} */ (
    jspb.Message.getWrapperField(this, proto.skygateway.TrackPacket, 4));
};


/**
 * @param {?proto.skygateway.TrackPacket|undefined} value
 * @return {!proto.skygateway.LiveKitWrapper} returns this
*/
proto.skygateway.LiveKitWrapper.prototype.setTrack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.skygateway.LiveKitWrapper.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skygateway.LiveKitWrapper} returns this
 */
proto.skygateway.LiveKitWrapper.prototype.clearTrack = function() {
  return this.setTrack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skygateway.LiveKitWrapper.prototype.hasTrack = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skygateway.DataChannelPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.skygateway.DataChannelPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skygateway.DataChannelPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skygateway.DataChannelPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
identity: jspb.Message.getFieldWithDefault(msg, 2, ""),
name: jspb.Message.getFieldWithDefault(msg, 3, ""),
payload: msg.getPayload_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skygateway.DataChannelPacket}
 */
proto.skygateway.DataChannelPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skygateway.DataChannelPacket;
  return proto.skygateway.DataChannelPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skygateway.DataChannelPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skygateway.DataChannelPacket}
 */
proto.skygateway.DataChannelPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.skygateway.DataChannelPacket.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skygateway.DataChannelPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skygateway.DataChannelPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skygateway.DataChannelPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skygateway.DataChannelPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIdentity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skygateway.DataChannelPacket.Type = {
  UNKNOWN: 0,
  TUNNEL: 1,
  BROADCAST: 2,
  CONTROL: 3
};

/**
 * optional Type type = 1;
 * @return {!proto.skygateway.DataChannelPacket.Type}
 */
proto.skygateway.DataChannelPacket.prototype.getType = function() {
  return /** @type {!proto.skygateway.DataChannelPacket.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.skygateway.DataChannelPacket.Type} value
 * @return {!proto.skygateway.DataChannelPacket} returns this
 */
proto.skygateway.DataChannelPacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string identity = 2;
 * @return {string}
 */
proto.skygateway.DataChannelPacket.prototype.getIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skygateway.DataChannelPacket} returns this
 */
proto.skygateway.DataChannelPacket.prototype.setIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.skygateway.DataChannelPacket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skygateway.DataChannelPacket} returns this
 */
proto.skygateway.DataChannelPacket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bytes payload = 4;
 * @return {!(string|Uint8Array)}
 */
proto.skygateway.DataChannelPacket.prototype.getPayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes payload = 4;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.skygateway.DataChannelPacket.prototype.getPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayload()));
};


/**
 * optional bytes payload = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.skygateway.DataChannelPacket.prototype.getPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.skygateway.DataChannelPacket} returns this
 */
proto.skygateway.DataChannelPacket.prototype.setPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skygateway.TrackPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.skygateway.TrackPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skygateway.TrackPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skygateway.TrackPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
frameNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
utime: jspb.Message.getFieldWithDefault(msg, 4, 0),
payload: msg.getPayload_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skygateway.TrackPacket}
 */
proto.skygateway.TrackPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skygateway.TrackPacket;
  return proto.skygateway.TrackPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skygateway.TrackPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skygateway.TrackPacket}
 */
proto.skygateway.TrackPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.skygateway.TrackPacket.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUtime(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skygateway.TrackPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skygateway.TrackPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skygateway.TrackPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skygateway.TrackPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getUtime();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.skygateway.TrackPacket.Type = {
  UNKNOWN: 0,
  VIDEO: 1,
  AUDIO: 2
};

/**
 * optional Type type = 1;
 * @return {!proto.skygateway.TrackPacket.Type}
 */
proto.skygateway.TrackPacket.prototype.getType = function() {
  return /** @type {!proto.skygateway.TrackPacket.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.skygateway.TrackPacket.Type} value
 * @return {!proto.skygateway.TrackPacket} returns this
 */
proto.skygateway.TrackPacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.skygateway.TrackPacket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skygateway.TrackPacket} returns this
 */
proto.skygateway.TrackPacket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 frame_number = 3;
 * @return {number}
 */
proto.skygateway.TrackPacket.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skygateway.TrackPacket} returns this
 */
proto.skygateway.TrackPacket.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 utime = 4;
 * @return {number}
 */
proto.skygateway.TrackPacket.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skygateway.TrackPacket} returns this
 */
proto.skygateway.TrackPacket.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bytes payload = 5;
 * @return {!(string|Uint8Array)}
 */
proto.skygateway.TrackPacket.prototype.getPayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes payload = 5;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.skygateway.TrackPacket.prototype.getPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayload()));
};


/**
 * optional bytes payload = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.skygateway.TrackPacket.prototype.getPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.skygateway.TrackPacket} returns this
 */
proto.skygateway.TrackPacket.prototype.setPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


goog.object.extend(exports, proto.skygateway);
